import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLogin: true,
    credntials: {
        login: "",
        password: ""
    },
    resetCode: null,
    userName: null,
    type: "carriers",
}
const name = 'login'

export const loginSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setIsLogin: (state, action) => {
            state.isLogin = action.payload
        },
        setEmail: (state, action) => {
            state.credntials.login = action.payload
        },
        setPass: (state, action) => {
            state.credntials.password = action.payload
        },
        setResetCode: (state, action) => {
            state.resetCode = action.payload
        },
        clearResetCode: (state) => {
            state.resetCode = null
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setUserType: (state, action) => {
            state.type = action.payload
        },
    }
})

export const { setIsLogin, setEmail, setPass, setResetCode, setUserName, setUserType, clearResetCode } = loginSlice.actions

export const selectIsLogin = (state) => state.Login.isLogin
export const selectCredentials = (state) => state.Login.credntials
export const selectResetCode = (state) => state.Login.resetCode
export const selectUserName = (state) => state.Login.userName
export const selectUserType = (state) => state.Login.type



export default loginSlice.reducer