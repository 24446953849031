import amplitude from "amplitude-js";
import moment from "moment";

export function getCampaignTypeById(id) {
    const types = [
        "Retention",
        "Nurturing",
        "Custom",
        "Policy Review",
        "New Product Introduction",
        "Other",
    ]
    if(types.length >= id) {
        return types[id-1]
    }
    return ""
}

export function getCampaignTypeId(name) {
    const types = [
        "Retention",
        "Nurturing",
        "Custom",
        "Policy Review",
        "New Product Introduction",
        "Other",
    ]
    return types.indexOf(name) + 1  
}


export function getCampaignProviderId(name) {
    const types = [
        "Remark",
        "HubSpot",
        "SalesForce",
        "SmartOffice",
        "Zinnia",
        "Atidot Life",
    ]
    return types.indexOf(name) + 1
}

export function getTrimedValue(str) {
    const comma = str.indexOf(".")

    return str.length >= 6 ? str.slice(0, comma ? comma+2 : 5) + str[str.length-1] : str
}

export function calculateFunnelData(arr) {
    let email = 0
    let open = 0
    let click = 0
    let bookings = 0
    let meetings = 0
    let submited = 0
    let deals = 0
    arr?.filter(({status}) => status !== 100).forEach(({ count }) => email += count);
    arr
        ?.filter(({status}) => status > 200)
        .forEach(({ count }) => open += count)
    arr
        ?.filter(({status}) => status > 300)
        .forEach(({ count }) => click += count)
    arr
        ?.filter(({status}) => status > 400)
        .forEach(({ count }) => bookings += count)
    arr
        ?.filter(({status}) => status > 500)
        .forEach(({ count }) => meetings += count)
    arr
        ?.filter(({status}) => status > 700)
        .forEach(({ count }) => submited += count)
        deals = arr?.find(({status}) => status === 800)?.count || 0

    return {
        email,
        open,
        click,
        bookings,
        meetings,
        "in-pipeline": submited,
        deals
    } 
}

export function getConversionHistoryData (metaData) {
    const { open, click, bookings, meetings, deals } = metaData
    const submited = metaData["in-pipeline"]
    const arr = []
    for (let index = 0; index < 7; index++) {
        arr.push({
            "date": `${index+1} Jun`,
            "open": open + 8*index,
            "click": click + 7*index,
            "bookings": bookings + 5*index,
            "meetings": meetings + 3*index,
            "inpipeline": submited + 2*index,
            "deals": deals + index
        })
    }
    return arr;
}

export function getLabelBySearchType (type) {
    const labels = {
        "name": "Name",
        "created_at": "Date",
        "num_of_policies": "No. Policies",
    }

    return labels[type] ? labels[type] : ""
}

export function isEqual(a, b) {
    if (a.length !== b.length)
      return false;
    else {
  
      for (var i = 0; i < a.length; i++)
        if (a[i] !== b[i])
          return false;
      return true;
    }
}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }
  
export function isObject(object) {
  return object != null && typeof object === 'object';
} 

export function getDate(string) {
  const date = new Date(string);
  const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
  const day = date.getDate() > 9 ? date.getDate() : "0"+date.getDate();
  return `${month}/${day}/${date.getFullYear()}`
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

        return JSON.parse(jsonPayload);
};

export const get_permission_limit = (userPermissions, permission_name) => userPermissions?.filter(p => p.action === permission_name)[0]?.limit

export const check_permission_limit = (userPermissions, permission_name, current_amount) => {
    const permissionLimit = get_permission_limit(userPermissions, permission_name)
    return (permissionLimit === null || current_amount < permissionLimit)
}


class Amplitude {
    instance = amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY)

    _setUser(user) {
        amplitude.getInstance().setUserId(user)
    }

    _throwEvent(event, params = {}) {
        amplitude.getInstance().logEvent(`P_${event.toUpperCase()}`, params)
    }
}

export const amplitudeService = new Amplitude()


export const processUserLoginData = (response, dispatch, setUserInitials, setUserRole, setUserPermissions, setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType) => {
    localStorage.setItem("token", response.data["access_token"])
    localStorage.setItem("refresh", response.data["refresh_token"])
    const payload = parseJwt(response.data["access_token"])
    dispatch(setCurrentUser(payload))
    localStorage.setItem("userName", payload['username'] || "")
    localStorage.setItem("userID", payload['user_id'])
    localStorage.setItem("companyID", payload['company_id'])
    localStorage.setItem("accountType", payload['account_type'])

    dispatch(setUserInitials(
        payload['username']?.includes("americanequity") ? "AE" : payload['username']?.charAt().toUpperCase()
    ));

    payload['roles'] && dispatch(setUserRole(payload['roles']))
    payload['permissions'] && dispatch(setUserPermissions(payload['permissions']))
    payload['user_limit'] && dispatch(setUserLimit(payload['user_limit']))
    payload["company_name"] && dispatch(setUserCompany(payload["company_name"]))
    payload["account_type"] && dispatch(setAccountType(payload["account_type"]))

    amplitudeService._setUser(payload['user_id'])
    
    dispatch(setMenuDisabled(payload["company_name"] === "platform-anonym"))

    return payload
}

export const reverseFormatToBytes = (size) => {

    if (size === "0B") return 0

    const k = 1000;
    var regex = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
    var number = size.match(regex)[0];
    var unit = size.match(regex)[1];

    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (String(unit).length === 1) {
      return parseFloat(number)
    } else {
      if (String(unit) === "KB") {
        return parseFloat(Math.floor(parseFloat(number) * (Math.pow(100, sizes.findIndex(el => el === unit)))))
      } else {
        return parseFloat(Math.floor(parseFloat(number) * (Math.pow(k, sizes.findIndex(el => el === unit)))))
      }

    }
  }

const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
];

export const nFormatter = (num, digits, currency = "") => {
    if (!num) num=0
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    /*eslint no-useless-escape: "off"*/
    const regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (digits || -1) + '})?');
    const trimedNumber = parseFloat((num / si[i].value).toString().match(regex)[0])
    return currency + trimedNumber.toString().replace(rx, "$1") + si[i].symbol;
}

export const nBuilder = (formatedNumber) => {
    let returnValue = formatedNumber
    si.filter(({ symbol }) => symbol).forEach((format) => {
        if(formatedNumber.includes(format.symbol)) { 
            returnValue = formatedNumber.replace(format.symbol, `${format.value}`.slice(1))}
    })
    return parseInt(returnValue.replace('.', ''))
}

export const getCountryCurrencySymbol = () => {
    const currencySymboles = {
        "US": "$",
        "IL": "$",
        "ES": "€",
        "FR": "€",
        "GB": "£",
    }
    const symbol = localStorage.getItem("token") ? currencySymboles[parseJwt(localStorage.getItem("token"))["country_code"]] : "$"

    return symbol ? symbol : ""
}

export const getNextMonths = (data, months = 12) => {
    const timelineKeys = data?.potential_premium_by_date ? Object.keys(data.potential_premium_by_date) : null
    const firstMonth = timelineKeys ? timelineKeys[timelineKeys.length < months ? 0 : timelineKeys.length-months] : null
    const date = timelineKeys ? moment(firstMonth) : moment()
    const twelveMonths = [
        date.format("MMM YYYY")
    ]
    for (let index = 0; index < months-1; index++) {
        twelveMonths.push(date.set('month', date.get('month')+1).format("MMM YYYY"))
    }
    return twelveMonths
}

export const getLastQuarters = () => {
    /* 
    - Generate last 6 quarter from today
    */
    let date = moment();
    let sixQuarters = [];
    [...Array(6)].forEach( () => {
        sixQuarters.push('Q' + date.format("Q_YYYY"))
        date.set('month', date.get('month')-3)
    });
    return sixQuarters
}

export const getTableauDashboard = (url) => {
    /* 
    - 
    */
}

export const validateValue = (item) => {
  if (parseInt(item) && item >= 0) {
    return true
  }
  return false
}

export const scrollTo = (position) => {
    window.scrollTo({
      top: position,
      behavior: "smooth"
    });
}; 

export const precisionKeys = {
    "low": 'low',
    "mid": 'medium',
    "high": 'high',
    "high+": 'super_high'
}  

export const getMoneyQueryFromAction = (action) => {

    if (action.payload["All"]) { return null}
  
    const currency = '$'
    const clean = value => value.replace(currency, "").replace("<", "").replace("K", "000")
    const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
    
    const query = []
    selectedEntries.forEach((filter) => {
      const value = filter[0]
      if (value.includes("-")) {
        const range = value.split("-")
        query.push({
          "operator": 'between',
          "value": `${nBuilder(clean(range[0]))},${nBuilder(clean(range[1]))}`
        })
      } else if (value[0] === "<") {
        query.push({"operator": 'lte', "value": `${nBuilder(clean(value))}`})
      } else if (value.includes("+")) {
        query.push({"operator": 'gte', "value": `${nBuilder(clean(value))}`})
      }
    })
  
    return query
}
  
export const getPercentageQueryFromAction = (action) => {
  
      if (action.payload["All"]) { return null}
    
      const convert = value => parseFloat(value.replace('%', "").replace("<", ""))
      const selectedEntries = Object.entries(action.payload).filter((item) => item[1])
      
      const query = []
      selectedEntries.forEach((filter) => {
        const value = filter[0]
        if (value.includes("-")) {
          const range = value.split("-")
          query.push({
            "operator": 'between',
            "value": `${convert(range[0])},${convert(range[1])}`
          })
        } else if (value[0] === "<") {
          query.push({"operator": 'lte', "value": `${convert(value)}`})
        } else if (value.includes("+")) {
          query.push({"operator": 'gte', "value": `${convert(value)}`})
        }
      })
    
      return query
}
  
export const getQueryFromPlainAction = (action) => {
    const query = []
  
    const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
  
    if(selected.length > 0) {
        selected.forEach((key) => {
  
        if (!query.find(({value}) => value === key)) {
            query.push({
            "operator": "equals",
            "value": key
          })
        }
        
      })
  
      return query
    }
  
    return null
}
  
  
export const getQueryFromPriority = (
  action, 
  longType = false, 
  replacement = {
    "high": 'H', "medium": "M", "low": "L"
  }
) => {
  
    const query = []
  
    const selected = Object.keys(action.payload).filter((key) => key !== "All" && action.payload[key])
    if(selected.length > 0) {
      selected.forEach((key) => {
  
        console.log(key);
      if (!query.find(({value}) => value === key)) {
          query.push({
          "operator": "equals",
          "value": longType ? key[0]?.toUpperCase()+key.slice(1, key?.length) : replacement[key]
        })
      }
      
    })
  
    return query
  }
  
  return null
}

export const addRealMonths = (date, count) => {
  const fm = moment(date).add(count, 'M');
  const fmEnd = moment(fm).endOf('month');
  return date.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;  
}