import React, { useState, useEffect, useRef }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Popover, Typography, makeStyles } from "@material-ui/core";
import { ArrowDropDown } from "@mui/icons-material";
import SelectFilter from '../../Filters/SelectFilter';

import RunHeaderItem from "../RunHeaderItem/RunHeaderItem";
import { selectGroup, setGroupByProject, setProjectIds } from "../../../Pages/BookAnalysis/BookAnalysisSlice";
import { amplitudeService } from "../../../global/helpers"

const useStyles = makeStyles(() => ({
    headerWrapper: { 
        maxWidth: "24%", 
        flexBasis: "24%", 
        cursor: "pointer", 
        display: "flex", 
        justifyContent: "space-between"
    },
    projectContaier: { 
        padding: "17px 24px 2px 22px"
    },
    projectItem: {
        paddingBottom: 18, 
        cursor: "pointer",
        "& .item--text": {
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 36,
            lineHeight: "42px",
            color: '#363ED3',
            cursor: "pointer",
            paddingRight: 15,
            minWidth: 49,
            textTransform: "capitalize"
        }
    },
    dropdownIconBox: {
        display: "flex",
        alignItems: "center",
        marginRight: 10,
        "& svg": {
            fill: '#6A6C71',
        }
    }

}))

const ProjectHeaderItem = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const headerRef = useRef()
    const containerRect = useRef()
    const selected = useSelector(selectGroup)

    const [openFilter, setOpenFilter] = useState(false);
    const [ defaultFilter, setDefaultFilter ] = useState({ "All": true })

    const handleFilterChange = (item) => {
        if (item !== 'all') {
            const projectName = props.projectNames.find(({ id }) => id === item)
            dispatch(setGroupByProject(projectName?.title))
            dispatch(setProjectIds([projectName?.id]))
        } else {
            dispatch(setGroupByProject('All'))
            dispatch(setProjectIds(props.projectNames.map(({ id }) => id)))
        }
        amplitudeService._throwEvent("PROJECTS_FILTER_CHANGE", {'value': item})
    }

    useEffect(() => {
        if (Object.keys(defaultFilter).length === 1) {
            const names = {
                "All": true,
              }
            props?.projectNames?.forEach((item) => Object.assign(names, {[item.title]: false}))
            setDefaultFilter(names)   
        }
    }, [props.projectNames, defaultFilter])

    return (
        <Grid className={classes.headerWrapper}>
            <SelectFilter
                title=""
                popoverText="Projects"
                customDropdown
                defaultValue={defaultFilter}
                reset={false}
                multiSelect={false}
                spacing={0}
                resetFilter={
                () => {
                    dispatch(setGroupByProject("All"))
                }
                }
                onChange={(value) => {
                    if (!value["All"]) {
                        const item = Object.keys(value).find((key) => value[key])
                        const project = props.projectNames.find(({ title }) => title === item)
                        dispatch(setGroupByProject(project.title))
                        dispatch(setProjectIds([project?.id]))
                    } else {
                        dispatch(setGroupByProject('All'))
                        dispatch(setProjectIds(props.projectNames.map(({ id }) => id)))
                    }
                }}
                dynamic
            >
                <RunHeaderItem
                reference={containerRect}
                {...props}
                value={selected}
                style={{ maxWidth: "unset", flexBasis: "unset", width: "100%" }}
                />
                <div className={classes.dropdownIconBox}>
                    <ArrowDropDown/>
                </div>  
            </SelectFilter>  
            <Popover
                open={openFilter}
                anchorEl={headerRef.current}
                onClose={() => setOpenFilter(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                style={{ marginTop: 14 }}
            >
                <Grid container direction="column" className={classes.projectContaier} style={{ width: containerRect?.current?.offsetWidth }}>
                    <Grid container justifyContent="flex-start" alignItems="center" key='all-projects' className={classes.projectItem} onClick={() => handleFilterChange('all')}>
                        <Typography className="item--text">
                            {props.projectNames?.length !== 1 ? "All" : props.projectNames[0]?.title}
                        </Typography>
                    </Grid>                    
                    {
                        props.projectNames.length > 1 && props.projectNames
                            ?.map((item) => {
                                return (
                                    <Grid container justifyContent="flex-start" alignItems="center" key={item.id} className={classes.projectItem} onClick={() => handleFilterChange(item.id)}>
                                        <Typography className="item--text">
                                            {item.title}
                                        </Typography>
                                    </Grid>
                                )
                        })
                    }
                </Grid> 
            </Popover>
        </Grid>
    )
}

export default ProjectHeaderItem