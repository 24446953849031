import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../../../Components/Demo/Header/styles";

export const useStyles = makeStyles(() => ({
    root: {
        '& thead th': {
         background: '#EEF0F4',
         border: '1px solid #fff',
         position: 'relative',
         fontWeight: 600,
         lineHeight: '12px',
         padding: '20px 10px',
        },
        '& th': {
            padding: '5px 10px',
            fontSize: '12px',
            fontFamily: 'Strawford, sans-serif',
        }
    },
    firstCol: {
        display: 'flex',
        '& p': {
            marginLeft: '8px',
            textDecoration: 'underline',
            color: '#363ED3',
        }
    },
    campItem: {
        textDecoration: 'underline',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontWeight: 700,
    },
    legendContainer: {
        position: 'absolute',
        right: '15px',
        bottom: '35px',
        background: '#F8F9FB',
        borderRadius: '4px',
        padding: '6px 8px',
        fontWeight: 700,
        fontSize: '12px',
        display: 'flex',
        '& div': {
            display: 'flex',
            fontWeight: 500,
            marginRight: '24px',
            '&:last-child': {
                marginRight: 0,
            }
        },
        '& p': {
            margin: '0 0 0 10px',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '60px 0',
    },
    prospects: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
    },
    totalTitlesWrapper: {
        fontFamily: 'Strawford, sans-serif',
        display: 'flex',
        gap: '30px',

        '& div': {
            fontSize: '12px',
            fontWeight: 700,
            '&.biggerSize span': {
                fontSize: '20px',
            }
        },
        '& p': {
            color: '#232033',
            fontSize: '12px',
            fontWeight: 400,
            margin: 0,
        },
        '& span': {
            color: '#232033',
            fontSize: '16px',
            fontWeight: 700,
        },
        '& span.colored': {
            color: '#363ED3',
        },
    },
    criteriaWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '60px',
        fontFamily: 'Strawford, sans-serif',
    },
    criteriaItem: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '6px 20px',
        marginLeft: '30px',
        width: '169px',
        '& span': {
            margin: 0,
            fontWeight: 700,
            fontSize: '16px',
            color: '#232033',
        },
        '& p': {
            margin: 0,
            fontWeight: 500,
            fontSize: '10px',
            color: '#6F7688',
        }
    },
    criteriaNumbersWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    popoverText: {
        fontSize: '11px',
        color: '#1C1C1C',
        padding: '8px',
    },
    filtersBlock: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    filtersBlockSide: {
        width: '50%',
        '& .filtersHeading': {
            background: '#F8F9FB',
            borderRight: '2px solid #FFFFFF',
            borderRadius: '8px 0px 0px 0px',
            padding: '14px 12px',
            display: 'flex',
        },
        '& h3': {
            fontWeight: 400,
            fontSize: '12px',
            color: '#232033',
            margin: '0 0 0 8px',
            textTransform: 'uppercase',
        }
    },
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: 'Strawford, sans-serif',
    },
    filterItem: {
        marginRight: '10px',
        marginTop: '10px',
    },
    clearFiltersContainer: {
        margin: '0 15px',
        color: '#363ED3',
        textDecoration: 'underline',
        fontSize: '12px',
        fontWeight: 700,
        textAlign: 'center',
        cursor: 'pointer',
        '& img': {
            marginRight: '5px',
        }
    },
    paginationContainer: {
        '& ul': {
            margin: '20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            listStyle: 'none',
            '& li': {
                cursor: 'pointer',
                fontSize: '14px',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '12px',
                borderRadius: '4px',
                '&:hover': {
                    background: 'rgba(222, 230, 240, 0.3)',
                },
                '&:last-child': {
                    margin: 0,
                },
                '&.selected, &.previous, &.next': {
                    background: 'rgba(222, 230, 240, 0.5)',
                },
                '&.next img': {
                    transform: 'rotate(180deg)',
                },
                '&.previous, &.next': {
                    color: '#363ED3',
                    fontWeight: 900,
                },
                '&.disabled': {
                    display: 'none',
                },
                '& a': {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }
        }
    },
    sortingHeadField: {
        display: 'flex',
        cursor: 'pointer',
        '& img': {
            marginLeft: '5px',
            '&.active': {
                transform: 'rotate(180deg)',
            }
        }
    }
}));

export const middleCellContent = {
    position: 'absolute',
    padding: '20px',
    top: '25px',
    background: '#EEF0F4',
    width: '100%',
    left: '0',
    zIndex: 1,
}

export const categoryHeadingItem = {
    background: '#F8F9FB !important',
    textTransform: 'uppercase',
    fontWeight: 700,
}

export const UIViewButton = styled(Button)({
    ...basicButtonStyles,
    fontSize: 16,
    padding: '25px 0',
    width: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#363ED3',
    borderColor: '#363ED3',
    color: '#FFF',
    '&:hover': {
        opacity: '0.9',
        backgroundColor: '#363ED3',
        borderColor: '#FFF',
        boxShadow: 'none',
    },
});

export const UIStartButton = styled(Button)({
    ...basicButtonStyles,
    fontSize: 14,
    padding: '25px 33px',
    width: 'auto',
    height: '44px',
    borderRadius: '4px',
    backgroundColor: '#0BCB47',
    borderColor: '#0BCB47',
    color: '#FFF',
    opacity: '0.4',
    '&:hover': {
        opacity: '0.4',
        backgroundColor: '#0BCB47',
        borderColor: '#0BCB47',
        boxShadow: 'none',
    },
});

export const UIExampleButton = styled(Button)({
    ...basicButtonStyles,
    fontSize: 14,
    padding: '25px 33px',
    width: 'auto',
    height: '44px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    borderColor: '#4AC670',
    color: '#4AC670',
    marginLeft: '15px',
    '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#4AC670',
        boxShadow: 'none',
    },
});