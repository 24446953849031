import React, { useEffect, useRef } from 'react';
import { Button, Grid, makeStyles, Popover, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import {ReactComponent as IconFolder} from '../../../Assets/icon-folder.svg'
import {ReactComponent as FileUploadedIcon} from '../../../Assets/icon-upload-success.svg' 
import {ReactComponent as UploadErrorIcon} from '../../../Assets/icon-upload-failed.svg' 
import {ReactComponent as ArrowIcon} from '../../../Assets/icon-arrow-left-alt.svg' 
//import {ReactComponent as SettingsIcon} from '../../../Assets/icon-preference.svg' 
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, setCsvInfo, setFileName} from '../../../Pages/NewProject/NewProjectSlice';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LinearProgress, OutlinedInput, Stack } from '@mui/material';
import newProjectApiService from '../../../Pages/NewProject/NewProjectService';
import clsx from 'clsx';
import { NextButton } from '../../ui/buttons';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    "& svg": {
      width: 74,
      height: 86
    }
  },
  uploadContainer:{
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 423 
  },
  uploadDone:{
    display: "flex",
    maxWidth: 999,
    margin: "auto 0",
    padding: "64px 0",
    background: "#FFFFFF",
    border: "1px solid #EBEFF6",
    borderRadius: 6,
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)"
  },
  dragnDropRoot:{
    minHeight: 388,
    maxWidth: 682,
    "& .MuiTypography-root": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19px",
      textAlign: "center"
    }
  },
  dragnDropText:{
      paddingTop: 43,
      paddingBottom: 15,
  },
  alertBox: {
    maxWidth: 371,
  },
  alertBoxRename: {
    marginTop: 16
  },
  loadingContainer: {
    paddingTop: 52,
    paddingBottom: 16,
    width: 371,
    "& .MuiTypography-root": {
      fontFamily: "Inter",
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "21px",
      textAlign: "center",
      textTransform: "uppercase"
    }
  },
  loadedAlert:{
    paddingTop: 16,
    paddingBottom: 25,
    "& .MuiTypography-root": {
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "21px",
      textAlign: "center"
    }
  },
  loadedAlertContainer:{
    "& .MuiTypography-root": {
      fontSize: 17,
      fontWeight: 500,
      lineHeight: "21px",
      textAlign: "left"
    }
  },
  progressBar:{
    color: "#242a8c",
    width:117,
    "& .MuiLinearProgress-root": {
      height:6, 
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#363ED3"
    }
  },
  progressBarError:{
    color: "#FF4B62",
    "& .MuiLinearProgress-root": {
      backgroundColor: "#FF4B62"
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#FF4B62"
    }
  },
  ButtonReupload: {
    color: "#fff",
    background: "#FFFFFF",
    border: "1px solid #0BCB47",
    width: 262,
    height: 50,
    borderRadius: 10,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center"
  },
  linkStyle: {
    color: "#363ED3",
    cursor:'pointer',
    textDecoration:'underline',
  },
  renamePopoverContainer: {
    width: 552,
    border: "1px solid #A2A0A0",
    borderRadius: 5,
    background: "#F8F9FB",
    padding: "58px 54px",
    lineHeight: "18px"
  },
  renamePopoverTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 6
  },
  renamePopoverText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#6A6C71"
  },
  renamePopoverStatus: {
    fontSize: 13,
    fontWeight: 400,
    color: "#6A6C71",
    marginBottom: 6
  },
  renamePopoverError: {
    color: "#D82832"
  },
  renameInputContainer: {
    paddingTop: 26,
  },
  renameControlsContainer: {
    paddingTop: 51
  },
  renameButton: {
    width: 148,
    height: 36,
    fontSize: 13,
    fontWeight: 400,
    borderRadius: 12,
    background: "#363ED3",
    color: "#FFF",
    "&.Mui-disabled": {
      background: "#E4E4E4",
      color: "#000",
    },
    "&:hover": {
      background: "#E4E4E4",
      color: "#000",
    }
  },
  cancelButton: {
    width: 148,
    height: 36,
    fontSize: 13,
    fontWeight: 400,
    borderRadius: 12,
    background: "#E4E4E4",
    color: "#000",
    "&:hover": {
      background: "#363ED3",
      color: "#FFF",
    }
  },
  headerTitle: {
    paddingBottom: 62,
    "& .MuiTypography-root": {
      fontFamily: "Strawford",
      fontSize: 32,
      fontWeight: 400,
      lineHeight: "43px",
      textAlign: "center"
    }
  },
  dragHeader: {
    paddingBottom: 32,
    maxWidth: 682
  },
  dragBoxTitle: {
    "& .MuiTypography-root": {
      fontFamily: "Strawford",
      fontSize: 32,
      fontWeight: 400,
      lineHeight: "36px",
      textAlign: "left"
    }
  },
  dragBoxText: {
    "& .MuiTypography-root": {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: "18px",
      paddingTop: 3,
      paddingRight: 20,
      textAlign: "right"
    }
  },
  nextButton: {
      color: "#fff",
      background: "#0BCB47",
      width: 262,
      height: 50,
      borderRadius: 10,
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "16px",
      textAlign: "center"
  },
  retryButton: {
      color: "#0BCB47",
      background: "#FFFFFF",
      width: 250,
      height: 50,
      border: "1px solid #0BCB47",
      borderRadius: 10,
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "16px",
      textAlign: "center"
  },
  goBackContainer: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#9299AA",
      position: "relative",
      top: -278,
      left: -10,
      fontFamily: "Strawford",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "24px",
      width: 80
  }
}))

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '2px dashed #363ED3',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    color: '#C2C2C2',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


const UploadFile = ({ 
  onUpload, 
  onNext = null, 
  onError = null,
  onReload = () => {}, 
  onBack = () => {}, 
  fileType = null, 
  title, 
  ws = true,
  spacing = true,
  anytype = false,
  back
}) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const socket = useRef(null)
    const [isFileUploaded,setIsFileUploaded] = React.useState(false);
    const [isFileLoading,setIsFileLoading] = React.useState(false)
    const [fileChunksProcessed, setFileChunksProccessed] = React.useState(0)
    const [fileChunksTotal, setFileChunksTotal] = React.useState(0)
    const [progress, setProgress] = React.useState(0)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')
    const [validationAlert, setValidationAlert] = React.useState('')
    
    const rootRef = useRef(null)
    const [showRenameOption, setShowRenameOption] = React.useState(false);
    const [renameStatus, setRenameStatus] = React.useState("Please change file name");
    const [renameError, setRenameError] = React.useState(false);
    const [isRename, setIsRename] = React.useState(false);
    const [renameButtonDisabled, setRenameButtonDisabled] = React.useState(false);
    const [currentName, setCurrentName] = React.useState(null);
    const [currentFile, setCurrentFile] = React.useState(null);
    const [newName, setNewName] = React.useState("");
    const projectId = useSelector(selectProjectId)

    const ApiService = new newProjectApiService()

    const handleUploadFile = (res) => {
      setIsFileLoading(false)
      setFileChunksProccessed(1)
      setFileChunksTotal(1)
      setCurrentFile(null)   
      setIsRename(false)        
      onUpload(res)
      setAlertTitle("Ready");
    }

    const handleClosePopover = () => {
      setAlertTitle("Error");
      setAlertMessage("file with this name already exist");
      setAlertSeverity("error");
      setShowAlert(true)
      setIsFileLoading(false)
      setIsFileUploaded(false)
      setCurrentFile(null)
      setNewName("")
      setIsRename(false)
      setRenameError(false)
      setShowRenameOption(false)
    }

    const handleOpenRename = (file) => {
      const renamedFile = newName?.match(/[.csv]$/g) ? newName : newName+".csv"
      setRenameButtonDisabled(true)
      setCurrentFile(file)
      setRenameStatus("Please change file name")
      setCurrentName(isRename ? renamedFile : file[0].name)
      setNewName(isRename? renamedFile : file[0].name)
      isRename && setRenameError(true)
      setShowRenameOption(true)
    }

    const uploadFile = (file) => {
      setFileChunksProccessed(0)
      setFileChunksTotal(0)
      let finished = false
      setShowAlert(false)
      const bodyFormData = new FormData()
      bodyFormData.append("file", file[0])
      const name = isRename ? newName : file[0].name
      const renamedFile = newName?.match(/[.csv]$/g) || !ws ? newName : newName+".csv"
      newName && bodyFormData.append("filename", renamedFile)
      const isNotValid = name.match(/[^a-zA-Z0-9_.\S]/g) || name?.replace(".csv", "").length > 30;
      if (!isNotValid) {
      ApiService._getCsvNameCheck(newName ? renamedFile : file[0].name, () => {
        setIsRename(false)
        setRenameError(false)
        setShowRenameOption(false)
        setAlertTitle("Uploading...");
        ApiService._postUploadCsv(bodyFormData, (response) => {
          setRenameButtonDisabled(false)
          setCurrentName(newName ? renamedFile : file[0].name)
          dispatch(setFileName(newName ? renamedFile : file[0].name))
          setNewName("")
          if (ws) {
            socket.current = new WebSocket("wss://"+process.env.REACT_APP_API_URL.split("https://")[1] + '/data/upload/progress')
            socket.current.onerror = (err) => {
              console.log(err)
            }
            socket.current.onopen = () => {
              const query = {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                "source_id": response.data['source_id']
              }
              socket.current.send(JSON.stringify(query))
              setIsFileLoading(true)
            }
            socket.current.onmessage = (ev) => {
              const data = JSON.parse(ev.data)
              if (fileChunksTotal === 0) {
                setFileChunksTotal(data['total_policies_to_upload'])
              }
              setFileChunksProccessed(data['progress'])
              if (data['finish']) {
                socket.current.close()
                handleUploadFile(response)
                finished = true
              }
            }
            socket.current.onclose = () => {
              if(!finished) {
                setCurrentFile(null)           
                setIsRename(false)
                setAlertTitle("Error while uploading file");
                setAlertMessage("Sorry couldn't upload your file. Please try uploading another file or try again later");
                setAlertSeverity("error");
                setShowAlert(true)
                setIsFileLoading(false)
              }
            }
          } else {
            handleUploadFile(response)
            finished = true
          }
          
        }, (error) => {
          console.log(error);
          if(error?.response?.status === 409) {
            handleOpenRename(file)
          } else {
            onError && onError(error)
            setAlertTitle("Error");
            setAlertMessage(error?.response?.data['description']);
            setAlertSeverity("error");
            setShowAlert(true)
            setIsFileLoading(false)
            setIsFileUploaded(false)
          }
        })
      }, (error) => {
        if(error.response.status === 409) {
          handleOpenRename(file)
        } else {
          setShowRenameOption(false)
          setCurrentFile(null)
          setCurrentName(null)
          setNewName("")
          setIsFileLoading(false)
          setIsRename(false)
          setRenameError(false)
        }
      })
      } else {
        setAlertTitle("File name not allowed");
        setValidationAlert("Name can contain only characters A-Z, numbers and '_', and be no longer than 30 characters")
        setNewName(file[0].name)
        setCurrentFile(file)
        setIsRename(true)
        setShowRenameOption(true)
      }
    }

    const handleRenameClick = () => {
      const isNotValid = newName.match(/[^a-zA-Z0-9_.\s]/g) || newName.name?.replace(".csv", "").length > 30;
      if(isNotValid) {
        setValidationAlert("Name can contain only characters A-Z, numbers and '_', and be no longer than 30 characters") 
        setAlertTitle("File name not allowed");
        setAlertSeverity("error");
        setShowAlert(true)
      } else {
        setIsRename(true)
        currentFile && uploadFile(currentFile)
      }
    }

    const handleChangeName = (e) => {
      const zeroValue = e.target.value.match(/\s+(.csv)*/g)  || e.target.value === ".csv" || e.target.value === ".";
      const isNotValid = e.target.value.match(/[^a-zA-Z0-9_.\S]/g) || e.target.value?.replace(".csv", "").length > 30;
      if(zeroValue) { 
        setAlertTitle("File name is empty");
        setValidationAlert("Please enter some value.") 
      }
      if(isNotValid) { 
        setAlertTitle("File name not allowed");
        setValidationAlert("Name can contain only characters A-Z, numbers and '_', and be no longer than 30 characters") 
      }
      (!zeroValue && !isNotValid) && setValidationAlert("")
      setRenameButtonDisabled(currentName === e.target.value || zeroValue || isNotValid)
      setNewName(e.target.value)
    } 

    const onDrop = React.useCallback((acceptedFiles) => {
       const handleUploadFile = (file) => {
        if (file[0]?.type === "text/csv" || !fileType) {
          setCurrentName(file[0].name)
          const isNotValid = file[0].name.match(/[^a-zA-Z0-9_.\s]/g) || file[0].name?.replace(".csv", "").length > 30;
          if(isNotValid) {
            setAlertTitle("File name not allowed");
            setAlertMessage("Name can contain only characters A-Z, numbers and '_', and be no longer than 30 characters");
            setAlertSeverity("error");
            setShowAlert(true)
            setIsFileLoading(false)
            setIsFileUploaded(false)
          } else {
            uploadFile(file)
          }

       } else {
        setAlertTitle("Incorrect file format");
        setAlertMessage("Please select a file of .csv format");
        setAlertSeverity("error");
        setShowAlert(true)
        setIsFileLoading(false)
        setIsFileUploaded(false)
       }
      
      }
      setIsFileUploaded(true)
      setIsFileLoading(true)
      handleUploadFile(acceptedFiles)
    //}, [])
  }, [dispatch])
    const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({accept: fileType, maxFiles:1,onDrop});

  useEffect(() => {
    if(parseInt(fileChunksProcessed) > 0) {
      const value = Math.round(fileChunksProcessed * (100/fileChunksTotal))
      setProgress(value)
    }
  }, [fileChunksProcessed, fileChunksTotal])

  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  
  const handleReuploadFile = () => {
    setIsFileUploaded(false)
    setIsFileLoading(false)
    setShowAlert(false)
    setAlertMessage('')
    dispatch(setCsvInfo([]))
    setNewName("")
    onReload()
  }
  
        return(
          (isFileUploaded || showAlert) ? 
              <Grid container justifyContent="center">
                  {back &&
                    <div className={classes.goBackContainer} style={{ top: -247 }} onClick={onBack}>
                      <ArrowIcon/> <p>Back</p>
                    </div>
                  }
                 <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.uploadDone} 
                  style={spacing ? {} : { margin: 0 }}
                  ref={rootRef}
                >
                <Grid item container justifyContent="center" alignItems="center" className={classes.headerTitle} style={spacing ? {} : { padding: 0 }}>
                  <Typography>{title}</Typography>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" className={classes.root}>
                  {showAlert ?  <UploadErrorIcon/> : isFileLoading ? <IconFolder/> :<FileUploadedIcon data-testid="fileUploaded" width={62} height={55}/>}
                </Grid> 
                <Grid item container justifyContent="center" alignItems="center" className={classes.loadingContainer}> 
                    <Typography>
                      {
                        newName ? 
                          newName?.match(/[.csv]$/g) ? newName : newName+".csv" 
                        : currentName
                      }
                    </Typography>
                </Grid>              
                <Grid container justifyContent="center" direction='column'>
                    <Grid container justifyContent="center">
                      <Stack sx={showAlert ? { color: "#FF4B62" } : {}}></Stack>
                      <LinearProgress data-testid="progressBar" variant="determinate" color="inherit" className={clsx(classes.progressBar, showAlert && classes.progressBarError)} value={showAlert ? 100 : progress}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" className={classes.loadedAlert}>
                  {
                  isFileLoading ?
                    <Grid container justifyContent="center" direction='column'>
                      <Grid container justifyContent="center">
                        <Typography>
                          {showAlert && alertMessage}
                        </Typography>
                      </Grid>
                      <Grid container justifyContent="center">  
                        {isFileLoading &&<Typography className={classes.loadingText}>Processing {progress}%</Typography>}
                      </Grid>
                    </Grid>
                    : 
                    <Typography>
                      {alertMessage}
                    </Typography>
                  }
                </Grid>
                
                <Grid container justifyContent="center">

                </Grid>
                {!(!isFileLoading && showAlert ) && fileType && <Grid container justifyContent="center">
                  <NextButton className={classes.nextButton} onClick={onNext} disabled={isFileLoading || showAlert}>Next</NextButton>
                </Grid>}
                {!(!isFileLoading && showAlert ) && !fileType && <Grid container justifyContent="center">
                  <NextButton className={classes.nextButton} onClick={handleReuploadFile} disabled={isFileLoading || showAlert}>Upload Another File</NextButton>
                </Grid>}
                {!isFileLoading && showAlert && <Grid container justifyContent="center">
                  <Button className={classes.retryButton} onClick={handleReuploadFile}>Try Another File</Button>
                </Grid>}
                {/* <Button className={classes.ButtonReupload} onClick={handleReuploadFile} variant="contained"> &lt; Choose Another File</Button> */}
                
              </Grid>
              
              <Popover 
                    open={showRenameOption}
                    anchorEl={rootRef.current}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center"
                    }}>
                      <Grid className={classes.renamePopoverContainer} container>
                        <Grid item xs={12}>
                        <Typography className={classes.renamePopoverTitle}>{"Rename uploaded file"}</Typography>
                        {<Typography className={classes.renamePopoverText}>"{currentName}" already exists in our system</Typography>}
                        </Grid>
                        <Grid item xs={12} className={classes.renameInputContainer}>
                          {<Typography className={clsx(classes.renamePopoverStatus, renameError ? classes.renamePopoverError : '')}>{renameStatus}</Typography>}
                          <OutlinedInput 
                            value={newName}
                            onChange={handleChangeName}
                            autoComplete={false}
                            error={renameError}
                            fullWidth
                            />
                            {validationAlert &&
                              <Alert severity={"error"} className={classes.alertBoxRename}>
                                  <AlertTitle>{alertTitle}</AlertTitle>
                                {validationAlert}
                              </Alert>
                            }
                        </Grid>
                        <Grid item container justifyContent='space-between' xs={12} className={classes.renameControlsContainer}>
                          <Button className={classes.cancelButton} onClick={handleClosePopover}>
                            Cancel
                          </Button>
                          <Button className={classes.renameButton} disabled={renameButtonDisabled} onClick={handleRenameClick}>
                            {"Upload"}
                          </Button>
                        </Grid>
                      </Grid>
                  </Popover>  
            </Grid>
          :
           <Grid className={classes.uploadContainer} container xs={12}>
                {back &&
                  <div className={classes.goBackContainer} onClick={onBack}>
                    <ArrowIcon/> <p>Back</p>
                  </div>
                }
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.uploadDone} 
                  ref={rootRef}
                >
                  <Grid item container justifyContent="space-between" className={classes.dragHeader} style={spacing ? {} : { padding: 0 }}>
                    <Grid item className={classes.dragBoxTitle}>         
                      <Typography>{title}</Typography>
                    </Grid>

                  </Grid>

                  <Grid 
                    item 
                    container 
                    justifyContent='center' 
                    alignItems='center' 
                    className={classes.dragnDropRoot} 
                    {...getRootProps({style})} data-testid="uploadCsvInput">
                      <input {...getInputProps()}/>
                      <IconFolder/>
                      <Typography className={classes.dragnDropText}>Drag & Drop or <span className={classes.linkStyle}>click to Upload</span> your {fileType ? "database" : ""} file</Typography>
                      <Grid item container justifyContent='center' alignItems='center' className={classes.alertBox}>         
                        {
                        (showAlert && alertSeverity === "error") && 
                        <Alert className={classes.loadedAlertContainer} severity={alertSeverity}>
                            <AlertTitle>{alertTitle}</AlertTitle>
                          {alertMessage}
                          </Alert>
                        }
                      </Grid>

                  </Grid>
                </Grid>
           </Grid>
          );
}

export default UploadFile