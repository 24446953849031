export const defaultGenderFilter = { 
    "All": true, 
    "Female": false, 
    "Male": false, 
    "Other": false 
}

export const defaultAgeFilter = {
    "All": true, 
    "<50": false, 
    "50-59": false, 
    "60-79": false, 
    "80+": false
}

export const defaultAgeFilterLife = {
    "All": true, 
    "<18": false, 
    "18-24": false, 
    "25-34": false, 
    "35-49": false, 
    "50-64": false, 
    "65-80": false, 
    "80+": false
}

export const defaultContractValueFilter = {
    "All": true, 
    "<$50K": false, 
    "$50K-$100K": false, 
    "$100K-$250K": false, 
    "$250K-$500K": false, 
    "$500K+": false
}

export const defaultLibrFilter = {
    "All": true, 
    "<$1K": false, 
    "$1K-$3K": false, 
    "$3K-$5K": false, 
    "$5K-$10K": false, 
    "$10K+": false
}

export const defaultOpportunityTypeFilter = {
    "All": true, 
    "New Business": false, 
    "Retention": false
}

export const defaultYearsFilter = {
    "All": true,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false, 
    "7": false,
    "8": false, 
    "9": false, 
    "10": false, 
    "11": false, 
    "12": false, 
    "13": false, 
    "14": false, 
    "15": false, 
    "16": false, 
    "17": false, 
    "18": false, 
    "19": false, 
    "20": false 
}

export const defaultPercentage = {
    "All": true, 
    "<10%": false,
    "10-25%": false,
    "25-50%": false,
    "50-75%": false,
    "+75%": false 
}

export const defaultAgentStatusFilter = {
    "All": true, 
    "Orphan": false, 
    "Dormant": false, 
    "Active Managing not Writing": false, 
    "Active Managing Writing": false
}

export const defaultLIBRStatusFilter = {
    "All": true, 
    "Active (No Fee)": false, 
    "Active (Fee)": false, 
    "Not Active (Fee)": false, 
    "Not Active (No Fee)": false
}

export const defaultStateFilter = {
    "All": true,
    "AL": false,
    "AK": false,
    "AZ": false,
    "AR": false,
    "CA": false,
    "CO": false, 
    "CT": false,
    "DE": false, 
    "FL": false, 
    "GA": false, 
    "HI": false, 
    "ID": false, 
    "IL": false, 
    "IN": false, 
    "IA": false, 
    "KS": false, 
    "KY": false, 
    "LA": false, 
    "ME": false, 
    "MD": false,
    "MA": false,
    "MI": false,
    "MN": false,
    "MS": false,
    "MO": false,
    "MT": false,
    "NE": false,
    "NV": false,
    "NH": false,
    "NJ": false,
    "NM": false, 
    "NY": false,
    "NC": false, 
    "ND": false, 
    "OH": false, 
    "OK": false, 
    "OR": false, 
    "PA": false, 
    "RI": false, 
    "SC": false, 
    "SD": false, 
    "TN": false, 
    "TX": false, 
    "UT": false, 
    "VT": false,
    "VA": false,
    "WA": false,
    "WV": false,
    "WI": false,
    "WY": false,
}

export const defaultPriorityFilter = {
    "All": true, 
    "high": false,
    "medium": false, 
    "low": false 
}

export const defaultSensetivityFilter = {
    "All": true, 
    "very sensitive": false,
    "sensitive": false, 
    "rather sensitive": false, 
    "rather insensitive": false,
    "insensitive": false, 
    "very insensitive": false
}
export const defaultStatusFilter = {
    "All": true, 
    "new": false, 
    "In Campaign": false, 
}

export const defaultProbabilityFilter = {"All": true }