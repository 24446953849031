import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { ReactComponent as IconStats } from '../../Assets/icon-stats.svg';
import { ReactComponent as IconUpload } from '../../Assets/icon-browse.svg'; 
import { ReactComponent as ArrowRight } from '../../Assets/icon-arrow-big.svg'; 
import  CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        display:"flex",
        justifyContent: 'center',
        flexGrow: 1,
    },
    popoverWrapper: {
        height: "97vh"
    },
    popover: {
        paddingBottom: 35,
        minHeight: 642,
        maxWidth: 996,
        background: "#FFFFFF",
        borderRadius: 25,
        border: "1px solid #EBEBEB",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    },
    popoverClose: {
        position: "relative",
        top: 34,
        right: -465,
        width: 16,
        height: 16,
        cursor: "pointer"
    },
    stepsContainer: {
        maxWidth: 811,
    },
    stepCard: {
        minHeight: 392,
        background: "#FFFFFF",
        borderRadius: 25,
        border: "1px solid #EBEBEB",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
        paddingTop: 34,
        "& .MuiTypography-root": {
            textAlign: "center"
        }
    },
    stepHeader: {
        "& .MuiTypography-root": {
            fontFamily: 'Strawford',
            fontSize: 20,
            fontWeight: 400,
            lineHeight: "20px",
            color: "#363ED3"
        }
    },
    stepSubHeader: {
        "& .MuiTypography-root": {
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "150%",
            color: "#312E40",
            paddingTop: 4
        }
    },
    stepSubText: {
        "& .MuiTypography-root": {
            fontFamily: 'Roboto',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "130%",
            color: "#515151",
            paddingTop: 22,
            "& span": {
                textDecoration: "none"
            }
        }
    },
    statsIconContainer: {
        textAlign: "center",
        paddingTop: 25,
        paddingBottom: 16
    },
    uploadIconContainer: {
        textAlign: "center",
        paddingTop: 50,
        paddingBottom: 39
    },
    customTitle: {
        fontFamily: 'Strawford',
        fontSize: 32,
        fontWeight: 400,
        lineHeight: "36px",
        textAlign: "center",
        color: "#312E40",
        paddingTop: 25,
        paddingBottom: 43
    },
    footerText: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 300,
        lineHeight: "120%",
        textAlign: "center",
        color: "#312E40",
        paddingTop: 32,
        "& a": {
            fontWeight: 500,
            color: "#000000"
        }
    },
    headerTitle:{
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#000000"
    },
    button: {
      color: "#312e40",
      "&:hover": {
        backgroundColor: "#363ED3",
        color: "white",
      },
      textTransform: "capitalize",
    },
    nextButtonContainer: {
        paddingTop: 32
    },
    nextButton: {
        color: "#fff",
        background: "#0BCB47",
        width: 168,
        height: 41,
        borderRadius: 10,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center"
    }

}));

const NewUserDemo = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleUploadClick = () => {
        history.push("/upload")
    }

    const handleClose = () => {
        history.push("/book")
    }

    return(
        <Grid container justifyContent="center" alignItems="center" className={classes.popoverWrapper}>
            <Grid container justifyContent="center" className={classes.popover}>
                <Grid container spacing={0} className={classes.root} data-testid="step1" >
                    <Grid container spacing={0} justifyContent="center">
                            <CloseIcon className={classes.popoverClose} onClick={handleClose}/>
                            <Grid item xs={12} alignItems="center">
                                <Typography variant="h4" className={classes.customTitle}>
                                    Only 2 steps are needed to test your data
                                </Typography>
                            </Grid>
                            <Grid container justifyContent="space-between" className={classes.stepsContainer}>
                                <Grid item container direction="column" justifyContent="center" xs={5} className={classes.stepCard}>
                                    <Grid item container  justifyContent="center" className={classes.stepHeader}>
                                        <Typography>Step 1</Typography>
                                    </Grid>
                                    <Grid item container justifyContent="center" className={classes.stepSubHeader}>
                                        <Typography>Upload Your File*</Typography>
                                    </Grid>
                                    <Grid item container justifyContent="center" className={classes.stepSubText}>
                                        <Typography>
                                            Only 8 fields are required. 
                                            <br></br>
                                            Please <Link to="/sample-info">click here</Link> to review detailed instructions
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.uploadIconContainer}>
                                        <IconUpload style={{ width: 132, height: 142 }}/>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={2} justifyContent="center" alignItems="center">
                                    <ArrowRight/>
                                </Grid>
                                <Grid item container direction="column" justifyContent="center" xs={5} className={classes.stepCard}>
                                    <Grid item className={classes.stepHeader}>
                                        <Typography>Step 2</Typography>
                                    </Grid>
                                    <Grid item className={classes.stepSubHeader}>
                                        <Typography>View Results</Typography>
                                    </Grid>
                                    <Grid item className={classes.stepSubText}>
                                        <Typography>
                                            The results demonstrate 1 year upside
                                            <br></br>
                                            potential, most of which will be recurring
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent="center" className={classes.statsIconContainer}>
                                        <IconStats/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center" className={classes.nextButtonContainer}>
                                <Button className={classes.nextButton} onClick={handleUploadClick}>
                                    Upload File
                                </Button>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography className={classes.footerText}>
                                    For any questions or difficulties, contact us at <a href="mailto:support@atidot.com">support@atidot.com</a>
                                </Typography>
                            </Grid>
                    </Grid>
                </Grid> 
                            
            </Grid>
        </Grid>
    )

}

export default NewUserDemo