import React, {useEffect, useMemo, useState} from "react";

import * as _ from 'lodash';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {default as LibTable} from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {CircularProgress} from "@material-ui/core";
import {categoryHeadingItem, UIExampleButton, UIStartButton, useStyles} from "./styles";
import NewBusinessIcon from '../../../Assets/icon-demo-newBusiness.svg';
import RetentionIcon from '../../../Assets/icon-demo-retention.svg';
import ChevronIcon from '../../../Assets/icon-chevron.svg';
import ClearIcon from '../../../Assets/icon-clear.svg';
import FilterIcon from '../../../Assets/icon-filter.svg';
import SortingIcon from '../../../Assets/icon-sorting-triangle.svg';
import FilterDropDownInput from "../../../Components/ui/FilterDropDownInput";
import axios from "axios";
import ReactPaginate from "react-paginate";
import {nFormatter} from "../../../global/helpers";
import NumberFormat from "react-number-format";
import {DEFAULT_CALCULATION_VALUES} from "../../../Components/Demo/useCalculations/useCalculations";
import DemoLayout from "../../../Components/Demo/DemoLayout";

function getRandomInt() {
    const   min = Math.ceil(0);
    const max = Math.floor(10);
    const randomNum = Math.floor(Math.random() * (max - min) + min)
    return [0,1,2,5,8].includes(randomNum) ? 0 : randomNum;
}

const DemoRecommendedActions = () => {
    const classes = useStyles();

    const itemsPerPage = 10;

    const [modalOpenSettings, setModalOpenSettings] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [sortedTableData, setSortedTableData] = useState([]);
    const [filters, setFilters] = useState({});
    const [sorting, setSorting] = useState({
        field: 'policyId',
        order: 'asc'// 'asc' | 'desc'
    });

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const totalPolicies = useMemo(() => {
        return sortedTableData.length
    }, [sortedTableData])

    const totalPremium = useMemo(() => {
        const uiPoliciesSum = sortedTableData.reduce((acc, cur) => {
            if (cur.action === 'New Business') {
                acc += Number(cur.potentialPremium);
            }
            return acc;
        } , 0)
        const termRetentionSum = sortedTableData.filter(({ action, productType }) =>
            action === 'Retention' && productType === 'Term Life').length * DEFAULT_CALCULATION_VALUES.AVG_LAPSE_TERM;
        const wlRetentionSum = sortedTableData.filter(({ action, productType }) =>
            action === 'Retention' && productType === 'Whole Life').length * DEFAULT_CALCULATION_VALUES.AVG_LAPSE_WL;
        return ((termRetentionSum + wlRetentionSum) * 4) + uiPoliciesSum;
    }, [sortedTableData])

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1000);
    }, [sortedTableData]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(sortedTableData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(sortedTableData.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, sortedTableData]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % sortedTableData.length;
        setItemOffset(newOffset);
    };

    const getColorByCamp = (camp) => {
        if (camp === 0) return '#F8F9FB'
        if (camp > 0 && camp <= 3) return '#85E5A3'
        if (camp > 3 && camp <= 6) return '#FFDA91'
        return '#FF9191'
    }

    const getData = async () =>
        await axios.get('demo-data.json'
        ,{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )

    const addRandomCamp = (data) => data.map(item => ({
        ...item,
        camp: getRandomInt()
    }))

    useEffect(() => {
        if (Object.keys(filters).length === 0) { // initial data fetch
            getData().then((response) => {
                setTableData(addRandomCamp(response.data))
                setSortedTableData(addRandomCamp(response.data))
            })
        }
        if (Object.keys(filters).length) {
            setSortedTableData(filterByMultipleFields(tableData))
        }
    }, [filters])

    const filterByMultipleFields = (initialData) =>//this func need refactor
        initialData.filter(item => {
            const res = Object.keys(filters).filter(filterKey => {
                const a = filters[filterKey].filter((filterValue) => {
                    if (filterValue.includes('-')) {// Check if its number compare
                        const [from, to] = filterValue.split('-');
                        if (from === 'all') return true
                        if (from && !to) return Number(item[filterKey]) >= Number(from)
                        return Number(item[filterKey]) >= Number(from) && Number(item[filterKey]) <= Number(to)
                    } else {
                        return item[filterKey] === filterValue
                    }
                })
                return !!a.length;
            })
            return res.length === Object.keys(filters).length
        })

    useEffect(() => {
        const sortedByField = applySorting(sortedTableData)
        setSortedTableData(sortedByField)
    }, [sorting])

    const filtersUpdateByField = (field, value) => {
        setFilters((prevState) => ({
            ...prevState,
            [field]: value
        }))
    }

    const resetFilters = () => {
        setFilters({})
    }

    const applySorting = (initialData) => {
        if (sorting.field === '') return initialData;
        return _.orderBy(initialData, [sorting.field], [sorting.order]);
    }

    const getSortingControl = (name, field) => {
        const activeSelection = sorting.field === field

        const getCorrectOrder = () => {
            if (!activeSelection) return 'asc'
            return sorting.order === 'asc' ? 'desc' : 'asc';
        }

        const handleClick = () => {
            if (isLoading) return;
            const sortingInfo = {
                field,
                order: getCorrectOrder()
            }
            setSorting(sortingInfo)
        }

        return (
            <div className={classes.sortingHeadField} onClick={handleClick}>
                {name}
                <img className={getCorrectOrder() === 'desc' ? 'active' : ''} src={SortingIcon} alt={`sort-by-${field}`} />
            </div>
        )
    }

    const getPotentialPremium = ({ potentialPremium, productType }) => {
        if (potentialPremium) return potentialPremium;
        const avgLapse = productType === 'Term Life' ? DEFAULT_CALCULATION_VALUES.AVG_LAPSE_TERM : DEFAULT_CALCULATION_VALUES.AVG_LAPSE_WL;
        return 1 * avgLapse * 4;
    }

    return (
        <DemoLayout
            pageTitle="Recommended Actions per Policy"
            wrapped={false}
            modalInfo={modalOpenSettings}
            onModalClose={(defaultValues) => setModalOpenSettings(defaultValues)}
        >
                <div className="page-content simple-shadow">
                    <div className={classes.prospects}>
                        <div className={classes.totalTitlesWrapper}>
                            <div>Prospects</div>
                            <div><p>Total Policies</p> <span>21166</span></div>
                            <div><p>Total Premium Potential</p> <span className="colored">$39.1M</span></div>
                        </div>
                        <div
                            className={classes.clearFiltersContainer}
                            onClick={() => resetFilters()}
                        >
                            <img src={ClearIcon} alt="Clear all filters" />
                            Clear all filters
                        </div>
                    </div>
                    <div className={classes.filtersBlock}>
                        <div className={classes.filtersBlockSide}>
                            <div className="filtersHeading">
                                <img src={FilterIcon} alt="Existing Policy" />
                                <h3><strong>Existing Policy</strong> Filters</h3>
                            </div>
                            <div className={classes.filtersContainer}>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Product Type"
                                        options={[
                                            { label: 'Whole', value: 'Whole Life' },
                                            { label: 'Term', value: 'Term Life' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('productType', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Annual Premium"
                                        options={[
                                            { label: '0 - 1k', value: '0-1000' },
                                            { label: '1k - 5k', value: '1000-5000' },
                                            { label: '5k - 15k', value: '5000-15000' },
                                            { label: '>= 15k', value: '15000-' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('annualPremium', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Gender"
                                        size="sm"
                                        options={[
                                            { label: 'Male', value: 'M' },
                                            { label: 'Female', value: 'F' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('gender', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Face Amount"
                                        size="sm"
                                        options={[
                                            { label: '0 - 20k', value: '0-20000' },
                                            { label: '20k - 60k', value: '20000-60000' },
                                            { label: '60k - 140k', value: '60000-140000' },
                                            { label: '>= 140k', value: '140000-' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('faceAmount', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Age"
                                        size="sm"
                                        options={[
                                            { label: '18 – 35', value: '18-35' },
                                            { label: '35 – 65', value: '35-65' },
                                            { label: '>= 65', value: '65-' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('age', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.filtersBlockSide}>
                            <div className="filtersHeading">
                                <img src={FilterIcon} alt="Existing Policy" />
                                <h3><strong>Upside Policy Potential</strong> Filters</h3>
                            </div>
                            <div className={classes.filtersContainer}>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Opportunity Type"
                                        options={[
                                            { label: 'New Business', value: 'New Business' },
                                            { label: 'Retention', value: 'Retention' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('action', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Face Amount"
                                        size="sm"
                                        options={[
                                            { label: '0 - 20k', value: '0-20000' },
                                            { label: '20k - 60k', value: '20000-60000' },
                                            { label: '60k - 140k', value: '60000-140000' },
                                            { label: '>= 140k', value: '140000-' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('potentialFaceAmount', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                                <div className={classes.filterItem}>
                                    <FilterDropDownInput
                                        label="Annual Premium"
                                        options={[
                                            { label: '0 - 1k', value: '0-1000' },
                                            { label: '1k - 5k', value: '1000-5000' },
                                            { label: '5k - 15k', value: '5000-15000' },
                                            { label: '>= 15k', value: '15000-' },
                                        ]}
                                        onChange={(value) => filtersUpdateByField('potentialPremium', value)}
                                        isInit={Object.keys(filters).length === 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content simple-shadow">
                    <div className={classes.prospects}>
                        <div className={classes.totalTitlesWrapper}>
                            <div>Answering your criteria</div>
                            <div className="biggerSize"><p>Number of prospects</p> <span>{totalPolicies}</span></div>
                            <div className="biggerSize"><p>Total Potential</p> <span className="colored">{`$${nFormatter(totalPremium, 2).toUpperCase()}`}</span></div>
                        </div>
                        <div>
                            <UIStartButton
                                onClick={() => setModalOpenSettings({
                                    name: 'contact',
                                    value: 'To test the campaign with this data, please contact our sales team.'
                                })}
                            >Start Campaign</UIStartButton>
                            <UIExampleButton
                                onClick={() => setModalOpenSettings({
                                    name: 'contact',
                                    value: 'To test the campaign with this data, please contact our sales team.'
                                })}
                            >
                                See Campaign Example
                            </UIExampleButton>
                        </div>
                    </div>
                    <div>
                        <div className={classes.root}>
                            <TableContainer component={Paper} sx={{ boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)' }}>
                                <LibTable>
                                    <TableHead classes={classes.root}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={6} sx={categoryHeadingItem}>
                                                Existing Policy Data
                                            </TableCell>
                                            <TableCell align="left" colSpan={5} sx={categoryHeadingItem}>
                                                Upside Policy Potential
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                {getSortingControl('Policy ID', 'policyId')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Product Type', 'productType')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Annual Premium', 'annualPremium')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Face Amount', 'faceAmount')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Gender', 'gender')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Age', 'age')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Upside Opportunity', 'behavioralCluster')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Product Type', 'productType')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Annual Premium', 'potentialPremium')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Face Amount', 'potentialFaceAmount')}
                                            </TableCell>
                                            <TableCell>
                                                {getSortingControl('Camp.', 'camp')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoading ? (
                                            <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <>
                                                {currentItems.length === 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={11} align="center">
                                                            No policies found
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {currentItems.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            borderLeft: '2px solid',
                                                            borderColor: getColorByCamp(item.camp)
                                                        }}
                                                        onClick={() => setModalOpenSettings({
                                                            name: 'policyDetails',
                                                            value: item
                                                        })}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={classes.firstCol}>
                                                                {item.action === 'New Business'
                                                                    ? <img src={NewBusinessIcon} alt="New Business" />
                                                                    : <img src={RetentionIcon} alt="Retention" />
                                                                }
                                                                <p>{item.policyId}</p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <strong>{item.productType}</strong>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            ${item.annualPremium}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {
                                                                <NumberFormat
                                                                    value={item.faceAmount}
                                                                    displayType="text"
                                                                    thousandSeparator=","
                                                                    prefix="$"
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.gender === 'M' ? 'Male' : 'Female'}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item.age}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <strong>{item.behavioralCluster || 'Not provided'}</strong>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <strong>{item.productType}</strong>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <NumberFormat
                                                                value={getPotentialPremium(item)}
                                                                displayType="text"
                                                                thousandSeparator=","
                                                                prefix="$"
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <NumberFormat
                                                                value={
                                                                    item.potentialFaceAmount && item.potentialFaceAmount !== -1
                                                                        ? item.potentialFaceAmount
                                                                        : item.faceAmount
                                                                }
                                                                displayType="text"
                                                                thousandSeparator=","
                                                                prefix="$"
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{
                                                            background: getColorByCamp(item.camp),
                                                            borderRadius: '10px 10px 0px 10px',
                                                        }}>
                                                            <div
                                                                className={classes.campItem}
                                                                style={item.camp === 0 ? { textDecoration: 'none' } : {}}
                                                            >
                                                                <span>{item.camp || '-'}</span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}
                                    </TableBody>
                                </LibTable>
                            </TableContainer>
                        </div>
                        {!isLoading && pageCount > 1 && (
                            <div className={classes.paginationContainer}>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={<img src={ChevronIcon} alt='next'/>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={<img src={ChevronIcon} alt='prev'/>}
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}
                        {!isLoading && pageCount > 1 && (
                            <div className={classes.legendContainer}>
                                <div>
                                    <img src={NewBusinessIcon} alt="New Business"/>
                                    <p>New Business</p>
                                </div>
                                <div>
                                    <img src={RetentionIcon} alt="Retention"/>
                                    <p>Retention</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
        </DemoLayout>
    )
}

export default DemoRecommendedActions;