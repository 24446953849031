import React from "react";
import UIDistribution, {colorsDefault} from "../../Components/Demo/Charts/UIDestribution/UIDestribution";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../../Components/Demo/Header/styles";
import Arrow from '../../Assets/icon-arrow-right.svg';
import {nFormatter} from "../../global/helpers";
import Table from "../../Components/Demo/Table/Table";
import {useHistory} from "react-router-dom";
import DemoLayout from "../../Components/Demo/DemoLayout";

export const UIButton = styled(Button)({
  ...basicButtonStyles,
  width: 'auto',
  padding: '14px 34px',
  maxWidth: '240px',
  height: 'auto',
  borderRadius: '10px',
  backgroundColor: '#363ED3',
  borderColor: '#FFF',
  color: '#FFF',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
});

export const UIViewButton = styled(Button)({
  ...basicButtonStyles,
  fontSize: 16,
  padding: '25px 0',
  width: '240px',
  height: 'auto',
  borderRadius: '10px',
  backgroundColor: '#0BCB47',
  borderColor: '#FFF',
  color: '#FFF',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
});

const chartData = [
  { name: "Term10", simple: 1000000, premium: 8657504, policies: 9483 },
  { name: "Term20", simple: 1000000, premium: 7042496, policies: 7714 },
  { name: "Whole Life", simple: 5000000, premium: 17200000, policies: 3969 },
];

const tableDate = [
  { name: "Term10", simple: 1000000, premium: 8657504, policies: 9483 },
  { name: "Term20", simple: 1000000, premium: 7042496, policies: 7714 },
  { name: "Whole Life", simple: 5000000, premium: 17200000, policies: 3969 },
];

const upsideTableDate = [
  { name: "Term10", simple: 1000000, premium: 11028729, policies: 9483 },
  { name: "Term20", simple: 1000000, premium: 8971382, policies: 7714 },
  { name: "Whole Life", simple: 5000000, premium: 19200000, policies: 3969 },
];

const Demo = () => {
  const history = useHistory();

  return (
    <DemoLayout
      pageTitle="Your Results"
    >
      <UIButton
          onClick={() => history.push('/demo-portfolio-estimates')}
      >
        View Estimate Of Your Full Portfolio</UIButton>
      <div className="demoCharts">
        <div className="demoCharts-item small">
          <UIDistribution
              id="small"
              paddingRight={0}
              chartData={chartData}
              showTable={false}
              showLegend
              totalText={<>Current Annual <br /> Premium</>}
              totalValue={`$${nFormatter(32900000, 1).toUpperCase()}`}
              categoryField="name"
              valueField="simple"
          />
        </div>
        <div className="betweenCharts">
          <p><strong>20%</strong></p>
          <img src={Arrow} alt="Arrow" />
        </div>
        <div className="demoCharts-item big">
          <UIDistribution
              id="big"
              paddingRight={0}
              chartData={chartData}
              showTable={false}
              totalText={<>Annual Premium <br /> Potential</>}
              categoryField="name"
              valueField="simple"
              totalValue={`$${nFormatter(39200000, 1).toUpperCase()}`}
          />
        </div>
      </div>
      <div className="chartLegend">
        {chartData.map(({ name }, index) => (
            <div key={index} className="chartLegend-item">
              <div className="chartLegend-colorIndicator" style={{ backgroundColor: colorsDefault[index] }} />
              <p>{name}</p>
            </div>
        ))}
      </div>
      <div className="tableContainer">
        <Table
            tableData={tableDate}
            premiumField="premium"
            premiumFieldName="Annual Premium"
            numbersRounding={1}
            showColors
        />
        <div className="betweenCharts">
          {/*<p><strong>1.19x</strong></p>*/}
          <img src={Arrow} alt="Arrow" />
        </div>
        <Table
            tableData={upsideTableDate}
            premiumField="premium"
            premiumFieldName="Potential Premium"
            numbersRounding={1}
            showColors
        />
      </div>
      <div className="center">
        <UIViewButton
            onClick={() => history.push('/demo-opportunities')}
        >
          View Details</UIViewButton>
      </div>
    </DemoLayout>
  )
};

export default Demo;
