import * as React from 'react';
import { default as LibTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {getCountryCurrencySymbol, nFormatter} from "../../../global/helpers";
import NumericFormat from 'react-number-format';
import {colorsDefault} from "../Charts/UIDestribution/UIDestribution";
import NumberFormat from "react-number-format";
import { makeStyles, styled } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableHead: {
      height: 66,
      "& .MuiTableCell-root": {
        verticalAlign: 'top',
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '16px',
        borderColor: "#F8F9FB",
        backgroundColor: "#F8F9FB"
      }
  },
  TableBody: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        color: "#232033",
        borderColor: "#F8F9FB"
      }
  },
  differenceCol: {
    backgroundColor: "#F8F9FB",
    borderBottom: "none",
      "&.MuiTableCell-root": {
        borderBottom: "none"
      }
  }
}));

const CustomPaper = styled(Paper)({
  minWidth: 524, 
  "@media (max-width:1200px)": {
    minWidth: "unset"
  }
})

export default function Table({
    tableData,
    premiumField,
    premiumFieldName,
    inputsEnabled,
    onInputsChange,
    numbersRounding = 0,
    showColors = false,
    backgroundColor = '#FFF',
    difference = false
}) {
  const classes = useStyles()
  const currency = getCountryCurrencySymbol()
  const totalPolicies = tableData.reduce((acc, cur) => {
      acc = acc + +cur.policies_count;
      return acc;
  }, 0);

    const totalPremium = tableData.reduce((acc, cur) => {
        acc = acc + +cur.potential_premium;
        return acc;
    }, 0);

    const totalRevenue = tableData.reduce((acc, cur) => {
        acc = acc + +cur.difference;
        return acc;
    }, 0);

  return (
    <TableContainer component={difference ? CustomPaper : Paper} sx={{
        boxShadow: '0 4px 4px rgba(0, 0, 0, 0.12)',
        backgroundColor: backgroundColor,
    }}>
      <LibTable>
        <TableHead className={classes.tableHead}>
          <TableRow sx={showColors ? {
              borderLeft: '5px solid',
              borderColor: '#F8F9FB',
          } : null}>
            <TableCell style={{ minWidth: 111 }}>Product</TableCell>
            <TableCell>Number of Policies</TableCell>
            <TableCell>{premiumFieldName}</TableCell>
            {
            difference && <TableCell>Revenue Gap</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody  className={classes.TableBody}>
          {tableData.map((row, index) => (
            <TableRow key={row.product_type} sx={showColors ? {
                borderLeft: '5px solid',
                borderColor: colorsDefault[index],
            } : null}>
              <TableCell component="th" scope="row" sx={{
                textTransform: "capitalize"
              }}>{row.product_type}</TableCell>
              <TableCell component="th" scope="row" style={{
                  height: 65,
                  padding: inputsEnabled ? '0 16px' : '16px',
              }}>
                {inputsEnabled ? (
                    <NumericFormat
                        value={row.policies_count}
                        customInput={TextField}
                        thousandSeparator=","
                        variant="outlined"
                        placeholder="0"
                        sx={ backgroundColor !== '#FFF' ? {
                            backgroundColor: 'white',
                        } : null}
                        onValueChange={(values) => {
                            onInputsChange(row.product_type, 'policies', values.value)
                        }}
                    />
                    ) :
                    <NumberFormat
                        value={row.policies_count}
                        displayType="text"
                        thousandSeparator=","
                    />
                }
              </TableCell>
              <TableCell component="th" scope="row" style={{
                  height: 65,
                  padding: inputsEnabled ? '0 16px' : '16px',
              }}>
                {inputsEnabled ? (
                    <NumericFormat
                        value={row[premiumField]}
                        customInput={TextField}
                        thousandSeparator=","
                        variant="outlined"
                        sx={ backgroundColor !== '#FFF' ? {
                            backgroundColor: 'white',
                        } : null}
                        placeholder="0"
                        onValueChange={(values) => {
                            onInputsChange(row.product_type, premiumField, values.value)
                        }}
                    />
                ) : nFormatter(row[premiumField], numbersRounding, currency).toUpperCase()}
              </TableCell>
              {
              difference && <TableCell component="th" scope="row" className={classes.differenceCol} style={{
                height: 65,
                padding: inputsEnabled ? '0 16px' : '16px',
                }}>
                {nFormatter(row.difference, numbersRounding, currency).toUpperCase()}
              </TableCell>
              }
            </TableRow>
          ))}
          <TableRow sx={showColors ? {
              borderLeft: '5px solid',
              borderColor: '#DCDCDC',
          } : null}>
            <TableCell component="th" scope="row">Total</TableCell>
            <TableCell component="th" scope="row">
                <NumberFormat
                    value={totalPolicies}
                    displayType="text"
                    thousandSeparator=","
                />
            </TableCell>
            <TableCell component="th" scope="row">
              {nFormatter(totalPremium, numbersRounding, currency).toUpperCase()}
            </TableCell>
            {
            difference && <TableCell component="th" scope="row" className={classes.differenceCol}>{nFormatter(totalRevenue, numbersRounding, currency).toUpperCase()}</TableCell>
            }
          </TableRow>
        </TableBody>
      </LibTable>
    </TableContainer>
  );
}
