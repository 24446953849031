import { createSlice } from "@reduxjs/toolkit";
import { getCountryCurrencySymbol } from "../../../global/helpers";

const initialState = {
    ageFilter :[0,99],
    faceAmountFilter: {"All":true},
    genderFilter :{"All":true,"Male":false,"Not specified":false,"Female": false},
    locationFilter : "",
    stateFilter : "",
    cityFilter : "",
    locationLabels: [],
    statesLabels: [],
    citiesLabels: [],
    uiFilter :[0,0],
    uiLimits:[0,0],
    uiLimitsMarks:[],
    uiMapFilter:"",
    ProductFilter:{},
    BehavioralFilter:{"All":true},
    optimalTresh:0,
    optimalTreshPrecision:0,
    precisionConfig: null,
    precisionLevel: 'medium',
    correlation:{},
    precision: [],
    hiddenHeaders:[],
    premiumMedian:0,
    moreFilters: [
        {
            name: "Product",
            isActive: false,
        },
        {
            name: "Household Income",
            isActive: false,
        }
    ]
}
const currency = getCountryCurrencySymbol()
const name = 'Charts'

export const ChartsSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setAgeFilter : (state,action) =>{
            state.ageFilter= action.payload 
        },
        setGenderFilter : (state,action) =>{
            state.genderFilter= action.payload
        },
        setLocationFilter : (state,action) =>{
            state.locationFilter= action.payload
        },
        setStateFilter : (state,action) =>{
            state.stateFilter= action.payload
        },
        setCityFilter : (state,action) =>{
            state.cityFilter= action.payload
        },
        setLocationLabels : (state,action) =>{
            state.locationLabels= action.payload
        },
        setStatesLabels : (state,action) =>{
            state.statesLabels= action.payload
        },
        setCitiesLabels : (state,action) =>{
            state.citiesLabels = action.payload
        },
        setUIFilter : (state,action) =>{
            state.uiFilter= action.payload
        },
        setUILimits : (state,action) =>{
            state.uiLimits = [0, action.payload]
        },
        setUILimitsMarks : (state,action) => {
            state.uiLimitsMarks = [{ value: 0, label: `${currency}0`}, action.payload]
        },
        setUIMapFilter : (state,action) =>{
            state.uiMapFilter = action.payload
        },
        setFaceAmountFilter: (state, action) => {
            state.faceAmountFilter = action.payload
        },
        setProductFilter :(state,action) =>{
            state.ProductFilter = action.payload
        },
        setBehavioralFilter :(state,action) =>{
            state.BehavioralFilter = action.payload
        },
        setMoreFilters: (state,action) =>{
            state.moreFilters = action.payload
        },
        setOptimalTresh: (state,action) =>{
            state.optimalTresh = action.payload
        },
        setOptimalTreshPrecision: (state,action) =>{
            state.optimalTreshPrecision = action.payload
        },
        setCorrelation: (state,action) =>{
            state.correlation= action.payload
        },
        setPrecision: (state, action) => {
            state.precision = action.payload
        },
        setPremiunMedian: (state,action) =>{
            state.premiumMedian = action.payload
        },
        setHiddenHeaders: (state,action) =>{
            state.hiddenHeaders = action.payload
        },
        setPrecisionConfig: (state,action) =>{
            state.precisionConfig = action.payload
        },
        setPrecisionLevel: (state,action) =>{
            state.precisionLevel = action.payload
        },
        resetFilters: (state) => {
            state.ageFilter = {"All":true}
            state.faceAmountFilter = {"All":true}
            state.genderFilter = {"All":true,"Male":false,"Not specified":false,"Female": false}
            state.locationFilter = ""
            state.stateFilter = ""
            state.cityFilter = ""
            state.locationLabels = []
            state.statesLabels = []
            state.citiesLabels = []
            state.uiFilter = [0,0]
            state.uiLimits = [0,0]
            state.uiLimitsMarks = []
            state.uiMapFilter = ""
            state.ProductFilter = {}
            state.BehavioralFilter = {"All":true}
            state.optimalTresh = 0
            state.optimalTreshPrecision = 0
            state.correlation = {}
            state.precision = []
            state.hiddenHeaders = []
            state.premiumMedian = 0
            state.moreFilters = [
                {
                    name: "Product",
                    isActive: false,
                },
                {
                    name: "Household Income",
                    isActive: false,
                }
            ]
          
        },
        resetAgeFilter: (state)=>{
            state.ageFilter =[0,99]
        },
        resetGenderFilter: (state)=>{
            state.genderFilter ={"All":true,"Male":false,"Not specified":false,"Female": false}
        },
        resetLocationFilter: (state)=>{
            state.locationFilter =""
        },
        resetUIFilter: (state)=>{
            state.uiFilter =state.uiLimits
            state.uiLimits=[0,0]
            state.uiLimitsMarks=[]
        },
        resetProductFilter: (state)=>{
            state.ProductFilter={}
        },
        resetMoreFilters: (state) => {
            state.moreFilters = [
                {
                    name: "Product",
                    isActive: false,
                },
                {
                    name: "Household Income",
                    isActive: false,
                }
            ] 
        },
     
    }
})

export const {
    setUIMapFilter,
    setAgeFilter,
    setGenderFilter,
    setLocationFilter,
    setStateFilter,
    setCityFilter,
    setLocationLabels,
    setStatesLabels,
    setCitiesLabels,
    setUIFilter,
    setOptimalTresh,
    setOptimalTreshPrecision,
    setPrecision,
    setUILimits,
    setUILimitsMarks,
    setProductFilter,
    setMoreFilters,
    resetFilters,
    setCorrelation,
    setPremiunMedian,
    setFaceAmountFilter,
    setBehavioralFilter,
    setHiddenHeaders,
    setPrecisionConfig,
    setPrecisionLevel,
    resetAgeFilter,
    resetGenderFilter,
    resetLocationFilter,
    resetUIFilter,
    resetProductFilter,
    resetMoreFilters
} = ChartsSlice.actions


export const selectCharts = (state) => state.Charts




export default ChartsSlice.reducer