export const REACT_APP_WEBSOCKET_URL = "wss://";

export const defaultChartColors = ["#6D69C1", "#a2e2ec", "#8E42BE", "#36ACA0", "#3695ac", "#BB36BB"]

export const mapperItemsUI = {
  "policy": false,
  "Product": false,
  "Policy Issue Date": false,
  "Age": false,
  "Gender": false,
  "Zip": false,
  "Premium": false,
  "Face Amount": false
} 
export const mapperItemsLapse = {
  "policy": false,
  "Product": false,
  "Policy Issue Date": false,
  "Age": false,
  "Gender": false,
  "Zip": false,
  "Premium": false,
  "Face Amount": false
}  

export const defaultColumnsAnnuities = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Age": true, 
    "Contract Value": true, 
    "Years Left in Surrender Charge": true, 
    "Income Account Value": false, 
    "Surrender Value": true, 
    "LIB Rider Status": false, 
    "LIB Fee": false, 
    "LIB Payment": false, 
    "Withdraw Activity": false, 
    "Subaccount activity": false, 
    "Contract Loss": false, 
    "Contract Loss %": false, 
    "Income Loss": false, 
    "Income Loss %": false, 
    "Withdraw Annual": false, 
    "Issue State": true, 
    "Residency State": false, 
    "Agent": false, 
    "Agent Status": false, 
    "Upside Story": true, 
    "AUM Upside": true, 
    "Priority": true 
  }
export const defaultColumnsRunAnnuities = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Age": true, 
    "Contract Value": true, 
    "Surrender Value": true, 
    "Years Left in Surrender Charge": true, 
    "Upside Story": true, 
    "Surrender Probability": true, 
    "AUM Upside": true, 
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
export const defaultColumnsNewLapse = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Face Amount": true, 
    "Issue Date": true, 
    "Upside Story": true, 
    "Suggested Premium": true, 
    "Premium Sensitivity": true,
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
export const defaultColumnsLife = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Face Amount": true, 
    "Issue Date": true, 
    "Upside Story": true, 
    "Premium Upside": true, 
    "Probability to lapse": true,
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
export const defaultColumnsLifeWL = { 
    "Policy ID": true, 
    "Product Name": true, 
    "Product Type": true, 
    "Current Age": true, 
    "Annual Premium": true, 
    "Face Amount": true, 
    "Cash Value": true, 
    "Issue Date": true, 
    "Upside Story": true, 
    "Suggested Premium": true, 
    "Premium Sensitivity": true,
    "Status": true, 
    "Opportunity Expiration Date": true 
  }
export const defaultColumnsCluster = { 
    "ID": true, 
    "Product Name": true, 
    "Current Age": true, 
    "Annual Premium": false, 
    "Face Amount": false, 
    "Date of Birth": true, 
    "State": true, 
    "City": true,
    "Agency": true,
    "Group": true,
    "Income": true,
    "Investment Focus": true,
    "Cluster": true
}

export const sortKeysAnnuities = {
  0: "policy",
  1: "MARKET_NAME_LAG",
  2: "product_type",
  3: "age",
  4: "cash_value",
  5: "YRS_REMAIN_SC",
  6: "IAV_LAG",
  7: "CSV_LAG",
  8: "RIDER_STATUS_LAG_WITH_FEE",
  9: "QTD_LIBR_WITHS_MONTH_CUMSUM_LAG",
  10: "RATIO_WITHS_LAG",
  11: "SUB_ACTIVITY",
  12: "CONTRACT_LOSS",
  13: "CONTRACT_LOSS_DOLLARS",
  14: "INCOME_ACOUNT_LOSS",
  15: "INCOME_ACOUNT_LOSS_DOLLARS",
  16: "QTD_WITHS_MONTH_CUMSUM_LAG",
  17: "State",
  18: "RES_STATE_LAG",
  19: "AGENT_ID_LAG",
  20: "AGENT_STATUS",
  21: "story",
  22: "medium_ppr",
  23: "PRIORITY",
}

export const sortKeysLife = {
  "Policy Id": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product_type",
  "Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Issue Date": "issue_date",
  "Upside Story": "story",
  "Premium Upside": "mean_premium_term",
  "Probability to lapse": "lapse_score",
  "Suggested Premium": "annualized_premium_res",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
}

export const sortKeysRunAnnuities = {
  "Policy Id": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story",
  "Cluster": "story",
  "Surrender Probability": "PRIORITY",
  "AUM Upside": "medium_ppr",
  "Status": "status",
  "Opportunity Expiration Date": "issue_date",
}

export const sortKeysRunAnnuitiesUI = {
  "Policy Id": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story",
  "Surrender Probability": "PRIORITY",
  "AUM Upside": "mean_premium_term",
  "Status": "status",
  "Opportunity Expiration Date": "issue_date",
}

export const sortKeysRunLife = {
  "Policy Id": "policy",
  "Product Name": "MARKET_NAME_LAG",
  "Product Type": "product_type",
  "Current Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Cash Value": "cash_value",
  "Issue Date": "issue_date",
  "Date of Birth": "dob",
  "State": "State",
  "City": "City",
  "Agency": "partner",
  "Group": "Group",
  "Income": "per_cap_income",
  "Investment Focus": "inv_risk",
  "Upside Story": "story",
  "Cluster": "cluster",
  "Premium Upside": "premium",
  "Suggested Premium": "annualized_premium_res",
  "Probability to lapse": "lapse_score",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
  "Status": "status",
  "Opportunity Expiration Date": "issue_date",
}

export const headerKeysAnnuities = {
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Age": "Age",
  "Contract Value": "Contract Value",
  "Yrs Left in Surr Charge": "Years Left in Surrender Charge",
  "Income Account Value": "Income Account Value",
  "Surrender Value": "Surrender Value",
  "LIBR status": "LIB Rider Status",
  "LIBR Payment if Active": "LIB Payment",
  "Withdraw Activity": "Withdraw Activity",
  "Subaccount activity": "Subaccount activity",
  "Contract Loss $": "Contract Loss",
  "Contract Loss %": "Contract Loss %",
  "Income Account Loss $": "Income Loss",
  "Income loss %": "Income Loss %",
  "Annual withdrawals": "Withdraw Annual", 
  "Issue state": "Issue State",
  "Residency State": "Residency State",
  "Agent Name": "Agent",
  "Agent status": "Agent Status",
  "Upside Story": "Upside Story",
  "AUM Upside": "AUM Upside",
  "Priority": "Priority",
}

export const headerKeysLife = {
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Current Age": "Current Age",
  "Annual Premium": "Annual Premium",
  "Face Amount": "Face Amount",
  "Cash Value": "Cash Value",
  "Issue Date": "Issue Date",
  "Upside Story": "Upside Story",
  "Premium Upside": "Premium Upside",
  "Suggested Premium": "Suggested Premium",
  "Probability to lapse": "Probability to lapse",
  "Premium Sensitivity": "Premium Sensitivity",
}

export const prospectsAnnuitiesTableHeaders = [
  'Policy Id',
  'Product Name',
  'Product Type',
  'Age',
  'Contract Value',
  'Yrs Left in Surr Charge',
  'Income Account Value',
  'Surrender Value',
  'LIBR status',
  'LIBR Payment if Active',
  'Withdraw Activity',
  'Subaccount activity',
  'Contract Loss %',
  'Contract Loss $',
  'Income loss %',
  'Income Account Loss $',
  'Annual withdrawals',
  'Issue state',
  'Residency State',
  'Agent Name',
  'Agent status',
  'Upside Story',
  'AUM Upside',
  'Priority',
]

export const prospectsLifeTableHeaders = [
  'Policy Id',
  'Product Name',
  'Product Type',
  'Current Age',
  'Annual Premium',
  'Face Amount',
  'Issue Date',
  'Upside Story',
  'Premium Upside',
  'Probability to lapse',
  'Suggested Premium',
  'Premium Sensitivity',
] 

export const runLifeTableHeaders = [
  'Policy Id',
  'Product Name',
  'Product Type',
  'Current Age',
  'Annual Premium',
  'Face Amount',
  'Issue Date',
  'Cash Value',
  'Upside Story',
  'Premium Upside',
  'Probability to lapse',
  'Suggested Premium',
  'Premium Sensitivity',
  'Status',
  'Opportunity Expiration Date',
] 

export const headerRunKeysLife = {
  "ID": "ID",
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Current Age": "Current Age",
  "Annual Premium": "Annual Premium",
  "Face Amount": "Face Amount",
  "Cash Value": "Cash Value",
  "Issue Date": "Issue Date",
  "Date of Birth": "Date of Birth",
  "State": "State",
  "City": "City",
  "Agency": "Agency",
  "Group": "Group",
  "Income": "Income",
  "Investment Focus": "Investment Focus",
  "Upside Story": "Upside Story",
  "Cluster": "Cluster",
  "Premium Upside": "Premium Upside",
  "Suggested Premium": "Suggested Premium",
  "Probability to lapse": "Probability to lapse",
  "Premium Sensitivity": "Premium Sensitivity",
  "Status": "Status",
  "Opportunity Expiration Date": "Opportunity Expiration Date",
}

export const runAnnuitiesTableHeaders = [
  'Policy Id',
  'Product Name',
  'Product Type',
  'Age',
  'Contract Value',
  'Surrender Value',
  'Years Left in Surrender Charge',
  'Upside Story',
  'Surrender Probability',
  'AUM Upside',
  'Status',
  'Opportunity Expiration Date',
]

export const runClusterTableHeaders = [
  'ID',
  'Product Name',
  'Product Type',
  'Current Age',
  'Date of Birth',
  'State',
  'City',
  'Agency',
  'Group',
  'Income',
  'Investment Focus',
  'Cluster',
  'Suggested Premium',
]

export const headerRunKeysAnnuities = {
  "Policy Id": "Policy ID",
  "Product Name": "Product Name",
  "Product Type": "Product Type",
  "Age": "Age",
  "Contract Value": "Contract Value",
  "Surrender Value": "Surrender Value",
  "Years Left in Surrender Charge": "Years Left in Surrender Charge",
  "Upside Story": "Upside Story",
  "Cluster": "Cluster",
  "Surrender Probability": "Surrender Probability",
  "AUM Upside": "AUM Upside",
  "Status": "Status",
  "Opportunity Expiration Date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}

export const prospectsAnnuitiesDataKeys = {
  "policy": "Policy ID",
  "product_market_name": "Product Name",
  "product_type": "Product Type",
  "age": "Age",
  "contract_value": "Contract Value",
  "years": "Years Left in Surrender Charge",
  "account_value": "Income Account Value",
  "surr_value": "Surrender Value",
  "libr_status": "LIB Rider Status",
  "libr_payment": "LIB Payment",
  "withdraw_activity": "Withdraw Activity",
  "sub_activity": "Subaccount activity",
  "contract_loss_dollars": "Contract Loss",
  "income_loss_dollars": "Income Loss",
  "contract_loss": "Contract Loss %",
  "income_loss": "Income Loss %",
  "withdraw_annual": "Withdraw Annual",
  "issue_state": "Issue State",
  "residence_state": "Residency State",
  "agent_id": "Agent",
  "agent_status": "Agent Status",
  "story": "Upside Story",
  "aum_upside": "AUM Upside",
  "priority": "Priority",
}

export const prospectsLifeDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "cash_value": "Cash Value",
  "issue_date": "Issue Date",
  "story": "Upside Story",
  "mean_premium_term": "Premium Upside",  
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "lapse_score": "Probability to lapse",
  "annualized_premium_sensitivity_NP": "Premium Sensitivity",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City"
}
export const runLifeDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "cash_value": "Cash Value",
  "issue_date": "Issue Date",
  "story": "Upside Story",
  "mean_premium_term": "Premium Upside",  
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "lapse_score": "Probability to lapse",
  "annualized_premium_sensitivity_NP": "Premium Sensitivity",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City"
}

export const runClusterDataKeys = {
  "policy": "Policy ID",
  "product_name": "Product Name",
  "product_type": "Product Type",
  "age": "Current Age",
  "premium": "Annual Premium",
  "face_amount": "Face Amount",
  "dob": "Date of Birth",
  "story": "Cluster",
  "mean_premium_term": "Premium Upside",
  "annualized_premium_res": "Suggested Premium",
  "priority": "Probability to lapse",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}
export const runAnnuitiesDataKeysLapse = {
  "policy": "Policy ID",
  "MARKET_NAME_LAG": "Product Name",
  "product": "Product Type",
  "age": "Age",
  "cash_value": "Contract Value",
  "CSV_LAG": "Surrender Value",
  "YRS_REMAIN_SC": "Years Left in Surrender Charge",
  "story": "Upside Story",
  "PRIORITY": "Surrender Probability",
  "medium_ppr": "AUM Upside",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}
export const runAnnuitiesDataKeysUI = {
  "policy": "Policy ID",
  "MARKET_NAME_LAG": "Product Name",
  "product": "Product Type",
  "age": "Age",
  "cash_value": "Contract Value",
  "CSV_LAG": "Surrender Value",
  "YRS_REMAIN_SC": "Years Left in Surrender Charge",
  "story": "Upside Story",
  "mean_premium_term": "AUM Upside",
  "status": "Status",
  "expiration_date": "Opportunity Expiration Date",
  "State": "State",
  "City": "City",
}


export const prospectsLifeHeaderKeys = {
  "Policy ID": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Current Age": "age",
  "Annual Premium": "premium",
  "Face Amount": "face_amount",
  "Cash Value": "cash_value",
  "Issue Date": "issue_date",
  "Upside Story": "story",
  "Cluster": "story",
  "Premium Upside": "mean_premium_term",
  "Suggested Premium": "annualized_premium_res",
  "Probability to lapse": "priority",
  "Premium Sensitivity": "annualized_premium_sensitivity_NP",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
}


export const runAnnuitiesHeaderKeysLapse = {
  "Policy ID": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story",
  "Cluster": "story",
  "Surrender Probability": "PRIORITY",
  "AUM Upside": "medium_ppr",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
}
export const runAnnuitiesHeaderKeysUI = {
  "Policy ID": "policy",
  "Product Name": "product_name",
  "Product Type": "product_type",
  "Age": "age",
  "Contract Value": "cash_value",
  "Surrender Value": "CSV_LAG",
  "Years Left in Surrender Charge": "YRS_REMAIN_SC",
  "Upside Story": "story",
  "Cluster": "story",
  "Probability to lapse": "PRIORITY",
  "AUM Upside": "mean_premium_term",
  "Status": "status",
  "Opportunity Expiration Date": "expiration_date",
}

export const frozenGridFieldsAnnuities = ["story", "aum_upside", "priority"]
export const frozenGridFieldsLife = ["story", "annualized_premium_res", "annualized_premium_sensitivity_NP", "mean_premium_term", "priority"]
export const frozenGridFieldsRunLife = ["story","annualized_premium_res", "annualized_premium_sensitivity_NP", "mean_premium_term", "lapse_score", "status", "expiration_date"]
export const frozenGridFieldsRunUILife = ["story", "mean_premium_term", "status", "expiration_date"]
export const frozenGridFieldsRunAnnuities = ["story", "PRIORITY",  "medium_ppr", "status", "expiration_date"]
export const frozenGridFieldsRunUIAnnuities = ["story", "mean_premium_term", "status", "expiration_date"]
export const frozenGridColumnsPositions = {
  0: 0,
  1: 101,
  2: 199,
}

export const frozenGridRunColumnsPositions = {
  0: 0,
  1: 125,
  2: 225,
  3: 342,
  4: 461
}

export const frozenGridRunUIColumnsPositions = {
  0: 0,
  1: 125,
  2: 225,
  3: 335
}


export const frozenGridRunColumnsPositionsAnnuities = {
  0: 0,
  1: 125,
  2: 224,
  3: 325,
  4: 454
}

export const frozenGridRunUIColumnsPositionsAnnuities = {
  0: 0,
  1: 125,
  2: 225,
  3: 325
}

export const frozenGridColumnsAnnuities = [
    'Upside Story',
    'Surrender Probability',
    'AUM Upside',
    'Status',
    'Opportunity Expiration Date',
]
export const frozenGridColumnsLife = [
    'Upside Story',
    'Suggested Premium',
    'Premium Sensitivity',
    'Premium Upside',
    'Probability to lapse',
]

export const frozenGridColumnsCluster = [
  'Cluster',
  'Suggested Premium',
]

export const frozenRunGridColumnsLife = [
    'Upside Story',
    'Suggested Premium',
    'Premium Sensitivity',
    'Premium Upside',
    'Probability to lapse',
    'Status',
    'Opportunity Expiration Date',
]

export const projectsSortKeys = {
  "Creation Date": "created_at",
  "Processed Records": "total_policies",
  "Number of Prospects": "prospects",
  "Potential AUM Upside": "aum",
  "Potential Premium Upside": "aum",
  "Covered by Campaigns": "campaigns",
}

export const runsSortKeys = {
  "Run Name": "model_type",
  "Run Date": "created_at",
  "Run Status": "run_id",
  "Prediction Date": "created_at",
  "Run ID": "run_id",
  "Status": "status",
  "Number of Prospects": "total_policies",
  "Premium Upside": "potential_retained",
  "Suggested Premium": "annualized_premium_res",
  "AUM Upside": "potential_retained",
  "Covered by Campaigns": "run_id"
}
export const lapseOnlyFields = ["Probability to lapse", 'Premium Sensitivity', "Surrender Probability", 'Suggested Premium']

export const reportTabsLife = [
  'Premium Breakdown',
  'Prospects',
]
export const reportTabsAnnuities = [
  'AUM Breakdown',
  'Prospects',
]
export const reportTabsCluster = [
  'Prospects Breakdown',
  'Prospects',
]

export const demoCsv = {
  headers: [
    "",
    "policy",
    "termdate",
    "product",
    "issue_date",
    "age",
    "gender",
    "zipcode",
    "premium",
    "face_amount"
  ],
  data_first_rows: [
    [
        "0",
        "140671",
        "2024-01-01",
        "Term Life",
        "2018-09-06",
        "44",
        "F",
        "29625",
        "438",
        "250000",
    ],
    [
        "1",
        "691596",
        "2024-01-01",
        "Term Life",
        "2020-11-18",
        "35",
        "F",
        "54703",
        "206.55",
        "300000",
    ],
    [
        "2",
        "88681",
        "2024-01-01",
        "Term Life",
        "2018-08-03",
        "31",
        "M",
        "75214",
        "304",
        "1600000",
    ],
    [
        "3",
        "158652",
        "32",
        "M",
        "30525",
        "352.8",
        "750000",
        "Term Life",
        "0.0072354283",
        "GA",
        "158652",
        "2018-12-18",
        "2024-01-01"
    ],
    [
        "5",
        "348377",
        "56",
        "M",
        "29707",
        "473.64",
        "100000",
        "Term Life",
        "0.009325665",
        "SC",
        "348377",
        "2019-08-14",
        "2024-01-01"
    ],
    [
        "8",
        "12807",
        "44",
        "M",
        "48872",
        "1690",
        "1450000",
        "Term Life",
        "0.008639509",
        "MI",
        "12807",
        "2018-03-12",
        "2024-01-01"
    ],
    [
        "9",
        "261928",
        "59",
        "M",
        "50457",
        "1559.68",
        "300000",
        "Term Life",
        "0.00887646",
        "IA",
        "261928",
        "2019-04-30",
        "2024-01-01"
    ],
    [
        "10",
        "24191",
        "58",
        "M",
        "95023",
        "2040",
        "250000",
        "Term Life",
        "0.009236691",
        "CA",
        "24191",
        "2018-03-06",
        "2024-01-01"
    ],
    [
        "11",
        "459753",
        "33",
        "F",
        "53711",
        "365.09",
        "1000000",
        "Term Life",
        "0.010410249",
        "WI",
        "459753",
        "2020-01-03",
        "2024-01-01"
    ],
    [
        "12",
        "761016",
        "36",
        "M",
        "60081",
        "1515.29",
        "750000",
        "Term Life",
        "0.008097954",
        "IL",
        "761016",
        "2021-01-27",
        "2024-01-01"
    ]
  ],
}

export const priorityMaping = {
  "H": "high",
  "M": "mid",
  "L": "low"
}

export const usStatesData = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]