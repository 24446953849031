import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    root: {
        background: "#fff",
        padding: "24px 46px",
        borderRadius: "8px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
    },
    container: {
        marginTop: 0,
        "& .MuiGrid-item.MuiGrid-grid-xs-3": {        
            paddingRight: 19,
            marginTop: 20,
            "&:nth-child(4n)": {
                paddingRight: 0
            },
            "&:nth-child(-n+4)": {
                marginTop: 0
            }
        }
    },
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: 235,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        }
    },
    tabActive: {
        color: '#363ED3',
        height: 39,
        position: 'relative',
        bottom: -1
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 25,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px",
    },
    card: {
        textAlign: 'center',
        paddingTop: 14,
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    cardActions: {
        justifyContent: 'center',
        paddingTop: 23,
        paddingBottom: 28
    },
    connectorLogo: {
        marginBottom: 23
    },
    confirmButton: {
        color: "#FFF",
        background: "#363ED3",
        borderRadius: "10px",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 20px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#363ED3",
            opacity: 0.85
        }
    },
}))