import Modal from "@material-ui/core/Modal";
import React from "react";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Backdrop from '@mui/material/Backdrop';
import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../Header/styles";
import Close from '../../../Assets/icon-close.svg';
import NewBusinessIcon from "../../../Assets/icon-demo-newBusiness.svg";
import RetentionIcon from "../../../Assets/icon-demo-retention.svg";
import NumberFormat from "react-number-format";
import {DEFAULT_CALCULATION_VALUES} from "../useCalculations/useCalculations";

export const UIButton = styled(Button)({
  ...basicButtonStyles,
  width: 'auto',
  padding: '14px 34px',
  maxWidth: '240px',
  height: 'auto',
  borderRadius: '10px',
  backgroundColor: 'transparent',
  borderColor: '#515151',
  color: '#515151',
  fontSize: '16px',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: 'transparent',
    borderColor: '#515151',
    boxShadow: 'none',
  }
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  boxShadow: 24,
  p: '20px 32px',
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    margin: '30px 0',
  },
  colWrapper: {
    '&:nth-child(2)': {
      padding: '0 20px',
    }
  },
  colTitle: {
    marginBottom: '10px',
    fontSize: '16px',
    fontWeight: 700,
  },
  colItem: {
    display: 'flex',
    alignItems: 'center',
    '& .combined': {
      display: 'flex',
    },
    '& img': {
      minWidth: '23px',
      marginRight: '10px',
    }
  },
  colContainer: {
    '& p': {
      minWidth: '150px',
      marginRight: '10px',
      fontSize: '16px',
    },
    '& span': {
      minWidth: '150px',
      fontSize: '16px',
      fontWeight: 700,
    }
  },
  closeContainer: {
    position: 'absolute',
    top: '26px',
    right: '28px',
    cursor: 'pointer',
    zIndex: 1,
  },
}));

const PolicyDetailsModal = ({ isOpen, onClose, policyData }) => {
  const classes = useStyles();

  if (!policyData) return null;

  const getPotentialPremium = ({ potentialPremium, productType }) => {
    if (potentialPremium) return potentialPremium;
    const avgLapse = productType === 'Term Life' ? DEFAULT_CALCULATION_VALUES.AVG_LAPSE_TERM : DEFAULT_CALCULATION_VALUES.AVG_LAPSE_WL;
    return 1 * avgLapse * 4;
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className={classes.closeContainer} onClick={onClose}>
              <img src={Close} alt="To try the demo" />
            </div>
            <div className="page-title" style={{ margin: '10px 0' }}>
              <h1>Policy Details</h1>
            </div>
            <div className={classes.container}>
              <div  className={classes.colWrapper}>
                <div className={classes.colTitle}>Existing Policy Information</div>
                <div className={classes.colContainer}>
                  <div className={classes.colItem}>
                    <p>Policy ID</p>
                    <span>{policyData.policyId}</span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Product Type</p>
                    <span>{policyData.productType}</span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Annual Premium</p>
                    <span>${policyData.annualPremium}</span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Face Amount</p>
                    <span>
                      {<NumberFormat
                          value={policyData.faceAmount}
                          displayType="text"
                          thousandSeparator=","
                          prefix="$"
                      />}
                    </span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Gender</p>
                    <span>{policyData.gender === 'M' ? 'Male' : 'Female'}</span>
                  </div>
                </div>
              </div>
              <div  className={classes.colWrapper}>
                <div className={classes.colTitle}>Opportunity</div>
                <div className={classes.colContainer}>
                  <div className={classes.colItem}>
                    <p>Opportunity Type</p>
                    <div className="combined">
                      {policyData.action === 'New Business'
                          ? <img src={NewBusinessIcon} alt="New Business" />
                          : <img src={RetentionIcon} alt="Retention" />
                      }
                      <span>{policyData.action}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div  className={classes.colWrapper}>
                <div className={classes.colTitle}>Policy Potential</div>
                <div className={classes.colContainer}>
                  <div className={classes.colItem}>
                    <p>Product Type</p>
                    <span>{policyData.productType}</span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Annual Premium</p>
                    <span>
                      {<NumberFormat
                          value={getPotentialPremium(policyData)}
                          displayType="text"
                          thousandSeparator=","
                          prefix="$"
                      />}
                    </span>
                  </div>
                  <div className={classes.colItem}>
                    <p>Face Amount</p>
                    <span>
                      {<NumberFormat
                          value={policyData.potentialFaceAmount || policyData.faceAmount}
                          displayType="text"
                          thousandSeparator=","
                          prefix="$"
                      />}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="center" style={{ marginBottom: 10 }}>
              <UIButton
                  onClick={onClose}
                  style={{ padding: '13px 25px', width: '260px' }}
              >
                Close</UIButton>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  )
};

export default PolicyDetailsModal;
