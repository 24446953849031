import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"

import { Grid, Popover, Typography, IconButton } from "@material-ui/core"
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const useStyles = makeStyles(() => ({
    dotsMenu: {
        "&:hover, &:focus": {
            backgroundColor: "#dee9fe"
        }
    },
    popoverContainer: {
        "& .MuiPaper-root": {
            background: "#fff",
            borderRadius: 10,
            border: "1px solid #D9E1EE",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)"
        }
    },
    actionsPopoverContainer: {
        borderRadius: 12,
        backgroundColor: "#fff",
        padding: 12,
    },
    actionButtonContainer: {
        width: '100%',
        textAlign: "left",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0000001f"
        }
    },
    actionButton: {
        color: "#312E40",
        padding: "3px 0",
        margin: 0,
        fontFamily: "Roboto",
        fontSize: "14px!important",
        fontWeight: "400!important",
    }
}))

export const MenuPopover = ({ menuItems }) => {


    const classes = useStyles()

    const [openActionsPopover, setOpenActionsPopover] = useState(false)
    const [popoverAnchor, setPopoverAnchor] = useState({ left: 0, top: 0 })

    if (menuItems === undefined) return <React.Fragment />;


    const handleActionsClick = (e) => {
        e.stopPropagation();
        const position = e.target.getBoundingClientRect();
        setPopoverAnchor({ top: position.top, left: position.left });
        setOpenActionsPopover((openActions) => !openActions);
    }


    return <React.Fragment>

        <IconButton className={classes.dotsMenu} aria-label="moreVert" >
            <MoreVertIcon onClick={(e) => handleActionsClick(e)} />
        </IconButton>

        <Popover
            open={openActionsPopover}
            anchorReference="anchorPosition"
            anchorPosition={popoverAnchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className={classes.popoverContainer}
            onClick={() => setOpenActionsPopover(false)}>
            <Grid container className={classes.actionsPopoverContainer}>
                {menuItems.map(({ name, onClick }, index) =>
                    <Grid item className={classes.actionButtonContainer} onClick={onClick} key={index}>
                        <Typography className={classes.actionButton}>
                            {name}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Popover>
    </React.Fragment>
}