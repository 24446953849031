import React from "react"
import { defaultChartColors } from "../../../../global/constants"

const Legend = ({ data }) => {

    return (
        <div className="chartLegend">
            {data?.map(({ id , index }) => (
            <div key={index} className="chartLegend-item">
                <div className="chartLegend-colorIndicator" style={{ backgroundColor: defaultChartColors[index] }} />
                <p>{id}</p>
            </div>
           ))}
        </div>
    )
}

export default Legend