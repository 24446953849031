import Header from "../Header";
import React from "react";
import Container from "@mui/material/Container";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import PolicyDetailsModal from "../PolicyDetailsModal/PolicyDetailsModal";

const possibleModalNames = 'contact | policyDetails';

const defaultModalSetup = {
    name: '',
    value: undefined,
}

const DemoLayout = ({
    children,
    pageTitle,
    pageTitleElement,
    overlayBgColor = '#F8F9FB',
    containerBgColor = '#FFF',
    modalInfo = defaultModalSetup,
    wrapped = true,
    onModalClose
}) => (
    <>
        <ContactUsModal
            isOpen={modalInfo.name === 'contact'}
            text={modalInfo.value}
            onClose={()=> onModalClose(defaultModalSetup)}
        />
        <PolicyDetailsModal
            isOpen={modalInfo.name === 'policyDetails'}
            policyData={modalInfo.value}
            onClose={()=> onModalClose(defaultModalSetup)}
        />
        <Header />
        <div className="page-root-wrapper" style={{ backgroundColor: overlayBgColor }}>
            <Container maxWidth="lg">
                <div className="page-title">
                    {pageTitleElement ? pageTitleElement : <h1>{pageTitle}</h1>}
                </div>
                {wrapped
                    ? (
                        <div className="page-content" style={{ backgroundColor: containerBgColor }}>
                            {children}
                        </div>
                    )
                    : children
                }
            </Container>
        </div>
    </>
)

export default DemoLayout;