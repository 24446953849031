import React from "react";
import {UIAnnotateButton, useStyles} from "./styles";
import FilterDropDownInput from "../../ui/FilterDropDownInput";
import {nFormatter} from "../../../global/helpers";
import NewBusinessIcon from '../../../Assets/icon-demo-newBusiness.svg';
import RetentionIcon from '../../../Assets/icon-demo-retention.svg';
import TotalIcon from '../../../Assets/icon-total.svg';

const CellBlock = ({ children, classNames, customStyles }) => {
  const classes = classNames + ' ' + useStyles().cellBlock;
  return (
      <div className={classes} style={customStyles}>{children}</div>
  )
}

const AnnotationBlock = ({ icon, type, totals, onClick, buttonText, height = 'auto' }) => {
    const classes = useStyles();
    return (
        <div className={classes.annotationItem} style={{ height }}>
            <div className={classes.annotationItemIcon}>
                <img src={icon} alt={type} />
            </div>
            <div className={classes.annotationItemContent}>
                <h3>{type}</h3>
                {totals && totals.map(({ title, count }) => (
                    <div key={title}>
                        <p>{title}</p>
                        <span>{count}</span>
                    </div>
                ))}
                {buttonText && <UIAnnotateButton onClick={onClick}>{buttonText}</UIAnnotateButton>}
            </div>
        </div>
    )
}

const NewOpportunitiesTable = ({ tableData }) => {
  const styles = useStyles();

  const retentionTotals = tableData.find(({ info }) => info.title === 'Retention')
  const newBusinessTotals = tableData.find(({ info }) => info.title === 'New Business')

  return (
    <div className={styles.container}>
      <div className={styles.annotationWrapper}>
        <AnnotationBlock
            icon={NewBusinessIcon}
            type="New Business"
            totals={[
                { title: 'Total Policies', count: newBusinessTotals.info.totalSimplePolicies },
                { title: 'Total Annual Premium', count: nFormatter(newBusinessTotals.info.totalPremiumPolicies, 2) },
            ]}
            buttonText="Data Modelling Report"
            height={230}
        />
        <AnnotationBlock
            icon={RetentionIcon}
            type="Retention"
            totals={[
                { title: 'Total Policies', count: retentionTotals.info.totalSimplePolicies },
                { title: 'Total Annual Premium', count: nFormatter(retentionTotals.info.totalPremiumPolicies, 2) },
            ]}
            buttonText="Data Modelling Report"
            height={290}
        />
        <AnnotationBlock
            icon={TotalIcon}
            type="Total"
        />
      </div>
      <table className={styles.table} cellSpacing={0}>
        <thead>
          <tr className={styles.headRowBlock}>
            <th className={styles.headBlock}>Opportunity</th>
            <th className={styles.headBlock}>Number of Policies</th>
            <th className={styles.headBlock}>Annual Premium</th>
            <th className={styles.headBlock}>Priority</th>
            <th className={styles.headBlock}>Action</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
        {tableData.map(({ opportunity }) => opportunity.map(({ title, policies, premium, priority }, index) => (
            <tr key={index} className={styles.rowBlock}>
              <td><CellBlock classNames={styles.opportunityTitle}>{title}</CellBlock></td>
              <td><CellBlock>{policies}</CellBlock></td>
              <td><CellBlock>{premium}</CellBlock></td>
              <td>
                <CellBlock
                    classNames={styles.prioBlock}
                    customStyles={{ color: priority === 'High' ? '#FF4B62' : '#E79C00' }}
                >
                  {priority}
                </CellBlock>
              </td>
              <td>
                <CellBlock classNames={styles.dropdownCell}>
                  <FilterDropDownInput
                      label="Product"
                      options={[
                        { label: 'Schedule a Meeting', value: 'meeting' },
                        { label: 'Ask for a call', value: 'call' },
                        { label: 'Email contact', value: 'email' },
                      ]}
                      onChange={(value) => console.log(value)}
                      isMultiple={false}
                      inputWidth={120}
                  />
                </CellBlock>
              </td>
            </tr>
        )))}
        </tbody>
        <tfoot className={styles.tableFoot}>
            <tr>
              <td></td>
              <td className={styles.footerNumberCount}>
                {
                  tableData.reduce((acc, { opportunity }) => {
                    opportunity.forEach(item => acc += item.policies)
                    return acc;
                  }, 0)
                }
              </td>
              <td className={styles.footerPremiumCount}>
                {
                  nFormatter(
                      tableData.reduce((acc, { opportunity }) => {
                        opportunity.forEach(item => acc += item.premium)
                        return acc;
                      }, 0), 2).toUpperCase()
                }
              </td>
              <td></td>
              <td></td>
            </tr>
        </tfoot>
      </table>
    </div>
  )
};

export default NewOpportunitiesTable;
