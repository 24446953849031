import React, { useRef, useState } from "react";

import clsx from "clsx";

import { Grid, makeStyles, Typography } from "@material-ui/core";

import { User } from '../../global/user'

import UserManagement from '../UserManagement/UserManagement';
import Connectors from './Connectors/Connectors'
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        padding: "24px 46px",
        borderRadius: "8px",
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
    },
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: 235,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        }
    },
    tabActive: {
        color: '#363ED3',
        height: 39,
        position: 'relative',
        bottom: -1
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 25,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px",
    },
}))




const Settings = () => {
    const classes = useStyles()
    const rootRef = useRef(null)

    const [activeTab, setActiveTab] = useState('User Management')

    const tabs = User().isSnowFlake ? [
        'User Management',
        'Connectors configuration',
    ] : ['User Management'];

    const handleChangeTab = (item) => {
        setActiveTab(item)
    }

    const getStepComponent = () => {
        switch (activeTab) {
            case 'User Management':
                return <UserManagement />
            case 'Connectors configuration':
                return <Connectors />

            default:
                break;
        }
    }
    return (
        <Grid container direction="column" ref={rootRef}>
            <HeaderContainer title="Settings"/>
            <Grid container justifyContent="flex-start">
                {
                    tabs.map((item) => (
                        <Grid item className={clsx(classes.tab, activeTab === item ? classes.tabActive : '')} onClick={() => handleChangeTab(item)}>
                            <Typography>{item}</Typography>
                        </Grid>
                    ))
                }
            </Grid>
            <Grid className={classes.main} style={{ maxWidth: window.innerWidth - 143 }}>
                {
                    getStepComponent()
                }
            </Grid>

        </Grid>
    )
}

export default Settings