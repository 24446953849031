import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    typeFilterContainer: {
      display: "flex",
      padding: "6px 8px",
      paddingLeft: 38,
      height: 26,
      "& .MuiTypography-root": {
        fontFamily: "Roboto!important",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        marginLeft: 10
      }
    },
    showOnPageSelect: {   
      border: "1px solid #DCDCDC",
      borderRadius: 4,
      width: 500,
    },
    modelIndicator: {
      display: "flex",
      alignItems: "center",
      //cursor: "pointer" 
    },
    exportToCSVButton: {
      backgroundColor: '#fff',
      border: '1px solid #d9e1ee',
      borderRadius: 12,
      marginLeft: 6,
      textTransform: 'capitalize',
      fontSize: 12
  
    },
    exportToCSVIcon: {
      color: '#d82832',
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
  
    },
    actionsPopoverContainer: {
        borderRadius: 12,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        padding: 12,
        width: 250
    },
    actionsPopoverContainerXL: {
        borderRadius: 12,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        padding: 12,
        width: 371
    },
    actionButtonContainer: {
        width: '100%',
        textAlign: "left",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0000001f"
        }
    },
    actionButton: {
        color: "#3691ef",
        padding: "3px 0",
        margin: 0,
        fontSize: "18px!important",
        fontWeight: "400!important",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
}));