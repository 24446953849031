import React, { useEffect } from "react";
import appApiService from '../../AppService';

import DashboardComponent from "../../Components/Dashboard/Dashboard";

const ApiService = new appApiService();

const Dashboard = () => {
  const [data, setData] = React.useState();


    useEffect(() => {
        if (!data) {
            ApiService._getDashboardData((response) => {
                setData(response.data)
            })
        }
    }, [data])

    const refreshAll = () => {
        ApiService._refreshAllDashboardsData((response) => {
            alert(response.data.message)
        })
    }

    return <DashboardComponent data={data} refreshAll={refreshAll}/>
}

export default Dashboard