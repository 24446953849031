import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    bgDark: {
        fontFamily: "Roboto!important",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px",
        color: '#232033',        
        background: "#F8F9FB",
        paddingLeft: 14,
        height: 57,
        "& .MuiTypography-root": {
          fontFamily: "Roboto!important",
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "24px",
        }
    },
    totalData: {
        display: "flex",
        "& .page-data": {
          paddingRight: 30,
          fontFamily: "Roboto!important",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "22px",
          "& span": {
            fontWeight: 500,
          }
        }
    },
    tableHeaderTitle: {        
      fontFamily: "Strawford",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "24px",
      color: "#232033"
    },
    header: {        
      paddingBottom: 16
    },
    tableAE: {
      "& .MuiTableCell-root": {
        padding: "16px 0"
      },
      "& .MuiTableHead-root.MuiTableCell-root": {
        width: "4.4%"
      }
    },
    tableTitle: {
      width: "49%",
      flexBasis: "49%",
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
      "& path": {
        fill: "#312E40"
      },
    },
    tableTitleBg: {
      maxWidth: "73%",
      flexBasis: "73%",
      paddingRight: 5,
    },
    tableTitleSm: {
      width: "27%",
      flexBasis: "27%",
      "& svg": {
        marginRight: 18,
        width: 20,
        height: 20,
        cursor: "pointer"
      },
      "& path": {
        fill: "#312E40"
      },
    },
    typeFilterContainer: {
      display: "flex",
      padding: "6px 8px",
      paddingLeft: 38,
      height: 26,
      "& .MuiTypography-root": {
        fontFamily: "Roboto!important",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        marginLeft: 10
      }
    },
    showOnPageSelect: {   
      border: "1px solid #DCDCDC",
      borderRadius: 4,
      paddingLeft: 12,
      width: 500,
    },
    typeIcon: {   
      borderRadius: "50%",
      width: 7,
      height: 7,
      cursor: "pointer"
    },
    checkBoxProps: {
      display: "flex",
      flexDirection: "row",
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px"
        },
        "& .MuiSvgIcon-root": {
            width: 14,
            height: 14,
        }
    },
    sticky: {
      position: "sticky",
      background: "#FFF",
    },
    modelIndicator: {
      display: "flex",
      alignItems: "center",
      //cursor: "pointer" 
    },
    headerActions: {
      display: "flex",
      justifyContent: "space-between",
      gap: 48
    }
}));