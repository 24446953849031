import './UIbyState.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLayoutEffect, useRef } from 'react';
import NoDataIndicator from '../NoDataIndicator/NoDataIndicator';

am4core.useTheme(am4themes_animated);

export default function UIbyState({paddingRight, chartData, title, currency, hasData = true}) {

  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("UIbyState", am4charts.XYChart);
    //x.padding(40, 40, 40, 40);
    x.mouseWheelBehavior="panY"
    x.strokeLinejoin="round"
    x.numberFormatter.numberFormat="#.a"
    x.zoomOutButton.disabled=true

    var categoryAxis = x.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "id";
    categoryAxis.renderer.minGridDistance = 0.5;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.strokeWidth=2;
    categoryAxis.renderer.grid.template.disabled = true;
   
    categoryAxis.renderer.cellStartLocation = 0.40
    categoryAxis.renderer.cellEndLocation = 0.60
   
      categoryAxis.start = 0;
      categoryAxis.end = 0.2;
     categoryAxis.keepSelection = true;
    
    
    var valueAxis = x.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.strokeWidth=1;

    var valueAxis2 = x.xAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = true;
    
    
    var series = x.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "id";
    series.dataFields.valueX = "value";
    series.tooltipText = "{valueX.value}"
    series.columns.template.fill = am4core.color("#4b54ff")
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.paddingRight = 30;
    series.columns.template.maxHeight = 10 ;

    function customizeGrip(grip) {
      // Remove default grip image
      grip.icon.disabled = true;
      
      // Disable background
      grip.background.disabled = true;
      
      // Add rotated rectangle as bi-di arrow
      var img = grip.createChild(am4core.Circle);
      img.width = 12;
      img.height = 12;
      img.fill = am4core.color("#4b54ff");
      img.align = "center";
      img.valign = "middle";

    }

    if(chartData?.length) {
      x.scrollbarY = new am4core.Scrollbar();
      //x.scrollbarX = new am4core.Scrollbar();
      x.scrollbarY.startGrip.disabled = true;
      x.scrollbarY.endGrip.disabled = true;
      //customizeGrip(x.scrollbarX.startGrip);
      //customizeGrip(x.scrollbarX.endGrip); 
    }
    
    var valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.Width=80
    valueLabel.minWidth=60
    valueLabel.label.text  = '{value}';
    x.numberFormatter.numberFormat=`${currency}#.a`
    x.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ]
    valueLabel.label.fontSize = 12;
    valueLabel.label.horizontalCenter = "right";
    valueLabel.label.dx = 10;
    
    categoryAxis.sortBySeries = series;
    x.data = chartData.filter(data => data.value != 0)
    
    let topContainer = x.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.width = am4core.percent(100);
    
    let axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.text = title;
    axisTitle.fontWeight = 700;
    axisTitle.fontFamily = "Roboto";
    axisTitle.align = "left";
    axisTitle.fontSize=16

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [chartData]);

   
   useLayoutEffect(() => {
    chart.current.paddingRight= paddingRight
}, [paddingRight]);
  
  return (
        <div className='BarsBox'>
          {!hasData && <NoDataIndicator/>}
          <div id="UIbyState" style={hasData ? {} : { opacity: .2 }}></div>
          <div style={{position: 'relative', zIndex:2, bottom: 20, width:'98%', height:16, backgroundColor: 'white', }}></div>
        </div>
  );
}
