import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCharts, setPrecisionLevel } from "../../Run/Charts/ChartsSlice";
import InformationPopup from "../InformationPopup/InformationPopup";
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useStyles } from "./styles";
import { nFormatter, precisionKeys } from "../../../global/helpers";
import clsx from "clsx";


const PrecisionPopup = ({ open, onClose, details, defaultValue }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isAnnuities = localStorage.getItem("accountType") === "annuities"

    const [filterTempValue, setFilterTempValue] = useState(defaultValue);
    const [selectedItem, setSelectedItem] = useState("Medium");
    const [precisionValues, setPrecisionValues] = useState({
      "low": '8',
      "mid": '24',
      "high": '34',
      "high+": '40'
    });
    const charts = useSelector(selectCharts)

    useEffect(() => {
        if (charts?.precisionConfig?.medium) {
          dispatch(setPrecisionLevel("medium"))
          setPrecisionValues({
            "low": charts.precisionConfig.low.precision.toString(),
            "mid": charts.precisionConfig.medium.precision.toString(),
            "high": charts.precisionConfig.high.precision.toString(),
            "high+": charts?.precisionConfig?.super_high?.precision?.toString()
          })
        }
      }, [charts.precisionConfig])

    return (
        <InformationPopup
            open={open}
            onClose={onClose}
            className={classes.popupContainer}
            closeControl={false}
            title=""
        >
            <p className={classes.title}>Atidot Model Accuracy</p>
            <Table>
                <TableHead className={classes.precisionTableHeade}>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><>Low Precision,<br/>High Recall</></TableCell>
                        <TableCell className={classes.spacingHeader}></TableCell>
                        <TableCell><>Medium Precision,<br/>Medium Recall</></TableCell>
                        <TableCell className={classes.spacingHeader}></TableCell>
                        <TableCell><>High Precision,<br/>Low Recall</></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.precisionTable}>
                    <TableRow>
                        <TableCell className={classes.itemTitle} style={{ paddingTop: "30px" }}>
                            Total Policies
                        </TableCell>
                        <TableCell
                            className={clsx(classes.precisionItem, classes.policyItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.total_policies}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.policyItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.total_policies}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.policyItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.total_policies}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                        Atidot Prospects Identified
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.low?.prospects}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.medium?.prospects}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.high?.prospects}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                        Precision
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.low?.precision}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.medium?.precision}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{details?.precision_metadata?.high?.precision}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                            Recall
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(details?.precision_metadata?.low?.recall, 2)}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(details?.precision_metadata?.medium?.recall, 2)}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(details?.precision_metadata?.high?.recall, 2)}%</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                            {isAnnuities ? "AUM" : "Premium"} Impact
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(Math.floor(details?.precision_metadata?.low?.premium_impact), 2)}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(Math.floor(details?.precision_metadata?.medium?.premium_impact), 2)}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">{nFormatter(Math.floor(details?.precision_metadata?.high?.premium_impact), 2)}</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                            Impact
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">Atidot is {details?.precision_metadata?.low?.impact}X<br/>more precise</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-container">
                                <div className="cell-content">Atidot is {details?.precision_metadata?.medium?.impact}X<br/>more precise</div>
                                <Divider/>
                            </div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.tableItem)}
                        >
                            <div className="cell-container">
                                <div className="cell-content">Atidot is {details?.precision_metadata?.high?.impact}X<br/>more precise</div>
                                <Divider/>
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.itemTitle}>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.impactItem)}
                        >
                            <div className="cell-content"></div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.impactItem, filterTempValue["mid"] ? "cell-active" : "")}
                        >
                            <div className="cell-content"></div>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell 
                            className={clsx(classes.precisionItem, classes.impactItem)}
                        >
                            <div className="cell-content"></div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className={classes.surrenderRateContainer}>
                <p>{`* Platform default accuracy level is ‘Medium’`}</p>
                <p>{`* Set your desired accuracy level during project creation`}</p>
            </div>
        </InformationPopup>
    )   
}

export default PrecisionPopup