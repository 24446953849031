import './ProductsChart.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useLayoutEffect, useState } from 'react';
import { getCountryCurrencySymbol } from '../../../../global/helpers';

am4core.useTheme(am4themes_animated);

const ProductsChart = ({ 
  chartData, 
  titleLapse, 
  titleUI,
  keys,
  isAnnuities
}) => {
  const currency = getCountryCurrencySymbol()

  const [ isUiData, setIsUiData ] = useState(true)
  const [ data, setData ] = useState([])

  useEffect(() => {
    let uiData = false
    const arr = chartData?.map((item) => {
      if (item.ui) {
        uiData = true
      }
      return {
        ...item,
        ui: item?.ui ? item.ui : 1000000
      }
    })
    setIsUiData(uiData)
    setData(arr)
  }, [chartData])
  
  useLayoutEffect(() => {

    // Intialize charts
    var lapseChart = am4core.create("ProductsLapseChart", am4charts.XYChart);
    lapseChart.paddingRight = 0;
    lapseChart.data = data;
    
    var uiChart = am4core.create("ProductsUIChart", am4charts.XYChart);
    uiChart.paddingLeft = 0;
    uiChart.data = data;
    
    // Create axes
    var lapseCategoryAxis = lapseChart.yAxes.push(new am4charts.CategoryAxis());
    lapseCategoryAxis.dataFields.category = "label";
    lapseCategoryAxis.renderer.grid.template.location = 0;
    lapseCategoryAxis.renderer.grid.template.opacity = 0;
    lapseCategoryAxis.renderer.minGridDistance = 15;
    lapseCategoryAxis.renderer.disabled = true;
    lapseCategoryAxis.renderer.baseGrid.disabled = true;
    
    var lapseValueAxis = lapseChart.xAxes.push(new am4charts.ValueAxis());
    lapseValueAxis.renderer.inversed = true;
    lapseValueAxis.min = 0;
    lapseValueAxis.max = 120;
    lapseValueAxis.strictMinMax = true;
    lapseValueAxis.numberFormatter = new am4core.NumberFormatter();
    lapseValueAxis.numberFormatter.numberFormat = "#.0a";
    lapseValueAxis.renderer.grid.template.opacity = 0;
    lapseValueAxis.renderer.hidden = true;
    lapseValueAxis.renderer.baseGrid.disabled = true;

    var uiCategoryAxis = uiChart.yAxes.push(new am4charts.CategoryAxis());
    uiCategoryAxis.dataFields.category = "label";
    uiCategoryAxis.renderer.grid.template.location = 0;
    uiCategoryAxis.renderer.minGridDistance = 15;
    uiCategoryAxis.renderer.grid.template.opacity = 0;
    
    var uiValueAxis = uiChart.xAxes.push(new am4charts.ValueAxis());
    uiValueAxis.min = 0;
    uiValueAxis.max = isAnnuities ? 60 : 120;
    uiValueAxis.strictMinMax = true;
    uiValueAxis.numberFormatter = new am4core.NumberFormatter();
    uiValueAxis.numberFormatter.numberFormat = "#.0a";
    uiValueAxis.renderer.grid.template.opacity = 0;
    uiValueAxis.renderer.hidden = true;
    uiValueAxis.renderer.baseGrid.disabled = true;
        
    // Create series 
    function createSeries(field, name, chart, rounded = false) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.calculatePercent = true;
      series.dataFields.valueXShow = "percent";
      series.dataFields.categoryY = "label";
      series.stacked = true;
      series.name = name;
      series.stroke = am4core.color("#fff");
      series.numberFormatter = new am4core.NumberFormatter();
      series.numberFormatter.numberFormat = `'${currency}'#.0a`;
      series.tooltip.background.opacity = 1;

      series.columns.template.adapter.add("fill",function(fill,target){
        return target.dataItem.dataContext["color"]
      })

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.width = 14;
      columnTemplate.tooltipText = "{name}: {valueX}";

      if (rounded) {
        columnTemplate.column.cornerRadius(60, 0, 60, 0);
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.locationX = -0.05;
        labelBullet.label.text = "{valueX}";
        labelBullet.label.fill = am4core.color("#312E40");
        labelBullet.label.horizontalCenter = "right"; 
        labelBullet.label.hideOversized = false;
        labelBullet.label.truncate = false;
        labelBullet.label.fontSize = 10;
        labelBullet.label.fontFamily = "Roboto";
        labelBullet.label.fontWeight = 500;
      }      
      
      if (!rounded) {
      }
      
      series.numberFormatter.bigNumberPrefixes = [
        { "number": 1e+3, "suffix": "K" },
        { "number": 1e+6, "suffix": "M" },
        { "number": 1e+9, "suffix": "B" }
      ]
    }   

    // Create lapse series 
    //createSeries("original", "Original", lapseChart);
    createSeries("lapse", "Retained", lapseChart, true);
    
    // Create ui series 
    var uiSeries = uiChart.series.push(new am4charts.ColumnSeries());
    uiSeries.dataFields.valueX = "ui";
    uiSeries.dataFields.valueXShow = "percent";
    uiSeries.calculatePercent = true;
    uiSeries.stroke = uiSeries.fill;
    uiSeries.columns.template.tooltipText = isUiData ? "{categoryY}: {valueX}" : "";
    uiSeries.dataFields.categoryY = "label";
    uiSeries.interpolationDuration = 1000;
    uiSeries.numberFormatter = new am4core.NumberFormatter();
    uiSeries.numberFormatter.numberFormat = `'${currency}'#.0a`;

    uiSeries.columns.template.adapter.add("fill",function(fill,target){
      return isUiData ? target.dataItem.dataContext["color"] : target.dataItem.dataContext["color"]+'50'
    })
    if (isUiData) {
      let labelBullet = uiSeries.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = -0.05;
      labelBullet.label.text = "{valueX}";
      labelBullet.label.fill = am4core.color("#312E40");
      labelBullet.label.horizontalCenter = "left"; 
      labelBullet.label.hideOversized = false;
      labelBullet.label.truncate = false;
      labelBullet.label.fontSize = 10;
      labelBullet.label.fontFamily = "Roboto";
      labelBullet.label.fontWeight = 500;
    }

      
    var uiColumnTemplate = uiSeries.columns.template;
    uiColumnTemplate.strokeWidth = 0;
    uiColumnTemplate.strokeOpacity = 1;
    uiColumnTemplate.column.cornerRadius(0, 60, 0, 60);
    uiColumnTemplate.width = 14;
    lapseCategoryAxis.renderer.cellStartLocation = 0.2;
    lapseCategoryAxis.renderer.cellEndLocation = 0.8;
    uiCategoryAxis.renderer.cellStartLocation = 0.2;
    uiCategoryAxis.renderer.cellEndLocation = 0.8;

    uiChart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ]

    let topLapseContainer = lapseChart.chartContainer.createChild(am4core.Container);
    topLapseContainer.layout = "absolute";
    topLapseContainer.toBack();
    topLapseContainer.paddingBottom = 33;
    topLapseContainer.width = am4core.percent(100);
    
    let axisLapseTitle = topLapseContainer.createChild(am4core.Label);
    axisLapseTitle.text = titleLapse;
    axisLapseTitle.fontFamily = "Roboto";
    axisLapseTitle.fontWeight = 700;
    axisLapseTitle.fontSize = 14;
    axisLapseTitle.align = "right";
    axisLapseTitle.marginLeft = 15;

    let topUIContainer = uiChart.chartContainer.createChild(am4core.Container);
    topUIContainer.layout = "absolute";
    topUIContainer.toBack();
    topUIContainer.paddingBottom = 33;
    topUIContainer.width = am4core.percent(100);
    
    let axisUITitle = topUIContainer.createChild(am4core.Label);
    axisUITitle.text = titleUI;
    axisUITitle.fontFamily = "Roboto";
    axisUITitle.fontWeight = 700;
    axisUITitle.fontSize = 14;
    axisUITitle.align = "left";
    axisUITitle.marginLeft = isAnnuities ? 62 : 54;

    return () => {
      lapseChart.dispose();
      uiChart.dispose();
    };
  }, [data]);
  
  return (
    <div className='content-products' id="ProductsChart">
      <div className='products-chart--lapse'>
        <div id="ProductsLapseChart" style={{minHeight: 115 + 39*keys.length}}></div>
        <div style={{position: 'relative', zIndex:2, bottom: 20, width:'27%', height:22, backgroundColor: 'white', borderRadius: '5px'}}/>
      </div>
      <div className='products-chart--ui'>
        <div id="ProductsUIChart" style={{minHeight: 115 + 39*keys.length}}></div>
        <div style={{position: 'relative', zIndex:2, bottom: 20, width:'18%', height:22, backgroundColor: 'white', borderRadius: '5px'}}/>
      </div>
    </div>
  );
}

export default ProductsChart