import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        marginTop: 36,
        maxWidth: 556,
        "@media (min-width: 1440px)": {
            maxWidth: 756
        }
    },
    DatabaseConnBackground: {
        justifyContent: "center",
        minHeight: "30vh",
        padding: 50,
    },
    customInput: {
        display: "flex",
        flexFlow: "row wrap",
        width: "100%",
        marginBottom: "20px",
    },
    customNameInput: {
        display: "flex",
        width: "100%",
        height: "50px",
        backgroundColor: "#ffffff",
        border: "1px solid #d9e1ee",
        borderRadius: "5px",
        outline: "0px",
        padding: "0 20px",
        margin: "5px 0px",
        "&:focus-within": {
            border: "1px solid #171b59",
        },
    },
    lblNombre: {
        margin: "4px 0px",
        fontWeight: "500",
        color: '#312e40',
        height: '18px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.38',
        letterSpacing: 'normal',
    },
    customTitle: {
        margin: "30px 0px",
    },
    maxWidth: {
        width: "100%",
    },
    TabPanelStyle: {
        padding: 0,
        paddingTop: 24,
    },
    loadingButton: {
        borderRadius: 12,
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: "#ecf1f9 ",
        color: "#363ed3 ",
        "&:hover": {
            backgroundColor: "#ecf1f9 ",
        },
    },
    testingButton: {
        borderRadius: 12,
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: "#363ed3",
        "&:hover": {
            backgroundColor: "#363ed3 ",
        },
        color: "white",
    },
    selectBody: {
        color: "red",
        "& .select__menu": {
            color: "red",
            fontWeight: "200",
        },
    },
    alertErrorStyle: {
        border: "none",
        width: "60%",
        color: "#d82832",
        "& .MuiAlert-icon": {
            color: "#d82832",
            marginRight: "6px",
        },
    },
    alertSuccessStyle: {
        border: "none",
        width: "60%",
        color: "#0bcb47",
        "& .MuiAlert-icon": {
            color: "#0bcb47",
            marginRight: "6px",
        },
    },
    styleInput: {
        height: "100%",
        padding: "0",
        width: "100%",
        border: "none",
        outline: "none",
        "&:-internal-autofill-selected": {
            boxShadow: "0 0 0 30px white inset !important",
            /* backgroundColor:'white !important' */
        },
    },
    nextButton: {
        color: "#fff",
        background: "#0BCB47",
        width: 250,
        height: 50,
        borderRadius: 10,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center"
    },
    nextButtonContainer: {
        paddingTop: 54
    },
}));