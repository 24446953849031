import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getNextMonths, getLastQuarters } from "./global/helpers";
import { defaultColumnsAnnuities } from "./global/constants";

const initialState = {
    loading: false,
    activeOption: 'Projects',
    search:'',
    step2Component:'',
    information: null,
    projectMetadata: null,
    campaignCounts: {},
    leads: null,
    leadsTotal: {
        prospects: 0,
        premium: 0,
        targetedProspects: 0,
        targetedPremium: 0
    },
    projectsLoaded: true,
    projectsSort: 'created_at',
    userInitials: '',
    userName: '',
    userRole: '',
    userCompany: '',
    accountType: '',
    menuDisabled: false,
    runsProgress: [],
    runsFinished: {
        ui: false,
        lapse: false
    },
    uiID: 0,
    lapseID: 0,
    columns: {
    }
}
const name = 'App'

export const AppSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setLoading: (state,action) => {
            state.loading = action.payload
        },
        setActiveOption: (state,action) => {
            state.activeOption = action.payload
        },
        setSearch: (state,action) => {
            state.search = action.payload
        },
        setStep2Component: (state,action) => {
            state.step2Component=action.payload
        },
        setProjectsSort: (state, action) => {
            state.projectsSort = action.payload
        },
        setProjectInformatiom: (state, action) => {
            state.information = {
                ...action.payload,
                projects: action.payload.projects
                .map((project) => {
                    let prospects = 0
                    let aum = 0
                    project?.runs?.forEach(({ prospects_count, potential_retained }) => {
                        if(prospects_count) prospects = prospects + prospects_count
                        if(potential_retained) aum = aum + potential_retained
                    })
                    return {
                        ...project,
                        aum,
                        prospects
                    }
                })
            }
        },
        setProjectMetadata: (state, action) => {
            const meta = action.payload?.metadata
            const period = getNextMonths(meta, 12)
            let sortedProjects = []
            let numOfSelectedProjects = 0
            let numOfProjects = 0

            period
            .filter((month) => meta.potential_premium_by_date[month])
            .map((month) => {
                const item = meta.potential_premium_by_date[month].filter((entry) => entry.potential_premium > 0)
                numOfProjects = numOfProjects + item.length

                return month
            })

            period
                .forEach((month) => {
                        const item = meta.potential_premium_by_date[month] ? meta.potential_premium_by_date[month].filter((entry) => entry.potential_premium > 0) : null
                        if (item) {                 
                            if (numOfSelectedProjects === 10) {
                                return   
                            }
    
                            if (numOfSelectedProjects + item.length <= 10 || numOfProjects < 10) {
                                numOfSelectedProjects = numOfSelectedProjects + item.length
                                sortedProjects = [ ...sortedProjects, ...item.map((entry) => ({ [month]: entry }))]
                            } else {
                                sortedProjects = [ ...sortedProjects, ...item.map((entry) => ({ [month]: entry }))].slice(0, 9)
                                return
                            }
                            numOfSelectedProjects = numOfSelectedProjects + item.length
                        } else if(numOfProjects < 10) {
                            numOfSelectedProjects++
                            sortedProjects = [ ...sortedProjects, {
                                [month]: [
                                    month,
                                    {
                                        potential_premium: 0,
                                        project_name: ""
                                    }
                                ]
                            }]
                        }

                }) 
                
            const timelineData = sortedProjects
                .map((month, index) => {
                    const values = Object.entries(month)
                    return {
                        id: `${values[0][0]}(${index})`,
                        ...values[0][1],
                        potential_premium: values[0][1].potential_premium ? values[0][1].potential_premium : null,
                    }
                })

            const convertedData = Object.entries(meta.potential_premium_by_quarter).map(([quarter, projects]) => {
                let products = {};
                for (const projectProducts of Object.values(projects)) {
                    for (const [product, value] of Object.entries(projectProducts)) {  
                        if (products[product]) {
                            products[product] = products[product] + value
                        } else {
                            products[product] = value
                        }
                    }
                }
                return { quarter, ...products} 
            })

            const projects = action.payload?.projects
            const quarterData = getLastQuarters().reverse().map(quarter => {

                const data = convertedData.find( item => item.quarter === quarter )
                
                const projectsInQuarter = projects.filter(project => 
                    moment(project.created_at, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format("Q_YYYY") == quarter.slice(1))
                const prospectsInQuarter = projectsInQuarter.reduce((a, project) => a + project.total_policies,0)

                return { quarter, prospects: prospectsInQuarter, ...data }
            }
            )

            state.projectMetadata = {
                premium_by_project: meta?.premium_by_project ? 
                Object.keys(meta.premium_by_project).map((key) => ({ id: action.payload.projects.find(({ project_id }) => project_id.toString() === key.replace("project_", "")).project_name, value: meta.premium_by_project[key]}))
                : [],
                potential_premium_by_project: meta?.potential_premium_by_project ? 
                Object.keys(meta.potential_premium_by_project).map((key) => ({ id: action.payload.projects.find(({ project_id }) => project_id.toString() === key.replace("project_", "")).project_name, value: meta.potential_premium_by_project[key]}))
                : [],
                total_premium_by_project : meta?.total_premium_by_project ? 
                Object.keys(meta.total_premium_by_project).map((key) => ({ id: action.payload.projects.find(({ project_id }) => project_id.toString() === key.replace("project_", "")).project_name, value: meta.total_premium_by_project[key]}))
                : [],
                potential_premium_by_date: timelineData,
                potential_premium_by_quarter: quarterData,
                prospects_count_by_project: meta?.prospects_count_by_project || [],
                potential_premium_by_project_and_product: meta?.potential_premium_by_project_and_product || [],
                prospects_count_by_run: meta?.prospects_count_by_run || {}
            }
        },
        setProjectsLoaded: (state, action) => {
            state.projectsLoaded = action.payload
        },
        setMenuDisabled: (state, action) => {
            state.menuDisabled = action.payload
        },
        setCampaignsCounts: (state, action) => {
            const data = {}
            Object.assign(data, 
                ...action.payload?.runs
                    ?.map(({ count, run_id }) => ({[run_id]: count}))
            )
            state.campaignCounts = data
        },
        setUserInitials: (state, action) => {
            state.userInitials = action.payload
        }, 
        setUserName: (state, action) => {
            state.userName = action.payload
        }, 
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        setUserPermissions: (state, action) => {
            state.userPermissions = action.payload
        },
        setUserLimit: (state, action) => {
            state.userLimit = action.payload
        },
        setUserCompany: (state, action) => {
            state.userCompany = action.payload
        },
        setCurrentUser: (state, action) => {
            state.user = action.payload
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload
        },
        setRunLeads: (state, action) => {
            const activeLeads = action.payload?.filter(({archive}) => !archive)
            state.leads = activeLeads
            state.leadsTotal = {
                prospects: activeLeads?.reduce((acc, cur) => {
                    acc = acc + +cur.num_of_policyholders;
                    return acc;
                }, 0),
                premium: activeLeads?.reduce((acc, cur) => {
                    acc = acc + +cur.aggregated_premium;
                    return acc;
                }, 0),
                targetedProspects: activeLeads?.reduce((acc, cur) => {
                    acc = acc + +cur.num_outcome_policies;
                    return acc;
                }, 0),
                targetedPremium: activeLeads?.reduce((acc, cur) => {
                    acc = acc + +cur.premium_opportunity;
                    return acc;
                }, 0)
            }
        },
        resetRunLeads: (state) => {
            state.leads = null
            state.leadsTotal = {
                prospects: 0,
                premium: 0,
                targetedProspects: 0,
                targetedPremium: 0
            }
        },
        setRunFinished: (state, action) => {
            state.runsFinished = action.payload
        },
        setUiID: (state, action) => {
            state.uiID = action.payload
        },
        setLapseID: (state, action) => {
            state.lapseID = action.payload
        },
        updateRunsProgress: (state, action) => {
            const run = state.runsProgress.findIndex(({ id }) => id === action.payload.id)
            if (run > -1) {            
                state.runsProgress.length > 1 ?
                    state.runsProgress = [ 
                        ...state.runsProgress.slice(1, run+1),
                        action.payload,
                        ...state.runsProgress.slice(run+1)
                    ]   
                : state.runsProgress = [ action.payload ]
            } else {
                state.runsProgress = [ ...state.runsProgress, action.payload]
            }   
        },
        removeRunProgress: (state, action) => {
            const run = state.runsProgress.findIndex(({ id }) => id === action.payload)
            if (parseInt(state.lapseID) === parseInt(action.payload)) {
                state.runsFinished.lapse = true   
            }
            if (parseInt(state.uiID) === parseInt(action.payload)) {
                state.runsFinished.ui = true   
            }
            if (run > -1) {
                state.runsProgress = [ 
                    ...state.runsProgress.slice(1, run+1),
                    ...state.runsProgress.slice(run+1)
                ]                
            }

        },
        setDemoData: (state, action) => {
            state.demoData = action.payload
        },
        resetMeta: (state) => {
            state.projectMetadata = null
        },
        setColumns: (state, action) => {
            state.columns = action.payload
        },
        resetData: (state) => {
            state.loading = false
            state.activeOption = "Projects"
            state.search = ''
            state.step2Component= ''
            state.information = null
            state.projectMetadata = null
            state.campaignCounts = {}
            state.projectsLoaded = true
            state.projectsSort = 'created_at'
            state.userInitials = ''
            state.userRole = ''
            state.userPermissions = []
        }    
    }
})

export const { setActiveOption, setSearch, setStep2Component, setProjectInformatiom, setProjectMetadata, setProjectsSort,
    setProjectsLoaded, setUserInitials, setUserRole, setUserPermissions, setUserLimit, setUserCompany, setCurrentUser, setRunLeads,
    setCampaignsCounts, setMenuDisabled, setRunFinished, setUiID, setLapseID, updateRunsProgress, removeRunProgress, 
    setUserName, setAccountType, setDemoData, setLoading, setColumns, resetRunLeads, resetData, resetMeta } = AppSlice.actions


export const selectLoading = (state) => state.App.loading
export const selectActiveOption = (state) => state.App.activeOption
export const selectSearch = (state) => state.App.search
export const selectStep2Component = (state) => state.App.step2Component
export const selectInformation = (state) => state.App.information
export const selectProjectsLoaded  = (state) => state.App.projectsLoaded
export const selectMenuDisabled  = (state) => state.App.menuDisabled
export const selectUserInitials  = (state) => state.App.userInitials
export const selectUserRole  = (state) => state.App.userRole
export const selectUserPermissions  = (state) => state.App.userPermissions
export const selectUserLimit  = (state) => state.App.userLimit
export const selectUserCompany  = (state) => state.App.userCompany
export const selectCurrentUser  = (state) => state.App.user
export const selectAccountType  = (state) => state.App.accountType
export const selectRunsProgress  = (state) => state.App.runsProgress
export const selectRunLeads  = (state) => state.App.leads
export const selectRunLeadsTotals  = (state) => state.App.leadsTotal
export const selectRunFinished  = (state) => state.App.runsFinished
export const selectCampaignsCounts  = (state) => state.App.campaignCounts
export const selectUserName  = (state) => state.App.userName
export const selectDemoData  = (state) => state.App.demoData
export const selectProjectMetadata  = (state) => state.App.projectMetadata
export const selectProjectsSort  = (state) => state.App.projectsSort
export const selectColumns  = (state) => state.App.columns



export default AppSlice.reducer