import { makeStyles, InputAdornment, IconButton, FormControl } from "@material-ui/core";
import React from "react";
import {ReactComponent as EyeIcon } from '../../Assets/icon-eye.svg';
import {ReactComponent as EyeShutIcon } from '../../Assets/icon-eye-shut.svg';
import {ReactComponent as CapsIcon } from '../../Assets/icon-caps.svg';
import { TextValidator } from "react-material-ui-form-validator";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    loginInput: {
        '& .MuiIconButton-root, .MuiIconButton-root:hover, .MuiIconButton-root:focus': {
            padding: 0,
            backgroundColor: "white"
        },
        '& .MuiInputBase-root': {
            backgroundColor: "white",
            borderRadius: 5,
            height: 44,
            fontWeight: 400
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c3c3c3',
            fontFamily: "'Roboto', sans-serif",
            fontSize: 16,
        },
        '& .MuiInputBase-input': {
            fontFamily: "'Roboto', sans-serif",
            fontSize: 16,
        },
        '& .MuiOutlinedInput-input': {
            padding: "0 14px",
            height: 44,
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            WebkitBoxShadow: "0 0 0 100px #2196f310 inset",
            WebkitTextFillColor: "#000000",
            caretColor: "#000000" 
        },
        '& .MuiSelect-outlined': {
            height: "unset",
        },
        '& .MuiFormHelperText-root': {
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "18px",
            color: "#6A6C71"
        },
        '& .MuiFormHelperText-contained': {
            margin: 0
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: "#D82832"
        },
        '& .MuiInputLabel-outlined': {
            position: "relative",
            top: 10,
            fontFamily: "'Roboto', sans-serif !important",
            fontSize: 16,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            padding: "0 4px",
            position: "absolute",
            top: 0,
        }

    },
    optional: {        
        '& .MuiOutlinedInput-input': {
        opacity: .6,
            '&:focus': {
                opacity: 1
            }
        }
    },
    capsIcon: {
        marginLeft: 10
    }
}))

const FormInput = (props) => {
    const { showText = true, optional = false } = props
    const classes = useStyles();
    const [showPassword, setShowPassword] = React.useState(showText);
    const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);
    const [focused, setFocused] = React.useState(false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleDetectCapseLock = (e) => setIsCapsLockOn(e.getModifierState("CapsLock"))

    return(
        <FormControl fullWidth>
            {
                (props.label && (focused || props.value)) && <div style={{ height: 16 }}/>
            }
            <TextValidator
                {...props}
                className={clsx(classes.loginInput, optional ? classes.optional : "")}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth 
                onKeyUp={handleDetectCapseLock}
                onFocus={onFocus}
                onBlur={onBlur}
                InputProps={!showText && {
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <EyeShutIcon/> : <EyeIcon />}
                        </IconButton>
                        {isCapsLockOn && <CapsIcon className={classes.capsIcon}/>}
                    </InputAdornment>
                    )
                }}/>
        </FormControl>
    )     
}

export default FormInput