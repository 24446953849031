import { Grid, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";
import { HeaderButton } from "../../ui/buttons";

const useStyles = makeStyles(() => ({
    headerContainer: {
        paddingBottom: 21
    },
    headerTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 24,
            fontWeight: 400,
            lineHeight: "24px",
            paddingBottom: 12 
        }
    },
    container: {
        background: "#FFFFFF",
        borderRadius: 8,
        minHeight: 88,
        paddingTop: 10,
        "& .MuiDivider-root": {
           alignSelf: "center",
           height: 50,
           width: 1
        },
        "& .header--item-5" : {
            maxWidth: "19%", 
            flexBasis: "19%",
        },
        "& .header--item-4" : {
            maxWidth: "24%", 
            flexBasis: "24%",
        },
    }

}));

const HeaderContainer = ({ 
    title = "",
    onActionClick = () => {},
    actionText = "", 
    actionPermission = false, 
    actionDisabled = false, 
    children
}) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.headerContainer}>
            <Grid container className={classes.headerTitle} justifyContent="space-between">
              <Typography style={children ? {} : { paddingBottom: 0}}>
                {title}
              </Typography>  

              {actionPermission && 
                <HeaderButton
                disabled={actionDisabled}
                onClick={onActionClick}>
                  {actionText}
                </HeaderButton>
              }    
            </Grid> 
            
            {children && <Grid container alignItems="flex-start" className={classes.container}>{children}</Grid>}
        </Grid>
    )
}

export default HeaderContainer