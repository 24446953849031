import { createSlice } from "@reduxjs/toolkit";

const date = new Date()
const tomorrow = new Date(date)
//tomorrow.setDate(tomorrow.getDate() + 1)

const initialState = {
    campaignName: '',
    campaignDescription: '',
    campaignType: 6,
    campaignProspects: 0,
    campaignScenario:'',
    campaignAction: 'Send an Email',
    runId:'',
    dateStart: (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear(),
    dateEnd: '',
    color: '#D20247',
    campaignId: null,
    createdCampaign: null,
    error: false,
    campaignsProgress: [],
    mailSubject: '', 
    mailDescription: '', 
    openMailer: false, 
}
const name = 'setNewCampaign'

export const newCampaignSlice = createSlice({
    name: name,
    initialState : initialState,
    reducers:{
        setCampaignName: (state, action) => {
            state.campaignName = action.payload
        },
        setCampaignDescription: (state, action) => {
            state.campaignDescription = action.payload
        },
        setCampaignScenario: (state, action) => {
            state.campaignScenario = action.payload
        },
        setCampaignAction: (state, action) => {
            state.campaignAction = action.payload
        },
        setCampaignType: (state, action) => {
            state.campaignType = action.payload
        },
        setCampaignProspects: (state, action) => {
            state.campaignProspects = action.payload
        },
        setDateStart: (state, action) => {
            state.dateStart = action.payload
        },
        setDateEnd: (state, action) => {
            state.dateEnd = action.payload
        },
        setRunId: (state, action) => {
            state.runId = action.payload
        },
        setColor: (state, action) => {
            state.color = action.payload
        },
        setCampaignId: (state, action) => {
            state.campaignId = action.payload
        },
        setCreatedCampaign: (state, action) => {
            state.createdCampaign = action.payload
        },
        setMailSubject: (state, action) => {
            state.mailSubject = action.payload
        },
        setMailDescription: (state, action) => {
            state.mailDescription = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setOpenMailer: (state, action) => {
            state.openMailer = action.payload
        },
        setCampaignData: (state, action) => {
            const { name, description, type, action: campaignAction, color, starts_at, ends_at, provider } = action.payload
            state.campaignName = name
            state.campaignDescription = description
            state.campaignAction = campaignAction
            state.campaignType = provider
            state.color = color
            const start = new Date(starts_at)
            const end = new Date(ends_at)
            state.dateStart = (start.getMonth() + 1) + "/" + start.getDate() + "/" + start.getFullYear()
            state.dateEnd = (end.getMonth() + 1) + "/" + end.getDate() + "/" + end.getFullYear()
            state.campaignScenario = type
        },
        updateCampaignsProgress: (state, action) => {
            const run = state.campaignsProgress.findIndex(({ id }) => id === action.payload.id)
            if (run > -1) {            
                state.campaignsProgress.length > 1 ?
                    state.campaignsProgress = [ 
                        ...state.campaignsProgress.slice(1, run+1),
                        action.payload,
                        ...state.campaignsProgress.slice(run+1)
                    ]   
                : state.campaignsProgress = [ action.payload ]
            } else {
                state.campaignsProgress = [ ...state.campaignsProgress, action.payload]
            }   
        },
        removeCampaignProgress: (state, action) => {
            const run = state.campaignsProgress.findIndex(({ id }) => id === action.payload)
            if (run > -1) {
                state.campaignsProgress = [ 
                    ...state.campaignsProgress.slice(1, run+1),
                    ...state.campaignsProgress.slice(run+1)
                ]                
            }

        },
        resetValues: (state) => {
            state.campaignName = ""
            state.campaignDescription = ""
            state.campaignScenario = ""
            state.campaignAction = "Send an Email"
            state.campaignType = 6
            state.mailSubject = ""
            state.mailDescription = ""
            state.runId = ""
            state.dateStart = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
            state.dateEnd = ''
            state.color = "#D20247"
            state.campaignId = null
        }
    },
})

export const { setCampaignName, setCampaignDescription, setCampaignScenario, setCampaignType, setCampaignProspects, setDateStart, setDateEnd, setRunId, setColor, setCampaignId, setCampaignAction,
setCampaignData, setCreatedCampaign, setMailSubject, setMailDescription, updateCampaignsProgress, setOpenMailer, removeCampaignProgress, setError, resetValues } = newCampaignSlice.actions

export const selectCampaignData = (state) => state.newCampaign
export const selectCampaignRunId = (state) => state.newCampaign.runId
export const selectCampaignId = (state) => state.newCampaign.campaignId
export const selectCreatedCampaign = (state) => state.newCampaign.createdCampaign
export const selectCampaignsProgress  = (state) => state.App.campaignsProgress

export default newCampaignSlice.reducer