import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../Header/styles";

export const useStyles = makeStyles(() => ({
    container: {
        padding: '50px',
        display: 'flex',
    },
    annotationWrapper: {
        marginTop: '80px',
        minWidth: '245px',
    },
    annotationItem: {
        background: '#F8F9FB',
        borderRadius: '8px 0px 0px 8px',
        padding: '14px 32px 14px 24px',
        display: 'flex',
        marginBottom: '2px',
    },
    annotationItemIcon: {
        marginRight: '16px',
        '& img': {
            width: '18px',
            height: '18px',
        }
    },
    annotationItemContent: {
        '& h3': {
            fontWeight: 700,
            fontSize: '16px',
            textDecoration: 'underline',
            margin: '-2px 0 0',
        },
        '& div': {
            marginTop: '13px',
            color: '#232033',
            '& p': {
                margin: 0,
                fontSize: '12px',
            },
            '& span': {
                fontSize: '20px',
                fontWeight: 700,
            },
            '&:last-child': {
                '& span': {
                    fontSize: '24px',
                    color: '#363ED3',
                },
            }
        }
    },
    table: {
        width: '100%',
    },
    tableBody: {
        '& tr:last-child td': {
            borderBottom: '1px solid #d7dce5',
        }
    },
    cellBlock: {
        padding: '20px',
        height: '56px',
        borderBottom: '1px solid #F8F9FB',
        fontSize: '16px',
        lineHeight: '16px',
    },
    headRowBlock: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.22)',
        borderRadius: '8px 8px 0px 0px',
    },
    headBlock: {
        textAlign: 'left',
        padding: '20px',
        backgroundColor: '#F8F9FB',
    },
    rowBlock: {
        '& td:first-child': {
            borderLeft: '1px solid #d7dce5',
        },
        '& td:last-child': {
            borderRight: '1px solid #d7dce5',
        },
    },
    tableFoot: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.22)',
        borderRadius: '0 0 8px 8px',
        backgroundColor: '#F8F9FB',
        '& td': {
            padding: '20px',
        }
    },
    opportunityTitle: {
        color: '#363ED3',
        textDecoration: 'underline',
        fontWeight: 500,
    },
    prioBlock: {
        fontSize: '13px',
    },
    footerNumberCount: {
        fontWeight: 700,
        fontSize: '24px',
    },
    footerPremiumCount: {
        fontWeight: 700,
        fontSize: '24px',
        color: '#363ED3',
    },
    dropdownCell: {
        padding: '10px 20px',
    }
}));

export const UIAnnotateButton = styled(Button)({
    ...basicButtonStyles,
    width: '100%',
    padding: '10px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: '#363ED3',
    borderColor: '#363ED3',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '13px',
    '&:hover': {
        opacity: '0.9',
        backgroundColor: '#363ED3',
        borderColor: '#363ED3',
        boxShadow: 'none',
    },
});