import { Button, Card, CardContent, Checkbox, Collapse, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { ReactComponent as IconUI } from '../../../Assets/icon-ui.svg'
import { ReactComponent as IconLapse } from '../../../Assets/icon-lapse.svg'
import { ReactComponent as IconDistribution } from '../../../Assets/icon-distribution.svg'
import { ReactComponent as IconPlus } from '../../../Assets/icon-plus.svg'
import { ReactComponent as IconMinus } from '../../../Assets/icon-minus.svg'
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import { selectDuration, setDuration,  selectProjectName, selectZipCheck, setDataEffectiveMonth, setDataEffectiveYear, setProjectName, setRunModels, setZipCheck, selectProductType, setProductType, selectValidateCheck, setValidateCheck } from "../../../Pages/NewProject/NewProjectSlice";
import  CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DataSource from "../../DataSource/DataSource";
import { selectStep2Component, selectUserCompany } from "../../../AppSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        justifyContent: 'center',
        flexGrow: 1,
        maxWidth: 774
    },
    popover: {
        marginTop: 25,
        paddingTop: 77,
        paddingBottom: 63,
        minHeight: 642,
        maxWidth: 1069,
        background: "#FFFFFF",
        borderRadius: 25,
        border: "1px solid #EBEBEB",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
        "@media (min-width: 1440px)": {
            maxWidth: 1266
        }
    },
    popoverClose: {
        position: "relative",
        top: -58,
        right: -118,
        width: 16,
        height: 16,
        cursor: "pointer"
    },
    rootCard: {
        minHeight: 94,
        margin: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '94px'
    },
    activeCard: {
        border: '2px solid #363ed3',
        borderRadius: 6,
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)"

    },
    inactiveCard: {
        border: "none",
        "&:hover": {
            border: "2px solid #363ed3",
            borderRadius: 6,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)"
        },
    },
    disabledCard: {
        border: "none",
        "&:hover": {
            border: "none",
            boxShadow: "none",
            cursor: "default",
        },
        "& svg": {
            filter: "grayscale(100%)",
        },
    },
    UnavailableCard: {
        color: '#a2a0a0',

    },
    modelsContainer: {
        paddingBottom: 34
    },
    ContentCardRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
    },
    ContentCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    ContentImageCard: {
        paddingTop: 10,
        width: 100
    },
    maxWidth: {
        width: '100%'
    },
    TitleProperties: {
        marginLeft: 77
    },
    formContainer: {
       paddingTop: 35,
    },
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%'
        },
        width: '100%',
        padding: "26px",
        background: "#FFFFFF",
        border: "1px solid #EBEFF6",
        borderRadius: 6,
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)"
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "19px",
        color: "#312E40",
        padding: 0,
        margin:0,
        marginLeft:14
    },
    collapseTitle: {
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: 'Strawford',
            fontSize: 22,
            fontWeight: 400,
            lineHeight: "26px",
            textAlign: "center",
            "& span": {
                color: "#363ED3",
                paddingLeft: 10,
            }
        },
    },
    collapseText: {
        paddingTop: 7,
        paddingBottom: 33,
        "& .MuiTypography-root": {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
            textAlign: "center",
            color: "#312E40",
            opacity: 0.75
        },
    },
    prefTitle: {
        paddingTop: 30,
        paddingBottom: 24,
        borderTop: "1px solid rgba(143, 147, 174, 0.15)",
        "& .MuiTypography-root": {
            fontFamily: 'Strawford',
            fontSize: 18,
            fontWeight: 500,
            lineHeight: "18px",
            textAlign: "left",
            "& span": {
                color: "#363ED3",
                paddingLeft: 10,
            }
        },
    },
    customInput: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',

    },
    customNameInput: {
        display:'flex',
        width: '100%',
        height: '50px',
        backgroundColor: '#ffffff',
        border: '2px solid #EBEFF6',
        borderRadius: '5px',
        outline: '0px',
        padding: '0 20px',
        margin: '5px 0px',
        opacity: .25,
        '&:focus-within':{
            opacity: 1
        }
    },
    customNameInputActive: {
        opacity: 1
    },
    lblNombre: {
        paddingBottom: 11,
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#312E40",

    },
    customTitle: {
        paddingBottom: 57,
    },
    bottomChecks:{
       paddingTop: 34,   
    },
    headerTitle:{
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#000000"
    },
    checkboxes: {
        "&.Mui-checked" : {
            color: "#363ED3"
        }
    },
    dateContainer: {
        paddingTop: 14,
        paddingBottom: 37
    },
    productContainer: {
        paddingTop: 14
    },
    selectContainer: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        backgroundColor: '#ffffff',
        border: '2px solid #EBEFF6',
        borderRadius: 3,
        outline: 0,
        padding: '7px 9px 0 17px',
        minWidth: 100,
        marginRight: 5,
        opacity: .25,
        '& .MuiInput-formControl':{
            margin: 0
        },
        '& .MuiFormLabel-root':{
            fontFamily: 'Roboto!important',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
            display: "flex",
            alignItems: "end",
            height: 43,
            paddingBottom: 37,
            paddingLeft: 19
        },
        '&:focus-within, &:focus':{
            opacity: 1
        },
        "& .MuiInput-underline:after": {
            border: "none"
        },
        "& .MuiInput-underline:before": {
            border: "none"
        }
    },
    durationContainer: {
        width: "100%",
        maxWidth: 151,
        border: '2px solid #EBEFF6',
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: 6,
        outline: 0,
        marginRight: 5,
        '&:hover':{
            border: '2px solid #363ed3',
        },
    },
    durationActive: {
        border: '2px solid #363ed3',
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
    },
    durationCard: {
        '& .MuiCardContent-root':{
            height: 42,
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
            padding: 0,
            paddingTop: 11,
            textAlign: "center"
        },
    },
    button: {
      color: "#312e40",
      "&:hover": {
        backgroundColor: "#363ED3",
        color: "white",
      },
      textTransform: "capitalize",
    },
    customNextButton: {
      float: "right",
      backgroundColor: "#363ed3",
      color: "white !important",
      width: "148px",
      borderRadius: "12px",
      padding: "9px 10px",
      fontSize: "13px",
      fontWeight: "normal",
      textTransform: "none",
      cursor: "pointer",
      marginTop: 34,
      "&.Mui-disabled": {
        color: "#CBCACB !important",
        backgroundColor: "#E4E5E5 !important",
      },
    },

}));

const productTypes = [
    "Whole Life",
    "Annuities",
    "Term",
    "Permanent",
]

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "Jun",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

const Step1 = ({ onNext = () => {}, onClose = () => {}}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const projectName = useSelector(selectProjectName)
    const zipCheck = useSelector(selectZipCheck);
    const dataSource = useSelector(selectStep2Component);
    const duration = useSelector(selectDuration);
    const product = useSelector(selectProductType);
    const validateCheck = useSelector(selectValidateCheck);
    const userCompany = useSelector(selectUserCompany)
    const [focus,setFocus] = React.useState({
        focus: false
    })
    const [collapsed, setCollapsed] = React.useState(false)
    const [effectiveDate, setEffectiveDate]= React.useState({year: '', month: ''})
    const [modelsTmp, setModelsTmp ] = React.useState({
        "Lapse": true, 
        "UI": userCompany === "platform-anonym",
        "Cross-sell": false
    })

    const renderYearItems = () => {
        const years = []
        for (let index = 2023; index < 2034; index++) {
            years.push(index)
        }
        return years.map((year) => <MenuItem value={year} key={`year${year}`}>{year}</MenuItem>)
    }

    const handlerDataEffectiveDate = (e) => {
        e.preventDefault()
        if(e.target.name==="effective_year"){
            dispatch(setDataEffectiveYear(e.target.value)) 
            setEffectiveDate({...effectiveDate, year : e.target.value})
        }else if(e.target.name==="effective_month"){
            dispatch(setDataEffectiveMonth(e.target.value)) 
            setEffectiveDate({...effectiveDate, month : e.target.value})
        }
    }

    const range = (start, stop) => {
        let listRange = []
        for(let i = start; i<=stop; i++){
            listRange.push(i.toString())
        }
        return listRange.reverse()
    }

    const calculateYears = (back) => {
        const year = new Date().getFullYear();
        return Array.from({length: back}, (v, i) => year - back + i + 1);
    }

    const handleModelClick = (type) => {
        setFocus({focus:false})
        setModelsTmp({
            ...modelsTmp,
            [type]: !modelsTmp[type]
        })
    }

    const handleDurationClick = (value) => {
        dispatch(setDuration(value))
    }

    const handleProductChange = (e) => {
        e.preventDefault()
        dispatch(setProductType(e.target.value))
    }

    const handleFocusProject = () =>{
        setFocus({focus:true})
    }
    
    const handleAway = () =>{
        setFocus(false)
    }  
    const checkNextStatus = () => {
        let currentStatus = true;
        if (Object.values(modelsTmp).filter((value) => value).length > 0 && (duration || !modelsTmp["UI"]) && dataSource !== '')
            currentStatus = !currentStatus;
        return currentStatus
    }

    const handleNext = () => {
            const newValue = 
            Object.keys(modelsTmp)
                .filter((key) => modelsTmp[key])
                .map((key) => key)
            dispatch(setRunModels(
                newValue
            ))
            onNext(newValue)
    }

    return (
        <ClickAwayListener onClickAway={handleAway}>
            <Grid container justifyContent="center" className={classes.popover}>    
                <Grid container spacing={0} className={classes.root} data-testid="step1" >
                    <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center"  >
                        <Grid item xs={12} >
                            <Typography variant="h4" className={classes.customTitle}>
                                Add New Data
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <DataSource/>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.formContainer}>
                        <form className={classes.formRoot} noValidate autoComplete="off">
                            <Grid container justifyContent="center" direction="column">
                                <Grid container justifyContent="center" className={classes.collapseTitle} onClick={() => setCollapsed(!collapsed)}>
                                    <Typography>
                                        Select Models to Run <span>{collapsed ? <IconMinus style={{ marginBottom: 5 }}/> : <IconPlus/>}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Collapse in={collapsed}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start" spacing={0}>       
                                    <Grid container justifyContent="center">
                                        <Typography className={classes.collapseText}>
                                            You can select one or more models to run, or you can do that later.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.maxWidth}>


                                        <div className={classes.customInput}>
                                            <label className={classes.lblNombre}>Project Name</label>
                                            <div className={clsx(classes.customNameInput, projectName && classes.customNameInputActive)}>
                                            <input data-testid="projectNameInput" id="projectNameInput" type=" text" value={projectName} required style={{height:'100%',padding:'0',width:'100%', border:'none', outline:'none'}}  placeholder="Type Name" onChange={(event) => dispatch(setProjectName(event.target.value))} onFocus={handleFocusProject} >
                                            </input>
                                            <div style={{width:'20px', display:'flex', alignItems:'center'}}>
                                            {projectName?.replaceAll(" ", "") && focus ? <CloseIcon style={{color:'lightgray'}} fontSize="small" onClick={(event) => dispatch(setProjectName(''))} /> : null}
                                            {projectName?.replaceAll(" ", "") && !focus ? <CheckIcon style={{color:'#0bcb47'}} fontSize="small" /> : null}
                                            </div>
                                                
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography className={classes.lblNombre}>Select Models</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.modelsContainer}>
                                            <Grid container spacing={0} justifyContent="center" alignItems="center" >
                                                <Grid item xs={4} className={modelsTmp["Lapse"] ? classes.activeCard : classes.inactiveCard}>
                                                    <Card data-testid="lapseModel" className={classes.rootCard} onClick={() => { handleModelClick("Lapse") }}>
                                                        <CardContent className={classes.ContentCardRow}>
                                                            <IconLapse  />
                                                            <Typography align={'center'} className={classes.title} color="textSecondary" gutterBottom>
                                                                Lapse
                                                            </Typography>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid  className={clsx(modelsTmp["UI"] ? classes.activeCard : classes.inactiveCard, userCompany !== "platform-anonym" ? classes.disabledCard : '')} item xs={4} >
                                                    <Card data-testid="uiModel" className={classes.rootCard} onClick={() => { 
                                                        userCompany === "platform-anonym" && handleModelClick("UI") 
                                                    }}>
                                                        <CardContent className={classes.ContentCardRow}>
                                                        
                                                                <IconUI />
                                                                <Typography align={'center'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    UI Model
                                                                </Typography>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid className={classes.disabledCard} item xs={4}>
                                                    <Card className={classes.rootCard} onClick={() => { 
                                                        //handleModelClick("Cross-sell") 
                                                        }}>
                                                        <CardContent className={classes.ContentCardRow}>
                                                            <IconDistribution />
                                                            <Typography align={'center'} className={classes.title} color="textSecondary" gutterBottom>
                                                                Cross-Sell
                                                            </Typography>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    
                                        <Grid item container xs={12}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.headerTitle}>Product Type:</Typography>
                                            </Grid>                                                    
                                            <Grid item xs={6}>
                                                <Typography className={classes.headerTitle}>Data Effective Date:</Typography>
                                            </Grid>
                                        </Grid>        
                                        <Grid className={classes.productContainer} item container xs={12}>                                                       
                                                    <Grid item container xs={6}>             
                                                        <FormControl className={classes.selectContainer}>
                                                            <Select
                                                            defaultValue='placeholder'
                                                            onChange={handleProductChange}
                                                            value={product}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                    maxHeight: 48 * 4.5 + 8,
                                                                    width: 'auto',
                                                                    },
                                                                    sx:{
                                                                        '::-webkit-scrollbar': {
                                                                            width: '14px',
                                                                        },
                                                                        '::-webkit-scrollbar-track': {
                                                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                                                        },
                                                                        '::-webkit-scrollbar-thumb': {
                                                                            backgroundColor: '#f3f7ff',
                                                                            border: '4px solid rgba(0, 0, 0, 0)',
                                                                            'background-clip': 'padding-box',
                                                                            'border-radius': '9999px',
                                                                        }
                                                                    }
                                                                },
                                                                anchoOrigin:{
                                                                    vertical: "bottom",
                                                                    horizontal:'center'
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                            }}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label',
                                                            name: 'product_type',
                                                            id: 'id_data_product_type', }}
                                                            >
                                                                
                                                                {productTypes.map((item) => (
                                                                    <MenuItem style={{marginRight: '12px',marginLeft:'6px'}}  key={item} value={item}>{item}</MenuItem> )
                                                                )}
                                                            </Select>
                                                        </FormControl > 
                                                   </Grid>                                                        
                                                    <Grid item container xs={6}>
                                                        <Grid item>
                                                            <FormControl className={classes.selectContainer}>
                                                                {!effectiveDate.year && <InputLabel id="year-select-label">Year</InputLabel>}
                                                                <Select 
                                                                    label="Year"
                                                                    value={effectiveDate.year}
                                                                    onChange={handlerDataEffectiveDate} 
                                                                    name="effective_year">
                                                                    {renderYearItems()}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControl className={classes.selectContainer}>
                                                                {!effectiveDate.month && <InputLabel id="year-select-month">Month</InputLabel>}
                                                                <Select label="Month" onChange={handlerDataEffectiveDate} name="effective_month">
                                                                    {
                                                                        months.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>                                         
                                        {Object.values(modelsTmp).filter((value) => value).length > 0 &&
                                        <Grid style={{ width: "100%" }}> 
                                            <Grid className={classes.bottomChecks}
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            >
                                                <Grid item container xs={12}>
                                                    <Grid item xs={8}>
                                                        <Typography className={classes.headerTitle}>Connect to external data:</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography className={classes.headerTitle}>Zip Code:</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography className={classes.headerTitle}>Validate:</Typography>
                                                    </Grid>             
                                                </Grid>
                                            
                                                <Grid item container xs={12}>
                                                    <Grid item container xs={8}>                                        
                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox className={classes.checkboxes} color='default' defaultChecked  />}  label={<span>Census</span>} />
                                                        </Grid>

                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox className={classes.checkboxes} color='default' defaultChecked />} label={<span>Industry data</span>} />
                                                        </Grid>

                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox className={classes.checkboxes} color='default' defaultChecked />} label={<span>Atidot</span>} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox value={zipCheck} onChange={e => dispatch(setZipCheck(e.target.checked))} className={classes.checkboxes}  color='default'  defaultChecked />} label="Zip" />
                                                        </Grid>
                                                    </Grid> 
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox value={validateCheck} onChange={e => dispatch(setValidateCheck(e.target.checked))} className={classes.checkboxes}  color='default' defaultChecked/>} label="Validate" />
                                                        </Grid>
                                                    </Grid>            
                                                </Grid>
                                                
                                            </Grid>
                                            { 
                                                modelsTmp["Lapse"] && <Grid container justifyContent="flex-start" className={classes.prefTitle}>
                                                    <Typography>
                                                        Lapse Preferences
                                                    </Typography>
                                                </Grid>}
                                            { 
                                                modelsTmp["Lapse"] &&    
                                            <Collapse in={modelsTmp["Lapse"]}>         
                                                <Grid item container xs={12}>
                                                    
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.headerTitle}>Analysis Period</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    
                                                    <Grid className={classes.dateContainer} item container xs={6}>
                                                        <Grid item className={clsx(classes.durationContainer, duration === 3 && classes.durationActive)}>
                                                            <Card className={classes.durationCard} onClick={() => handleDurationClick(3)}>
                                                                <CardContent>
                                                                    <Typography>
                                                                        3 months
                                                                    </Typography>

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item className={clsx(classes.durationContainer, duration === 4 && classes.durationActive)}>
                                                            <Card className={classes.durationCard} onClick={() => handleDurationClick(4)}>
                                                                <CardContent>
                                                                    <Typography>
                                                                        4 months
                                                                    </Typography>

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                            </Collapse>                                    
                                            }
                                            {/* <Grid container justifyContent="flex-start" className={classes.prefTitle}>
                                                <Typography>
                                                    UI Preferences <span><IconPlus/></span>
                                                </Typography>
                                            </Grid>                           
                                            <Grid container justifyContent="flex-start" className={classes.prefTitle}>
                                                <Typography>
                                                    Cross-Sell Preferences <span><IconPlus/></span>
                                                </Typography>
                                            </Grid>    */}
                                        </Grid>
                                        }
                                        
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </form>
                        
                        <Grid container justifyContent="center" alignItems="center">
                                            <Button
                                                onClick={handleNext}
                                                className={clsx(
                                                classes.button,
                                                classes.customNextButton
                                                )}
                                                disabled={checkNextStatus()}
                                                data-testid={"Next"}
                                            >
                                                {"Next"}
                                            </Button>
                                        </Grid>
                    </Grid>


                </Grid>
                
                <CloseIcon className={classes.popoverClose} onClick={onClose}/>
        </Grid>
    </ClickAwayListener>
    );
}

export default Step1;