export const median = (numbers) => {
    if(numbers.length==0){
        return 0
    }
    const sorted = numbers.sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
}
var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
];

export const checkData = (item) => {
    if (item) {
      try {
        if (Object.keys(item).length !== 0) {
          return true
        }
        return false
      } catch (error) {
        return false
      }
    }
    return false
  }