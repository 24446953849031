import { Divider, Grid, Typography, Paper, makeStyles, Button } from "@material-ui/core";
import React from "react";
import { useHistory } from 'react-router-dom';
import moment from "moment/moment";

import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";

import { ReactComponent as DollarIcon } from "../../Assets/icon-dollar.svg";
import { ReactComponent as MoneySaveIcon } from "../../Assets/icon-money-save.svg"
import { ReactComponent as HumanIcon } from "../../Assets/icon-people.svg";
import Charts from "../../Assets/fadedCharts.png";
import { User } from '../../global/user'

import HeaderDashboard from "../Headers/HeaderDashboard";
import HeaderContainer from "../Headers/HeaderContainer/HeaderContainer";
import clsx from "clsx";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(() => ({
    metaContainer: {
        padding: 16,
        background: "#FFFFFF",
        boxShadow: "0px 0px 6px 0px rgba(106, 108, 113, 0.10)",
        borderRadius: 8,
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 22,
            fontWeight: 400,
            color: "#000000",
        },
    },
    metaTitle: {
        paddingBottom: 37
    },
    metaItemContainer: {
        paddingLeft: 12,
        "&.meta--item--first": {
            paddingLeft: 0
        },
        "& .MuiTypography-root": {
            fontFamily: "Roboto!important",
            fontSize: 16,
            fontWeight: 700
        },
        "& .MuiTypography-root.MuiTypography-h1": {
            fontFamily: "Roboto!important",
            fontSize: 22,
            fontWeight: 700,
            color: "#232033",
        },
        "& span.MuiTypography-root": {
            fontFamily: "Roboto!important",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#232033",
        },
        "& .MuiDivider-root": {
            width: "100%"
        },
    },
    metaItem: {
        background: "#FFFFFF",
        borderRadius: 8,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: "19px 16px 5px 20px",
        marginTop: 10,
        minHeight: 194,
        "@media (max-width:1282px)": {
            minHeight: 211
        }
    },
    metaItemTitle: {
        paddingLeft: 20
    },
    metaListItem: {
        padding: "14px 0",
    },
    uiIcon: {
        "& path": {
            color: "#363ED3",
            fill: "#363ED3"
        }
    },
    lapseIcon: {
        "& path": {
            color: "#0BCB47",
            fill: "#0BCB47"
        },
        "& mask": {
            color: "#0BCB47",
            fill: "#0BCB47"
        },

    },
    primaryIcon: {
        "& path": {
            color: "#36C5D9",
            fill: "#36C5D9"
        }
    },
    secondaryIcon: {
        "& path": {
            color: "#37C173",
            fill: "#37C173"
        }
    },
    tretiaryIcon: {
        "& path": {
            color: "#36ACA0",
            fill: "#36ACA0"
        },
        "& mask": {
            color: "#36ACA0",
            fill: "#36ACA0"
        },

    },
    operationsContainer: {
        border: "1px solid #EBEBEB25",
        borderRadius: 8,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: "30px 16px 16px 16px",
        marginTop: 31
    },
    operationsHeaderItem: {
        background: "#FFFFFF",
        borderRadius: 8,
        boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
        padding: 16,
        "&:last-child": {
            background: "#363ED3",
            color: "#FFFFFF"
        }
    },
    operationsPeriodContaner: {
        opacity: .25,
        "& ul": {
            display: "flex",
            listStyleType: "none",
            margin: 0,
            paddingTop: 27,
            paddingBottom: 17
        },
        "& li": {
            paddingLeft: 24
        },
    },
    chartsContaner: {
        background: `url('${Charts}'), no-repeat`,
        backgroundSize: 'cover',
        minHeight: 331,
        "@media (max-width:1397px)": {
            backgroundSize: 'contain'
        }
    },
    dialogNewCampaign: {
        textAlign: "center",
        padding: '2rem',
        height: '9rem',
        fontFamily: 'Roboto',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '1.75rem'
    },

    confirmButton: {
        color: "#FFF",
        background: "#363ED3",
        borderRadius: "10px",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 54px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#363ED3",
            opacity: 0.85
        }
    },
    values: {
        textAlign: "center",
    },
}))


const DashboardComponent = ({ data, refreshAll }) => {
    const classes = useStyles()
    const history = useHistory();

    const currency = getCountryCurrencySymbol()
    const isAnnuities = localStorage.getItem("accountType") === "annuities"

    
    const renderValue = (value, cash = true) => {
        if (value) {
            return cash ? nFormatter(data?.prospects, 2, currency) : <NumberFormat value={Math.floor(value)} displayType={'text'} thousandSeparator={true}/>
        } else {
            return "-" 
        }
        
    }

    return (
        <Grid container>
            <HeaderContainer title="Dashboard">
                <HeaderDashboard data={{
                    date:  data?.last_run_date ? moment(data.last_run_date).format('MMM DD, YYYY') : "-",
                    effectiveDate: data?.last_effective_date ? moment(data.last_effective_date).format('MMM DD, YYYY') : "-",
                }}/>
            
                {User().isDev &&<Grid item>
                     <button onClick={refreshAll}>refresh all</button>
                </Grid>}
            </HeaderContainer>

            <Grid container className={classes.metaContainer}>
                <Grid container>
                    <Typography className={classes.metaTitle}>Opportunities</Typography>
                </Grid>
                <Grid container direction="column" xs={4} className={clsx(classes.metaItemContainer,"meta--item--first")}>
                    <Typography className={classes.metaItemTitle}>In the Pipeline</Typography>
                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.prospects, 2)}</Typography></Grid>
                            <Grid item xs={7}><Typography>Prospects</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><MoneySaveIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.potential_premium, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Annual Deposits" : "Annual Premium"}</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><DollarIcon className={classes.tretiaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.assets, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Total AUM" : "Cash Value"}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="column" xs={4} className={classes.metaItemContainer}>
                    <Typography className={classes.metaItemTitle}>Campaigns in Process</Typography>

                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.targeted_prospects, 2)}</Typography></Grid>
                            <Grid item xs={7}><Typography>Targeted Prospects</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><MoneySaveIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.annual_deposit_premium, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Annual Deposits" : "Targeted Annual Premium"}</Typography></Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item xs={1}><DollarIcon className={classes.tretiaryIcon} /></Grid>
                            <Grid item xs={4}><Typography variant="h1">{nFormatter(data?.targeted_aum, 2, currency)}</Typography></Grid>
                            <Grid item xs={7}><Typography>{isAnnuities ? "Targeted AUM" : "Cash Value"}</Typography></Grid>
                        </Grid>
                    </Grid>

                </Grid>
                
                <Grid container direction="column" xs={4} className={classes.metaItemContainer}>
                    <Typography className={classes.metaItemTitle}>Results to Date</Typography>
                    <Grid container direction="column" className={classes.metaItem}>
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item container justifyContent="center" xs={1}><HumanIcon className={classes.primaryIcon} /></Grid>
                            <Grid item xs={10}>
                                <Grid container alignItems="center">
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.retained_policies_count, 2)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Retained Policies" : "Retained Policies"}</Typography></Grid>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.rollover_policies_count, 2)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Rollover Policies" : "New Policies"}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid container alignItems="center" className={classes.metaListItem}>
                            <Grid item container justifyContent="center" xs={1}><DollarIcon className={classes.secondaryIcon} /></Grid>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.retained_aum, 2, currency)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Retained AUM" : "Retained Premium"}</Typography></Grid>
                                    <Grid item container justifyContent="center" xs={3}><Typography variant="h1" className={classes.values}>{nFormatter(data?.rollover_aum, 2, currency)}</Typography></Grid>
                                    <Grid item xs={8}><Typography>{isAnnuities ? "Rollover AUM" : "New Premium"}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>

            <Grid container direction="column" className={classes.operationsContainer}>
                <Typography>Operations</Typography>
                <Grid container style={{ opacity: .25 }}>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Prospect reached</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Engaged</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">0</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Closed deals</Typography>
                            <Typography>2% from target</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" xs={3} className={classes.operationsHeaderItem}>
                        <Grid item>
                            <Typography variant="h4">$0</Typography>
                        </Grid>
                        <Typography>Assets</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className={classes.operationsPeriodContaner}>
                    <ul>
                        <li style={{ color: "#363ED3" }}>All</li>
                        <li>Last day</li>
                        <li>Last 7 days</li>
                        <li>Last 30 days</li>
                        <li>Custom range</li>
                    </ul>
                </Grid>
                <Grid container justifyContent="center" className={classes.chartsContaner}>
                <Paper elevation={3} className={classes.dialogNewCampaign}>
                    <Typography>Please run a campaign to enable Operations Dashboard</Typography>
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.confirmButton}
                        data-testid="loginButton"
                        disabled={false}
                        onClick={() => {
                            history.push("/")
                        }}
                    >
                        Create Campaign
                    </Button>
                </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DashboardComponent