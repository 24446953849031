import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import MapperItem from './MapperItem/MapperItem';
import { useSelector } from 'react-redux';
import { selectEmptyKeys, selectRunModel } from '../../Pages/NewProject/NewProjectSlice';
import { includes } from 'lodash';

const useStyles = makeStyles(() => ({
    errorContainer: {
        maxWidth: 740,
        paddingLeft: 17,
        visibility: "hidden",
        "& .MuiTypography-root": {
            padding: 0,
            margin: 0,
            fontSize: 9,
            fontWeight: 400,
            lineHeight: "100%",
            color: "#FF4B62"
        }
    }
}))

const SubModel = ({ 
    buckets, 
    title,
    SubModelColor,
    menuIsOpen,
    fieldSelection,
    headers,
    handleMenuOpen,
    handleMenuClose,
    handleSelectChange,
    handlePopoverOpen,
    outSideRef
}) => {
    const classes = useStyles()
    const [color, setColor] = useState("#000000")
    const [error, setError] = useState(false)
    const emptyKeys = useSelector(selectEmptyKeys)
    
    useEffect(() => {
        switch (SubModelColor) {
            case "red":
                setColor("#FF4B62")
                break;
            case "yellow":
                setColor("#FFEC22")
                break;
            case "blue":
                setColor("#4EDCEE")
                break;
            case "gray":
                setColor("#CACACA")
                break;
        
            default:
                break;
        }
    }, [SubModelColor])

    useEffect(() => {
        setError(
            buckets?.reduce((a, i) => emptyKeys.includes(i.id) || a, false)
        )
    }, [emptyKeys, buckets])
    
    return (
            <Grid item container direction="column" justifyContent="center" alignItems="center">
                {
                    title && 
                    <Grid container justifyContent="flex-start" style={{ maxWidth: 740 }}>
                        {title}
                    </Grid>
                }
                {
                    buckets?.map(
                        (item, idx) => 
                        <MapperItem
                            key={idx}
                            item={item}
                            error={emptyKeys?.includes(item.id)}
                            position={
                                buckets.length === 1 ? "center"
                                : idx === 0 ? "top"
                                : idx === buckets.length-1 ? "bottom" 
                                : "center"
                            }
                            color={color}
                            menuIsOpen={menuIsOpen}
                            fieldSelection={fieldSelection} 
                            headers={headers}
                            handleMenuOpen={handleMenuOpen}
                            handleMenuClose={handleMenuClose}
                            handlePopoverOpen={handlePopoverOpen}
                            handleSelectChange={handleSelectChange}
                            autoMapping={handleSelectChange}
                            outSideRef={outSideRef}/>
                    )
                }
                <Grid container justifyContent="flex-start" className={classes.errorContainer} style={error ? { visibility: "visible"} : {}}>
                    <Typography>Required Field for Selected Model</Typography>
                </Grid>
            </Grid>
    )
}

export default SubModel