import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, CircularProgress, Grid, styled } from "@material-ui/core";

// Components
import { basicButtonStyles } from "../../../Components/Demo/Header/styles";
import SelectFilter from "../../Filters/SelectFilter";
import PriorityIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator";

// Redux
import { selectActiveTab, selectProductsData, selectProspectsData, selectRuns, setCashValueFilter, setDurationFilter, setLapsePremiumFilter, setPremiumTermFilter, setProductNameFilter, setStoryTypeFilter, setUIFaceAmountFilter } from "../../../Pages/BookAnalysis/BookAnalysisSlice";
import { selectColumns, setColumns } from "../../../AppSlice";
import { selectProspectsFilters, setAgeFilter, setAgentStatusFilter, setContractLossFilter, setContractValueFilter, setIncomeAccFilter, setIncomeLossFilter, setLIBRStatusFilter, setLapseFaceAmountFilter, setLibrFilter, setPriorityFilter, setProductFilter, setResStateFilter, setStateFilter, setStoryFilter, setSubActivityFilter, setSurrValueFilter, setSurrYearValueFilter, setUIPremiumFilter, setWithdravalActivityFilter } from "../../../Pages/BookAnalysis/BookAnalysisSlice";

// Services
import { getCountryCurrencySymbol } from "../../../global/helpers";
import { defaultAgeFilter, defaultAgeFilterLife, defaultAgentStatusFilter, defaultContractValueFilter, defaultLIBRStatusFilter, defaultLibrFilter, defaultOpportunityTypeFilter, defaultPercentage, defaultPriorityFilter, defaultYearsFilter } from "../constants";

import ColumnsPopup from "../../Popups/ColumnsPopup/ColumnsPopup";
import BookAnalysisService from "../../../Pages/BookAnalysis/BookAnalysisService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

// Icons
import ProspectsTotal from "../ProspectsTotal/ProspectsTotal";
import FiltersContianer from "../../Filters/FiltersContianer/FiltersContianer";
import TableContainer from "../../Prospects/TableContainer/TableContainer";
import StoryIcon from "../StoryIcon/StoryIcon";
import { checkPermission } from "../../../global/user";

export const UICreateButton = styled(Button)({
    ...basicButtonStyles,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    padding: '13px 22px',
    height: 'auto',
    width: 'auto',
    maxWidth: 168,
    borderRadius: 10,
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
      opacity: '0.85',
      backgroundColor: '#0BCB47',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#0BCB47',
      borderColor: '#FFF',
      boxShadow: 'none'
    },
    '&.Mui-disabled': {
      opacity: '0.25',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
});

const ApiService = new BookAnalysisService()

const ProspectsContainer = ({
    userType,
    data,
    headers,
    page,
    loaded,
    clear,
    sort,
    onChangePage,
    onFiltersChange,
    handleChangePage,
    handleSelectPolicy,
    handleSort,
    handleResetFilters,
    handleResetUIFilters,
    handleResetLapseFilters,
    handleExportAll,
    handleExport,
    renderRow,
    rowsPerPage,
    setRowsPerPage,
    divider, 
    divided = true, 
    mode = 'tab',
    frozenColumns = [],
    hiddenColumns = []
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const currency = getCountryCurrencySymbol()

    const [ defaultMarketLagFilter, setDefaultMarketLagFilter ] = useState({ "All": true })
    const [ defaultStateFilter, setDefaultStateFilter ] = useState({ "All": true })
    const [ defaultDurationFilter, setDefaultDurationFilter ] = useState({ "All": true })
    const [ defaultOpportunityFilter, setDefaultOpportunityFilter ] = useState({ "All": true })
    const [ defaultProductFilter, setDefaultProductFilter ] = useState({ "All": true })
    const [ defaultPremiumUIFilter, setDefaultPremiumUIFilter ] = useState({ "All": true, [`<${currency}500`]: false, [`<${currency}500-${currency}1500`]: false, [`<${currency}1500-${currency}2500`]: false, [`<${currency}2500-${currency}5K`]: false, [`<${currency}5K-${currency}10K`]: false, [`${currency}10K<`]: false })
    const [ defaultFaceAmountFilter, setDefaultFaceAmountFilter ] = useState({ "All": true })
    const [ openColumns, setOpenColumns ] = useState(false)
    const [ tmpColumns, setTmpColumns ] = useState(null)

    const summaryData = useSelector(selectProductsData)
    const runs = useSelector(selectRuns)
    const run_ids = [
      ...summaryData.lapse_runs?.map(({ run_id }) => run_id),
      ...summaryData.ui_runs?.map(({ run_id }) => run_id)
    ]
    const prospectsData = useSelector(selectProspectsData)
    const prospectsFilters = useSelector(selectProspectsFilters)
    const activeTab = useSelector(selectActiveTab)
    const columns = useSelector(selectColumns)

    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isAE = (activeTab === "ProspectsAE" && mode === "tab") || isAnnuities

    const onChangeColumns = (item, value) => {
      dispatch(setColumns({
        ...columns,
        [item]: value
      }))
      
    }

    const discardChangeColumns = () => {
      dispatch(setColumns(tmpColumns))
      setOpenColumns(false)
    }

    const applyColumns = () => {
      ApiService._patchRunColumn(
        run_ids,
        Object.keys(columns)?.filter((item) => columns[item]),
        () => {
          setTmpColumns(columns)
          setOpenColumns(false)
        },
        () => {}
      )
    }

    const handleCreateCampaignClick = () => {
      if (userType === "platform-anonym") {
        //setOpenRegisterDialog(true)
      } else {
        history.push(`/NewCampaign?run_ids=${run_ids?.join(',')}`)
      }
    }

    useEffect(() => {
      return () => {
        setTmpColumns(null)
      }
    }, [])

    useEffect(() => {
        setTmpColumns(columns)
    }, [columns])

    useEffect(() => {
        onChangePage()
    }, [onChangePage])
  
    useEffect(() => {
        onFiltersChange()
    }, [onFiltersChange])

    useEffect(() => {
        if (Object.keys(data.uniqueStories).length > 0) {
          setDefaultOpportunityFilter(data.uniqueStories)
        }
        if (Object.keys(data.uniqueProducts).length > 0) {
          setDefaultProductFilter(data.uniqueProducts)
        }
        if (Object.keys(data.face_amount_ranges).length > 0) {
          setDefaultFaceAmountFilter(data.face_amount_ranges)
        }
        if (Object.keys(data.unique_states).length > 0) {
          setDefaultStateFilter(data.unique_states)
        }
        if (Object.keys(data.uniqueProducts).length > 0) {
          setDefaultMarketLagFilter(data.uniqueProductNames)
        }
        if (Object.keys(data.durationSegments).length > 0) {
          setDefaultDurationFilter(data.durationSegments)
        }
      }, [data]) 

    return (
        <>
            <Grid container xs={12} justifyContent="space-between">

              <ProspectsTotal
                count={data.count}
                premium={data.total_premium}
                disabled={!prospectsData?.count || history.location.search?.includes("demo") || !checkPermission('create_campaign')}
                onCreateCampaignClick={handleCreateCampaignClick}
              />

              <FiltersContianer
                title="Existing Policy Data"
                size="xs"
                onReset={handleResetUIFilters}
              >
                {
                  (summaryData?.lapse_runs?.length  || summaryData?.ui_runs?.length) ? 
                  isAE ? 
                  <Grid container>
                      {columns["Product Type"] && <SelectFilter
                        title="Product Type"
                        popoverText="Product Type"
                        defaultValue={defaultProductFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setProductFilter(defaultProductFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setProductFilter(value))
                        }}
                        dynamic
                        />}
                            
                      {columns["Age"] && <SelectFilter
                        title="Age"
                        popoverText="Age"
                        defaultValue={defaultAgeFilter}
                        reset={clear}
                        resetFilter={
                          (e) => {
                            dispatch(setAgeFilter(defaultAgeFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setAgeFilter(value))
                        }}
                        renderControl={
                          (item) => {
                            if(item[0] === "<") {
                              return `-50`
                            } else if(item.includes("<")) {
                            return `80+;`
                            } else {
                              return item
                            }
                          }
                        }
                        />}

                      {columns["Contract Value"] && <SelectFilter
                        title="Contract Value"
                        popoverText="Contract Value"
                        defaultValue={defaultContractValueFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setContractValueFilter(defaultContractValueFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setContractValueFilter(value))
                        }}
                        />}

                      {columns["Years Left in Surrender Charge"] && <SelectFilter
                        title="Years in Surrender Charge"
                        popoverText="Years in Surrender Charge"
                        defaultValue={defaultYearsFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setSurrYearValueFilter(defaultYearsFilter))}
                        onChange={(value) => dispatch(setSurrYearValueFilter(value))}
                        />} 

                      {columns["Surrender Value"] && <SelectFilter
                        title="Surrender Value"
                        popoverText="Surrender Value"
                        defaultValue={defaultContractValueFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setSurrValueFilter(defaultContractValueFilter))}
                        onChange={(value) => { dispatch(setSurrValueFilter(value)) }}
                        />}   

                      {columns["LIB Payment"] && <SelectFilter
                        title="LIBR Pmnt"
                        popoverText="LIBR Pmnt"
                        defaultValue={defaultLibrFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setLibrFilter(defaultContractValueFilter))}
                        onChange={(value) => { dispatch(setLibrFilter(value)) }}
                        />} 

                      {columns["Withdraw Activity"] && <SelectFilter
                        title="Withdraw Activity"
                        popoverText="Withdraw Activity"
                        defaultValue={defaultPriorityFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setWithdravalActivityFilter(defaultPriorityFilter))}
                        onChange={(value) => { dispatch(setWithdravalActivityFilter(value)) }}
                        />}

                      {columns["Subaccount activity"] && <SelectFilter
                        title="Subacct. Activity"
                        popoverText="Subacct. Activity"
                        defaultValue={defaultPriorityFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setSubActivityFilter(defaultPriorityFilter))}
                        onChange={(value) => { dispatch(setSubActivityFilter(value)) }}
                        />}                        

                      {columns["Income Account Value"] && <SelectFilter
                        title="Income Acc. Value"
                        popoverText="Income Acc. Value"
                        defaultValue={defaultContractValueFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setIncomeAccFilter(defaultContractValueFilter))}
                        onChange={(value) => dispatch(setIncomeAccFilter(value))}
                        />}   

                      {columns["LIB Rider Status"] && <SelectFilter
                        title="LIBR Status"
                        popoverText="LIBR Status"
                        defaultValue={defaultLIBRStatusFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setLIBRStatusFilter(defaultLIBRStatusFilter))}
                        onChange={(value) => dispatch(setLIBRStatusFilter(value))}
                        />}

                      {columns["Contract Loss %"] && <SelectFilter
                        title="Contract Loss"
                        popoverText="Contract Loss"
                        defaultValue={defaultPercentage}
                        reset={clear}
                        resetFilter={() => dispatch(setContractLossFilter(defaultPercentage))}
                        onChange={(value) => dispatch(setContractLossFilter(value))}
                        />}                        

                      {columns["Income Loss %"] && <SelectFilter
                        title="Income Loss"
                        popoverText="Income Loss"
                        defaultValue={defaultPercentage}
                        reset={clear}
                        resetFilter={() => dispatch(setIncomeLossFilter(defaultPercentage))}
                        onChange={(value) => dispatch(setIncomeLossFilter(value))}
                        />}    

                      {columns["Agent Status"] && <SelectFilter
                        title="Agent Status"
                        popoverText="Agent Status"
                        defaultValue={defaultAgentStatusFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setAgentStatusFilter(defaultAgentStatusFilter))}
                        onChange={(value) => dispatch(setAgentStatusFilter(value))}
                        />}   

                      {columns["Issue State"] && <SelectFilter
                        title="Issue state"
                        popoverText="Issue state"
                        defaultValue={defaultStateFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setStateFilter(defaultStateFilter))}
                        onChange={(value) => dispatch(setStateFilter(value))}
                        dynamic
                        />}  

                      {columns["Residency State"] && <SelectFilter
                        title="Resid. State"
                        popoverText="Resid. State"
                        defaultValue={defaultStateFilter}
                        reset={clear}
                        resetFilter={() => dispatch(setResStateFilter(defaultStateFilter))}
                        onChange={(value) => dispatch(setResStateFilter(value))}
                        />}                      
                  </Grid> 
                  :
                  <Grid container>
                      {columns["Product Name"] && <SelectFilter
                        title="Product Name"
                        popoverText="Product Name"
                        defaultValue={defaultMarketLagFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setProductNameFilter(defaultMarketLagFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setProductNameFilter(value))
                        }}
                        dynamic
                        />}

                      {columns["Product Type"] && <SelectFilter
                        title="Product Type"
                        popoverText="Product Type"
                        defaultValue={defaultProductFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setProductFilter(defaultProductFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setProductFilter(value))
                        }}
                        dynamic
                        />}
                            
                      {columns["Current Age"] && <SelectFilter
                        title="Current Age"
                        popoverText="Current Age"
                        defaultValue={defaultAgeFilterLife}
                        reset={clear}
                        resetFilter={
                          (e) => {
                            dispatch(setAgeFilter(defaultAgeFilterLife))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setAgeFilter(value))
                        }}
                        />}
                      
                      {columns["Annual Premium"] && <SelectFilter 
                        title="Annual Premium"
                        popoverText="Annual Premium"
                        defaultValue={defaultPremiumUIFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            //dispatch(resetUIFilter()); 
                            dispatch(setUIPremiumFilter(defaultPremiumUIFilter));
                          }
                        }
                        onChange={(value) => {
                          dispatch(setUIPremiumFilter(value))
                        }}  
                        />} 
                        
                      {columns["Face Amount"] && <SelectFilter
                      title="Face Amount"
                      popoverText="face amount"
                      defaultValue={defaultFaceAmountFilter}
                      reset={clear}
                      resetFilter={
                        () => {
                          setDefaultFaceAmountFilter(defaultFaceAmountFilter)
                          dispatch(setUIFaceAmountFilter(defaultFaceAmountFilter))
                        }
                      }
                      onChange={(value) => {
                        dispatch(setUIFaceAmountFilter(value))
                      }}
                      dynamic
                      />}
                      
                        <SelectFilter 
                          title="Policy Duration"
                          popoverText="Policy Duration"
                          defaultValue={defaultDurationFilter}
                          reset={clear}
                          resetFilter={
                            () => {
                              dispatch(setDurationFilter(defaultDurationFilter));
                            }
                          }
                          onChange={(value) => {
                            dispatch(setDurationFilter(value))
                          }} 
                          dynamic 
                          />      
                  </Grid> 
                  :
                  <Grid container justifyContent="center" alignContent="center" style={{minHeight: 119}}>
                    <CircularProgress style={{color: "#363ED3",width:48,height:48}}/>
                  </Grid>  
                }
              </FiltersContianer>

              <FiltersContianer
                title="Atidot Recommends"
                size="sm"
                onReset={handleResetLapseFilters}
                openSettings={() => setOpenColumns(true)}
              >
                {
                  (summaryData?.lapse_runs?.length  || summaryData?.ui_runs?.length) ? 
                  isAE ? 
                  <Grid container>
                      {columns["Upside Story"] && <SelectFilter
                        title="Upside Story"
                        popoverText="Upside Story"
                        defaultValue={defaultOpportunityFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setStoryFilter(defaultOpportunityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setStoryFilter(value))
                        }}
                        dynamic
                        />}   

                      {columns["Priority"] && <SelectFilter
                        title="Priority"
                        popoverText="priority"
                        defaultValue={defaultPriorityFilter}
                        icons={{
                          "high": <PriorityIndicator priority="high"/>,
                          "medium": <PriorityIndicator priority="mid"/>,
                          "low": <PriorityIndicator priority="low"/>,
                        }}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setPriorityFilter(defaultPriorityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setPriorityFilter(value))
                        }}
                        />}

                      {columns["AUM Upside"] && <SelectFilter
                        title="AUM Upside"
                        popoverText="AUM Upside"
                        defaultValue={defaultContractValueFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setLapsePremiumFilter(defaultContractValueFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setLapsePremiumFilter(value))
                        }}
                        dynamic
                        />}                        
                  </Grid> 
                  : 
                  <Grid container>
                      {columns["Upside Story"] && <SelectFilter
                        title="Upside Story"
                        popoverText="Upside Story"
                        defaultValue={defaultOpportunityFilter}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setStoryFilter(defaultOpportunityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setStoryFilter(value))
                        }}
                        dynamic
                        />}

                      <SelectFilter
                          title="Opportunity Type"
                          popoverText="Opportunity Type"
                          defaultValue={defaultOpportunityTypeFilter}
                          icons={{
                            "New Business": <StoryIcon storyType="ui"/>,
                            "Retention": <StoryIcon storyType="lapse"/>,
                          }}
                          reset={clear}
                          resetFilter={
                            () => {
                              dispatch(setStoryTypeFilter(defaultOpportunityTypeFilter))
                            }
                          }
                          onChange={(value) => {
                            dispatch(setStoryTypeFilter(value))
                          }}
                          multiSelect={false}
                          dynamic
                          />                  

                      {columns["Probability to lapse"] && <SelectFilter
                        title="Probability to Lapse"
                        popoverText="Probability to Lapse"
                        defaultValue={defaultPriorityFilter}
                        icons={{
                          "high": <PriorityIndicator priority="high"/>,
                          "medium": <PriorityIndicator priority="mid"/>,
                          "low": <PriorityIndicator priority="low"/>,
                        }}
                        reset={clear}
                        resetFilter={
                          () => {
                            dispatch(setPriorityFilter(defaultPriorityFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setPriorityFilter(value))
                        }}
                        />}
                      
                      {columns["Premium Upside"] && <SelectFilter
                        title="Premium Upside"
                        popoverText="Premium Upside"
                        defaultValue={defaultContractValueFilter}
                        reset={clear.recomended}
                        resetFilter={
                          () => {
                            dispatch(setPremiumTermFilter(defaultContractValueFilter))
                          }
                        }
                        onChange={(value) => {
                          dispatch(setPremiumTermFilter(value))
                        }}
                        dynamic
                        />}  
                  </Grid>
                  :
                  <Grid container justifyContent="center" alignContent="center" style={{minHeight: 119}}>
                    <CircularProgress style={{color: "#363ED3",width:48,height:48}}/>
                  </Grid>
                }
              </FiltersContianer>

            </Grid>

            <TableContainer 
                  data={data} 
                  page={page} 
                  headers={headers}
                  sort={sort}
                  handleSort={handleSort}
                  handleChangePage={handleChangePage}
                  handleChangeRows={setRowsPerPage}
                  handleSelectPolicy={handleSelectPolicy}
                  handleExportAll={handleExportAll}
                  handleExport={handleExport}
                  exportOptions={runs}
                  loaded={loaded}
                  divided={divided}
                  divideIndex={divider}
                  userType={userType}
                  renderRow={renderRow}
                  frozenColumns={frozenColumns}
                  hiddenColumns={hiddenColumns}
                  rowsPerPage={rowsPerPage}
                  filters={prospectsFilters}
            />
                   

            <ColumnsPopup
              open={openColumns}
              options={columns}
              handleChange={onChangeColumns}
              handleClose={discardChangeColumns}
              handleApply={applyColumns}
            />          
        </>
    )
}

export default ProspectsContainer