import React from 'react';
import {UIButton, UIButtonInverted, useStyles} from "./styles";
import Logo from '../../../Assets/atidot-logo.svg'
import Container from "@mui/material/Container";
import { useHistory } from 'react-router-dom';
import ScrollToTop from '../useScrollTop'

export const baseRedirectUrl = 'https://www.atidot.com';

const Header = () => {
  const history = useHistory();

  const classes = useStyles();

  const redirectToApp = () => history.push("/login");

  return (
    <div className={classes.header}>
      <ScrollToTop />
      <Container maxWidth="lg">
        <div className={classes.wrapper}>
          <div className="logo">
            <a href={baseRedirectUrl} target="_blank">
              <img src={Logo} alt="Atidot"/>
            </a>
          </div>
          <div className={classes.linksBlock}>
            <nav>
              <ul className={classes.navList}>
                {/*<li>*/}
                {/*  <a*/}
                {/*    href={`${baseRedirectUrl}/contact`}*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    Contact us*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a
                      href={`${baseRedirectUrl}/about`}
                      target="_blank"
                  >
                    about  Atidot
                  </a>
                </li>
              </ul>
            </nav>
            <div className={classes.buttons}>
              <UIButton onClick={redirectToApp}>CONTACT US</UIButton>
              <UIButtonInverted onClick={redirectToApp}>LOG IN</UIButtonInverted>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
};

export default Header;
