import React from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectActiveTab } from "../../../../Pages/BookAnalysis/BookAnalysisSlice";
import clsx from "clsx";
import { frozenGridColumnsPositions } from "../../../../global/constants";

export const renderTableRow = (rowData, columnsToHide = [], onRowClick = () => {}) => {
        return Object.keys(rowData)
        .filter((key) => !columnsToHide?.includes(key) || columnsToHide.length === 0)
        .map((key) => (
            <TableCell 
                component="th" 
                scope="row" 
                style={key === "policy" ? 
                    {
                    fontWeight: 700,
                    lineHeight: "16px",
                    color: "#363ED3",
                    cursor: "pointer"
                    } : {}
                }
                onClick={() => onRowClick(rowData)}>
                {rowData[key]}
            </TableCell>
        ))
}

const PoliciesTable = ({ 
    data, 
    headers, 
    sort, 
    onSort, 
    renderRow = renderTableRow, 
    loaded = true, 
    columnsToHide = [], 
    onRowClick = () => {},
    divided = false,
    divideIndex = 4,
    frozenColumns = [],
    frozenPositions = frozenGridColumnsPositions,
}) => {
    const classes = useStyles()
    const width = window.innerWidth * .09
    const activeTab = useSelector(selectActiveTab)

    const isAE = activeTab === "ProspectsAE"

    return (
        <TableContainer>
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {
                        headers.map((item, index) => (
                        (divided && index === divideIndex) ? 
                        <React.Fragment>
                            <TableCell component="th" scope="row" style={{ maxWidth: width  }} key={`policy-table-header-${item}`}>
                                <div>
                                    <p>{item}</p>
                                    <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={isAE ? { width: 5, padding: 0, background: "#FFFFFF" } : { width: "0.02%", background: "#FFFFFF"}}  key={`policy-table-header-${item}-split`}/>
                        </React.Fragment>
                        :
                        <TableCell
                        component="th" 
                        scope="row" 
                        key={`policy-table-header-${item}`}
                        className={clsx(
                            item === "Upside Opportunity" ? classes.tableUpsideHeader : "",
                            frozenColumns.includes(item) ? classes.sticky : "",
                            )
                        }
                        style={index < 4 ? 
                            { 
                                maxWidth: width,
                            } : 
                            {
                                right: frozenColumns.indexOf(item) > -1 ? frozenPositions[(frozenColumns.length-1) - frozenColumns.indexOf(item)] : 0,  
                                boxShadow: frozenColumns.indexOf(item) > -1 && (frozenColumns.indexOf(item) === 0 || frozenColumns.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
                                borderLeft: frozenColumns.indexOf(item) > -1 && (frozenColumns.indexOf(item) === 0 || frozenColumns.length === 1) ? "5px solid #FFF" : "none" 
                            }
                        }>
                            <div>
                                <p>{item}</p>
                                <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                            </div>
                        </TableCell>
                        ))
                        }
                </TableRow>                    
            </TableHead>
            {
                loaded ? 
                <TableBody>
                    {
                        data
                        ?.map((item, index) => (
                            <TableRow className={classes.tableBody} key={`policy-table-row-${index}`}>{renderRow(item, columnsToHide, onRowClick)}</TableRow>
                        ))
                    }
                </TableBody>
                :
                <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                    <CircularProgress style={{color: "#000",width:96,height:96}}/>
                </Grid>
                }
        </TableContainer>
    )
}

export default PoliciesTable