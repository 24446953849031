import { MenuItem, Select, styled } from "@material-ui/core";
import React, { useState } from "react";

export const campaignTypeSelectOptions = [
    "Call Policyholder",
    "Schedule a Meeting",
    "Send an Email",
    "Don’t Engage",
    "Postpone"
]

export const TypeSelect = styled(Select)({
    border: "1px solid #DCDCDC",
    borderRadius: 4,
    width: 170,
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "9px 0 9px 12px",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '16px',
        color: "#312E40"
    },
    "& p": {
        margin: 0
    }
})

const CampaignTypeSelect = ({ defaultValue = "None", story = "", model = "", onChange = () => {} }) => {
    const [ typeSelected, setTypeSelcted ] = useState(defaultValue)

    const handleChange = (e) => {
        setTypeSelcted(e.target.value)
        onChange(story, model, e.target.value)
    }

    return (
        <TypeSelect
        fullWidth
        variant="outlined"
        labelId="camapaigntype-select-label"
        value={typeSelected}
        onChange={handleChange}>
            {
                campaignTypeSelectOptions.map((item) => (
                    <MenuItem value={item}><p>{item}</p></MenuItem>
                ))
            }
            <MenuItem value="None" style={{ display: "none" }}>None</MenuItem>
        </TypeSelect>
    )
}

export default CampaignTypeSelect