import { configureStore } from '@reduxjs/toolkit'
//import logger from 'redux-logger'
import NewProjectReducer from '../Pages/NewProject/NewProjectSlice'
import NewCampaignReducer from '../Pages/CreateCampaign/CreateCampaignSlice'
import LoginReducer from '../Pages/Login/LoginSlice'
import AppReducer from '../AppSlice'
import RunReducer from '../Components/Run/RunSlice'
import FiltersReducer from '../Components/Filters/FiltersSlice'
import TableSlice from '../Components/Tables/TableSlice'
import ChartsReducer from '../Components/Run/Charts/ChartsSlice'
import BookReducer from '../Pages/BookAnalysis/BookAnalysisSlice'
import AlertDialogReducer from '../Components/AlertDialog/AlertDialogSlice'

export default configureStore({
  reducer: {
      newProject: NewProjectReducer,
      newCampaign: NewCampaignReducer,
      App: AppReducer,
      run: RunReducer,
      filters: FiltersReducer,
      tables: TableSlice,
      Charts: ChartsReducer,
      Login: LoginReducer,
      Book: BookReducer,
      AlertDialog: AlertDialogReducer
  },  
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})