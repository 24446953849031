import Modal from "@material-ui/core/Modal";
import React from "react";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Backdrop from '@mui/material/Backdrop';
import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../Header/styles";
import {baseRedirectUrl} from "../Header/Header";
import Close from '../../../Assets/icon-close.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    boxShadow: 24,
    p: '80px 250px',
    width: '100%',
    maxWidth: '1100px',
};

const useStyles = makeStyles(() => ({
    closeContainer: {
        position: 'absolute',
        top: '26px',
        right: '28px',
        cursor: 'pointer',
    }
}));

export const UIViewButton = styled(Button)({
    ...basicButtonStyles,
    fontSize: 16,
    padding: '17px 0',
    width: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
        opacity: '0.9',
        backgroundColor: '#0BCB47',
        borderColor: '#FFF',
        boxShadow: 'none',
    },
});

const ContactUsModal = ({
    isOpen,
    onClose,
    text = 'To try the demo with your actual data, contact our sales team.'
}) => {
  const classes = useStyles();

  const openExternalUrl = () => {
      window.open(
          `${baseRedirectUrl}/contact`,
          '_blank'
      );
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className={classes.closeContainer} onClick={onClose}>
                <img src={Close} alt="To try the demo" />
            </div>
            <div className="page-title">
              <h1 style={{
                  fontWeight: 600,
              }}>{text}</h1>
            </div>
              <div className="center">
                  <UIViewButton
                    onClick={openExternalUrl}
                  >
                      Contact Us
                  </UIViewButton>
              </div>
          </Box>
        </Fade>
      </Modal>
    </>
  )
};

export default ContactUsModal;
