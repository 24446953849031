import ApiService from "../../global/service";

export default class BookAnalysisService {
    _getProducts = (responseHandler, errorHandler) => ApiService.get("/projects/summary", {}, responseHandler, errorHandler)

    _getProductsByIDs = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/metadata${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _getDemoProducts = (responseHandler, errorHandler) => ApiService.get("/projects/summary/demo", {}, responseHandler, errorHandler)

    _getImpactByProducts = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/summary/products${demo ? "?is_demo=true " : ""}`, {}, responseHandler, errorHandler)

    _getImpactByStories = (demo = false, project_ids, responseHandler, errorHandler) => ApiService.get(`/projects/project_ids=${project_ids.join(",")}/summary/stories${demo ? "?is_demo=true" : ""}`, {}, responseHandler, errorHandler, process.env.REACT_APP_API_URL, {}, true)

    _patchRunColumn = (run_ids, columns, responseHandler, errorHandler) => ApiService.patch(`/runs/run_ids=${run_ids.join(",")}`, {}, responseHandler, errorHandler, { columns_to_display: {columns} })

    _postProspectsData = (demo = false, run_ids, payload, responseHandler, errorHandler, model_type = null, page = 0, take = 10, sort = 'policy') => ApiService.post(
        `/results/run_ids=${run_ids.join(",")}${demo ? "/demo" : ""}?metadata=true&skip=${page*take}&take=${take}&sort=${sort}${model_type ? `&model_type=${model_type}` : ""}`, 
        {}, responseHandler, errorHandler, payload
    )

    _postProspects = (run_ids, payload, responseHandler, errorHandler) => ApiService.post(
        `/results/run_ids=${run_ids.join(",")}`, 
        {}, responseHandler, errorHandler, payload
    )

    
    _postExportCsv = (run_ids, data, requestHandler, errorHandler) => ApiService.post(`/results/${run_ids.join(",")}/file/download`, {}, requestHandler, errorHandler, data)

    _postDemoEmail = (data = {}, responseHandler, errorHandler) => ApiService.post("/v1/metadata/emails/demo", {}, responseHandler, errorHandler, { ...data }, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _postGptSession = (data = {}, responseHandler, errorHandler) => ApiService.post("/v1/gpt/session", {}, responseHandler, errorHandler, { ...data }, process.env.REACT_APP_CAMPAIGNS_API_URL)

    _postCampaignsStories = (data = {}, responseHandler, errorHandler) => ApiService.post("/campaigns/stories", {}, responseHandler, errorHandler, { ...data })
}