import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
 
const useStyles = makeStyles((theme) => ({
    selectContainer: {
      display: 'flex',
      alignItems: "center",
      border: "solid 1px #ecf1f9",
      minHeight: 32,
      padding: '5px 12px !important',
      margin: '5px 0px',
      borderRadius: 4,
      paddingLeft: 12,
      marginLeft: 0,      
      cursor: "pointer",
      '& .MuiTypography-caption': {
        fontFamily: "Strawford!important",
        fontSize: 12,
        fontWeight: 400,
        color: "#312E40"
      }
    },
    customContainer: {
      display: 'flex',
      width: '100%'
    },
    filterTitle: {
      marginTop: 12,
      marginLeft: 18,
      marginBottom: 11,
      fontSize: 12,
      textTransform: "uppercase"
    },
    UISlider: {
      width: 200,
      marginLeft: 26,
      marginTop: 40,
      '& .MuiSlider-valueLabel': {
        fontSize: '10px',
      },
    },
    saveButton: {
      marginRight: 12
    },
    clearButton: {
      marginLeft: 12
    },
    GenderPopover: {
      width: 273,
      margin: '9px 12px 8px 7px',
      border: "solid 1px #d9e1ee"
    },
    checkBoxProps: {
      marginLeft: 26
    },
    checkLabel: {
      fontSize: 13,
      textTransform: "capitalize"
    },
    customArrowDropDown: {
      height: 19,
      color: '#8F95AD',
      marginTop: '-3px',
    },
    customResetIcon: {
      height: "14px !important",
      color: '#8F95AD',
      cursor: "pointer",
      marginTop: '0px',
      position: "relative",
      top: "3px",
      lef: "3px",
      borderRadius: 85,
      width: "14px !important",
      '&:hover': {
        backgroundColor: '#BAC2E0',
      }
    },
    saveButtonContainer: { 
      padding: '20px 0'
    }, 
    filtersControlsContainer: {
      paddingTop: 9,
      marginRight: 10
    },
    popoverRoot: {
      minWidth: 273,
      margin: '9px 12px 8px 7px',
      border: "solid 1px #d9e1ee",
    }
  
  }));

const Filter = ({ title, popoverText, controlComponent, PopoverComponent, resetFilter, onFilterApply, value, spacing, style, labeled}) => {
    const classes = useStyles();
    
    const [openFilter, setOpenFilter] = React.useState(false);
    const [filterAnchor, setFilterAnchor] = React.useState({
      top: 0,
      left: 0
    });
    const filterRef = React.useRef();
  
    const handleOpenFilter = () =>  {
      if (!openFilter) {
        const position = filterRef.current.getBoundingClientRect()
        setFilterAnchor({
          left: position.x,
          top: position.y + position.height
        })
        setOpenFilter(true)
      }
    }
    
    const handleClose = (e) => {
      //e.stopPropagation();
      setOpenFilter(false);
      onFilterApply(value)
    }

    const handleApply = (e) => {
      //handleClose(e)
    }

    const handleReset = (e) => {
      resetFilter(e)
      handleClose(e)
    }

    return (
        <Grid item ref={filterRef} onClick={handleOpenFilter} className={labeled ? classes.selectContainer : classes.customContainer} style={style ? { ...style, marginRight: spacing } : {  marginRight: spacing }}>
          {labeled && <Typography variant="caption">{title}:</Typography>}
          {controlComponent}
          {labeled && <ArrowDropDownIcon className={classes.customArrowDropDown}/>}
            <Popover
            anchorReference="anchorPosition"
            anchorPosition={filterAnchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={openFilter}
            onClose={handleClose}
            >
            {                
            <PopoverComponent reset={handleReset} apply={handleApply}/>
            }

            </Popover>
        </Grid>
    )
}

export default Filter
