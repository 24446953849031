import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import React from "react";
import { useMobileDetect } from "../../global/useMobileDetect";
import backgroundImage from "../../Assets/register-background.png"
import { ReactComponent as AtidotText } from "../../Assets/icon-atidot-text-white.svg";

const useStyles = makeStyles(() => ({
    root: {
      minHeight: '100vh',
      justifyContent : 'center',
      backgroundColor:'#f8f9fb'
    },
    loginBackground :{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent : 'center',
        minHeight: '100vh'
    },
    banner: {
        width: '33%',
        padding: "50px 7%",
        backgroundColor: "#363ed3",
        backgroundSize: "cover",
        color: '#fff',
        textAlign: "center",
        '& .MuiTypography-root': {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "25px",
            paddingTop: 32
        },
        '& h1': {
            fontSize: 40,
            fontWeight: 700,
            lineHeight: "18px",
            margin: 0
        }
    },
    loginContainer: {
        width: '67%',
        color: '#312E40',
        background: '#F8F9FB',
        alignItems: "center"
    },
    mobileContainer: {
      height: "100vh",
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: 600,
      backgroundColor: "#f8f9fb"
    }

}));

const LoginContainer = (props) => {
    const classes = useStyles();
    const isMobile = useMobileDetect()

    return(
        isMobile ? 
            <Grid container justifyContent='center' alignItems='center' className={classes.mobileContainer}>
                Mobile is Not Supported
            </Grid>
        :
            <Paper variant="elevation" elevation={2} className={classes.loginBackground} >  
                <Grid container direction="column" justifyContent="center" className={classes.banner} style={{ backgroundImage: `url('${backgroundImage}')` }}>

                    <Grid item>
                        <AtidotText/>
                    </Grid>
                    <Grid item>
                        <Typography>Bringing the AI to the Heart of Life Insurance</Typography>
                    </Grid>
                </Grid>    
                <Grid container  justifyContent="center" className={classes.loginContainer}> 
                    {props.children}
                </Grid>   
            </Paper>
    )

}

export default LoginContainer;