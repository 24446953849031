import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import reportWebVitals from './reportWebVitals';
//import Project from './Pages/Projects/Projects';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './App';
import Login from './Pages/Login/Login';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { Provider } from 'react-redux';
import Store from './Store/Store';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@mui/material';
import Demo from "./Pages/Demo/Demo";
import DemoRecommendedActions from "./Pages/Demo/DemoRecommendedActions";
import DemoPortfolioEstimates from "./Pages/Demo/DemoPortfolioEstimates/DemoPortfolioEstimates";
import DemoOpportunities from "./Pages/Demo/DemoOpportunities/DemoOpportunities";
import Confirm from './Pages/Confirm/Confirm';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetCode from './Pages/ResetCode/ResetCode';
import SendCode from './Pages/SendCode/SendCode';

const theme = createTheme({
  typography: {
   "fontFamily": `'Inter', sans-serif !important`,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <Login />
          </ThemeProvider>
        </Route>
        <Route exact path="/registration" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <RegistrationPage />
          </ThemeProvider>
        </Route>
        <Route exact path="/confirmaccount" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <Confirm />
          </ThemeProvider>
        </Route>
        <Route exact path="/sendnewcode" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <SendCode />
          </ThemeProvider>
        </Route>
        <Route exact path="/confirmreset" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <ResetCode />
          </ThemeProvider>
        </Route>
        <Route exact path="/forgotpassword" >
          <ThemeProvider theme={theme}> 
            <CssBaseline />
            <ForgotPassword />
          </ThemeProvider>
        </Route>
        <Route exact path="/demo" >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Demo />
          </ThemeProvider>
        </Route>
        <Route exact path="/demo-opportunities" >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DemoOpportunities />
          </ThemeProvider>
        </Route>
        <Route exact path="/demo-portfolio-estimates" >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DemoPortfolioEstimates />
          </ThemeProvider>
        </Route>
        <Route exact path="/demo-recommended-actions" >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DemoRecommendedActions />
          </ThemeProvider>
        </Route>
        <Route path="/" >
        <ThemeProvider theme={theme}> 
          <CssBaseline />
          <App />
        </ThemeProvider>
        </Route>
      </Switch> 
     
  </BrowserRouter>
</Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
