import ApiService from "../../global/service";

export default class newProjectApiService {

    _postNewProject = (data, requestHandler, errorHandler) => ApiService.post('/projects/new', {}, requestHandler, errorHandler, data)

    _postNewRun = (data, requestHandler, errorHandler) => ApiService.post('/runs/new', {}, requestHandler, errorHandler, data)

    _postCreateAllRuns = (data, requestHandler, errorHandler) => ApiService.post('/runs/many', {}, requestHandler, errorHandler, data)

    _postProjectMapper = (id, data, requestHandler, errorHandler) => ApiService.post(`data/${id}/mapper`, {}, requestHandler, errorHandler, data, process.env.REACT_APP_DH_API_URL)

    _getMapperValidated = (id, model, requestHandler, errorHandler) => ApiService.get(`validation?source_id=${id}&model_type=${model}&model_subtype=basic`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)

    _postExecuteProject = (data, requestHandler, errorHandler) => ApiService.post('/model/run/execute', {}, requestHandler, errorHandler, data)

    _sendCsvData = (data ,requestHandler, errorHandler) => ApiService.patch('data', { }, requestHandler, errorHandler, data, process.env.REACT_APP_DH_API_URL)

    _getCsvHeaders = (source_id, requestHandler, errorHandler) => ApiService.get(`data/${source_id}/headers_and_10_top_lines`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)

    _getDBTableColumns = (id, tableName, requestHandler, errorHandler) => ApiService.get(`/data/db/table_columns/${id}/${tableName}`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)
    
    _getSFTableColumns = (id, requestHandler, errorHandler) => ApiService.get(`data/sf/table_columns/${id}`, {}, requestHandler, errorHandler, process.env.REACT_APP_DH_API_URL)

    _postS3Data = (id, projectId, requestHandler, errorHandler) => ApiService.post(`/data/${id}/s3`, {
        projectid: projectId
    }, requestHandler, errorHandler)

    _getValidatedPolicies = (id, modelName, requestHandler, errorHandler) => ApiService.get(`/data/${id}/data_validation/${modelName}`, {}, requestHandler, errorHandler)

    _postDataBaseConnection = (projectId = null ,data, requestHandler, errorHandler) => ApiService.post("data/db/connect", projectId ? { projectid: projectId } : {}, requestHandler, errorHandler, data,  process.env.REACT_APP_DH_API_URL)

    _postS3BucketUrl = (projectId, data, requestHandler, errorHandler) => ApiService.post("/data/url", {
            projectid: projectId    
    }, requestHandler, errorHandler, data)

    _getDataSource = (requestHandler, errorHandler) => ApiService.get('/data', {}, requestHandler, errorHandler)

    _postUploadCsv = (data, requestHandler, errorHandler) => ApiService.post('', {}, requestHandler, errorHandler, data, process.env.REACT_APP_DH_API_URL)

    _getCsvNameCheck = (fileName, requestHandler, errorHandler) => ApiService.get(`?filename=${fileName}`, {}, requestHandler, errorHandler,  process.env.REACT_APP_DH_API_URL)

    _deleteCancelProjectCreation = (projectId, requestHandler, errorHandler) => ApiService.delete(`/project/${projectId}`, requestHandler, errorHandler)
    
}