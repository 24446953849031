import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UploadFile from "../../Components/StepsComponents/UploadFile/UploadFile";
import { setAutoMapping, setCsvInfo, setSourceId } from "../NewProject/NewProjectSlice";
import newProjectApiService from "../NewProject/NewProjectService";

const Upload = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const ApiService = new newProjectApiService()

    const handleUploadCsv = (response) => {
        dispatch(setCsvInfo(response.data['csv_info']))
        dispatch(setSourceId(response.data['source_id']))  
        if (parseInt(response.data['source_id'])) {   
            ApiService._getCsvHeaders(response.data['source_id'], (res) => {
                const data = res.data['csv_info']
                if (data?.lapse_auto_mapping && data?.ui_auto_mapping ) {
                  dispatch(setAutoMapping(
                    [
                      ...data.lapse_auto_mapping,
                      ...data.ui_auto_mapping.filter((item) => !data.lapse_auto_mapping.find(({ column_name }) => column_name === item.column_name))
                    ]
                  ))
                } else if (data?.lapse_auto_mapping) {
                  dispatch(setAutoMapping(data.lapse_auto_mapping))
                } else if (data?.ui_auto_mapping) {
                  dispatch(setAutoMapping(data.ui_auto_mapping))
                }
              }, (error) => {
                console.log(error);
              })
            
        }   
    }

    const handleNext = () => {
        history.push("/newmapper")
    }
    
    const handleBack = () => {
        history.push("/firstlogin")
    }

    return (
        <Grid container justifyContent="center">
            <UploadFile 
            title="Upload Your File"
            onNext={handleNext} 
            onBack={handleBack} 
            onUpload={handleUploadCsv} 
            fileType=".csv"
            ws={false}/>
        </Grid>
    )
}

export default Upload