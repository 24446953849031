import { ReactComponent as LoaderIcon } from "../../Assets/icon-loader.svg"
import { ReactComponent as LogoIcon } from "../../Assets/icon-logo-letter.svg"
import "./style.css"

const Loader = () => (
    <div className="loader">
        <LoaderIcon/>
        <LogoIcon/>
    </div>
)

export default Loader