import React from 'react';
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { selectStep2Component, selectUserRole, setStep2Component } from '../../AppSlice';
import { useHistory } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import clsx from 'clsx';
import Step2 from '../../Components/StepsComponents/Step2/Step2';
import { amplitudeService } from '../../global/helpers';
import appApiService from '../../AppService';

import {useStyles} from './style'
import { checkPermission } from '../../global/user';

const ApiService = new appApiService()


export default function NewDataSource(){
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isFileUploaded,setIsFileUploaded] = React.useState(false);
    const [sourceID,setSourceID] = React.useState(null);
    
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ["Select Data Source"];

    const userRole = useSelector(selectUserRole)
    const selectedOption = useSelector(selectStep2Component)

    const handleExitNewDataSource = React.useCallback(
      (confirmedUpload) => {
        if (!confirmedUpload && sourceID) {
          ApiService._patchDeleteDataSource(sourceID, () => {
            setSourceID(null)
            history.push("/datasources")
          }, (err) => {console.log(err)})
        } else {
          history.push("/datasources")
        }
      }
    , [history, sourceID])

    React.useEffect(() => {
        return () => {
            if (history.action === 'POP') {
              handleExitNewDataSource()
            }
        };
    }, [history, handleExitNewDataSource]);

    const handleUploadDataSource = (response) => {
      amplitudeService._throwEvent("ADD_DATA_SOURCE")
      setSourceID(response.data.source_id) 
      setIsFileUploaded(true) 
    }

  const handleNext = () => {
    handleExitNewDataSource(true)
    dispatch(setStep2Component(''))
  };

  const handleBack = () => {
    if (!selectedOption) {
      handleExitNewDataSource()
    } else {
      dispatch(setStep2Component(''))
    }
  }

  const handleGoBackClick = () => {
    dispatch(setStep2Component(''))
  }

  const checkStepStatus =() =>{
    let currentStatus=true
    if(isFileUploaded){
      currentStatus=!currentStatus 
    }
    return currentStatus
  }
  
  React.useEffect(() => {
    if(!userRole || !checkPermission('add_datasource')) {
      history.push('/')
    }
  }, [userRole, history])
        
  return (
    <Grid container className={classes.root}>
        
      <Grid container direction="column"
            justifyContent="flex-start"
            alignItems="center" className={classes.StepperContainer}> 
        {activeStep === steps.length ? (
           <Grid item xs={6}>
            
           
          </Grid>
        ) : (
           
            <Grid item container style={{ minHeight: "calc(70vh - 170px)"}} justifyContent='center' alignItems='center'>    
              <Step2 onFileUpload={handleUploadDataSource} onDBLoad={handleUploadDataSource} onS3BucketLoad={handleUploadDataSource} onBack={handleGoBackClick} back={false}/>
           
          </Grid>
        )}
        <div className={classes.customFooter}>
         <div className={classes.customButtonContainer}>
            <Button onClick={handleBack} className={clsx(classes.button, classes.customBackButton)} style={{color:'#312e40'}}>
              <ChevronLeftIcon  style={{widht: '15px', height: '15px', marginRight: -5, paddingLeft: '0px'}} /> Back
            </Button>          
  
            <Button
              onClick={handleNext}
              className={clsx(classes.button,classes.floatRight,classes.customNextButton)}
              disabled = {checkStepStatus()}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
            </div>
          </div> 
      </Grid>
      
    </Grid>
  );
}
