import React from "react";
import { ReactComponent as IconPlus } from "../../../Assets/icon-plus-alt.svg";
import { ReactComponent as IconEqual } from "../../../Assets/icon-equal.svg";
import { ReactComponent as Arrow } from '../../../Assets/icon-arrow-right.svg';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {basicButtonStyles} from "../../../Components/Demo/Header/styles";
import "./style.css"
import UIDistribution, {colorsDefault} from "../../../Components/Demo/Charts/UIDestribution/UIDestribution";
import Table from "../../../Components/Demo/Table/Table";
import { styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectProductsData, selectProductsIsLoading, setActiveTab } from "../BookAnalysisSlice";
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import { UIViewButton } from "../../../Components/ui/buttons";

const useStyles = makeStyles(() => ({
    chartTitle: {
        fontFamily: "Roboto!important",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "21px",
        color: '#312E40',        
        "& span": {
            color: 'black', 
            fontWeight: 500,
        }
        
    }

}));

export const UIButton = styled(Button)({
  ...basicButtonStyles,
  width: 'auto',
  padding: '14px 34px',
  maxWidth: '240px',
  height: 'auto',
  borderRadius: '10px',
  backgroundColor: '#363ED3',
  borderColor: '#FFF',
  color: '#FFF',
  fontFamily: "Strawford",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "center",
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
});

const Overview = () => {
    const classes = useStyles()
    const productsData = useSelector(selectProductsData)
    const dispatch = useDispatch()  
    const currency = getCountryCurrencySymbol()

    if (useSelector(selectProductsIsLoading)) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <Grid container direction="column">
        <Grid container justifyContent="space-between">
            <Typography className={classes.chartTitle}>
            <span>Your projected premium with Atidot could be {productsData.upside}% higher with {nFormatter(productsData.premium_annual, 2, currency)} annualized premium</span>
            </Typography>
            <UIButton style={{ display: "none" }}>
                Portfolio Calculator
            </UIButton>
        </Grid>
        <Grid container direction="column">                
            <Grid container className="chart" justifyContent="space-between">
                <div className="chart-item small single">
                    <UIDistribution
                        id="small"
                        paddingRight={0}
                        chartData={productsData.current}
                        showTable={false}
                        showLegend
                        totalText={<>Current</>}
                        toolTipText="Our projected annual performance of your data set"
                        categoryField="name"
                        valueField="simple"
                    />
                </div>
                <div className="betweenCharts">
                    <IconPlus/>
                </div>
                <div className="pairedChart">
                    <div className="chart-item small">
                        <UIDistribution
                            id="top"
                            paddingRight={0}
                            chartData={productsData.newBuisnes}
                            showTable={false}
                            showLegend
                            totalText={<>New business</>}
                            toolTipText="Policy upgrades and cross-sell opportunities"
                            categoryField="name"
                            valueField="simple"
                        />
                    </div>

                    <div className="chart-item small">
                        <UIDistribution
                            id="bottom"
                            paddingRight={0}
                            chartData={productsData.retention}
                            showTable={false}
                            showLegend
                            totalText={<>Retention</>}
                            toolTipText="Lapse prevention opportunities"
                            categoryField="name"
                            valueField="simple"
                        />
                    </div>
                </div>
                <div className="betweenCharts">
                    <p><strong>{productsData.upside}%</strong> Upside</p>
                    <IconEqual/>
                </div>
                <div className="chart-item big">
                    <UIDistribution
                        id="big"
                        paddingRight={0}
                        chartData={productsData.total}
                        showTable={false}
                        totalText={<>Your Potential</>}
                        toolTipText="Our projected full potential performance of your data set"
                        categoryField="name"
                        valueField="simple"
                    />
                </div>
            </Grid>
                
        </Grid>
        <Grid container justifyContent="center">      
            <div className="chartLegend">
                {productsData.products?.map(({ product_type }, index) => (
                    <div key={index} className="chartLegend-item">
                    <div className="chartLegend-colorIndicator" style={{ backgroundColor: colorsDefault[index] }} />
                    <p>{product_type}</p>
                    </div>
                ))}
            </div>
        </Grid>
        <Grid container justifyContent="center">      
        <div className="tableContainer">
            <Table
                tableData={productsData.products ? productsData.products : []}
                premiumField="potential_premium"
                premiumFieldName="Annual Premium"
                numbersRounding={2}
                showColors
            />
            <div className="betweenTable">
                <Arrow />
            </div>
            <Table
                tableData={productsData.productsDiff ? productsData.productsDiff : []}
                premiumField="potential_premium"
                premiumFieldName="Annual Premium"
                numbersRounding={2}
                showColors
                difference
             />
        </div>
        </Grid>
        <Grid container justifyContent="center">
            <UIViewButton disableRipple onClick={() => dispatch(setActiveTab("Immediate Opportunities"))}>
                Details
            </UIViewButton>
        </Grid>
    </Grid>
    )
}

export default Overview