import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// MUI
import { makeStyles, Grid, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";

// Components
import { ReactComponent as HelpIcon } from '../../Assets/icon-help.svg';
import { ReactComponent as ErrorIcon } from '../../Assets/icon-mapper-error.svg';
import SubModel from "./SubModel";
import InformationPopup from "../Popups/InformationPopup/InformationPopup";
import { NextButton } from "../ui/buttons";

// Redux
import { selectAutoMapping, selectCsvInfo, selectFileName, selectMapperError, selectMapperOptions, selectRunModel, selectRunModels, selectValidationError, setCsvInfo, setEmptyKeys, setMapperOptions, setValidationError } from "../../Pages/NewProject/NewProjectSlice";

// Common
import appApiService from "../../AppService";
import { nFormatter } from "../../global/helpers";
import { demoCsv } from "../../global/constants";
import { User } from "../../global/user";


const useStyles = makeStyles(() => ({
    tableStyle: {
        padding: 15,
        "& table": {
            borderRadius: 8,
            boxShadow: '0px 0px 0px 2px #e2e8f2',
        },
    },
    headerTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 34,
            fontWeight: 500,
            lineHeight: "34px",
            color: "#000000",
            textTransform: "capitalize",
            paddingBottom: 6,
            "@media (min-width:1440px)": {
                paddingBottom: 22
            }
        },
    },
    headerText: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto!important",
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#000000",
            textAlign: "left",
            maxWidth: 506,
            paddingBottom: 10,
        },
        "& span": {
            textDecoration: "underline",
            cursor: "pointer"
        },
    },
    helperTitle: {
        "& .MuiTypography-root": {
            fontFamily: "Roboto!important",
            fontSize: 15,
            fontWeight: 500,
            lineHeight: "18px",
            color: "#000000",
            textTransform: "capitalize"
        },
    },
    helperIcon: {
        cursor: "pointer",
        paddingLeft: 11,
        visibility: "hidden"
    },
    errorContainer: {
        paddingTop: 136,
        paddingBottom: 28
    },
    errorText: {
        "& .MuiTypography-root": {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "100%",
            color: "#FF4B62",
            paddingBottom: 18
        },
    },
    nextButtonContainer: {
        paddingTop: 10
    },
    nextButton: {
        color: "#fff",
        background: "#0BCB47",
        width: 250,
        height: 50,
        borderRadius: 10,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center"
    }
}))

const ApiService = new appApiService()

const MapperComponent = ({ outSideRef, onExecute, demo = false }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const csvInfo = useSelector(selectCsvInfo)
    const autoMapping = useSelector(selectAutoMapping)
    const mapperError = useSelector(selectMapperError)
    const validationError = useSelector(selectValidationError)
    const runModel = useSelector(selectRunModel)
    const runModels = useSelector(selectRunModels)
    const mapperOptions = useSelector(selectMapperOptions);
    const fileName = useSelector(selectFileName);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [popoverAnchor, setPopoverAnchor] = useState(null)
    const [fieldSelection, setFieldSelection] = useState({})
    const [headers, setHeaders] = React.useState([])
    const [columnsToParse, setColumnToParse] = React.useState([])
    const [dividedModels, setDividedModels] = useState({})
    const [dividedModelsOptional, setDividedModelsOptional] = useState({})
    const [menuIsOpen, setMenuIsOpen] = useState(runModel === "UI" ? {
        "policy": false,
        "Product": false,
        "Policy Issue Date": false,
        "Age": false,
        "Gender": false,
        "Zip": false,
        "Premium": false,
        "Face Amount": false
    } : {
        "policy": false,
        "product": false,
        "issue_date": false,
        "age": false,
        "gender": false,
        "zip_code": false,
        "premium": false,
        "face_amount": false
    })

    const isModel = {
        ui: runModels.includes("UI"),
        lapse: runModels.includes("Lapse"),
        cross: runModels.includes("Cross-sell"),
    }
    const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnStage;

    const handleSelectChange = (newValue, itemValue) => {
        setFieldSelection(prevState => ({ ...prevState, [itemValue]: newValue }));
        setNextButtonDisabled(false)
        dispatch(setEmptyKeys([]))
    }

    const handleMenuOpen = (itemValue) => {
        setMenuIsOpen(prevState => ({ ...prevState, [itemValue]: !prevState[itemValue] }));
    }

    const handlePopoverOpen = (ref) => {
        setPopoverAnchor(ref)
        setPopoverOpen(true)
    }


    const handleClickNext = () => {
        setNextButtonDisabled(true)
        const requiredFields = [
            "gender",
            "premium",
            "face_amount",
            "zip_code"
        ];
        isBroker ? requiredFields.push("current_age") : requiredFields.push("age")
        if (isModel.ui || isModel.lapse || demo) {
            requiredFields.push(...["policy", "termdate", "product", "issue_date"])
        }
        if (isModel.cross) {
            requiredFields.push("issue_date")
        }
        const emptyValues = [];
        requiredFields.forEach((header) => {
            if (!Object.entries(mapperOptions)?.find((item) => item[0] === header && item[1])) {
                emptyValues.push(header)
            }
        });
        dispatch(setEmptyKeys(emptyValues))
        !emptyValues.length && onExecute(() => setNextButtonDisabled(false))
    }

    useEffect(() => {
        if (autoMapping?.length && runModel?.toLowerCase() !== "ui") {
            const autoValues = {}
            autoMapping.forEach((item) => {
                    if (item.rename_to) {
                        Object.assign(autoValues, { [item.column_name]: item.rename_to })
                    } else {
                        Object.assign(autoValues, { [item.column_name]: "" })
                    }
                })  
            setFieldSelection(autoValues)
            //dispatch(setMapperOptions(autoMapping))
        }
    }, [autoMapping, dispatch, runModel])

    useEffect(() => {
        if (demo) {
            dispatch(setCsvInfo(demoCsv))
        }
    }, [demo, dispatch])

    useEffect(() => {
        const HeadersTemp = []
        csvInfo?.headers?.forEach((header) => {
            HeadersTemp.push({ value: header, label: header })
        })
        setHeaders(HeadersTemp.sort((a, b) => a.value > b.value ? 1 : -1))
    }, [csvInfo])

    useEffect(() => {
        const models = {}
        const modelsOptional = {}
        models["gray"] = {
            data: [{ id: "policy", label: "Policy ID", tooltip: "Policy ID: uniquely identifies the policy within an existing book of business" }],
            title: ""
        }
        models["red"] = {
            data: [
                { id: "termdate", label: "Termination Date", tooltip: "Termination Date: the date on which the policy’s coverage ends" },
                { id: "product", label: "Product", tooltip: "Product Type: the type of insurance policy (term or life)" },
                { id: "issue_date", label: "Issue Date", tooltip: "Issue Date: the date on which the policy’s coverage began" }
            ],
            title: "POLICY"
        }
        if (isModel.cross) {
            models["red"] = {
                data: [
                    // { id: "effective_date", label: "Effective Date"}
                    { id: "issue_date", label: "Issue Date", tooltip: "Issue Date: the date on which the policy’s coverage began" }
                ],
                title: "POLICY"
            }
        }

        models["yellow"] = {
            data: [
                { id: "age", label: "Issue Age", tooltip: "Issue Age: the amount of time the policy has been active" },
                { id: "gender", label: "Gender", tooltip: "Gender: the gender of the policyholder" },
                { id: "zip_code", label: "Zipcode", tooltip: "Zip Code: the zip code in which the policyholder lives" }
            ],
            title: "POLICYHOLDER"
        }
        models["blue"] = {
            data: [
                { id: "premium", label: "Premium", tooltip: "Annual Premium: the dollar amount the policyholder pays each year" },
                { id: "face_amount", label: "Face Amount", tooltip: "Face Amount: the dollar amount the policyholder’s beneficiaries receive upon their death" }
            ],
            title: "COVERAGE"
        }
        if (isBroker) {
            models["gray"] = {
                data: [
                    { id: "policy", label: "Policy ID", tooltip: "Policy ID: uniquely identifies the policy within an existing book of business" },
                    { id: "customer_id", label: "Customer ID", tooltip: "Customer ID: uniquely identifies the customer within an existing book of business" }
                ],
                title: ""
            }
            models["red"] = {
                data: [
                    { id: "termdate", label: "Termination Date", tooltip: "Termination Date: the date on which the policy’s coverage ends" },
                    { id: "product_name", label: "Product Name", tooltip: "Product Name: the name of insurance product" },
                    { id: "issue_date", label: "Issue Date", tooltip: "Issue Date: the date on which the policy’s coverage began" }
                ],
                title: "POLICY"
            }
            models["yellow"] = {
                data: [
                    { id: "current_age", label: "Current Age", tooltip: "Current Age: the amount of time the policy has been active" },
                    { id: "gender", label: "Gender", tooltip: "Gender: the gender of the policyholder" },
                    { id: "zip_code", label: "Zipcode", tooltip: "Zip Code: the zip code in which the policyholder lives" }
                ],
                title: "POLICYHOLDER"
            }
            models["blue"] = {
                data: [
                    { id: "premium", label: "Premium", tooltip: "Annual Premium: the dollar amount the policyholder pays each year" },
                    { id: "face_amount", label: "Face Amount", tooltip: "Face Amount: the dollar amount the policyholder’s beneficiaries receive upon their death" },
                    { id: "cash_value", label: "Cash Value", tooltip: "" }
                ],
                title: "COVERAGE"
            }

            modelsOptional["gray"] = {
                data: [
                    { id: "agent_id", label: "Agent ID", tooltip: "Agent ID: uniquely identifies the agent within an existing book of business (Optional Field)" }
                ]
            }
            modelsOptional["red"] = {
                data: [
                    { id: "payment_interval", label: "Payment Interval", tooltip: "Payment Interval: the amount of time between policyholder payments (Optional Field)" }
                ]
            }
            modelsOptional["yellow"] = {
                data: [
                    { id: "state", label: "State", tooltip: "State: the state of residence of the policyholder (Optional Field)" },
                    { id: "address", label: "Address", tooltip: "Address: the home address of the policyholder (Optional Field)" },
                    { id: "first_name", label: "First Name", tooltip: "First Name: first name of the policyholder (Optional Field)" },
                    { id: "last_name", label: "Gender", tooltip: "Last Name: last name of the policyholder (Optional Field)" },
                    { id: "phone", label: "Phone", tooltip: "Phone: the phone number of the policyholder (Optional Field)" },
                    { id: "email", label: "Email", tooltip: "Email: the email address of the policyholder (Optional Field)" }
                ]
            }
            setDividedModelsOptional(modelsOptional)
        }
        setDividedModels(models)
        setColumnToParse(["Premium", "premium", "face_amount", "FaceAmount", "Face Amount", "AnnualPremium"])
    }, [runModel])

    useEffect(() => {
        dispatch(setMapperOptions(
            Object.assign({}, ...Object.entries(fieldSelection)
            .filter((item) => item[0] !== 'null')
            .map((item) => ({ [item[0]]: item[1] })))
        ))
    }, [fieldSelection, dispatch, runModel])

    const add3dots = (text) => text.length > 10 ? text.slice(0, 10) + '...' : text

    return (
        <Grid container justifyContent="center">
            {mapperError.present &&
                <Grid item container justifyContent="center" alignContent="center" className={classes.errorContainer}>
                    <ErrorIcon />
                </Grid>
            }
            <Grid item container justifyContent="center" direction="column" xs={12} >
                <Grid item container justifyContent="center" className={classes.headerTitle}>
                    <Typography>Match Data Fields</Typography>
                </Grid>
                {
                    !mapperError.present ?
                        <Grid item container justifyContent="center" className={classes.headerText}>
                            {demo ?
                                <ul>
                                    <Typography style={{ maxWidth: "unset" }}>
                                        <li>The demo file has been uploaded and default mapping has been applied.<br />
                                        You can review the <a href="mock_term_wl_1.csv" download="mock_term_wl_1.csv">demo file</a> here.</li>
                                        <li>To commence the full Demo with your data file, <Link to="/registration">please create an account</Link></li>
                                    </Typography>
                                </ul>
                                : <Typography>To ensure prediction results are possible, please match the column title to the predefined model field</Typography>
                            }
                        </Grid>
                        :
                        <Grid item container justifyContent="center" className={classes.errorText}>
                            {mapperError.message === "" && <Typography>Cannot resolve your file fields.</Typography>}
                            {mapperError.message !== "" && <Typography>{mapperError.message}</Typography>}
                        </Grid>
                }
            </Grid>
            {!mapperError.present &&
                <Grid container justifyContent="center">
                    <Grid container style={{ maxWidth: 740 }} direction="row" justifyContent="space-between" alignItems="baseline" >
                        <Grid container xs={2}>
                            <Grid item className={classes.helperTitle}>
                                <Typography>Field Name</Typography>
                            </Grid>
                            <Grid item className={classes.helperIcon}>
                                <HelpIcon />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.helperTitle}>
                            <Typography>Model Field</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        {
                            dividedModelsOptional["gray"] ?
                            <> 
                                <Grid container xs={6} style={{ maxWidth: 370, paddingRight: 10 }}>
                                    {Object.keys(dividedModels).map((key) => {
                                        return <SubModel
                                            buckets={dividedModels[key]?.data}
                                            title={dividedModels[key]?.title}
                                            SubModelColor={key}
                                            fieldSelection={fieldSelection}
                                            menuIsOpen={menuIsOpen}
                                            headers={headers}
                                            handlePopoverOpen={handlePopoverOpen}
                                            handleSelectChange={handleSelectChange}
                                            handleMenuOpen={handleMenuOpen}
                                            outSideRef={outSideRef}
                                        />
                                    })}
                                </Grid>
                                <Grid container xs={6} alignItems="flex-start" style={{ maxWidth: 370, paddingLeft: 10 }}>
                                    {Object.keys(dividedModelsOptional).map((key) => {
                                        return <SubModel
                                            buckets={dividedModelsOptional[key]?.data}
                                            title={dividedModelsOptional[key]?.title}
                                            SubModelColor={key}
                                            fieldSelection={fieldSelection}
                                            menuIsOpen={menuIsOpen}
                                            headers={headers}
                                            handlePopoverOpen={handlePopoverOpen}
                                            handleSelectChange={handleSelectChange}
                                            handleMenuOpen={handleMenuOpen}
                                            outSideRef={outSideRef}
                                        />
                                    })}
                                </Grid>
                            </>
                            :
                            Object.keys(dividedModels).map((key) => {
                                return <SubModel
                                    buckets={dividedModels[key]?.data}
                                    title={dividedModels[key]?.title}
                                    SubModelColor={key}
                                    fieldSelection={fieldSelection}
                                    menuIsOpen={menuIsOpen}
                                    headers={headers}
                                    handlePopoverOpen={handlePopoverOpen}
                                    handleSelectChange={handleSelectChange}
                                    handleMenuOpen={handleMenuOpen}
                                    outSideRef={outSideRef}
                                />
                            })
                        }
                    </Grid>
                    <Grid container justifyContent="center" className={classes.nextButtonContainer}>
                        <NextButton onClick={handleClickNext} className={classes.nextButton} disabled={nextButtonDisabled}>Next</NextButton>
                    </Grid>
                </Grid>
            }
            <Popover
                id="simple-popover"
                open={popoverOpen}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <div className={classes.ResumePopover}>
                    <TableContainer className={classes.tableStyle}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead style={{ borderBottom: '2px solid #e2e8f2' }} >
                                <TableRow className={classes.tableHeader}>
                                    {
                                        csvInfo?.headers?.map((item, index) => {


                                            return (index === 0 ?
                                                <TableCell style={{ borderBottom: 'none' }} className={classes.tableFirstCell} key={index} align="center">{item}</TableCell>
                                                :
                                                index === csvInfo?.headers?.length - 1 ?
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.tableLastCell} key={index} align="center">{item}</TableCell>
                                                    :
                                                    <TableCell style={{ borderBottom: 'none' }} className={classes.tableCell} key={index} align="center">{item}</TableCell>

                                            )
                                        })



                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {csvInfo?.data_first_rows?.slice(0, 3).map((row, index) => (
                                    <TableRow style={{ borderBottom: '2px solid #e2e8f2' }}
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {row.map((cell, index2) => {
                                            return (

                                                <TableCell style={{ borderBottom: 'none' }} className={classes.TableCellBody} key={index2} align="center">{columnsToParse.includes(csvInfo.headers[index2]) && parseFloat(cell)  ? "$" + nFormatter(cell, 0) : add3dots(cell)}</TableCell>
                                            )
                                        })
                                        }


                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>

                </div>
            </Popover>
            <InformationPopup
                open={validationError.present}
                title={validationError.title}
                message={validationError.message}
                onClose={() => dispatch(setValidationError({ present: false, message: "" }))}
                closeText="OK"
                error />
        </Grid>

    )
}

export default MapperComponent