import React from "react"
import { Grid, Typography, makeStyles } from "@material-ui/core"
import { ReactComponent as ArrowRightIcon } from "../../../../Assets/icon-arrow-right.svg"
import UIDistribution from "../../../Demo/Charts/UIDestribution/UIDestribution"
import { defaultChartColors } from "../../../../global/constants";
import clsx from "clsx";

const defaultTitles = [
  "Original performance",
  "Potential AUM Upside",
  "Potential Performance with Atidot",
]

const useStyles = makeStyles((theme) => ({
    projectsHistoryContainer: {
      paddingTop: 40,
      "& .MuiGrid-item": {
        display: "flex"
      },
      "&.book": {
        paddingTop: 12
      }
    },
    projectsHistoryTitle: {
      alignItems: 'center',
      flexDirection: 'column',
      alignContent: 'center',
      paddingBottom: 25,
      "& .MuiTypography-root": {
        paddingBottom: "20px",
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#312E40"
      }
    },
    projectsHistoryChart: {
      width: 214,
      height: 214
    },
    arrowContainer: {
      alignItems: "center",
      "& path": {
        fill: "#363ED3"
      },
      "& svg": {
        width: 44
      }
    }
}));

const HistoryChart = ({
    originalData = [],
    aumData = [],
    potentialData = [],
    justify = "space-between",
    titles = defaultTitles,
    className = "",
}) => {
    const classes = useStyles();

    return (
      <Grid container justifyContent={justify} className={clsx(classes.projectsHistoryContainer, className)}>

        <Grid item>
          <Grid container justifyContent="center" className={classes.projectsHistoryTitle}>
            <Typography>{titles[0]}</Typography>

            <div className={classes.projectsHistoryChart}>
              <UIDistribution
                id="original"
                paddingRight={0}
                chartData={originalData}
                showTable={false}
                showLegend
                totalText={""}
                toolTipText=""
                innerRadius={95}
                colorList={defaultChartColors} />
            </div>
          </Grid>
        </Grid>

        <Grid item className={classes.arrowContainer}>
          <ArrowRightIcon />
        </Grid>

        <Grid item>
          <Grid container justifyContent="center" className={classes.projectsHistoryTitle}>
            <Typography>{titles[1]}</Typography>

            <div className={classes.projectsHistoryChart}>
              <UIDistribution
                id="potential"
                paddingRight={0}
                chartData={aumData}
                showTable={false}
                showLegend
                totalText={""}
                toolTipText=""
                innerRadius={95}
                colorList={defaultChartColors} />
            </div>
          </Grid>
        </Grid>

        <Grid item className={classes.arrowContainer}>
          <ArrowRightIcon />
        </Grid>

        <Grid item>
          <Grid container justifyContent="center" className={classes.projectsHistoryTitle}>
            <Typography>{titles[2]}</Typography>

            <div className={classes.projectsHistoryChart}>
              <UIDistribution
                id="life"
                chartData={potentialData}
                totalText={""}
                toolTipText=""
                innerRadius={95}
                colorList={defaultChartColors} />
            </div>
          </Grid>
        </Grid>

      </Grid>
    )
}

export default HistoryChart