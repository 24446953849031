import ApiService from "../../global/service";

export default class RunApiService {

    _postModelRunResult = (query = "", id, buffer, data, requestHandler, errorHandler, demo = false) => ApiService.post(`/results/${demo ? id + "/demo" : id}?metadata=true&skip=0&take=${buffer}${query !== "" ? "&"+query : ""}`, {}, requestHandler, errorHandler, data)
    
    _postModelRunResultCount = (id, data, requestHandler, errorHandler) => ApiService.post(`/results/${id}?metadata=false`, {}, requestHandler, errorHandler, data)

    _postModelPolices = (query = "", buffer, page, id, data, requestHandler, errorHandler, demo = false) => ApiService.post(
        `/results/${demo ? id + "/demo" : id}?skip=${buffer*page}&take=${buffer}${query !== "" ? "&"+query : ""}`, 
        {}, requestHandler, errorHandler, data
        )

    _postExportCsv = (runId, data, requestHandler, errorHandler, demo = false) => ApiService.post(`/results/${runId}/file${demo ? "/demo" : ""}/download`, {}, requestHandler, errorHandler, data)

}