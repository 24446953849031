import Modal from "@material-ui/core/Modal";
import React from "react";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Backdrop from '@mui/material/Backdrop';
import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../Header/styles";
import Table from "../Table/Table";
import {UIViewButton} from "../../../Pages/Demo/Demo";

export const UICalculateButton = styled(Button)({
  ...basicButtonStyles,
  width: 'auto',
  padding: '14px 34px',
  maxWidth: '240px',
  height: 'auto',
  borderRadius: '10px',
  backgroundColor: '#363ED3',
  borderColor: '#FFF',
  color: '#FFF',
  fontSize: '16px',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: '0.5',
    color: '#FFF'
  }
});

export const UIBackButton = styled(UICalculateButton)({
  backgroundColor: 'transparent',
  borderColor: '#515151',
  color: '#515151',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#515151',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  boxShadow: 24,
  p: '20px 32px',
};

const useStyles = makeStyles(() => ({
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: "solid 1px #ecf1f9",
    minHeight: 50,
    padding: '5px 12px !important',
    margin: '5px 0px',
    borderRadius: 8,
    paddingLeft: 12,
    marginLeft: 0,
    marginBottom: 15,
    cursor: "pointer",
    '& h6': {
      fontWeight: '600',
      minWidth: '150px',
    }
  },
  customArrowDropDown: {
    height: 19,
    color: '#8F95AD',
    marginTop: '-3px',
  },
  popover: {
    width: 273,
    margin: '9px 12px 8px 7px',
    border: "solid 1px #d9e1ee"
  },
  checkBoxProps: {
    marginLeft: 26
  },
  checkLabel: {
    fontSize: 16
  },
  filterTitle: {
    marginTop: 12,
    marginLeft: 18,
    marginBottom: 11,
    fontSize: 16,
  },
  valueContainer: {
    backgroundColor: '#F3F7FF',
    borderRadius: 10,
    padding: '3px 20px',
    marginLeft: '20px',
    fontSize: '16px',
    width: '100%',
  },
  wrapperInput: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  separator: {
    borderTop: "solid 1px #ecf1f9",
    marginBottom: 15,
  },
  lapseStyled: {
    border: '1px solid rgba(106, 108, 113, 0.1)',
    borderRadius: '8px',
    padding: '13px 15px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& p': {
      marginRight: '50px',
      fontWeight: 500,
      fontSize: '16px',
      color: '#232033',
      width: '50%',
    }
  }
}));

const PortfolioEstimatesModal = ({ isOpen, onClose, data, onInputChange, lapseRate, handleLapseRate }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="page-title" style={{ margin: '10px 0' }}>
              <h1>Portfolio Estimates</h1>
            </div>
            <div style={{
              fontWeight: 600,
              fontSize: '16px',
              color: '#232033',
              marginBottom: '10px',
            }}>Please Fill Out Requested Fields</div>
            <div className="tableContainer" style={{ marginBottom: '10px' }}>
              <Table
                  tableData={data}
                  premiumField="premium"
                  premiumFieldName="Premium"
                  inputsEnabled
                  onInputsChange={onInputChange}
              />
            </div>
            <div className={classes.lapseStyled}>
              <p>Annual Lapse Rate</p>
              <TextField
                  variant="outlined"
                  value={lapseRate}
                  onChange={(e) => handleLapseRate(e.target.value)}
                  placeholder="0"
                  type="number"
              />
            </div>
            <div className="center" style={{ marginBottom: 10 }}>
              <UIViewButton
                  onClick={onClose}
                  style={{ padding: '13px 25px', width: '260px' }}
              >
                Calculate my portfolio potential</UIViewButton>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  )
};

export default PortfolioEstimatesModal;
