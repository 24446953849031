import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, styled, TableCell } from "@material-ui/core";
import moment from "moment";

// Components
import { basicButtonStyles } from "../../../Components/Demo/Header/styles";
import PriorityIndicator from "../PriorityIndicator/PriorityIndicator";
import CreateCampaignsDialog from "../CreateCampaignsDialog/CreateCampaignsDialog";
import UnregisteredPopup from "../../../Components/Popups/UnregisteredPopup/UnregisteredPopup";
import DetailsPopup from "../../../Components/Popups/DetailsPopup/DetailsPopup";
import ProspectsContainer from "../../../Components/Book/ProspectsContainer/ProspectsContainer";
import RiderStatusIndicator from "../RiderStatusIndicator/RiderStatusIndicator";

// Redux
import { resetFilters, resetLapseFilters, resetUIFilters, selectHeaders, selectIds, selectProductsData, selectProspectsData, selectProspectsFilters, selectQueryFilters, setProductsIsLoading, setProspectsData, setProspectsDataAE, setQueryFilters } from "../BookAnalysisSlice";
import { selectColumns, selectUserCompany, setDemoData } from "../../../AppSlice";
import BookAnalysisService from "../BookAnalysisService";

// Services
import { getCountryCurrencySymbol, nFormatter, amplitudeService } from "../../../global/helpers";

import { useStyles } from "../../../Components/Book/ProspectsContainer/styles";
import { frozenGridColumnsPositions, frozenGridFieldsAnnuities, headerKeysAnnuities, headerKeysLife, prospectsAnnuitiesTableHeaders, prospectsAnnuitiesDataKeys, prospectsLifeTableHeaders, sortKeysAnnuities, sortKeysLife, prospectsLifeDataKeys, frozenGridFieldsLife, frozenGridColumnsAnnuities, frozenGridColumnsLife } from "../../../global/constants";
import StoryIcon from "../../../Components/Book/StoryIcon/StoryIcon";
import { setSlice } from "../../../Components/Tables/TableSlice";
import { CsvBuilder } from "filefy";

export const UIProspectsButton = styled(Button)({
    ...basicButtonStyles,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "19px",
    padding: '26px 0',
    width: '248px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#0BCB47',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      opacity: '0.25',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
  });

const ApiService = new BookAnalysisService()

const ProspectsAE = ({ projectType, mode = 'tab', annuities = false }) => {
    const currency = getCountryCurrencySymbol()
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const rootRef = useRef(null)
    const summaryData = useSelector(selectProductsData)
    const headersData = useSelector(selectHeaders)
    const prospectsData = useSelector(selectProspectsData)
    const prospectsFilters = useSelector(selectProspectsFilters)
    const ids = useSelector(selectIds)
    const queryFilters = useSelector(selectQueryFilters)
    const columns = useSelector(selectColumns)
    const user = useSelector(selectUserCompany)
    const demo = history.location.search.includes("demo") || user === "platform-anonym" 
    const [ openCampaignsDialog, setOpenCampaignsDialog ] = useState(false)
    const [ openRegisterDialog, setOpenRegisterDialog ] = useState(false)
    const [ selectedPolicy, setSelectedPolicy ] = useState(null)
    const [ page, setPage ] = useState(0)
    const [ rowsPerPage, setRowsPerPage ] = useState(10)
    const [ sort, setSort ] = useState("policy")
    const [ loading, setLoading ] = useState(false)
    const [ clear, setClear ] = useState(false)
    const [ isDemo, setIsDemo ] = useState(false)
    const tableHeaders = annuities ? prospectsAnnuitiesTableHeaders : prospectsLifeTableHeaders
    const sortKeys = annuities ? sortKeysAnnuities : sortKeysLife
    const headerKeys = annuities ? headerKeysAnnuities : headerKeysLife
    const prospectsDataKeys = annuities ? prospectsAnnuitiesDataKeys : prospectsLifeDataKeys
    const frozenGridFields = annuities ? frozenGridFieldsAnnuities : frozenGridFieldsLife
    const frozenGridColumns = annuities ? frozenGridColumnsAnnuities : frozenGridColumnsLife

    const loaded = (!loading && (summaryData?.lapse_runs || summaryData?.ui_runs))
    const filteredFrozenFields = frozenGridFields.filter((field) => columns[prospectsDataKeys[field]])

    const handleCloseCampaignsDialog = () => {
      setOpenCampaignsDialog(false)
    }

    const handleChangeRowsPerPage = (value) => {
      setPage(0);
      dispatch(setSlice(0));
      setRowsPerPage(parseInt(value));
    };

    const handleChangePage = (e = null, newPage) => {
      setPage(newPage);
      amplitudeService._throwEvent("TABLE_CHANGE_PAGE", {'title': "Prospects AE", 'value': newPage, 'page': window.location.pathname})
    };

    const handleSortClick = (item) => {
      if (item) {
          const orderBy = `${sort.includes("-") ? "" : "-"}${sortKeys[item]}`
          setSort(orderBy)
      }
    }

    const renderRow = (rowData, columnsToHide, onClick) => {
      return Object.keys(rowData)
        .filter((key) => (!columnsToHide.includes(key) || columnsToHide.length === 0) && columns[prospectsDataKeys[key]])
        .map((key, index) => (
          <TableCell component="th" scope="row" key={index} className={filteredFrozenFields.includes(key) ? classes.sticky : ''} style={key === "policy" ? {
            fontWeight: 700,
            lineHeight: "16px",
            color: "#363ED3",
            cursor: "pointer",
            } 
            : filteredFrozenFields.includes(key) ? {
              right: frozenGridColumnsPositions[(filteredFrozenFields.length-1) - filteredFrozenFields.indexOf(key)],  
              boxShadow: (filteredFrozenFields.indexOf(key) === 0 || filteredFrozenFields.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
            }
            : {}
          }
            onClick={() => onClick(rowData)}>
            {
              ["priority", "withdraw_activity", "sub_activity", "annualized_premium_sensitivity_NP"].includes(key) ? <PriorityIndicator priority={isNaN(rowData[key]) ? rowData[key]?.toLowerCase() : rowData[key]}/>
              : key === "libr_status" ? <RiderStatusIndicator status={rowData[key]?.toLowerCase()}/>
              : ["income_loss", "contract_loss"].includes(key) ? rowData[key] !== undefined && rowData[key] !== "null" ? `${rowData[key]}%` : "N/A"
              : ["issue_date", "end_of_surr"].includes(key) ? moment(rowData[key]).format("MM/DD/YYYY")
              : ["policy", "agent_id"].includes(key) || key === "" ? rowData[key]?.length > 10 ? rowData[key].slice(0, 9)+"..." : rowData[key]
              : ["age", "years"].includes(key) || key === "" ? rowData[key]? rowData[key] : "N/A" 
              : key === "story" ? rowData[key]? <Grid item style={{ display: "flex"}} alignItems="center">
                  <div style={{ minWidth: 20, marginRight: 8 }}><StoryIcon storyType={rowData["model_type"]}/></div>
                  <div>{rowData[key]}</div>
                </Grid> : "N/A" 
              : !isNaN(rowData[key]) ? rowData[key] ? nFormatter(rowData[key], 2, currency) : "N/A" 
              : rowData[key] ? rowData[key] : "N/A" 
            }
          </TableCell>
        ))
    }

    const handleResetFilters = () => {
      setClear(true)
      dispatch(resetFilters())
    }

    const handleResetLapseFilters = () => {
      setClear(true)
      dispatch(resetLapseFilters())
    }

    const handleResetUIFilters = () => {
      setClear(true)
      dispatch(resetUIFilters())
    }

    
    const exporttocsv = (id, name, filter = {}) => {
      ApiService._postExportCsv([id], {
        filters: filter
      }, (response) => {
        if (response.data.length > 0) {
          const data = response.data.split('\n')
          const csvHeaders = data.shift().split(',')
          const csvData = data.map((row) => {
            const rowValue = row.split(",")
            return rowValue
          })

          //let fileName = `project${project !== "All" ? `_${project}` : "s_all"}_${headersData.effective_date}_${prospectsData.count}_policies.csv`
          let fileName = `${name}_${headersData.effective_date}.csv`
          let csvBuilder = new CsvBuilder(fileName)
            .setColumns(csvHeaders)
            .addRows(csvData)
          csvBuilder.exportFile()
        }
      }, (error) => console.log(error))
    }

    const onPageChange = useCallback(() => {
      if (prospectsData?.prospects.length && (summaryData?.lapse_runs?.length || summaryData?.ui_runs?.length)) {
        setLoading(true)
        ApiService._postProspectsData(
          demo,
          [
            ...summaryData?.lapse_runs.map(({ run_id }) => run_id),
            ...summaryData?.ui_runs.map(({ run_id }) => run_id),
            ...summaryData?.cluster_runs.map(({ run_id }) => run_id)
          ],
          {
            lapse_filters: {
              "precision": [
                  {
                      "operator": "precision",
                      "value": "medium"
                  }
              ],
              ...queryFilters.lapse
            },
            ui_filters: { ...queryFilters.ui }
        }, (response) => {
          annuities ? dispatch(setProspectsDataAE(response.data)) : dispatch(setProspectsData(response.data))
          setLoading(false)
        }, 
        (error) => { console.log(error); setLoading(false) }, 
        prospectsFilters.storyType, page, rowsPerPage, sort)
      }
    }, [page, sort, rowsPerPage, dispatch])  

    const onFiltersChange = useCallback(() => {
      if (summaryData?.lapse_runs?.length || summaryData?.ui_runs?.length) {
        setLoading(true)
        const UIFilters = {
          product: prospectsFilters.product,
          cash_value: prospectsFilters.cash_value,
          face_amount: prospectsFilters.face_amount,
          face_amount_prediction: prospectsFilters.face_amount_prediction,
          CSV_LAG: prospectsFilters.surrender_value,
          YRS_REMAIN_SC: prospectsFilters.surrender_year_value,
          IAV_LAG: prospectsFilters.income_value,
          AGENT_STATUS: prospectsFilters.agent_status,
          QTD_LIBR_WITHS_MONTH_CUMSUM_LAG: prospectsFilters.libr_payment,
          RATIO_WITHS_LAG: prospectsFilters.withdraw_activity,
          SUB_ACTIVITY: prospectsFilters.sub_activity,
          gender: prospectsFilters.gender,
          age: prospectsFilters.age,
          story: prospectsFilters.story,
          CONTRACT_LOSS: prospectsFilters.contract_loss,
          INCOME_ACOUNT_LOSS: prospectsFilters.income_lost,
          State: prospectsFilters.state,
          RES_STATE_LAG: prospectsFilters.res_state,
          PRIORITY: prospectsFilters.priority,
          RIDER_STATUS_LAG_WITH_FEE: prospectsFilters.libr_status,
          Duration_segment: prospectsFilters.Duration_segment,
          mean_premium_term: prospectsFilters.mean_premium_term,
          MARKET_NAME_LAG: prospectsFilters.MARKET_NAME_LAG,
          premium: prospectsFilters.premium,
        }
        const LapseFilters = {
          product: prospectsFilters.product,
          cash_value: prospectsFilters.cash_value,
          face_amount: prospectsFilters.face_amount,
          face_amount_prediction: prospectsFilters.face_amount_prediction,
          CSV_LAG: prospectsFilters.surrender_value,
          YRS_REMAIN_SC: prospectsFilters.surrender_year_value,
          IAV_LAG: prospectsFilters.income_value,
          AGENT_STATUS: prospectsFilters.agent_status,
          QTD_LIBR_WITHS_MONTH_CUMSUM_LAG: prospectsFilters.libr_payment,
          RATIO_WITHS_LAG: prospectsFilters.withdraw_activity,
          SUB_ACTIVITY: prospectsFilters.sub_activity,
          gender: prospectsFilters.gender,
          age: prospectsFilters.age,
          story: prospectsFilters.story,
          CONTRACT_LOSS: prospectsFilters.contract_loss,
          INCOME_ACOUNT_LOSS: prospectsFilters.income_lost,
          State: prospectsFilters.state,
          RES_STATE_LAG: prospectsFilters.res_state,
          PRIORITY: prospectsFilters.priority,
          RIDER_STATUS_LAG_WITH_FEE: prospectsFilters.libr_status,
          Duration_segment: prospectsFilters.Duration_segment,
          MARKET_NAME_LAG: prospectsFilters.MARKET_NAME_LAG,
          premium: prospectsFilters.premium,
          potential_premium_retained: prospectsFilters.mean_premium_term,
        }
        let ui = {}
        let lapse = {}
        const activeUIFilters = Object.entries(UIFilters)
          .filter((item) => item[1] !== null)
          .map((item) => ({ [item[0]]: item[1] }))
        const activeLapseFilters = Object.entries(LapseFilters)
          .filter((item) => item[1] !== null)
          .map((item) => ({ [item[0]]: item[1] }))
        if (activeUIFilters.length > 0) {
          Object.assign(ui, ...activeUIFilters)
        }
        if (activeLapseFilters.length > 0) {
          Object.assign(lapse, ...activeLapseFilters)
        }
        if (activeUIFilters.length > 0 || activeLapseFilters.length > 0) {
         dispatch(setQueryFilters({ lapse, ui }))
        }

        ApiService._postProspectsData(
          demo,
          [
            ...summaryData?.lapse_runs.map(({ run_id }) => run_id),
            ...summaryData?.ui_runs.map(({ run_id }) => run_id),
            ...summaryData?.cluster_runs.map(({ run_id }) => run_id)
          ],
          {
          lapse_filters: {
            "precision": [
                {
                    "operator": "precision",
                    "value": "medium"
                }
            ],
            ...lapse
          },
          ui_filters: { ...ui },
          cluster_filters: summaryData?.cluster_runs?.length ? { ...ui } : {}
        }, (response) => {
             setIsDemo(false)
             annuities ? dispatch(setProspectsDataAE(response.data)) : dispatch(setProspectsData(response.data))
             setLoading(false)
             setClear(false)
        }, (error) => {  
                setLoading(false)
                console.log(error)
        }, prospectsFilters.storyType, 0, rowsPerPage, sort)       
      }
    }, [ annuities, demo, summaryData, prospectsFilters, dispatch])
    
  useEffect(() => {
      return () => {
        setClear(true)
        dispatch(resetFilters())
      }
  }, [dispatch])

    return (
        <Grid container direction="column" justifyContent="center" ref={rootRef}>
            <ProspectsContainer
              projectType={projectType}
              data={prospectsData}
              headers={tableHeaders.filter((header) => columns[headerKeys[header]] || ["AUM Upside"].includes(header))}
              page={page}
              clear={clear}
              sort={sort}
              loaded={loaded}
              onChangePage={onPageChange}
              onFiltersChange={onFiltersChange}
              handleChangePage={handleChangePage}
              handleResetFilters={handleResetFilters}
              handleResetUIFilters={handleResetUIFilters}
              handleResetLapseFilters={handleResetLapseFilters}
              handleSort={handleSortClick}
              handleSelectPolicy={(policy) => setSelectedPolicy(policy)}
              handleExportAll={() => exporttocsv()}
              handleExport={(id, model, name) => exporttocsv(id, name, model === "ui" ? queryFilters.ui : queryFilters.lapse)}
              renderRow={renderRow}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={handleChangeRowsPerPage}
              divided={false}
              frozenColumns={frozenGridColumns.filter((header) => columns[headerKeys[header]])}
              hiddenColumns={[
                "gender",
                "zipcode",
                "targeted_aum",
                "retained_policies",
                "retained_aum",
                "rollover_aum",
                "end_of_surr",
                "libr_fee",
                "joint_owner",
                "owned_by_trust",
                "power_of_attroney",
                "corparate_owned",
                "surr_prob",
              ]}
              divider={19}
            />
            <CreateCampaignsDialog open={openCampaignsDialog} onClose={handleCloseCampaignsDialog}/>
            <UnregisteredPopup open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}/>
            <DetailsPopup anchor={rootRef.current} open={selectedPolicy !== null && prospectsData?.prospects?.length > 0} onClose={() => setSelectedPolicy(null)} policy={prospectsData?.prospects?.find((item) => item.policy === selectedPolicy)}/>
        </Grid>
    )
}

export default ProspectsAE;