import { makeStyles } from "@material-ui/styles"
export const useStyles = makeStyles(() => ({
    tableHeader: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto!important",
        fontSize: 12,
        fontWeight: 700,
        lineHeight: "16px",
        color: "#232033",
        background: "#EEF0F4",
        borderBottom: "none",
        verticalAlign: "top",
        width: "10.5%",
        "& div": {
          display: "flex",
        },
        "& p": {
          paddingRight: 5,
          margin: 0
        },
        "& svg": {
          cursor: "pointer",
          marginTop: -5
        }
      }
    },
    tableUpsideHeader: {
      minWidth: 220
    },
    tableBody: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto!important",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        borderBottom: "1px solid #EEF0F4",
        textAlign: "left",
      },
      "&:hover .MuiTableCell-root": {
        background: "#fbfaff"
      }
    },
    sticky: {
      position: "sticky",
    }

}));
