import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";

import { Button, Grid, makeStyles, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import {
    setUserCompany, setUserInitials, setUserRole, setUserPermissions,
    setUserLimit, setMenuDisabled, setCurrentUser, setAccountType, setLoading
} from "../../../AppSlice";
import { clearResetCode, setUserName } from "../../../Pages/Login/LoginSlice";
import appApiService from "../../../AppService";

import FormInput from "../FormInput";
import InformationPopup from "../../Popups/InformationPopup/InformationPopup";

import { decryptData, encryptData } from "../../../Pages/CreateCampaign/helpers";
import { processUserLoginData } from "../../../global/helpers";
import { amplitudeService } from "../../../global/helpers";

const useStyles = makeStyles((theme) => ({
    signInTitle: {
        color: "#312E40",
        fontFamily: "'Strawford', sans-serif !important",
        fontSize: 24,
        fontWeight: 600,
    },
    signInText: {
        marginBottom: 20,
        fontWeight: 400,
        fontSize: 16,
        fontFamily: "'Roboto', sans-serif !important",
    },
    buttonContainer: {
        textAlign: "center"
    },
    buttonBlock: {
        background: '#0BCB47',
        width: "100%",
        height: 46,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: 18,
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
            backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        },
    },
    signInRegister: {
        textAlign: 'center',
        color: '#1B1C1E',
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        '& span': {
            color: '#3444CB',
            cursor: "pointer"
        }
    },
    loginForm: {
        maxWidth: 367,
    },
    loginOptions: {
        display: "flex",
        justifyContent: "space-between",
        '& .MuiFormControlLabel-root': {
            margin: 0,
            '& .MuiTypography-root': {
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "18px",
                color: '#1B1C1E',
            }
        },
        '& .MuiButtonBase-root': {
            paddingLeft: 0,
            paddingRight: 6,
            marginLeft: "-3px",
            width: 24
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: "white"
        },
        '& .MuiSvgIcon-root': {
            width: 16,
            height: 16
        }
    },
    forgotPassword: {
        display: "flex",
        alignItems: "center",
        '& a': {
            fontFamily: "'Inter', sans-serif",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "18px",
            textDecoration: 'none',
            color: "#3444CB"
        }
    },
    loginInputLabel: {
        color: "#312E40",
        fontFamily: "'Roboto', sans-serif !important",
        fontWeight: 400,
        fontSize: 16,
        paddingBottom: 8
    },
    remeberCheckBox: {
        color: "#c3c3c3",
        '&.MuiCheckbox-colorSecondary.Mui-checked svg': {
            color: "#363ed3"
        }
    },
    capsIcon: {
        paddingLeft: 2
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        }
    }
}));

const LoginForm = () => {
    const classes = useStyles();
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const history = useHistory();
    const dispatch = useDispatch()
    const [rememberUser, setRememberUser] = React.useState(true);
    const formRef = React.useRef();

    const [showAlert, setShowAlert] = React.useState(false);
    const [showUnactiveUserMessage, setShowUnactiveUserMessage] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')

    function handleSubmit() {
        setDisabled(true)
        setShowAlert(false)
        axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/login", {
            email: username,
            password: password
        }).then((response) => {
            sessionStorage.removeItem("atidot_platform_data")
            if (rememberUser) {
                localStorage.setItem("stored_user", JSON.stringify({ username, password: encryptData(password) }))
            }
            setShowAlert(false)

            const payload = processUserLoginData(response, dispatch, setUserInitials, setUserRole, setUserPermissions,
                setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType)

            const isAnnuities = payload["account_type"] === "annuities"

            delete payload['password']
            amplitudeService._throwEvent("LOGIN", payload)

            // if AE type account skip to prospects
            if (!isAnnuities) {
                // When a register user that have a project is logging in… 
                // he should go after login to the book analysis page and not projects.
                const ApiService = new appApiService()
                dispatch(setLoading(true))
                ApiService._getRunsCount(payload["company_id"],
                    (response) => {
                        const isProjectsPresent = response.data?.count !== 0
                        setDisabled(false)
                        dispatch(setLoading(false))
                        history.push(isProjectsPresent ? "/book" : "/")
                    }, 
                    () => history.push("/")
                )
                //history.push("/")               
            } else {
                setDisabled(false)
                history.push("/prospects")
            }

        }).catch((error) => {
            setAlertSeverity("error")
            if (error.response.status === 400) {
                setAlertMessage("Email or password is incorrect")
            } else if (error.response.status === 404) {
                setAlertMessage("User with given credentials not found")
            } else if (error.response.status === 403) {
                setShowUnactiveUserMessage(true)
                localStorage.setItem("resetEmail", username)
            } else {
                setAlertMessage("Server Error")
            }
            setDisabled(false)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        const remeberedUser = JSON.parse(localStorage.getItem("stored_user"))
        if (remeberedUser?.username && remeberedUser?.password) {
            setUsername(remeberedUser?.username)
            setPassword(decryptData(remeberedUser?.password))
        }
    }, [])

    return (
        <Grid container direction="column" className={classes.loginForm}>
            <Grid item>
                <Typography align="left" component="h1" variant="h5" className={classes.signInTitle}>
                    Log in
                </Typography>
                <Typography align="left" component="p" className={classes.signInText}>
                    Welcome back to Atidot! Please enter your details
                </Typography>
            </Grid>
            <Grid item>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>

                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <FormInput
                                error={showAlert && alertSeverity === "error"}
                                label="Email"
                                type="email"
                                name="email"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                placeholder="Enter your email"
                                required
                                autoFocus
                                data-testid="emailInput" />
                        </Grid>
                        <Grid item>
                            <FormInput
                                error={showAlert && alertSeverity === "error"}
                                showText={false}
                                label="Password"
                                name="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                placeholder="Enter your password"
                                required
                                data-testid="passwordInput" />
                            <div className={classes.loginOptions}>
                                <div>
                                    <FormControlLabel control={<Checkbox defaultChecked value={rememberUser} onChange={() => setRememberUser(!rememberUser)} className={classes.remeberCheckBox} />} label="Remember me" />
                                </div>
                                <div
                                    className={classes.forgotPassword}
                                    onClick={() => {
                                        dispatch(clearResetCode())
                                        dispatch(setUserName(''))
                                        localStorage.removeItem("resetEmail")
                                        localStorage.removeItem("registeredPassword")
                                        localStorage.removeItem("resetCode")
                                    }}>
                                    <Link to={`/forgotpassword`}>Forgot password</Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.buttonBlock}
                                data-testid="loginButton"
                                disabled={!(username.replaceAll(" ", "") && password.replaceAll(" ", "")) || disabled}
                            >
                                Log in
                            </Button>
                        </Grid>

                        <Grid item justifyContent="center" className={classes.alert}>
                            {showAlert ?
                                <Alert variant="outlined" severity={alertSeverity}>
                                    <AlertTitle>{alertMessage}</AlertTitle>
                                </Alert>
                                : ''}
                        </Grid>
                        <Grid item justifyContent="center">
                            <Typography className={classes.signInRegister}>
                                Don’t have an account? <span onClick={() => history.push("/registration")}>Sign up</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
            <InformationPopup
                open={showUnactiveUserMessage}
                onClose={() => {
                    setShowAlert(false)
                    setShowUnactiveUserMessage(false);
                }}
                onConfirm={() => {
                    setShowAlert(false)
                    setShowUnactiveUserMessage(false);
                    history.push("/sendnewcode");
                }}
                title={<>Please validate your email address to<br />continue to the platform.</>}
                message="To resend the email validation email click the button below."
                confirmText="Resend Email"
                closeControl={false}
                error
            />
        </Grid>
    )
}

export default LoginForm