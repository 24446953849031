import React from "react"
import { makeStyles } from "@material-ui/core"
import Tableau from "../../Components/Tableau/Tableau"

const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        height: "100vh",
        border: "1px solid #EBEBEB",
        borderRadius: 25,
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        padding: 24,
        "& .vizDiv":  {
            width: "100%",
            border: "1px solid rgba(194, 194, 194, 0.50)",
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)",
        },
    },
    header: {
        width: "100%",
        "& h1":  {
            fontFamily: "Strawford!important",
            fontSize: 24,
            fontWeight: 400,
            lineHeight: "29px",
            paddingBottom: 5,
        },
    },
})

const TableauDemo = () => {
    const classes = useStyles()    

    return (
        <>
            <div className={classes.header}>
                <h1>Tableau Dashboard</h1>
            </div>
            <div className={classes.root}>
                <Tableau/>
            </div>
        </>
    )
}

export default TableauDemo