import {makeStyles} from "@material-ui/core";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";

export const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#363ED3',
    width: '100%',
    fontFamily:'Strawford !important',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 75,
  },
  linksBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  navList: {
    listStyle: 'none',
    display: 'flex',
    '& a': {
      fontSize: '12px',
      color: '#FFF',
      textTransform: 'uppercase',
      textDecoration: 'none',
    },
    '& li:first-child': {
      marginRight: '40px',
    }
  },
  buttons: {
    '& button:first-child': {
      marginRight: '10px',
    }
  }
}));

export const basicButtonStyles = {
  fontFamily: "Strawford, sans-serif!important",
  fontWeight: 400,
  width: '140px',
  height: '40px',
  borderRadius: '4px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  '&:focus': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    opacity: '.7',
  },
};

export const UIButton = styled(Button)({
  ...basicButtonStyles,
  backgroundColor: '#FFF',
  borderColor: '#FFF',
  color: '#363ED3',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#FFF',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
});
export const UIButtonInverted = styled(Button)({
  ...basicButtonStyles,
  backgroundColor: '#363ED3',
  borderColor: '#FFF',
  color: '#FFF',
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
});
