export const DEFAULT_CALCULATION_VALUES = {
    RECALL: 0.17,
    UPSELL_POTENTIAL_TERM: 0.21,
    UPSELL_POTENTIAL_PERMANENT: 0.18,
    UPSELL_INCREASE_TERM: 1.27,
    UPSELL_INCREASE_PERMANENT: 1.12,
    AVG_LAPSE_TERM: 950.75,
    AVG_LAPSE_WL: 4527.68,
}

export const useCalculations = (lapseIndex = 0.1, upsellIndex = 0) => {

    const calculatePies = ({ name, premium, policies }) => {
        const PremiumInputValue = Number(premium);
        const PoliciesInputValue = Number(policies);
        const upsellPotential = name.toLowerCase() === 'term'
            ? DEFAULT_CALCULATION_VALUES.UPSELL_POTENTIAL_TERM
            : DEFAULT_CALCULATION_VALUES.UPSELL_POTENTIAL_PERMANENT
        const upsellIncrease = name.toLowerCase() === 'term'
            ? DEFAULT_CALCULATION_VALUES.UPSELL_INCREASE_TERM
            : DEFAULT_CALCULATION_VALUES.UPSELL_INCREASE_PERMANENT
        const recall = DEFAULT_CALCULATION_VALUES.RECALL;

        if (PremiumInputValue === 0) return {
            smallPie: 0,
            bigPie: 0,
        }

        const AverageAnnualPremiumPerPolicy = Math.round((PremiumInputValue / PoliciesInputValue + Number.EPSILON) * 100) / 100;
        const NumberOfPoliciesToUpsell = Math.floor(PoliciesInputValue * upsellPotential);
        const UpsellWithoutModeling = PoliciesInputValue * upsellIndex * AverageAnnualPremiumPerPolicy;
        const LapseWithoutModeling = Math.floor(-AverageAnnualPremiumPerPolicy * PoliciesInputValue * lapseIndex);
        const NumbersOfLapsers = Math.round(PoliciesInputValue * lapseIndex);
        //Recall
        const LapseWithModeling = NumbersOfLapsers * recall;
        const PremiumLapseWithModeling = Math.floor(-LapseWithModeling * AverageAnnualPremiumPerPolicy);
        const AnnualPremiumIncrease = Math.floor(
            AverageAnnualPremiumPerPolicy *
            NumberOfPoliciesToUpsell *
            upsellIncrease + PremiumLapseWithModeling
        );

        // Small Pie
        const PortfolioPerformanceWithoutModelling = PremiumInputValue + UpsellWithoutModeling + LapseWithoutModeling;
        //Big Pie
        const AnnualPremium = AnnualPremiumIncrease + PremiumInputValue;

        return {
            smallPie: PortfolioPerformanceWithoutModelling,
            bigPie: AnnualPremium,
        }
    }

    return {
        calculatePies
    };
}