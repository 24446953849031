import { styled } from "@mui/material";
import { Button } from '@material-ui/core';
import { basicButtonStyles } from '../Demo/Header/styles'

export const uiViewButtonStyles = {
    ...basicButtonStyles,
    fontFamily: "Roboto!important",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    padding: '26px 0',
    width: '100%',
    maxWidth: '248px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
        opacity: '0.85',
        backgroundColor: '#0BCB47',
        borderColor: '#FFF',
        boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#239948',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
    '&.Mui-disabled': {
      backgroundColor: '#0BCB4725',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
};

export const UIViewButton = styled(Button)(uiViewButtonStyles);


export const HeaderButton =  styled(Button)(() => ({
  ...uiViewButtonStyles,
  width: 168,
  height: 41
}));

export const NextButton =  styled(Button)(() => ({
  ...uiViewButtonStyles,
  width: 250,
  height: 50
}));

export const uiButtonStyles = {
    ...basicButtonStyles,
    width: 'auto',
    padding: '14px 34px',
    maxWidth: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    '&:hover': {
      opacity: '0.85',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#1D2398',
      borderColor: '#FFF',
      boxShadow: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: '#1D2398',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
  };

export const UIButton = styled(Button)({
  ...uiButtonStyles
});

export const ClearButton = styled(Button)({
  width: 'auto',
  color: '#363ED3',
  fontFamily: "Strawford!important",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "center",
  textDecoration: "underline",
  textTransform: "capitalize",
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  '&:focus': {
    backgroundColor: 'transparant',
    boxShadow: 'none'
  },
  '& svg': {
    marginLeft: 8
  },
});  