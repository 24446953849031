import React from "react";
import { makeStyles } from "@material-ui/styles"
import { Checkbox, FormGroup } from "@mui/material";
import { FormControlLabel, Grid, Typography } from "@material-ui/core";
import UserRoleItem from "../UserRoleItem/UserRoleItem";

const useStyles = makeStyles(() => ({
    roleCheckbox: {
        width: "100%"
    },
    checkBox: {
        padding: 0,
        paddingLeft: 23,
        "& .MuiCheckbox-colorSecondary.Mui-checked svg": {
            fill: "#363ED3"
        },
        "& .MuiCheckbox-colorSecondary svg": {
            fill: "#DEE5F0"
        },
        "& .MuiButtonBase-root": {
            padding: 0,
        }
    },
    checkDescription: {
        paddingLeft: 0,
        "& .MuiTypography-body1": {        
            fontFamily: "Roboto!important",
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "14px",
    
        }
    }
}))


const UserRoleSelector = (props) => {
    const { roles = [], defaultValue } = props
    const classes = useStyles()
    
    return (
        <FormGroup>
            {
                roles?.map((item) => (
                    <Grid container alignItems='center' direction="column" key={item.label}> 
                        <FormControlLabel
                        className={classes.roleCheckbox}
                        key={item.label}
                        control={
                            <Checkbox
                            {...props}
                            color="primary"
                            value={item.id}
                            name="roleIds"
                            checked={item.id === parseInt(defaultValue)}
                            />
                        }
                        label={<UserRoleItem label={item.label} role={item.id}/>}
                        />
                        <Grid container className={classes.checkDescription}>
                            <Typography>
                                {item.description}
                            </Typography>
                        </Grid>    
                    </Grid>
                ))
            }
        </FormGroup>
    )
}

export default UserRoleSelector