import { makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    /*  root: {
       width: '100%',
      
     }, */
     button: {
       marginRight: theme.spacing(1),
       '&:hover':{
         backgroundColor: '#000dff'
       },
       textTransform: 'capitalize'
     },
     instructions: {
       marginTop: theme.spacing(1),
       marginBottom: theme.spacing(1),
     
     },
     TitleProperties:{
         marginLeft: 77
     },
     ExitProperties:{
       position:"absolute",
       right:20
   },
     floatRight:{
       float: 'right'
     },
     headerBorder:{
       borderBottom: "1px solid #d9e1ee"
     },
     footerBorder:{
       borderTop: "1px solid #d9e1ee",
       position:'absolute',
       bottom:0,
       paddingTop: 5
     },
      StepperContainer:{
        backgroundColor:"#f8f9fb"
     },
     customFooter: {
       display: 'flex',
       paddingLeft: '25%',
       paddingRight: '25%',
       justifyContent: 'center',
       alignItems: 'center',
       width: '100%',
       backgroundColor: 'white',
       marginTop:'35px',
       position: "fixed",
       bottom: 0
     },
     customButtonContainer:{
       gridColumn: '2',
       display: 'flex',
       width: '100%',
       justifyContent: 'space-between',
       padding: '29px 40px',
     },
     customNextButton: {
       backgroundColor: '#0BCB47',
       color: 'white !important',
       width: '148px',
       borderRadius: '12px',
       padding: '9px 10px',
       fontSize: '13px',
       fontWeight: 'normal',
       textTransform: 'none',
       cursor:'pointer',
       '&:hover':{
         opacity: .9
       },
       '&.Mui-disabled':{
         backgroundColor: '#239948',
       }
     },
     customBackButton: {
       backgroundColor: '#FFFFFF',
       width: '148px',
       borderRadius: '12px',
       padding: '9px 10px',
       fontSize: '13px',
       fontWeight: 'normal',
       textTransform: 'none',
       cursor:'pointer',
       '&.Mui-disabled':{
         backgroundColor: "gray !important"
       },
       '&:hover':{
         backgroundColor: "#363ed3",
         color: '#FFFFFF !important'
       }
     },
     customColorStreper:{
       backgroundColor : '#363ed3',
     },
     icon: {
       width: '32px',
       height: '32px',
       color: "white",
       border: 'solid 1px #d9e1ee',
       borderRadius:'50%',
       "& text": { fill: "#242a8c" },
       
      
       "&$activeIcon": {
         color: "#363ed3",
         "& text": { fill: "white" }
       },
     },
     activeIcon: {},
     completedIcon: {},
     root: {
       display: 'flex',
       marginTop: 10,
       '& > * + *': {
         marginLeft: theme.spacing(2),
       },
     },
     uploadContainer:{
       "@media (min-width: 1445px)": {
         marginBottom:'20%',
       }
     },
     uploadDone:{
       marginBottom: 100,
       "@media (min-width: 1445px)": {
         marginBottom:'85%'
       }
     },
     dragnDropRoot:{
       height: 320,
       width:  550,
       justifyContent: 'center ',
       "@media (min-width: 1445px)": {
         marginLeft: '10%'
       }
     },
     titleText:{
       "@media (min-width: 1445px)": {
         marginLeft: '10%'
       },
       marginTop:'5%',
       marginBottom:'3%'
     },
     loading: {
       display:'flex',
       alignItems:'center',
       padding:10,
       marginTop:15,
     },
     loadingText: {
       marginLeft:5
     },
     loadedText:{
       fontSize: 12, 
      letterSpacing: 0.15,
      color:'#6a6c71',
      marginTop:25
     },
     loadedAlert:{
       marginTop: 25
     },
     ButtonReupload   : {
       fontSize: 13,
       marginTop: 30,
       paddingTop: 13,
       paddingBottom:13,
       paddingLeft: 60,
       paddingRight: 60,
       backgroundColor: "#fff",
       border:"solid 1px #d9e1ee",
       borderRadius:12,
       boxShadow:"none",
       textTransform:"capitalize",
       "&:hover":{
         boxShadow:"none"
       }
     },
     greenIcon: {
       position: "absolute",
       top: "26.5%",
       right: "48%",
     },
     linkStyle: {
       color: 'blue',
       cursor:'pointer',
       textDecoration:'underline',
   
     },
     centerStepper:{
       justifyContent:'center',
       "@media (min-width: 1445px)": {
         marginLeft: '10%'
       }
     }
   }))