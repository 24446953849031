import { Grid, Typography, makeStyles, Card, TextField } from "@material-ui/core"; 
import enGB  from 'date-fns/locale/en-GB';
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { selectCampaignData, selectCampaignId, setCampaignAction, setCampaignDescription, setCampaignName, setCampaignScenario, setCampaignType, setColor, setDateEnd, setDateStart, setError, setMailDescription, setMailSubject, setOpenMailer } from "../../../Pages/CreateCampaign/CreateCampaignSlice";
import ColorPicker from "../../ColorPicker/ColorPicker";
import { Alert, Box, FormControl } from "@mui/material";
import IconLife from "../../../Assets/icon-life.svg";
import IconHubSpot from "../../../Assets/icon-hubspot.svg";
import IconSmartOffice from "../../../Assets/icon-smartoffice.svg";
import IconSalesForce from "../../../Assets/icon-salesforce.svg";
import IconRemark from "../../../Assets/icon-remark.svg";
import IconZinnia from "../../../Assets/icon-zinnia.svg";
import FormInput from "../../Forms/FormInput";
import Gallery from "react-photo-gallery"
import Lightbox from "react-image-lightbox"
import 'react-image-lightbox/style.css';
import { directTemplates, emailTextPlaceHolder, journeyTemplates } from "./constants";
import { UIButton } from "../../ui/buttons";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
    customInput: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',
        '& .MuiInputBase-root': {
            fontFamily: "Roboto!important",
            fontWeight: 400,
            fontSize: 12
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d9e1ee',
        },
        '&.MuiInputBase-input input::placeholder':{
            color: "#6A6C71"
        },
    },
    customNameInput: {
        display:'flex',
        width: '100%',
        height: '44px',
        backgroundColor: '#ffffff',
        border: '1px solid #d9e1ee',
        borderRadius: '5px',
        outline: '0px',
        padding: '0 10px',
        '& textarea':{
            paddingTop: 10
        },
        '& textarea::placeholder':{
            color: "#6A6C71"
        },
        '& input, textarea':{
            fontFamily: "Roboto!important",
            fontWeight: 400,
            fontSize: 12
        },
        '&:focus-within':{
            border: '1px solid #171b59',
        },
        '&:hover': {
            border: '1px solid #363ed3',
        }
    },
    dateInput: {  
        '& .MuiInputBase-root input': {
            padding: '14px 0',
        }
    },
    newCampaignTitle: {
        fontFamily: "Roboto!important",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        paddingBottom: 10
    },
    actionDesc: {
        fontFamily: "Roboto!important",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#6A6C71"
    },
    block: {
        gap: 8
    },
    prospectsLink: {
        fontFamily: "Roboto!important",
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "24px"
    },
    inputTitle: {
        fontFamily: "Roboto!important",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
        paddingBottom: 10
    },
    inputTitlePaddingSm: {
        paddingBottom: 2
    },
    journeyTitle: {
        fontFamily: "Roboto!important",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "22px",
        paddingBottom: 16,
    },
    newCampaignCardContainer: {
        maxWidth: 176,
    },
    newCampaignCard: {
        paddingTop: 12,
        height: 45,
        textAlign: 'center',
        cursor: "pointer",
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 8,
        boxShadow: 'none',
        border: '1px solid #d9e1ee',
        width: '100%',
        '&:hover': {
            border: '1px solid #363ed3',
        }
    },
    newCampaignCardImage: {
        padding: 4
    },
    cardActive: {
        border: '1px solid #363ed3'
    },
    paramsContainer: {
        paddingRight: 16,
        gap: 36,
    },
    actionContainer: {
        backgroundColor: '#F8F9FB',
        border: '1px solid #00000007',
        borderRadius: 6,
        boxShadow: "0px 0px 6px 0px #0000001A",
        padding: 24,
        height: "100%",
        gap: 24,
    },
    mailerTemplate: {
        cursor: "pointer",
        display: "flex",
        marginLeft: 3,
        maxWidth: "29%",
        flexBasis: "29%",
        "@media (min-width:1553px)": {
            marginLeft: 42,
        },
        "& img": {
            width: "100%"
        },
        "&:first-child": {
            marginLeft: 0
        },
        "&:hover": {
            border: '1px solid #363ed3'
        }
    },
    mailerForm: {
        width: 377,
        "& .MuiDialogTitle-root": {
            fontFamily: "Roboto!important",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "26px",
        },
    },
    space: {
        gap: 8
    },
    spaceMD: {
        gap: 24
    },
    noPadding: {
        padding: 0
    }
})

const OpenJourney = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    
    return (
    <Grid item container>
        <Grid item container>
            <label className={classes.journeyTitle}>See firsthand what your customer journey could look like with our platform.<br/>Click below for a Journey Demo.</label>
        </Grid>
        <Grid item container>
            <UIButton onClick={() => dispatch(setOpenMailer(true))}>Journey Demo</UIButton>
        </Grid>
    </Grid> 
    )
}

const ProviderOptions = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const campaignData = useSelector(selectCampaignData)
    const { campaignType } = campaignData

    const handleTypeClick = (id) => {
        dispatch(setCampaignType(id))
    }

    return (
        <>
            <Typography className={classes.newCampaignTitle}>Please choose a provider for campaign execution</Typography>
            <Grid item container direction="row" className={classes.space}>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(1)}>
                    <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 1 ? classes.cardActive : '')}>
                        <img src={IconRemark} alt="Remark"/>
                    </Card>
                </Grid>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(2)}>
                    <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 2 ? classes.cardActive : '')}>
                        <img src={IconHubSpot} alt="HubSpot"/>
                    </Card>
                </Grid>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(3)}>
                    <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 3 ? classes.cardActive : '')}>
                        <img src={IconSalesForce} alt="SalesForce"/>
                    </Card>
                </Grid>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(4)}>
                        <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 4 ? classes.cardActive : '')}>
                            <img src={IconSmartOffice} alt="SmartOffice"/>
                        </Card>
                </Grid>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(5)}>
                    <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 5 ? classes.cardActive : '')}>
                        <img src={IconZinnia} alt="Zinnia"/>
                    </Card>
                </Grid>
                <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleTypeClick(6)}>
                    <Card className={clsx(classes.newCampaignCard, classes.newCampaignCardImage, campaignType === 6 ? classes.cardActive : '')}>
                        <img src={IconLife} alt="Atidot Life" style={{ maxWidth: 148 }}/>
                    </Card>
                </Grid>
            </Grid>         
        </>
    )
}

const EmailDataForm = ({ textLabel = "Add text", subjectLabel = "Email subject"}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const campaignData = useSelector(selectCampaignData)
    
    const handleChangeSubject = (name) => {
        dispatch(setError(false))
        dispatch(setMailSubject(name))
    }
        
    const handleChangeMailText = (text) => {
        dispatch(setError(false))
        dispatch(setMailDescription(text))
    }

    return(
        <>
            {subjectLabel && <Grid item container>
                <Grid item container>
                    <label className={classes.inputTitle}>{subjectLabel}</label>
                </Grid>
                <Grid item container>
                    <div className={classes.customInput}>
                        <FormInput
                            error={campaignData.error}
                            data-testid="emailNameInput" 
                            id="emailNameInput" 
                            type="text" 
                            value={campaignData.mailSubject}
                            style={{height:'100%',padding:'0',width:'100%', border:'none', outline:'none'}}
                            onChange={(event) => handleChangeSubject(event.target.value)}
                            placeholder="Type Name"
                            autoFocus
                            validators={['required', 'matchRegexp:^[a-zA-Z0-9-_]{4,30}$']}
                            errorMessages={['this field is required', 'Please enter a valid subject']}
                            />
                    </div>
                </Grid>

            </Grid>}
            <Grid item container>
                <Grid item container>
                    <label className={classes.inputTitle}>{textLabel}</label>
                </Grid>
                <Grid item container>
                    <div className={classes.customInput}>
                        <div className={classes.customNameInput} style={{height:'unset', minHeight: 160, paddingRight: 0}}>
                            <textarea
                                data-testid="emailDescInput" 
                                id="emailDescInput" 
                                type=" text"
                                required 
                                value={campaignData.mailDescription}
                                style={{height:'100%',  width:'100%', border:'none', outline:'none'}} 
                                placeholder={emailTextPlaceHolder} 
                                onChange={(e) => handleChangeMailText(e.target.value)}/>
                                            
                        </div>
                    </div>
                </Grid>
            </Grid>        
        </>
    )
}

const TemplatePicker = ({ templates }) => {
    const classes = useStyles()
    const [activeTemplate, setActiveTemplate] = useState(0);
    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);

    const handleOpenLightBox = useCallback((e, {photo, index}) => {
        setActiveTemplate(index)
        setLightBoxIsOpen(true);
    }, []);

    const closeLightbox = () => {
      setLightBoxIsOpen(false);
    };
    
    const imageRenderer = useCallback(
        ({ index, key, photo, onClick }) => (
            <div
              onClick={(e) => onClick(e, { photo, index })}
              style={{ height: photo.height, width: photo.width }}
              className={clsx(classes.mailerTemplate, activeTemplate !== index ? "not-selected" : classes.cardActive) }
              >
                <img alt={photo.title} {...photo} key={key} index={index}/>
                <style>{`.not-selected:hover{border:1px solid #363ed3}`}</style>
            </div>
        ),
        [activeTemplate, classes.cardActive]
    );

    return(
        <>
            <Grid item container>
                <Typography className={clsx(classes.inputTitle, classes.noPadding)}>Select Template</Typography>
            </Grid>
            <Grid item container>
                <Gallery photos={templates}  renderImage={imageRenderer} onClick={handleOpenLightBox}/>
                {lightBoxIsOpen ? (          
                    <Lightbox
                        mainSrc={templates[activeTemplate].src}
                        nextSrc={templates[(activeTemplate + 1) % templates.length].src}
                        prevSrc={templates[(activeTemplate + templates.length - 1) % templates.length].src}
                        onCloseRequest={closeLightbox}
                        onMovePrevRequest={() =>
                            setActiveTemplate((activeTemplate + templates.length - 1) % templates.length)
                        }
                        onMoveNextRequest={() =>
                            setActiveTemplate((activeTemplate + 1) % templates.length)
                        }
                    />
                ) : null}    
            </Grid>        
        </>
    )
}

const SendEmail = () => {
    const classes = useStyles()

    return (
    <Grid container className={classes.actionContainer} xs={6} direction="column">
        <Grid item container className={classes.spaceMD}>
                <ProviderOptions/>   
                <TemplatePicker templates={journeyTemplates}/>  
                <EmailDataForm/> 
                <OpenJourney/>                             
        </Grid>
    </Grid>
    )
}

const ScheduleMeeting = () => {
    const classes = useStyles()

    return (
    <Grid container className={classes.actionContainer} xs={6} direction="column">
        <Grid item container>
            <Typography className={classes.newCampaignTitle}>
                This scenario leverages our exclusive journey framework to encourage<br/>
                policyholders to schedule meetings with agents. It builds on ChatGPT's<br/>
                foundational technology by integrating our custom algorithms for<br/>
                personalized engagement, all powered by our proprietary AI-driven<br/>
                predictive models
            </Typography>
            <OpenJourney/>                             
        </Grid>
    </Grid>
    )
}

const CallPolicyholder = () => {
    const classes = useStyles()

    return (
    <Grid container className={classes.actionContainer} xs={6} direction="column">
        <Grid item container className={classes.spaceMD}>
            <ProviderOptions/>  
            <EmailDataForm subjectLabel="" textLabel="Add an operator script for a call here:"/>                
        </Grid>
    </Grid>
    )
}

const DirectMail = () => {
    const classes = useStyles()

    return (
    <Grid container className={classes.actionContainer} xs={6} direction="column">
        <Grid item container className={classes.spaceMD}>
            <ProviderOptions/>  
            <TemplatePicker templates={directTemplates}/>  
            <EmailDataForm subjectLabel="" textLabel="Add a text here:"/>                            
        </Grid>
    </Grid>
    )
}

const DoNotEngage = () => {
    const classes = useStyles()

    return (
    <Grid container className={classes.actionContainer} xs={6} direction="column">
        <Grid item container>
            <Typography className={classes.actionDesc}>
                Selecting the "Do Not Engage" action for a list of prospects means that these individuals will not be targeted by any campaigns.
                <br/>
                <br/>
                This option serves as an exclusion setting, preventing the assigned prospects from receiving any communications or engagements through campaigns. Once set, prospects in this category are barred from assignment to any other campaigns, ensuring they are excluded from all further engagement initiatives.
            </Typography>                 
        </Grid>
    </Grid>
    )
}

const getActionComponent = (scenario) => {
    switch (scenario) {
      case "Send an Email":
        return <SendEmail/>

      case "Schedule a Meeting":
        return <ScheduleMeeting/>

      case "Call Policyholder":
        return <CallPolicyholder/>

      case "Send Direct Mail":
        return <DirectMail/>
        
      case "Do Not Engage":
        return <DoNotEngage/>
    
      default:
        return ''
    }
}

const CampaignStep1 = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const campaignData = useSelector(selectCampaignData)
    const { campaignName, campaignAction, campaignDescription, campaignProspects, dateStart, dateEnd, color, error } = campaignData
    const campaignId = useSelector(selectCampaignId)
    const [isValidDate, setIsValidDate] = useState(true);

    const handleChangeName = (name) => {
        dispatch(setError(false))
        dispatch(setCampaignName(name))
    }

    const handleChangeDescription = (text) => {
        dispatch(setError(false))
        dispatch(setCampaignDescription(text))
    }
    
    const handleScenarioChange = (value) => {
        dispatch(setCampaignAction(value))
    }

    const handleCampaignColorChange = (event) => {
        dispatch(setColor(event.target.value))
    }
    const handleStartDateChange = (value) => {
        const dateUI = new Date(value)
        const monthprefix = dateUI.getMonth() + 1 < 10 ? "0" : ""
        const dayprefix = dateUI.getDate() < 10 ? "0" : ""
        const dateString = monthprefix + (dateUI.getMonth() + 1) + "/" + dayprefix + dateUI.getDate() + "/" + dateUI.getFullYear()
        dispatch(setDateStart(dateString))
        if(dateEnd) {
            const [month, day, year] = dateEnd.split("/")
            const stampEnd = new Date(+year, month-1, +day).getTime()
            setIsValidDate(stampEnd >= dateUI.getTime())
        }
    }
    const handleEndDateChange = (value) => {
        const dateUI = new Date(value)
        const monthprefix = dateUI.getMonth() + 1 < 10 ? "0" : ""
        const dayprefix = dateUI.getDate() < 10 ? "0" : ""
        const dateString = monthprefix + (dateUI.getMonth() + 1) + "/" + dayprefix + dateUI.getDate() + "/" + dateUI.getFullYear()
        dispatch(setDateEnd(dateString))
        const [month, day, year] = dateStart.split("/")
        const stampStart = new Date(+year, month-1, +day).getTime()
        setIsValidDate(dateUI.getTime() >= stampStart)
    }  

    return (
    <Grid container xs={12}>
            <Grid container xs={6} className={classes.paramsContainer} direction="column"> 
                <Grid item container>
                        <Grid item container>
                            <label className={classes.inputTitle}>Campaign Name</label>                         
                        </Grid>
                        <Grid item container>                             
                            <div className={classes.customInput}>
                                    <FormInput
                                            error={error}
                                            type="text"
                                            data-testid="projectNameInput" 
                                            id="projectNameInput" 
                                            value={campaignName}
                                            style={{height:'100%',padding:'0',width:'100%', border:'none', outline:'none'}}
                                            onChange={(event) => handleChangeName(event.target.value)}
                                            placeholder="Please enter a name for the current campaign"
                                            autoFocus
                                            validators={['required', 'matchRegexp:^(?=[a-zA-Z0-9!@#$%^&*?():;}{~`]).{1,50}$']}
                                            errorMessages={['this field is required', 'Please enter a valid name']}
                                            />
                                    <div style={{width:'20px', display:'flex', alignItems:'center'}}>
                                    </div>
                            </div>                              
                    </Grid>

                </Grid>
                <Grid item container>
                    <Grid item container>
                                    <label className={classes.inputTitle}>Campaign Description</label>
                    </Grid>
                    <Grid item container>
                            <div className={classes.customInput}>
                                    
                                    <div className={classes.customNameInput} style={{height:'unset', minHeight: 92, paddingRight: 0}}>
                                        <textarea
                                            data-testid="projectDescInput" 
                                            id="projectDescInput" 
                                            type=" text"
                                            value={campaignDescription}
                                            style={{height:'100%',  width:'100%', border:'none', outline:'none'}} 
                                            placeholder="Please enter a description for the current campaign" 
                                            onChange={(e) => handleChangeDescription(e.target.value)}/>
                                            
                                    </div>
                            </div>
                    </Grid>
                </Grid>

                <Grid item container>                            
                                 {/* !history.location.search?.replace("?run_ids=", "")?.includes(",") && 
                                 <Typography className={classes.prospectsLink}>View Prospects (<NumberFormat thousandSeparator={true} displayType={'text'} value={campaignProspects} />)</Typography>  
                                    <Link 
                                    to={`/run?${history.location.search?.replace("?run_ids=", "")}`}
                                    >
                                        <Typography className={classes.prospectsLink}>View Prospects (<NumberFormat thousandSeparator={true} displayType={'text'} value={campaignProspects} />)</Typography>           
                                    </Link> }                      */}
                                                          
                                {!history.location.search?.replace("?run_ids=", "")?.includes(",") && 
                                <Typography className={classes.prospectsLink}>View Prospects (<NumberFormat thousandSeparator={true} displayType={'text'} value={campaignProspects} />)</Typography>
                                }
                </Grid>


                <Grid item container>
                                <FormControl>
                                    <Typography className={classes.inputTitle}>Campaign Color:</Typography>
                                    <ColorPicker handleChangeColor={handleCampaignColorChange} value={color}/>
                                </FormControl>                                 
                </Grid>

                <Grid item container>
                        <Grid item container xs={12} direction="row" justifyContent="center" spacing={1}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                    
                                <DatePicker
                                            label="Start Date"
                                            mask="__/__/____"
                                            displayStaticWrapperAs="desktop"
                                            value={dateStart}
                                            onChange={(NewValue) => handleStartDateChange(NewValue)}
                                            PopperProps={{
                                            placement: "bottom-end"
                                            }}
                                            renderInput={({ inputRef, inputProps, InputProps}) => (
                                                <Box>
                                                    <Typography className={classes.inputTitle}>Start Date</Typography>                                    
                                                    <div className={classes.customNameInput} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField className={classes.dateInput} style={{height:'100%',padding:'0',width:'100%', border:'none', outline:'none', justifyContent:'center'}} ref={inputRef} {...inputProps} disabled={campaignId ? true : false} />
                                                        {InputProps?.endAdornment}
                                                    </div>
                                                </Box>
                                            )}/>
                                </LocalizationProvider>    
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                    
                                <DatePicker
                                            label="End Date"
                                            mask="__/__/____"
                                            displayStaticWrapperAs="desktop"
                                            value={dateEnd}
                                            onChange={(NewValue) => handleEndDateChange(NewValue)}
                                            renderInput={({ inputRef, inputProps, InputProps}) => (
                                                <Box>
                                                    <Typography className={classes.inputTitle}>End Date</Typography>
                                                    <div className={classes.customNameInput} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField className={classes.dateInput} style={{height:'100%',padding:'0',width:'100%', border:'none', outline:'none', justifyContent:'center'}} ref={inputRef} {...inputProps}/>
                                                        {InputProps?.endAdornment}
                                                    </div>
                                                </Box>
                                            )}/>
                                </LocalizationProvider>    
                                {!isValidDate && <Alert severity="error">
                                                Campaign start date can not be before end date
                                            </Alert>
                                }
                            </Grid>
                        </Grid> 
                </Grid>
                      
                <Grid item container>
                    <Typography className={classes.inputTitle}>Campaign Action</Typography>
                </Grid>
                <Grid item container direction="row" id="Campaign Action" className={classes.space}>
                    <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleScenarioChange("Send an Email")}>
                        <Card className={clsx(classes.newCampaignCard, campaignAction === "Send an Email" ? classes.cardActive : '')}>
                            Send an Email
                        </Card>
                    </Grid>
                    <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleScenarioChange("Schedule a Meeting")}>
                        <Card className={clsx(classes.newCampaignCard, campaignAction === "Schedule a Meeting" ? classes.cardActive : '')}>
                            Schedule a Meeting
                        </Card>
                    </Grid>
                    <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleScenarioChange("Call Policyholder")}>
                        <Card className={clsx(classes.newCampaignCard, campaignAction === "Call Policyholder" ? classes.cardActive : '')}>
                            Call Policyholder
                        </Card>
                    </Grid>
                    <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleScenarioChange("Send Direct Mail")}>
                        <Card className={clsx(classes.newCampaignCard, campaignAction === "Send Direct Mail" ? classes.cardActive : '')}>
                            Send Direct Mail
                        </Card>
                    </Grid>
                    <Grid item container xs={4} className={classes.newCampaignCardContainer} onClick={() => handleScenarioChange("Do Not Engage")}>
                        <Card className={clsx(classes.newCampaignCard, campaignAction === "Do Not Engage" ? classes.cardActive : '')}>
                            Do Not Engage
                        </Card>
                    </Grid>                         
                </Grid>
               
            </Grid>        
            {getActionComponent(campaignAction)}

    </Grid>
    )
}

export default CampaignStep1