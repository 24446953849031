import React, {useEffect, useMemo, useState} from "react";
import PortfolioEstimatesModal from "../../../Components/Demo/PortfolioEstimatesModal/PortfolioEstimatesModal";
import {useHistory} from "react-router-dom";
import Table from "../../../Components/Demo/Table/Table";
import Arrow from "../../../Assets/icon-arrow-right.svg";
import UIDistribution, {colorsDefault} from "../../../Components/Demo/Charts/UIDestribution/UIDestribution";
import {nFormatter} from "../../../global/helpers";
import {UIViewButton} from "../Demo";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../../../Components/Demo/Header/styles";
import ArrowLeft from '../../../Assets/icon-arrow-left.svg'
import TextField from "@mui/material/TextField";
import {useCalculations} from "../../../Components/Demo/useCalculations/useCalculations";
import DemoLayout from "../../../Components/Demo/DemoLayout";

const UIButton = styled(Button)({
    ...basicButtonStyles,
    width: '200px',
    fontSize: '16px',
    padding: '10px 19px',
    maxWidth: '240px',
    borderRadius: '10px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    height: '50px',
    '&:hover': {
        opacity: '0.9',
        backgroundColor: '#363ED3',
        borderColor: '#FFF',
        boxShadow: 'none',
    },
});

const UITestDataButton = styled(Button)({
    ...basicButtonStyles,
    fontSize: 16,
    padding: '25px 0',
    width: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    borderColor: '#363ED3',
    color: '#363ED3',
    '&:hover': {
        opacity: '0.9',
        backgroundColor: 'transparent',
        borderColor: '#363ED3',
        boxShadow: 'none',
    },
});

const lapseContainer = {
    border: '1px solid rgba(106, 108, 113, 0.1)',
    borderRadius: '8px',
    padding: '13px 15px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 450,
    width: '100%',
    marginBottom: 40,
}

const lapseLabel = {
    marginRight: '50px',
    fontWeight: 500,
    fontSize: '16px',
    color: '#232033',
    width: '50%',
}

const defaultPiesData = [
    { name: "Term", premium: '1000', policies: '17197' },
    { name: "Permanent", premium: '765', policies: '11876' },
];

const DemoPortfolioEstimates = () => {
  const history = useHistory();

    const [modalOpenSettings, setModalOpenSettings] = useState(undefined);
    const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

    const [lapseRate, setLapseRate] = useState('');
    const [upsideLevel, setUpsideLevel] = useState(0);

    const { calculatePies } = useCalculations(Number(lapseRate) / 100)

    const [inputsData, setInputsData] = useState(
        [
            { name: "Term", premium: '', policies: '' },
            { name: "Permanent", premium: '', policies: '' },
        ]
    );

    const [inputsUpsideData, setInputsUpsideData] = useState(
        [
            { name: "Term", premium: 0, policies: 0 },
            { name: "Permanent", premium: 0, policies: 0 },
        ]
    );

    const handleInputsChange = (name, field, value) => {
        const state = inputsData.map((item) => {
            if (item.name === name) {
                item[field] = value
            }
            return item
        })
        setInputsData(state)
    }

    const smallPieData = useMemo(() => {
        const data = open ? defaultPiesData : inputsData.map(item => ({
            name: item.name,
            policies: item.policies,
            premium: calculatePies(item).smallPie
        }))
        const total = data.reduce((acc, cur) => {
            acc = acc + +cur.premium;
            return acc;
        }, 0);
        return {
            data,
            total
        }
    }, [inputsData, inputsUpsideData]);

    const isFormEmpty = useMemo(() => {
        return inputsData.filter(item => item.premium === '').length > 0;
    } ,[inputsData])

    const bigPieData = useMemo(() => {
        const data = open ? defaultPiesData : inputsUpsideData
        const total = data.reduce((acc, cur) => {
            acc = acc + +cur.premium;
            return acc;
        }, 0);
        return {
            data,
            total
        }
    }, [inputsUpsideData]);

    useEffect(() => {
        if (open) return;
        const upsideInfo = inputsData.map(item => ({
            name: item.name,
            policies: item.policies,
            premium: calculatePies(item).bigPie
        }))
        setInputsUpsideData(upsideInfo)
    }, [open, inputsData, lapseRate])

    useEffect(() => {
        !isFormEmpty && setUpsideLevel(nFormatter(bigPieData.total / smallPieData.total, 2))
    }, [isFormEmpty, bigPieData, smallPieData])

    const renderPies = () => {
        if (isFormEmpty) return;
        return (
            <>
                <div className="demoCharts">
                    <div className="demoCharts-item small">
                        <UIDistribution
                            id="small"
                            paddingRight={0}
                            chartData={smallPieData.data}
                            showTable={false}
                            showLegend
                            categoryField="name"
                            valueField="premium"
                            allowDefaultSorting={false}
                            totalText={<>Current <br /> Premium</>}
                            totalValue={`$${nFormatter(smallPieData.total, 2).toUpperCase()}`}
                        />
                    </div>
                    <div className="betweenCharts">
                        <p><strong>{upsideLevel}x</strong></p>
                        <img src={Arrow} alt="Arrow" />
                    </div>
                    <div className="demoCharts-item big">
                        <UIDistribution
                            id="big"
                            paddingRight={0}
                            chartData={bigPieData.data}
                            showTable={false}
                            totalText={"Premium Potential"}
                            categoryField="name"
                            valueField="premium"
                            totalValue={`$${nFormatter(bigPieData.total, 2).toUpperCase()}`}
                            allowDefaultSorting={false}
                        />
                    </div>
                </div>
                <div className="chartLegend">
                    {smallPieData.data.map(({ name }, index) => (
                        <div key={index} className="chartLegend-item">
                            <div className="chartLegend-colorIndicator" style={{ backgroundColor: colorsDefault[index] }} />
                            <p>{name}</p>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    return (
    <>
        <PortfolioEstimatesModal
            isOpen={open}
            onClose={handleClose}
            data={inputsData}
            onInputChange={handleInputsChange}
            lapseRate={lapseRate}
            handleLapseRate={setLapseRate}
        />
        <DemoLayout
            pageTitleElement={<>
                <UIButton
                    onClick={() => history.push('/demo')}
                >
                    <img src={ArrowLeft} alt="back" style={{ marginRight: 20 }} />
                    Back to Results
                </UIButton>
                <h1>Portfolio Estimates</h1>
            </>}
            modalInfo={modalOpenSettings}
            onModalClose={(defaultValues) => setModalOpenSettings(defaultValues)}
            wrapped={false}
        >
            <div>
                <div style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#232033',
                    marginTop: '60px',
                    marginBottom: '20px',
                    paddingLeft: '60px',
                }}>Please Fill Out Requested Fields</div>
                <div className="tableContainer">
                    <Table
                        tableData={inputsData}
                        premiumField="premium"
                        premiumFieldName="Annual Premium"
                        inputsEnabled
                        onInputsChange={handleInputsChange}
                        numbersRounding={2}
                        backgroundColor="#EBEBEB"
                    />
                    <div className="betweenCharts">
                        <p><strong>{upsideLevel}%</strong></p>
                        <img src={Arrow} alt="Arrow" />
                    </div>
                    <Table
                        tableData={inputsUpsideData}
                        premiumField="premium"
                        premiumFieldName="Annual Premium Potential  "
                        numbersRounding={2}
                    />
                </div>
                <div style={lapseContainer}>
                    <p style={lapseLabel}>Annual Lapse Rate</p>
                    <TextField
                        variant="outlined"
                        value={lapseRate}
                        onChange={(e) => setLapseRate(e.target.value)}
                        placeholder="0"
                        type="number"
                    />
                </div>
                <div className="center" style={{ marginBottom: 80 }}>
                    <UITestDataButton
                        onClick={() => setModalOpenSettings({
                            name: 'contact',
                            value: 'To test the campaign with this data, please contact our sales team.'
                        })}
                    >Test with My Data</UITestDataButton>
                    <UIViewButton
                        onClick={() => history.push('/demo-opportunities')}
                    >
                        View Details</UIViewButton>
                </div>
                {renderPies()}
            </div>
        </DemoLayout>
    </>
  )
};

export default DemoPortfolioEstimates;
