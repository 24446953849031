import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as AtidotText } from "../../Assets/icon-atidot-text.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearResetCode, selectUserName, setResetCode } from "../Login/LoginSlice";
import CodeConfirm from "../../Components/Forms/CodeConfirm/CodeConfirm";
import { encryptData } from "../CreateCampaign/helpers";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";

const ResetCode = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [alertMessage, setAlertMessage] = useState("")
    const userName = useSelector(selectUserName)

    function handleConfirm(code) {
        setAlertMessage("")
        dispatch(setResetCode(code))
        localStorage.setItem("resetCode", encryptData(code))
        history.push("/forgotpassword")
    }
    
    const handleResendCode = (callback) => {
        axios.get(
            process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${userName}/password/reset/code`
        )
            .then(() => {
                setAlertMessage("")
                dispatch(clearResetCode())
                callback()
                const date = new Date();
                history.push("/confirmreset?"+date.getTime())
            })
            .catch((err) => {
                setAlertMessage("Previous code has not expired yet!")
            })
    }


    return (
        <LoginContainer>             
            <Grid container direction="column" justifyContent="center" alignContent="center">
                    <Grid align="center" item>
                        <AtidotText/>
                    </Grid>  
                    <CodeConfirm
                        onConfirm={handleConfirm}
                        onResend={handleResendCode}
                        errorMessage={alertMessage}
                        timeToExpire={(date) => date.getMinutes() < 30}
                        codeLength={4}
                        confirmText="Continue"/>  
            </Grid>
        </LoginContainer>  
    )
}

export default ResetCode