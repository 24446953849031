
import './DistributionMap.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setUIMapFilter } from '../ChartsSlice';
import { parseJwt } from '../../../../global/helpers';
import NoDataIndicator from '../NoDataIndicator/NoDataIndicator';

am4core.useTheme(am4themes_animated);
am4core.options.deferredDelay = 600;

const mapChartConfigByISOCode = {
  "US": { name: "usa", regionDatalink: "", type: "states", countyKeyId: "", projection: new am4maps.projections.AlbersUsa()},
  "IL": { name: "usa", regionDatalink: "", type: "states", countyKeyId: "", projection: new am4maps.projections.AlbersUsa()},
  "GB": { name: "ukCounties", regionDatalink: "ukCounties", type: "counties", countyKeyId: "id", projection: new am4maps.projections.Miller()},
  "FR": { name: "france", regionDatalink: "franceDepartments", type: "departments", countyKeyId: "ID_REGION", projection: new am4maps.projections.Miller()},
  "ES": { name: "spain", regionDatalink: "spainProvinces", type: "provinces", countyKeyId: "COMMUNITY", projection: new am4maps.projections.Miller(), zoomDefault: { 
    zoomLevel: 1.495839001190856, 
    homeGeoPoint: {
      latitude: 40.07184845920223,
      longitude: -4.126429657347824
    }
  }}
}

export default function DistiributionMap({ paddingRight, mapData, mapDataByCounties, title, currency, sm = false, hasData = true}) {
  const dispatch= useDispatch()
  const chart = useRef(null);
  let mapImports={}

  React.useEffect(()=>{
    mapImports={}
  },[])

  useLayoutEffect(() => {
    const countryCode = parseJwt(localStorage.getItem("token"))["country_code"]
    const mapConfig = mapChartConfigByISOCode[countryCode]
    let total = 0
    mapData.forEach((item) => {
      if(item?.value > 0) total =+ item.value
    })
    let x = am4core.create("DistributionMapDiv", am4maps.MapChart);
    import("@amcharts/amcharts4-geodata/" + mapConfig.name + "Low")
      .then((module) => {
        x.geodata = module.default
      })
    x.projection = mapConfig.projection;

    var polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;

    polygonSeries.data = mapData

    let heatLegend = x.createChild(am4maps.HeatLegend);
    heatLegend.id = "heatLegend";
    heatLegend.series = polygonSeries;
    heatLegend.align = "left";
    heatLegend.height = am4core.percent(60);
    heatLegend.orientation = "vertical";
    heatLegend.valign = "middle";
    heatLegend.marginRight = am4core.percent(4);
    var minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.label.horizontalCenter = "left";
   

    var maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.label.horizontalCenter = "right";
   

    polygonSeries.events.on("datavalidated", function(ev) {
      let heatLegend = ev.target.map.getKey("heatLegend");
    
      let min = heatLegend.series.dataItem.values.value.low;
      let minRange = heatLegend.valueAxis.axisRanges.getIndex(0);
      minRange.value = min;
      minRange.label.text = "No Customers";
    
      let max = heatLegend.series.dataItem.values.value.high;
      let maxRange = heatLegend.valueAxis.axisRanges.getIndex(1);
      maxRange.value = max;
      maxRange.label.text = total > 0 && max > min ? "Most Customers" : '';
    });
   
    heatLegend.valueAxis.renderer.dx = 0;
    
    heatLegend.valueAxis.fontSize = 9;

    heatLegend.valueAxis.numberFormatter.numberFormat = '#,###.';

    heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
      return "";
    });
    
    if (sm) {
      heatLegend.hide()
    }

   
    // Add zoomout button
    var back = x.createChild(am4core.ZoomOutButton);
    back.align = "right";
    back.hide();
    back.events.on("hit", function(ev) {
    countySeries.hide();
    x.goHome();
    dispatch(setUIMapFilter(""))
    polygonSeries.show()
    back.hide();
    })

    // County series
    var countySeries = x.series.push(new am4maps.MapPolygonSeries());
    countySeries.mapPolygons.template.tooltipText = "{name}";
    countySeries.mapPolygons.template.numberFormatter.numberFormat = "#,###.";
    let countyTmp = []
    if(mapConfig.type !== "states") {
      import('@amcharts/amcharts4-geodata/'+mapConfig.regionDatalink + "Low")
      .then((module) => {
        countyTmp = module.default
        countySeries.geodata = module.default
      })
    }
    countySeries.hide();
    if(mapConfig.zoomDefault !== undefined) {
      x.homeZoomLevel = mapConfig.zoomDefault.zoomLevel
      x.homeGeoPoint = mapConfig.zoomDefault.homeGeoPoint
    }

    var countyTemplate = countySeries.mapPolygons.template;
    countyTemplate.tooltipText = "{name}: " + currency + "{value}";
    countyTemplate.numberFormatter.numberFormat = "#,###.";
    countyTemplate.nonScalingStroke = true;
    countyTemplate.strokeWidth = 0.5;
    
    polygonSeries.mapPolygons.template.events.on("over", function (event) {
      handleHover(event.target);
    })

    polygonSeries.mapPolygons.template.events.on("hit", function (event) {
        handleHover(event.target);
        var chart = event.target.series.chart;
        if(mapConfig.type === "states") {
          var stateId = event.target.dataItem.dataContext.id.split("-")[1].toLowerCase()
          chart.zoomToMapObject(event.target);
          polygonSeries.hide()
          if(!mapImports[stateId]){
            import('@amcharts/amcharts4-geodata/region/'+mapConfig.name + "/" + stateId + "Low")
              .then(module => {
                mapImports[stateId]= module
                countySeries.geodata = module.default;

                const data = [] 
                mapDataByCounties.forEach((county) => {
                  const countyData = countySeries.geodata?.features.find(({ properties }) => properties.name === county.name)
                  if (countyData) {
                    data.push({...countyData, value: county.value})
                  }
                })
                countySeries.data = data
                
              
                countySeries.heatRules.push({
                  property: "fill",
                  target: countySeries.mapPolygons.template,
                  min: am4core.color("#242a8c").lighten(0.7),
                  max: am4core.color("#242a8c").lighten(0),
                });
              
                countySeries.show();
            })
          }else{
            const data = [] 
            mapDataByCounties.forEach((county) => {
              const countyData = countySeries.geodata?.features.find(({ properties }) => properties.name === county.name)
              if (countyData) {
                data.push({...countyData, value: county.value})
              }
            })
            countySeries.data = data
          
            countySeries.geodata = mapImports[stateId].default;

            countySeries.heatRules.push({
              property: "fill",
              target: countySeries.mapPolygons.template,
              min: am4core.color("#242a8c").lighten(0.7),
              max: am4core.color("#242a8c").lighten(0),
            });
          
            countySeries.show();
            
          }
          back.show();
          dispatch(setUIMapFilter(stateId)) 
      } else {
        const countyId = event.target.dataItem.dataContext.id
        countySeries.geodata = {
          "type": 'FeatureCollection', "features": countyTmp?.features.filter((item) => item.properties[mapConfig.countyKeyId] === countyId)
        }
        chart.zoomToMapObject(event.target);
        const data = [] 
        mapDataByCounties.forEach((county) => {
          const countyData = countyTmp?.features.find(({ properties }) => properties.name === county.name)
          if (countyData !== undefined) {
            data.push({...countyData, value: county.value})
          }
        })
        countySeries.data = data
        polygonSeries.hide()
        heatLegend.series = countySeries

        countySeries.heatRules.push({
          property: "fill",
          target: countySeries.mapPolygons.template,
          min: am4core.color("#242a8c").lighten(0.7),
          max: am4core.color("#242a8c").lighten(0),
        });

        countySeries.show();
        back.show();
      }
    })

    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
      }
      else {
        heatLegend.valueAxis.hideTooltip();
      }
    
    }
    polygonSeries.mapPolygons.template.events.on("out", function (event) {
      heatLegend.valueAxis.hideTooltip();
    })

   

    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: am4core.color("#242a8c").lighten(0.7),
      max: am4core.color("#242a8c").lighten(0),
    });

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: " + currency + "{value}";
    polygonTemplate.numberFormatter.numberFormat = "#,###.";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#3c5bdc")

    x.series.push(polygonSeries);

    x.paddingRight =  20;   

    let topContainer = x.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.zIndex = 2;
    topContainer.width = am4core.percent(100);
    
    let axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.text = title;
    axisTitle.fontWeight = 700;
    axisTitle.fontFamily = "Roboto";
    axisTitle.align = "left";
    axisTitle.fontSize=16
    axisTitle.position = "absolute"

    x.events.on("ready", function () {
      x.goHome();
    })
    
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [mapData, mapDataByCounties, dispatch]);

   
   useLayoutEffect(() => {
    chart.current.paddingRight= paddingRight
}, [paddingRight]);
  
  return (
    <div className="DistributionMap" style={sm ? { height: "unset", margin: 0, padding: 0 } : {}}>
        {!hasData && <NoDataIndicator/>}
        <div id="DistributionMapDiv" style={sm ? { height: "unset", opacity: hasData ? 1 : .2 } : {}}></div>
        <div className="BlockAMCHARTlogo"></div>
    </div>
  );
}
