import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CsvBuilder } from 'filefy';

// MUI
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';

// Components
import UIDistribution from './Charts/UIDistribution/UIDistribution';
import DistiributionMap from './Charts/DistributionMap/DsitributionMap';
import UIbyState from './Charts/UIbyState/UIbyState';
import UIbyGender from './Charts/UIbyGender/UIbyGender';
import UIbyIncome from './Charts/UIbyIncome/UIbyIncome';
import UIbyAge from './Charts/UIbyAge/UIbyAge';

// Api services
import RunApiService from './RunService';
import appApiService from '../../AppService';

// Redux
import { resetFilters, selectCharts, setOptimalTreshPrecision, setPrecisionLevel, setUILimits, setUILimitsMarks } from './Charts/ChartsSlice';
import { setUnderinsured, setLoaded, selectRun, setFaceAmountRanges, setZeroValuesByGender, setHasLeads,  setProjectName, setRunName, setPolicies, setDate, setFiltersConfig, setImpact, setPotentialPremium, setPoliciesActual, setLapseRate, setDuration, setProductValues, setBehavioralClusterValues, setEffectiveDate, setTotal, setHitRate, setModel, setStatusValues, setPrecisionMeta, setPoliciesNumber, setMarketLag, setStateValues, setDurationValues } from './RunSlice';
import { selectColumns, setColumns, setRunLeads } from "../../AppSlice";

// Common
import { deepEqual, parseJwt, nFormatter, getCountryCurrencySymbol, validateValue, scrollTo, addRealMonths } from '../../global/helpers';
import { checkData } from './Functions';
import clsx from 'clsx';
import TableContainer from '../Prospects/TableContainer/TableContainer';
import { defaultColumnsCluster, defaultColumnsLife, defaultColumnsLifeWL, defaultColumnsNewLapse, defaultColumnsRunAnnuities, frozenGridColumnsAnnuities, frozenGridColumnsCluster, frozenGridFieldsRunAnnuities, frozenGridFieldsRunLife, frozenGridFieldsRunUIAnnuities, frozenGridFieldsRunUILife, frozenGridRunColumnsPositions, frozenGridRunColumnsPositionsAnnuities, frozenGridRunUIColumnsPositions, frozenGridRunUIColumnsPositionsAnnuities, frozenRunGridColumnsLife, headerRunKeysAnnuities, headerRunKeysLife, lapseOnlyFields, priorityMaping, prospectsLifeHeaderKeys, reportTabsAnnuities, reportTabsCluster, reportTabsLife, runAnnuitiesDataKeysLapse, runAnnuitiesDataKeysUI, runAnnuitiesHeaderKeysLapse, runAnnuitiesHeaderKeysUI, runAnnuitiesTableHeaders, runClusterDataKeys, runClusterTableHeaders, runLifeDataKeys, runLifeTableHeaders, sortKeysRunAnnuities, sortKeysRunAnnuitiesUI, sortKeysRunLife, usStatesData } from '../../global/constants';
import StoryIcon from '../Book/StoryIcon/StoryIcon';
import PriorityIndicator from '../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator';
import { selectFilters } from '../Filters/FiltersSlice';
import HeaderRun from '../Headers/HeaderRun';
import moment from 'moment';
import { setSlice } from '../Tables/TableSlice';
import UIbyAgent from './Charts/UIbyAgent/UIbyAgent';
import DetailsPopup from '../Popups/DetailsPopup/DetailsPopup';

const useStyles = makeStyles(() => ({
  ChartCointainer: {
    borderRadius: 12,
    border: "solid 1px #ecf1f9",
    backgroundColor: "#fff",
    marginBottom: 10,
    marginLeft: 10

  },
  tableHeader: {
    borderRadius: 8,
    backgroundColor: "#eef0f4"
  },
  detailsStyle: {
    fontSize: 16,
    color: "#6a6c71",
    marginBottom: 22,
    marginTop: 47
  },
  tableStyle: {
    paddingLeft: 18,
    paddingRight: 24
  },
  tableFirstCell: {
    borderBottom: "none !important",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    color: "#312e40 !important",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: "16px !important",
    paddingBottom: "15px !important",
    minWidth:"120px !important",
  },
  tableLastCell: {
    borderBottom: "none !important",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    color: "#312e40 !important",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    paddingTop: "16px !important",
    paddingBottom: "15px !important",
    
  },
  tableCell: {
    borderBottom: "none !important",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
    color: "#312e40 !important",
    fontWeight: "500 !important",
    paddingTop: "16px !important",
    paddingBottom: "15px !important",
    minWidth:"100px !important",
    "MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-icon":{
      position: "absolute",
    }
  },
  sortLabel: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    textTransform: "capitalize"
  },
  TableCellBody: {
    border: "none",
    borderBottom: "solid 1px #eef0f4 !important",
    fontFamily: "Inter !important",
    fontSize: "13px !important",
    paddingTop: "24px !important",
    paddingBottom: "21px !important",
    minWidth:"100px !important",
    textAlign: "left!important"
  },
  tableTitle: {
    marginTop: 24,
    marginLeft: 32.1,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 600
  },
  tableRowSecondary: {
    background: '#F8F8FA'
  },
  exportToCSVIcon: {
    color: '#d82832',
  },
  exportToCSVButtonContainer: {
    margin: 10,
    border: '1px solid #d9e1ee',
    borderRadius: 12,
  },
  exportToCSVButton: {
    backgroundColor: '#fff',
    borderRadius: 12,
    textTransform: 'capitalize',
    fontSize: 12

  },
  marginRightButton: {
    marginRight: "1%"
  },
  paddingRightButton: {
    paddingRight: "3%"
  },
  headersIcon: {
    fontSize: 13
  },
  boxIcon:{
    display:'block',
    width: 18,
    height: 18,
    marginLeft:4,
    backgroundColor:'transparent',
  },
  storiesCollapse: {
    width: "100%",
    display: "flex",
    padding: "5px 20px",
  },  
  storiesItem: {
    width: "100%",
    maxWidth: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderLeft: "1px solid #d8d8d8",
    height: 103,
    paddingLeft: "22px"
  },   
  storiesFirstItem: {
    borderLeft: "none",
    paddingLeft: "0",
    paddingRight: "22px",
  },   
  storiesLastItem: {
    borderRight: "1px solid #d8d8d8",
    paddingRight: "22px",
  }, 
  storiesData: {
    display: "flex",
    justifyContent: "space-between",
  }, 
  storiesDataLabel: {
    opacity: .7,
  }, 
  storiesDataValue: {
    fontSize: ".9rem",
    fontWeight: 500,
  },
  storiesDataBody: {
    padding: "16px 0!important"
  }, 
  eventIcon: {
    backgroundColor: "#dbe8fe",
  },
  cashIcon: {
    backgroundColor: "rgba(241, 95, 107, 0.2)",
  },
  policyIcon: {
    backgroundColor: "#fef9e6",
  },
  ownerIcon: {
    backgroundColor: "#def9e6",
  },
  buttonCampaign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '10px 20px',
    border: 'solid 1px #d9e1ee',
    color: '#171b59',
    borderRadius: 12,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    "@media  (maxWidth: 1500px)": {
      display: 'none',
    },
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'none',
    width: "100%",
    height: 36,
    marginLeft: 10
  },
  main: {
      justifyContent: "space-between",
      background: "#FFFFFF",
      border: "1px solid #EBEBEB",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
      borderRadius: 25,
      borderTopLeftRadius: 0,
      padding: "24px",
      "& .MuiGrid-grid-xs-5": {
        maxWidth: "48%",
        flexBasis: "48%",
      },
      "& .MuiGrid-grid-xs-4": {
        maxWidth: "31%",
        flexBasis: "31%",
      }
  },
  tab: {
      background: '#FFFFFF',
      border: '1px solid #EBEBEB',
      borderBottom: 'none',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      width: 235,
      height: 38,
      textAlign: "center",
      paddingTop: 12,
      color: '#6A6C71',
      marginRight: 5,
      cursor: "pointer",
      "& .MuiTypography-root": {
          fontFamily: "Strawford!important",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "19px"
      }
  },
  tabActive: {
      color: '#363ED3',
      height: 39,
      position: 'relative',
      bottom: -1,
      zIndex: 900
  },
  sticky: {
    position: "sticky",
    background: "#FFF",
  },
}));

const ApiService = new RunApiService()
const ApplicationApiService = new appApiService();

const Breakdown = ({ 
  data,
  emptyValues,
  type
}) => {
  const classes = useStyles()
  const currency = getCountryCurrencySymbol()
  const isCluster = type?.model?.toLowerCase() === "cluster"

  return (
    <Grid container>
              <Grid item xs={6} className={classes.ChartCointainer}>

              <DistiributionMap title={"Prospects Map"} paddingRight={10} mapData={data.mapData} mapDataByCounties={data.mapDataByCounties} hasData={emptyValues.state} currency={isCluster ? "" : currency}/>

              </Grid>
              
              <Grid item xs={5} className={classes.ChartCointainer}>
                <UIbyState title={"By State"} paddingRight={10} chartData={data.uiByStateData} hasData={emptyValues.state} currency={isCluster ? "" : currency}/>
              </Grid>

              <Grid item xs={4} className={classes.ChartCointainer}>
                
              <UIbyGender paddingRight={10} title={"By Gender"} chartData={data.uiByGenderData} hasData={emptyValues.gender} currency={isCluster ? "" : currency}/>
              </Grid>

              {!isCluster && <Grid item xs={8} className={classes.ChartCointainer}>
                
              <UIDistribution paddingRight={10} title={"By Policy Year"} chartData={data.uiDistributionData} hasData={emptyValues.duration} currency={isCluster ? "" : currency}/>
              </Grid>}

              <Grid item xs={isCluster ? 8 : 6} className={classes.ChartCointainer}>
                
                    <UIbyIncome paddingRight={10} title={"By Income Groups"} chartData={data.uiByIncomeData} hasData={emptyValues.income} currency={isCluster ? "" : currency}/>
              </Grid>

              <Grid item xs={5} className={classes.ChartCointainer}>
                <UIbyAge paddingRight={10} title={"By Age"} chartData={data.uiByAgeData} hasData={emptyValues.age} currency={isCluster ? "" : currency}/>
              </Grid>

              {isCluster && <Grid item xs={6} className={classes.ChartCointainer}>
                    <UIbyAgent id='ByStatus' paddingRight={10} title={"By Cluster"} chartData={data.uiByClusterData} hasData={emptyValues.cluster} currency={isCluster ? "" : currency} label='Cluster'/>
              </Grid>}
              
              {isCluster && <Grid item xs={12} className={classes.ChartCointainer}>
                <UIbyAgent id='ByAgency' paddingRight={10} title={"By Agency"} chartData={data.uiByPartnerData} hasData={emptyValues.partner} currency={isCluster ? "" : currency} label='Agency'/>
              </Grid>}

              <Grid item xs={6} className={classes.ChartCointainer}>
                <UIbyAgent id='ByAgentsTop' paddingRight={10} title={"By Agents: Top 5"} chartData={data.uiByTopAgentData} hasData={emptyValues.agent} currency={isCluster ? "" : currency}/>
              </Grid>
              <Grid item xs={5} className={classes.ChartCointainer}>
                <UIbyAgent id='ByAgentsBot' paddingRight={10} title={"By Agents: Bottom 5"} chartData={data.uiByBottomAgentData}  hasData={emptyValues.agent} currency={isCluster ? "" : currency}/>
              </Grid>
              <Grid item xs={6} className={classes.ChartCointainer}>
                <UIbyAgent id='ByIMOTop' paddingRight={10} title={"By IMO: Top 5"} chartData={[]} hasData={false} currency={isCluster ? "" : currency}/>
              </Grid>
              <Grid item xs={5} className={classes.ChartCointainer}>
                <UIbyAgent id='ByIMOBot' paddingRight={10} title={"By IMO: Bottom 5"} chartData={[]} hasData={false} currency={isCluster ? "" : currency}/>
              </Grid>

          </Grid>
  )
}

const Run = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const currency = getCountryCurrencySymbol()
  const rootRef = useRef(null)
  const isAnnuities = localStorage.getItem("accountType") === "annuities"
  const [ clear, setClear ] = useState({
    existing: false,
    recomended: false,
    status: false
  })
  const [queryFilters, setQueryFilters] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(null)
  const [openExportOptions, setOpenExportOptions] = useState(false)
  const [open, setOpen] = useState(true);  
  const [mapData, setMapData] = useState([])
  const [mapDataByCounties, setmapDataByCounties] = useState({})
  const [uiByStateData, setUIbyStateData] = useState([])
  const [uiByGenderData, setUIbyGenderData] = useState([])
  const [uiDistributionData, setUIDistributionData] = useState([])
  const [uiByIncomeData, setUIbyIncomeData] = useState([])
  const [uiByAgeData, setUIbyAgeData] = useState([])
  const [uiByTopAgentData, setUIbyTopAgentData] = useState([])
  const [uiByBottomAgentData, setUIbyBottomAgentData] = useState([])
  const [uiByClusterData, setUIbyClusterData] = useState([])
  const [uiByPartnerData, setUIbyPartnerData] = useState([])
  const [emptyValues, setEmptyValue] = useState({
    age: true,
    gender: true,
    state: true,
    income: true,
    duration: true,
    agent: true
  })

  const [order, setOrder] = useState("policy");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ selectedPolicy, setSelectedPolicy ] = useState(null)
  const [filtereddata, setFilteredData] = useState([])
  const [results, setResults] = useState([])
  const [isNewLapse, setIsNewLapse] = useState(null)

  const run = useSelector(selectRun)
  const charts = useSelector(selectCharts)
  const filters = useSelector(selectFilters)
  const columns = useSelector(selectColumns)
  const isLapse = run?.model?.toLowerCase()?.replace("v2", "") === "lapse"
  const isCluster = run?.model?.toLowerCase() === "cluster" || JSON.parse(sessionStorage.getItem("run_data"))?.model === "cluster" 
  const isDemo = history.location.search.includes("demo")
  const [activeTab, setActiveTab] = useState(isCluster ? "Prospects Breakdown" : isAnnuities ? "AUM Breakdown" : "Premium Breakdown");

  const tableHeaders = isCluster ? runClusterTableHeaders : 
    isAnnuities ? runAnnuitiesTableHeaders : runLifeTableHeaders
  const headersKeys =  isAnnuities ? headerRunKeysAnnuities : headerRunKeysLife
  const dataKeysAnnuities = isLapse ? runAnnuitiesDataKeysLapse : runAnnuitiesDataKeysUI
  const dataKeys = isCluster ? runClusterDataKeys : 
    isAnnuities ? dataKeysAnnuities : runLifeDataKeys
  const tabs = isCluster ? reportTabsCluster : 
    isAnnuities ? reportTabsAnnuities : reportTabsLife
  const frozenRunGridColumns = isCluster ? frozenGridColumnsCluster :
    isAnnuities ? frozenGridColumnsAnnuities : frozenRunGridColumnsLife
  const frozenGridFieldsLapse = isAnnuities ? frozenGridFieldsRunAnnuities :  frozenGridFieldsRunLife
  const frozenGridFieldsUI = isAnnuities ? frozenGridFieldsRunUIAnnuities : frozenGridFieldsRunUILife
  const frozenPositionsUI = isAnnuities ? frozenGridRunUIColumnsPositionsAnnuities : frozenGridRunUIColumnsPositions
  const frozenPositionsLapse = isAnnuities ? frozenGridRunColumnsPositionsAnnuities : frozenGridRunColumnsPositions
  const sortAnnuities = isLapse ? sortKeysRunAnnuities : sortKeysRunAnnuitiesUI
  const sortKeys = isAnnuities ? sortAnnuities : sortKeysRunLife

  const tableHeadersKeysAnnuities = isLapse ? runAnnuitiesHeaderKeysLapse : runAnnuitiesHeaderKeysUI
  const tableHeadersKeys = isAnnuities ? tableHeadersKeysAnnuities : prospectsLifeHeaderKeys
  const frozenGridFields = isLapse ? frozenGridFieldsLapse : frozenGridFieldsUI
  const filteredFrozenFields = columns ? frozenGridFields.filter((field) => columns[dataKeys[field]] && (isLapse || !["priority", "PRIORITY"].includes(field))) : []
  const frozenGridColumnsPositions = isLapse ? frozenPositionsLapse : frozenPositionsUI

  const hiddenHeaders = isCluster ? [] : ["AUM Upside", "Suggested Premium"]
  
  let countiesImports = {}

  const scrollToTop = () => {
    scrollTo(0)
  };

  const BuildData = (chartsData) => {
    let mapTemp = []
    let mapByCountiesTemp = []
    let UIbyStateTemp = []
    let UIbyGenderTemp = []
    let UIbyIncomeTemp = []
    let UIbyAgeTemp = []
    let UIbyAgentTemp = []
    let UIbyClusterTemp = []
    let UIbyPartnerTemp = []
    let UIDistributionTemp = []
    
    const contryCode = parseJwt(localStorage.getItem("token"))["country_code"]

    let totalAge = 0
    let totalGender = 0
    let totalIncome = 0
    let totalState = 0
    let totalDuration = 0
    let totalAgent = 0
    let totalPartner = 0
    let totalCluster = 0
    chartsData.age && Object.values(chartsData.age).forEach((item) => totalAge = totalAge + item)
    chartsData.gender && Object.values(chartsData.gender).forEach((item) => totalGender = totalGender + item)
    chartsData.income && Object.values(chartsData.income).forEach((item) => totalIncome = totalIncome + item)
    chartsData.duration && Object.values(chartsData.duration).forEach((item) => totalDuration = totalDuration + item)
    chartsData.agent && Object.values(chartsData.agent).forEach((item) => totalAgent = totalAgent + item)
    chartsData.partner && Object.values(chartsData.partner).forEach((item) => totalPartner = totalPartner + item)
    chartsData.cluster && Object.values(chartsData.cluster).forEach((item) => totalCluster = totalCluster + item)
    chartsData.location.states && Object.values(chartsData.location.states).forEach((item) => totalState = totalState + item)
    setEmptyValue({
      age: chartsData.age !== null || totalAge !== 0,
      gender: chartsData.gender !== null || totalGender !== 0,
      state: chartsData.location.states !== null || totalState !== 0,
      income: chartsData.income !== null || totalIncome !== 0,
      duration: chartsData.duration !== null || totalDuration !== 0,
      agent: chartsData.agent !== null || totalAgent !== 0,
      partner: chartsData.partner !== null || totalPartner !== 0,
      cluster: chartsData.cluster !== null || totalCluster !== 0,
    })
    
    //Populate By Age
    if(chartsData.age?.length > 0) {
      chartsData.age.forEach((ageItem) => {
        const entries = Object.entries(ageItem)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            const ranges = item[0].split("_")
            let id = ""
            if (ranges.length === 1) {
              id = `${item[0]}<`
            } else if (ranges[0] === '0') {
              id = `<${ranges[1]}`  
            } else {
              id = `${ranges[0]}-${ranges[1]}`
            }
            UIbyAgeTemp.push({ id, value: item[1]})
          }
        })
      })
      UIbyAgeTemp.reverse()
    } else {
      UIbyAgeTemp = []
      setUIbyAgeData(UIbyAgeTemp)
    }
          
    //Populate UI by Gender Temp Array
    if(chartsData.gender?.length > 0) {
      UIbyGenderTemp = []
      chartsData.gender.forEach((gender) => {
        const entries = Object.entries(gender)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            if (item[0] === "F") {
              UIbyGenderTemp.push({ id: "Female", value: item[1], color: "#ff656f", real: 2 })
            } else if (item[0] ===  "M") {
              UIbyGenderTemp.push({ id: "Male", value: item[1], color: "#4b54ff",real: 1 })
            }
          }
        })
      })
      dispatch(setZeroValuesByGender([...UIbyGenderTemp.filter(({value}) => !value).map(({id}) => id), "Not specified"]))
    } else {
      UIbyGenderTemp = [
        { id: "Female", value: 0, color: "#ff656f", real: 2 },
        { id: "Male", value: 0, color: "#4b54ff",real: 1 },
      ]
      setUIbyGenderData(UIbyGenderTemp)
      dispatch(setZeroValuesByGender(["Male","Female","Not specified"]))
    }

    //Populate By Duration Segment 
    if(chartsData.duration?.length > 0) {
      chartsData.duration.forEach((duration) => {
        const entries = Object.entries(duration)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            UIDistributionTemp.push({ id: item[0], value: item[1]})
          }
        })
      })
    } else {
      UIDistributionTemp = []
      setUIDistributionData(UIDistributionTemp)
    }

    //Populate By Income Groups
    if(chartsData.income?.length > 0) {
      chartsData.income.forEach((income) => {        
        const entries = Object.entries(income)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            const ranges = item[0].split("_")
            let id = "<50"
            if (ranges.length === 1 && item[0] !== "100") {
              id = `${item[0]}<`
              UIbyIncomeTemp.push({ id, value: item[1]})
            } else if (ranges[0] === '0') {
              id = `<${ranges[1]}` 
              UIbyIncomeTemp.push({ id, value: item[1]})
            } else if (ranges.length === 2) {
              id = `${ranges[0]}-${ranges[1]}`
              UIbyIncomeTemp.push({ id, value: item[1]})
            }
          }
        })
      })
      UIbyIncomeTemp = [ ...UIbyIncomeTemp, { id:"100<", value: chartsData.income[100]}]
      UIbyIncomeTemp.reverse()
    } else {
      UIbyIncomeTemp = []
      setUIbyIncomeData(UIbyIncomeTemp)
    }

    //Populate By State
    if(chartsData.location.states?.length > 0 && chartsData.location.cities?.length > 0) {
      mapTemp = []
      UIbyStateTemp = []
      chartsData.location.states.forEach((state) => {        
        const entries = Object.entries(state)
        entries.forEach((item) => {
          mapTemp.push({ id: contryCode === "IL" ? "US-"+item[0] : contryCode +"-"+item[0], value: item[1] })
          UIbyStateTemp.push({ id: item[0], value: item[1]})
        })
      })
      chartsData.location.cities.forEach((city) => {        
        const entries = Object.entries(city)
        entries.forEach((item) => {
          mapByCountiesTemp.push({ name: item[0], value: item[1]})
        })
      })
    } else {
      mapTemp = []
      UIbyStateTemp = []
      mapByCountiesTemp = []
      setUIbyStateData(UIbyStateTemp)
      setmapDataByCounties(mapByCountiesTemp)
      setMapData(mapTemp)
    }

    //Populate By Agent
    if(chartsData.agent?.length > 0) {
      chartsData.agent.forEach((agentItem) => {
        const entries = Object.entries(agentItem)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            UIbyAgentTemp.push({ id: item[0], value: item[1] })
          }
        })
      })
    } else {
      UIbyAgentTemp = []
    }

    //Populate By Partner
    if(chartsData.partner?.length > 0) {
      chartsData.partner.forEach((partnerItem) => {
        const entries = Object.entries(partnerItem)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            UIbyPartnerTemp.push({ id: item[0], value: item[1] })
          }
        })
      })
    } else {
      UIbyPartnerTemp = []
    }
    
    //Populate By Cluster
    if(chartsData.cluster?.length > 0) {
      chartsData.cluster.forEach((clusterItem) => {
        const entries = Object.entries(clusterItem)
        entries.forEach((item) => {
          if (validateValue(item[1])) {
            UIbyClusterTemp.push({ id: item[0], value: item[1] })
          }
        })
      })
    } else {
      UIbyClusterTemp = []
    }

    const resMap = Array.from(mapTemp.reduce((acc, { value, ...r }) => {
      const key = JSON.stringify(r);

      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUiByState = Array.from(UIbyStateTemp.reduce((acc, { value, ...r }) => {
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyGender = Array.from(UIbyGenderTemp.reduce((acc, { value, ...r }) => {
      const key = JSON.stringify(r.id);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyIncome = Array.from(UIbyIncomeTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r.id);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyAge = Array.from(UIbyAgeTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyAgent = Array.from(UIbyAgentTemp.reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyPartner = Array.from(UIbyPartnerTemp.reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIbyCluster = Array.from(UIbyClusterTemp.reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    const resUIDistribution = Array.from(UIDistributionTemp.sort((a, b) => a.real > b.real ? 1 : -1).reduce((acc, { value, ...r }) => {
      delete r.real
      const key = JSON.stringify(r);
      const current = acc.get(key) || { ...r, value: 0 };
      return acc.set(key, { ...current, value: current.value + parseFloat(value) });
    }, new Map()).values());

    if(checkData(chartsData)){
      setmapDataByCounties(mapByCountiesTemp)
      setMapData(resMap)
      setUIbyStateData(resUiByState)
      setUIbyGenderData(resUIbyGender)
      setUIbyIncomeData(resUIbyIncome)
      setUIbyAgeData(resUIbyAge)
      setUIbyClusterData(resUIbyCluster)
      setUIbyPartnerData(resUIbyPartner)
      setUIbyTopAgentData(
        resUIbyAgent
          .sort((a, b) => a.value > b.value ? 1 : -1)
          .slice(0, resUIbyAgent.length > 4 ? 5 : resUIbyAgent.length - 1)
      )
      setUIbyBottomAgentData(
        resUIbyAgent
          .sort((a, b) => b.value > a.value ? 1 : -1)
          .slice(0, resUIbyAgent.length > 4 ? 5 : resUIbyAgent.length - 1)
          .reverse()
      )
      setUIDistributionData(resUIDistribution)
      //handleRequestSort(1)
    }
    setOpen(false)
    

  }

  const handleRequestSort = (property) => {
    const orderBy = `${order.includes("-") ? "" : "-"}${sortKeys[property]}`
    setOrder(orderBy);
  };

  const handleChangePage = (e, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setPage(0);
    dispatch(setSlice(0));
    setRowsPerPage(parseInt(value));
  };
  
  const AssignData = (tableData, newLapseColumns = false) => {
    const Rows = tableData.map((item) => (
      isAnnuities ? 
      {
        "policy": item["policy"],
        "Policy ID": item["policy"],
        "Product Name": item["MARKET_NAME_LAG"],
        "Product Type": item["product"],
        "Age": item["age"],
        "Contract Value": item["cash_value"],
        "Surrender Value": item["CSV_LAG"],
        "Years Left in Surrender Charge": item["YRS_REMAIN_SC"],
        "Upside Story": item["story"],
        "Surrender Probability": item["PRIORITY"],
        "AUM Upside": isLapse ? item["medium_ppr"] : item["mean_premium_term"],
        "Status": item["in_campaign"] === 1 ? "In Campaign" : item["status"],
        "Opportunity Expiration Date": addRealMonths(moment(item["issue_date"]), 4).format('YYYY-MM-DD'),
      }
      : isCluster ? 
      {
        "ID": item["policy"],
        "Product Name": item["MARKET_NAME_LAG"],
        "Current Age": item["age"],
        "Date of Birth": item["dob"] ? moment(item["dob"]).format('YYYY-MM-DD') : "N/A",
        "State": item["State"],
        "City": item["City"],
        "Agency": item["partner"],
        "Group": item["Easylife Group"],
        "Income": item["per_cap_income"],
        "Investment Focus": item["inv_risk"],
        "Cluster": item["cluster"],
        "model_type": "cluster",
      }
      : newLapseColumns ? 
      {
        "policy": item["policy"],
        "Policy ID": item["policy"],
        "Product Name": item["MARKET_NAME_LAG"],
        "Product Type": item["product"],
        "Current Age": item["age"],
        "Annual Premium": item["premium"],
        "Face Amount": item["face_amount"],
        "Issue Date": item["issue_date"] ? moment(item["issue_date"]).format('YYYY-MM-DD') : "N/A",
        "Cash Value": item["cash_value"],
        "Upside Story": item["story"] ? item["story"] : item["Story"],
        "Suggested Premium": item["annualized_premium_res"],
        "Premium Sensitivity": item["annualized_premium_sensitivity_NP"],
        "Status": item["in_campaign"] === 1 ? "In Campaign" : item["status"],
        "Opportunity Expiration Date": addRealMonths(moment(item["issue_date"]), 4).format('YYYY-MM-DD'),
        "model_type": item["model_type"],
      }
      : {
        "policy": item["policy"],
        "Policy ID": item["policy"],
        "Product Name": item["MARKET_NAME_LAG"],
        "Product Type": item["product"],
        "Current Age": item["age"],
        "Annual Premium": item["premium"],
        "Face Amount": item["face_amount"],
        "Issue Date": item["issue_date"] ? moment(item["issue_date"]).format('YYYY-MM-DD') : "N/A",
        "Cash Value": item["cash_value"],
        "Upside Story": item["story"] ? item["story"] : item["Story"],
        "Premium Upside": isLapse ? item["premium"] : item["mean_premium_term"],
        "Probability to lapse": item["lapse_score"],
        "Status": item["in_campaign"] === 1 ? "In Campaign" : item["status"],
        "Opportunity Expiration Date": addRealMonths(moment(item["issue_date"]), 4).format('YYYY-MM-DD'),
        "model_type": item["model_type"],
      }
    ))
      
    dispatch(setHasLeads(Rows?.length > 0))
    setFilteredData(Rows)
    setResults(tableData.map((item) => (
      {
        ...item,
        surr_value: item.CSV_LAG,
        contract_value: item.cash_value,
        account_value: item.IAV_LAG,
        libr_status: item.RIDER_STATUS_LAG_WITH_FEE,
        libr_fee: item.CURR_LIBR_WITH_AMOUNT_lag,
        libr_payment: item.QTD_LIBR_WITHS_MONTH_CUMSUM_LAG,
        withdraw_activity: priorityMaping[item.RATIO_WITHS_LAG] ? priorityMaping[item.RATIO_WITHS_LAG] : "N/A",
        model_type: isCluster ? "cluster" : isLapse ? "lapse" : "ui",
        priority: isAnnuities ? item.priority : item.PRIORITY,
      }
    )))
  }

  const renderRow = (rowData, columnsToHide, onClick) => {
    return Object.keys(rowData)
      .filter((key) => (!columnsToHide.includes(key) || columnsToHide.length === 0) && columns[key])
      .map((key, index) => {
      return (
          <TableCell component="th" scope="row" 
          className={clsx(classes.TableCellBody, filteredFrozenFields.includes(tableHeadersKeys[key]) ? classes.sticky : '')}  key={`policy-table-cell-${page}-${index}`} align="center" style={key === "Policy ID" ? {
            fontWeight: 700,
            lineHeight: "16px",
            color: "#363ED3"
            } : filteredFrozenFields.includes(tableHeadersKeys[key]) ? {
              right: frozenGridColumnsPositions[(filteredFrozenFields.length-1) - filteredFrozenFields.indexOf(tableHeadersKeys[key])],  
              boxShadow: (filteredFrozenFields.indexOf(tableHeadersKeys[key]) === 0 || filteredFrozenFields.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
            }
            : {}}
            onClick={() => onClick(rowData)}>
            {
              key === "Upside Story"  ? rowData[key] ? <Grid item style={{ display: "flex"}} alignItems="center">
                <div style={{ minWidth: 20, marginRight: 8 }}><StoryIcon storyType={rowData["model_type"]}/></div>
                <div>{rowData[key]}</div>
              </Grid> : "N/A" :
              key === "Policy ID"  ? `${rowData[key]}`?.length > 10 ? `${rowData[key]}`.slice(0, 10)+"..." : rowData[key] : 
              ["Probability to lapse", "Surrender Probability", "Premium Sensitivity"].includes(key) ? <PriorityIndicator priority={isNaN(rowData[key]) ? rowData[key]?.toLowerCase() : rowData[key]}/> : 
              ["Current Age", "Age"].includes(key) ? parseFloat(rowData[key]) ? Math.floor(rowData[key]) : "N/A" : 
              !isNaN(rowData[key]) && !["Zipcode", "Years Left in Surrender Charge", "State", "City"].includes(key) ? nFormatter(rowData[key], 0, currency) : rowData[key] ? rowData[key]: "N/A" 
            }
          </TableCell>
      )
    })
  }

  useEffect(() => {
    countiesImports = {}
    return () => {
      dispatch(resetFilters())
    }
  }, [])

  // Run Report data caching
  useEffect(() => {
    const storedRun = sessionStorage.getItem("run_data")
    !storedRun && sessionStorage.setItem("run_data", JSON.stringify({ 
      projectName: run.projectName,
      runName: run.runName, 
      model: run.model, 
      date: run.date, 
      policies_in_run: run.policies,
      premium: run.policiesActual,
      prospects: run.underinsured,
      potential: run.potentialPremium,
      duration: run.duration,
      effective_date: run.effective_date,
    }))
  }, [run.projectName, run.runName, run.model, run.date, run.policies, run.policiesActual, run.duration, run.underinsured, run.potentialPremium, run.effective_date])

  // Run Report cache data loading
  useEffect(() => {
    if(sessionStorage.getItem("run_data")) {
      const runData = JSON.parse(sessionStorage.getItem("run_data"))
      dispatch(setProjectName(runData.projectName))
      dispatch(setRunName(runData.runName))
      dispatch(setModel(runData.model))
      dispatch(setDate(runData.date))
      dispatch(setPolicies(runData.policies_in_run))
      dispatch(setDuration(runData.duration))
      dispatch(setEffectiveDate(runData.effective_date))
      dispatch(setPoliciesActual(runData.premium))
      dispatch(setUnderinsured(runData.prospects))
      dispatch(setPotentialPremium(runData.potential))
      if (runData?.model?.toLowerCase()?.replace("v2", "") === "lapse") {
        dispatch(setOptimalTreshPrecision("medium"))
        dispatch(setPrecisionLevel("low"))
      }
    }
  }, [])  

  // Get Campaigns data
  useEffect(() => {
    ApplicationApiService._getAllCampaigns((response) => {
        dispatch(setRunLeads(response.data.metadata_list))
    }, (err) => console.log(err))
  }, [dispatch])
  
  // Get Run Report data 
  useEffect(() => {
    setIsDataLoaded(false)
    scrollToTop()
    setOpen(true)
    let queryParamas = {
      "filters": filters
    }
    const payload = {}
    Object.assign(payload, ...Object.entries(queryParamas.filters).filter((item) => item[1]?.length).map((item) => ({ [item[0]]: item[0] === "precision" ? parseInt(item[1]) : item[1]}) ))
    setQueryFilters(payload)
    const params = history.location.search?.replace("?", "").split("&")
    const runid = params[0]
    if(runid) {
    ApiService._postModelRunResult("sort=created_at", runid, 10, { 
      filters: isLapse ? {
        ...payload,
        "precision": [
          {
            "operator": "precision",
            "value": charts.optimalTreshPrecision
          }
        ]} : payload
    }, (response) => { 
      const data = response.data
      // To Do - rework setters to one redux function
      dispatch(setTotal(data["initial_prospects_count"]))
      dispatch(setPoliciesNumber(data["total_policies"]))
      //dispatch(setPotential(response.data["predict_result"]["full_book"]["current_premium"]))
      dispatch(setUnderinsured(data["count"]))
      dispatch(setPotentialPremium(data["potential_premium_retained"]))
      dispatch(setPoliciesActual(data[isLapse ? "five_year_retention_opportunity" : "potential_premium_impact"]))
      dispatch(setImpact(data["potential_impact"]))
      dispatch(setLapseRate(data["upsell_potential"]))
      dispatch(setHitRate(data['hit_rate']))
      
      dispatch(setPrecisionMeta(data['precision_metadata']))

      if (deepEqual(charts.uiFilter, [0, 0])) {
        dispatch(setUILimits(data["max_mean_premium_term"]))
        dispatch(setUILimitsMarks({ value: data["max_mean_premium_term"], label: nFormatter(data["max_mean_premium_term"], 1, currency)}))
      }

      // set dynamic filter values
      if(data["face_amount_median"] && Object.keys(charts.faceAmountFilter).length === 1) {
        dispatch(setFaceAmountRanges(data['face_amount_median']))
      }
      dispatch(setMarketLag(data['unique_product_names']))
      dispatch(setBehavioralClusterValues(data["unique_stories"]))
      dispatch(setProductValues(data["unique_product_types"]))
      dispatch(setStatusValues(data["policy_statuses"]))
      dispatch(setDurationValues(data["duration_segments"]))
      dispatch(setStateValues(
        data["unique_states"]
          ?.map((label) => usStatesData.find(({ abbreviation }) => abbreviation === label)?.name)
      ))


      const isWL = data["results"]?.length ? data["results"][0].cash_value !== undefined : false
      const isNL = data["results"]?.length ? data["results"][0].annualized_premium_sensitivity_NP !== undefined : false
      const modelColumns = isAnnuities ? defaultColumnsRunAnnuities : 
        isWL ? defaultColumnsLifeWL : 
        isNL ? defaultColumnsNewLapse : defaultColumnsLife
      const defaultColumns = isCluster ? defaultColumnsCluster : modelColumns
      
      if (isNewLapse === null) {
        setIsNewLapse(isNL)
      } 

      const ChartsData = {
        age: data["premium_potential_impacts"]?.by_age,
        gender: data["premium_potential_impacts"]?.by_gender,
        location: { states: data["premium_potential_impacts"]?.by_state, cities: data["premium_potential_impacts"]?.by_city },
        duration: data["premium_potential_impacts"]?.by_duration, 
        income: data["premium_potential_impacts"]?.by_income,
        agent: data["premium_potential_impacts"]?.by_agents,
        partner: data["premium_potential_impacts"]?.by_partner,
        cluster: data["premium_potential_impacts"]?.by_cluster
      }
      const columnsConfig = data["columns_to_display"]?.columns
      const tmp = {}
      if (columnsConfig?.length > 0) {
          Object.assign(tmp, ...Object.keys(defaultColumns)
              .map((key) => ({[key]: columnsConfig.includes(key) || hiddenHeaders.includes(key)})))
          dispatch(setColumns(tmp))
      } else {
          Object.assign(tmp, ...Object.keys(isCluster ? defaultColumnsCluster : defaultColumns)
              .map((key) => ({[key]: isCluster ? defaultColumnsCluster[key] : defaultColumns[key]})))    
          dispatch(setColumns(tmp))
      }
      BuildData(ChartsData)
      AssignData(data["results"], isNL)
      dispatch(setFiltersConfig({ filters: isLapse ? {
        ...payload,
        "precision": [
          {
            "operator": "precision",
            "value": charts.optimalTreshPrecision
          }
        ]} : payload }))
      setIsDataLoaded(true)
      dispatch(setLoaded(true))
      setClear({
        existing: false,
        recomended: false,
        status: false
      })
    }, (error) => {
      setIsDataLoaded(true)
      console.log(error)
    }, params?.length > 1)
    }
  }, [dispatch, history, filters, charts.optimalTreshPrecision])

  useEffect(() => {
    if(queryFilters) {
      const query = `sort=${order}`
      const params = history.location.search?.replace("?", "").split("&")
      ApiService._postModelPolices(query, rowsPerPage, page, history.location.search.replace("?", "").split("&")[0], {
        "filters": queryFilters
      }, (response) => {
        const data = response.data
        dispatch(setHasLeads(data["results"]?.length > 0))
        AssignData(data["results"], isNewLapse)
      }, (error) => console.log(error), params?.length > 1)
    } 
  }, [dispatch, page, rowsPerPage, order])

  const exporttocsv = (filter = {}) => {
    ApiService._postExportCsv(history.location.search.replace("?", "").split("&")[0], {
      "filters": filter
    }, (response) => {
      if (response.data.length > 0) {
        const data = response.data.split('\n')
        const csvHeaders = data.shift().split(',')
        const csvData = data.map((row) => {
          const rowValue = row.split(",")
          return rowValue
        })
        
        let fileName = run.projectName.replace(" ", "_") + "_" + run.runName.replace(" ", "_") + "_" + run.date.replace(" ", "_") + "_" + run.underinsured + "_" + run.model + ".csv"
        let csvBuilder = new CsvBuilder(fileName.replace(" ", "_"))
          .setColumns(csvHeaders)
          .addRows(csvData)
        csvBuilder.exportFile()
      }
    }, (error) => console.log(error), isDemo)
  }
      
  const handleChangeTab = (item) => {
      setActiveTab(item)
  }

  return (
    run.loaded ?
      <Grid container className={classes.rootContent} direction="row" justifyContent="center" alignItems="center" style={{maxWidth: window.innerWidth-120}} data-testid="run" ref={rootRef}>
        {!isDataLoaded && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{ position: 'fixed' }}
        open={open}
        />
        }

        <HeaderRun clear={clear} setClear={setClear}/>
        
        <Grid container justifyContent="flex-start">
                {
                    tabs.map((item) => (
                        <Grid item className={clsx(classes.tab, 
                          activeTab === item ? classes.tabActive : ''
                        )} 
                        onClick={() => handleChangeTab(item)}
                        >
                            <Typography>{item}</Typography>
                        </Grid>                        
                    ))
                }
        </Grid>
        <Grid container className={classes.main}>
        {["Premium Breakdown", "AUM Breakdown", "Prospects Breakdown"].includes(activeTab) && 
          <Breakdown 
            data={{
              mapData,
              mapDataByCounties,
              uiByStateData,
              uiByGenderData,
              uiDistributionData,
              uiByAgeData,
              uiByIncomeData,
              uiByTopAgentData,
              uiByBottomAgentData,
              uiByPartnerData,
              uiByClusterData
            }}
            emptyValues={emptyValues}
            type={run}
          />
        }
        {activeTab === "Prospects" &&
        <Grid container>
          <TableContainer
            data={{ 
              prospects: filtereddata, 
              count: run.underinsured, 
            }}
            page={page}
            headers={tableHeaders.filter((header) => ((columns[headersKeys[header]] || hiddenHeaders.includes(header)) && (isLapse || !lapseOnlyFields.includes(header))))}
            sort={order}
            handleSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRows={(e) => handleChangeRowsPerPage(e)}
            handleSelectPolicy={(policy) => setSelectedPolicy(policy)}
            handleExportAll={() => exporttocsv()}
            handleExport={() => exporttocsv(queryFilters)}
            frozenColumns={frozenRunGridColumns.filter((header) => columns[headersKeys[header]] && (isLapse || !lapseOnlyFields.includes(header)))}
            frozenPositions={frozenGridColumnsPositions}
            hiddenColumns={!isLapse ? [...lapseOnlyFields, "policy"] : ["policy"]}
            renderRow={renderRow}
            rowsPerPage={rowsPerPage}
            loaded={isDataLoaded}
            divided={false}
            divider={19}
            filters={queryFilters}
          />
          <DetailsPopup anchor={rootRef.current} open={selectedPolicy !== null && results?.length > 0} onClose={() => setSelectedPolicy(null)} policy={results?.find((item) => item["policy"] === selectedPolicy)}/>
        </Grid>
        }

        </Grid>  
                   
        
        <Grid item xs={12}>

        </Grid>
      </Grid>
      : <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      /* onClick={handleClose} */
      >
        <CircularProgress color="inherit" />
      </Backdrop>
  )
}

export default Run