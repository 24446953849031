import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import DataManagement from '../../DataManagement/DataManagement';
import { selectSourceId } from '../../../Pages/NewProject/NewProjectSlice';
import { NextButton } from '../../ui/buttons';

const useStyles = makeStyles(() => ({
    nextButton: {
        color: "#fff",
        background: "#0BCB47",
        width: 250,
        height: 50,
        borderRadius: 10,
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center"
    },
    nextButtonContainer: {
        paddingTop: 10
    },

}));
export default function SelectDataSource({ onNext }) {
    const classes = useStyles();
    const selectedFile = useSelector(selectSourceId)


    return (
        <Grid
        container
        direction="column"
        item
        xs={12}>
            <Grid
                container
                direction="column"
                justifyContent="center"
            >
                <DataManagement/>

            </Grid>
            <Grid container justifyContent="center" className={classes.nextButtonContainer}>
                <NextButton onClick={onNext} className={classes.nextButton} disabled={!selectedFile}>Next</NextButton>
            </Grid>
        </Grid>
    )
}