import { Divider } from "@material-ui/core";
import React from "react";
import RunHeaderItem from "./RunHeaderItem/RunHeaderItem";

const HeaderDashboard = ({ data, titles = ["Latest Update", "Latest Prediction Date"] }) => {

    return (
        <>
            <RunHeaderItem 
                title={titles[0]}
                value={data?.date}
                toolTipInfoText="Latest model run or data update"
                />

            <Divider/>

            <RunHeaderItem 
                title={titles[1]}
                value={data.effectiveDate}
                toolTipInfoText="Latest model run or data update"
                 />         

        </>
    )
}

export default HeaderDashboard