import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, AutoGraphOutlined, GridViewOutlined } from "@mui/icons-material";
import { ReactComponent as GrowthIcon } from '../../Assets/icon-growth.svg';
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import ConversionFunnel from "../../Components/Run/Charts/ConversionFunnel/ConversionFunnel";
import Engagement from "../../Components/Run/Charts/Engagement/Enagagement";
import { getCountryCurrencySymbol, nFormatter } from "../../global/helpers";
import clsx from "clsx";
// import ProgressionChart from "../../Components/Run/Charts/ProgressionChart/ProgressionChart";
import moment from "moment";


const useStyles = makeStyles(() => ({    
    tableCointainerView: {
        borderRadius: 12,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        marginBottom: 10,
        marginTop: "10px!important",
        padding: 30
    },
    tableTitle: {
        marginTop: 24,
        marginLeft: 32.1,
        marginBottom: 16,
        fontSize: 16,
        fontWeight: 600
    },
    tableHeader: {
      borderRadius: 8,
      backgroundColor: "#eef0f4",
      height: 80,
      '& .MuiTableCell-stickyHeader': {

          backgroundColor: "#eef0f4",
      },
      '& th:first-child': {
      borderRadius: '1em 0 0 1em'
      },
      '& th:last-child': {
      borderRadius: '0 1em 1em 0'
      },
    },
    tableCell: {
        fontFamily: "Inter !important",
        fontSize: "13px !important",
        color: "#312e40 !important",
        fontWeight: "500 !important",
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
    },
    tableCampaignCell: {
        borderBottom: "solid 1px #eef0f4 !important"
    },
    tableRow: {
        backgroundColor: "#eef0f4",
        '& td:first-child': {
        borderRadius: '1em 0 0 1em'
        },
        '& td:last-child': {
        borderRadius: '0 1em 1em 0'
        },
        height: 60,
    },
    tableRowCampaign: {
        height: 60,
        '& td:first-child': {
        borderRadius: '0'
        },
        '& td:last-child': {
        borderRadius: '0'
        },
    },
    boxIcon:{
      width: '18px!important',
      height: '18px!important',
      marginLeft:4,
      backgroundColor:'transparent',
      opacity: .7,
      cursor: 'pointer'
    },
    infoCard: {
        padding: "20px 6px 5px 16px",
        borderRadius: 12,
        border: "1px solid #D9E1EE",
        background: "#FFF",
        height: 140,
        "& .MuiTypography-h3": {
            color: '#1B1C1E',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: 0.15,
            textTransform: "capitalize",
        },
        "& .MuiTypography-h2": {
            color: '#1B1C1E',
            fontSize: 42,
            fontWeight: 500,
            lineHeight: '51px'
        },
        "& .MuiTypography-p": {
            fontSize: 13,
            fontWeight: 500,
            lineHeight: "16px",
            color: "#919191",
        },
    },
    infoCardSecondary: {
        background: "#363ed3",
        "& .MuiTypography-h3, .MuiTypography-h2": {
            color: "#FFF"
        },
    },
    infoCardDecreaseIcon: {
        borderRadius: "50%",
        background: "rgba(245, 132, 132, 1)",
        boxShadow: "0 0 0 3px rgba(245, 132, 132, 0.27)",
        width: 17,
        height: 17,
        textAlign: "center", 
        "& path": {
            fill: "#fff"
        },
        "& svg": {
            marginBottom: "1px",
            transform: "rotate(180deg)"
        }
    },
    infoCardIncreaseIcon: {
        borderRadius: "50%",
        background: "rgba(133, 212, 178, 1)",
        boxShadow: "0 0 0 3px rgba(133, 212, 178, 0.27)",
        width: 17,
        height: 17,
        textAlign: "center", 
        "& path": {
            fill: "#fff"
        },
        "& svg": {
            marginBottom: "1px"
        }
    },
    chartRangeSelector: {
        "& .MuiTabs-indicator": {
            bottom: 25,
            background: "#363ed365"
        },
        "& .MuiButtonBase-root": {
            fontSize: 12,
            minWidth: "unset",
            minHeight: "unset",
            padding: "0 6px",
            textTransform: "none",
        }
    },
    chartRangeTab: {
        "& .MuiTab-textColorInherit.Mui-selected": {
            color: "#363ed365"
        }
    },
    engagementTable: {
        background: "#eee",
        border: "none",
        borderRadius: 0,
        width: "auto!important",
        "& .MuiTableCell-root": {
            padding: "4px 16px"
        }
    },
    engagementRow: {
        background: "#fff"
    },
    engagementHeaderCell: {
        paddingTop: "10px!important"
    },
    includeRowsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        background: "#fff",
        padding: "19px 0 10px 0"
    },
    includeRowsCheckbox: {
        "& .MuiCheckbox-root": {
            padding: "4px 4px 4px 9px"
        },
        "& .MuiSvgIcon-root": {
            width: 18,
            height: 18
        },
        "& .MuiTypography-root": {
            width: 74,
            textTransform: "capitalize",
            //borderRight: "3px #67b7dc solid"
        },
        padding: "10px 0"
    },
    noProjectsGrid: {
      opacity: 0.5,
      height: "50vh",
      textAlign: 'center',
      paddingTop: "25vh" 
    },
    title: {
        fontSize: "16px!important",
        fontWeight: "600!important"
    },

}))


const viewHeaders = [
    "Email",
    "Last Updated",
    "Policy ID",
    "Status",
    "Annual Premium"
]

const reportsHeaders = [
    "Campaigns",
    "Start Date",
    "Prospects",
    "Reached",
    "Engaged/call to action",
    "Scheduled meetings",
    "Rejected",
    "Dropped",
    "Follow up",
    "Closed"
]

const EnagagementDataMock = [{
    "date": "1 Jun",
    "open": 300,
    "click": 300,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "2 Jun",
    "open": 400,
    "click": 200,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "3 Jun",
    "open": 500,
    "click": 400,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "4 Jun",
    "open": 600,
    "click": 330,
    "bookings": 150,
    "meetings": 145,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "5 Jun",
    "open": 700,
    "click": 500,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "6 Jun",
    "open": 730,
    "click": 600,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},{
    "date": "7 Jun",
    "open": 800,
    "click": 640,
    "bookings": 170,
    "meetings": 130,
    "inpipeline": 100,
    "deals": 70
},]

const CampaignView = ({ 
    campaignDetails, 
    campaignPolicies, 
    numOfPolicies, 
    policiesByStatus,
    funnelData,
    EnagagementData,
    page, 
    handleChangePage 
}) => {
    const classes = useStyles()
    const [viewMode, setViewMode] = useState('graph');
    const [chartRange, setChartRange] = useState(0);
    const [premiumGenerated, setPremiumGenerated] = useState(0);
    const [includedData, setIncludedData] = useState({
        email: true,
        open: true,
        click: true,
        bookings: true,
        meetings: true,
        inpipeline: true,
        deals: true,

    });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(viewHeaders[0]);
    const currency = getCountryCurrencySymbol()

    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

   
    const getSort = (a, b, orderBy) => {
        if (orderBy === "Last Updated") {
          return moment(b[orderBy]) < moment(a[orderBy]) ? -1 : 1
   
        } else  if (!Number.isNaN(parseFloat(b[orderBy]))) {
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1
        } else {
          return b[orderBy] < a[orderBy] ? -1 : 1
        }
    }
   
    const getOrder = (order, orderBy) => {
       return order === 'desc'
          ?(a, b) => getSort(a, b, orderBy)
         : (a, b) => -getSort(a, b, orderBy);
     }

    const handleChangeIncludedData = (event) => {
        setIncludedData({
          ...includedData,
          [event.target.name]: event.target.checked,
        });
    }
    

    const handleChangeOrder = (id) => {
        if (orderBy !== id) {
            setOrder('asc')
        }
        const newOrder = order === "asc" ? 'desc' : 'asc'
        setOrderBy(id)
        setOrder(newOrder)
    }
    
    // useEffect(() => {
    //     return () => {
    //         setOrderBy(viewHeaders[0])
    //         setOrder('asc')
    //         handleChangePage(null, 0)
    //     }
    // }, [handleChangePage])

    useEffect(() => {
        if (campaignDetails?.premium_opportunity && policiesByStatus?.deals) {
            setPremiumGenerated(
                (campaignDetails?.premium_opportunity/numOfPolicies) * policiesByStatus.deals
            )
        }
    }, [campaignDetails, numOfPolicies, policiesByStatus])

    return(
        <>
            <Grid container direction="row" justifyContent="flex-start" xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Grid container className={classes.infoCard}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                Number of Prospects
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                                <NumberFormat value={numOfPolicies} displayType={"text"} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardIncreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container className={clsx(classes.infoCardSecondary,classes.infoCard)}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                Potential Premium
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                            {nFormatter(campaignDetails?.premium_opportunity ? campaignDetails.premium_opportunity : 0, 0, currency)}
                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardDecreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container className={classes.infoCard}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                View
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                                <NumberFormat value={policiesByStatus?.email || 0} displayType={"text"} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item container justifyContent="space-between" style={{ visibility: "hidden"}}>
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardIncreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container className={classes.infoCard}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                Click
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                                <NumberFormat value={policiesByStatus?.click || 0} displayType={"text"} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>

                                        <Grid item container justifyContent="space-between" style={{ visibility: "hidden"}}>
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardIncreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container className={classes.infoCard}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                Closed deals
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                                <NumberFormat value={policiesByStatus?.deals || 0} displayType={"text"} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardIncreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container className={clsx(classes.infoCardSecondary,classes.infoCard)}>
                                        <Grid item container>
                                            <Typography variant="h3">
                                                Annual Premium Generated
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="h2">
                                            {<NumberFormat value={premiumGenerated?.toString().split(".")[0]} displayType={'text'} thousandSeparator={true} prefix={currency}/>}
                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="p">
                                                    2% from target
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.infoCardIncreaseIcon}>
                                                <GrowthIcon/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                        <Grid container xs={12} direction="column" className={classes.tableCointainerView}>

                            <Grid container xs={12} justifyContent="space-between">
                                <Grid item container xs={2}>
                                    <Typography className={classes.title}>Statistic</Typography>
                                </Grid>
                                <Grid item container xs={6} justifyContent="flex-end">
                                    <Tabs value={chartRange} onChange={(event, newValue) => setChartRange(newValue)}  className={classes.chartRangeSelector} textColor="inherit" indicatorColor="inherit">
                                        <Tab label="Current" className={classes.chartRangeTab}/>
                                        <Tab label="Last day" className={classes.chartRangeTab}/>
                                        <Tab label="Last 7 days" className={classes.chartRangeTab}/>
                                        <Tab label="Last 30 days" className={classes.chartRangeTab}/>
                                        <Tab label="Custom range" className={classes.chartRangeTab}/>
                                    </Tabs>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} justifyContent="space-between">
                                <Grid item container xs={6} justifyContent="flex-start">
                                    <Typography  className={classes.title}>Conversion Funnel</Typography>
                                </Grid>
                                <Grid item container xs={6} justifyContent="space-between">
                                        <Typography className={classes.title}>Engagement</Typography>
                                        <ToggleButtonGroup value={viewMode} exclusive onChange={(e, newValue) => setViewMode(newValue)}>
                                            <ToggleButton value="grid">
                                                <GridViewOutlined/>
                                            </ToggleButton>
                                            <ToggleButton value="graph">
                                                <AutoGraphOutlined/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                </Grid>                                
                            </Grid>

                            <Grid container xs={12}>
                                    
                                <Grid item container xs={6}>
                                    <ConversionFunnel data={funnelData} includingSeries={includedData} total={numOfPolicies}/>
                                </Grid>

                                <Grid item container xs={5}>
                                        {viewMode === "graph" ? <Grid container>
                                                {EnagagementData && <Engagement data={EnagagementData} inculdingSeries={includedData}/>}
                                            </Grid> :
                                            <Grid >
                                                {EnagagementData && <TableContainer  className={classes.engagementTable}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                Object.keys(EnagagementData[0])
                                                                    ?.filter((header) => header === "date" || includedData[header])
                                                                    ?.map((item) => <TableCell className={classes.engagementHeaderCell}>{item}</TableCell>)
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            EnagagementData?.map((row, id) => 
                                                                <TableRow key={id} className={classes.engagementRow}>
                                                                    {
                                                                        Object.entries(row)
                                                                            ?.filter((column) => column[0] === "date" || includedData[column[0]])
                                                                            ?.map((column) => <TableCell>{column[1]}</TableCell>)
                                                                    }
                                                                </TableRow>        
                                                            )
                                                        }
                                                    </TableBody>
                                                </TableContainer>}
                                            </Grid>
                                        }
                                </Grid>

                                        <Grid xs={1} className={classes.includeRowsContainer} justifyContent="flex-end">
                                            <FormControl component="fieldset" variant="standard">
                                                <FormLabel component="legend">Included:</FormLabel>
                                                <FormGroup>
                                                    {
                                                        Object.keys(includedData).filter((item) => item !== "email").map((item) => (
                                                            <FormControlLabel className={classes.includeRowsCheckbox} control={
                                                                <Checkbox checked={includedData[item]} onChange={handleChangeIncludedData} name={item}/>
                                                            } label={item}/>
                                                        ))
                                                    }
                                                </FormGroup>
                                            </FormControl>  
                                        </Grid>
                            </Grid>
                        </Grid>
                        
                        {/*<Grid container direction="row" justifyContent="flex-start" xs={12} spacing={1}>
                            <Grid item xs={3}>
                                <Grid container className={classes.infoCard}>
                                    <ProgressionChart title="Engagedment" color="#f97789"/>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container className={classes.infoCard}>
                                    <ProgressionChart title="Engagedment2" color="coral"/>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container className={classes.infoCard}>
                                    <ProgressionChart title="Coversion" color="yellow"/>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container className={classes.infoCard}>
                                    <ProgressionChart title="Reach" color="yellow"/>
                                </Grid>
                            </Grid>
                                                </Grid>**/}
                        <Grid item xs={12} className={classes.tableCointainerView}>
                            <Grid 
                                container 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                                <Grid item style={{paddingBottom: 20}}>
                                    <Typography className={classes.title}>Prospects List</Typography>
                                </Grid>
                            </Grid>
                            
                        <TableContainer className={classes.table}>
                            {campaignPolicies?.length > 0 ? <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{borderRadius: 8}}>
                                            <TableRow className={classes.tableHeader}>
                                                {viewHeaders.map((header, index) => (
                                                    <TableCell className={classes.tableCell} key={index}  align="center">
                                                        <Grid container justifyContent="space-between">
                                                            <Grid item sx={{ padding: "10px 0"}}>
                                                                <div style={{ display: 'flex',justifyContent: 'space-between'}}>
                                                                    <Typography className={classes.cellText}>{header}</Typography>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}} onClick={() => handleChangeOrder(header)}>
                                                                    {
                                                                    order === 'asc' || orderBy !== header ? <ArrowUpward className={classes.boxIcon}/> : <ArrowDownward className={classes.boxIcon}/>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>    
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow sx={{ height: 2}}></TableRow>
                                            {
                                                stableSort(campaignPolicies, getOrder(order, orderBy))?.map((item) => (
                                                        <TableRow className={classes.tableRowCampaign}>
                                                            {
                                                                Object.keys(item).filter((key) => key !== "campaign_id" && key !== "policy_status" && key !== "comments").map((key) => (
                                                                    <TableCell className={classes.tableCampaignCell}>
                                                                        {
                                                                            key === "policy_status_info" ?  item[key].status : 
                                                                            key === "policy_id" || key === "email" ? `${item[key]}`?.length > 10 ? `${item[key]}`.slice(0, 10)+"..." : item[key] : 
                                                                            key === "premium" ? nFormatter(item[key] ? item[key] : 0, 2, currency) : 
                                                                            item[key] 
                                                                        }
                                                                    </TableCell> 
                                                            ))}
                                                        </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table> : 
                                    <Grid className={classes.noProjectsGrid}>
                                        <Typography variant="h3"> No Data </Typography>
                                    </Grid>
                                }
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[10]}
                                    rowsPerPage={10}
                                    count={numOfPolicies}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    />
                        </TableContainer>
                    </Grid> 
                    {/* <Grid item xs={12} className={classes.tableCointainerView}>
                        <Grid 
                            container 
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <Grid item style={{paddingBottom: 20}}>
                                <Typography className={classes.title}>Reports Aggregated</Typography>
                            </Grid>
                        </Grid> 

                        <TableContainer className={classes.table}>
                            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead sx={{borderRadius: 8}}>
                                            <TableRow className={classes.tableHeader}>
                                                {reportsHeaders.map((header, index) => (
                                                    <TableCell className={classes.tableCell} key={index}  align="center">
                                                        <Grid container justifyContent="space-between">
                                                            <Grid item sx={{ padding: "10px 0"}}>
                                                                <div style={{ display: 'flex',justifyContent: 'space-between'}}>
                                                                    <Typography className={classes.cellText}>{header}</Typography>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}} onClick={() => handleChangeOrder(header)}>
                                                                    {
                                                                    order === 'asc' || orderBy !== header ? <ArrowUpward className={classes.boxIcon}/> : <ArrowDownward className={classes.boxIcon}/>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>    
                                                    </TableCell>
                                                ))}
                                                <TableCell style={{padding: 0}}/>
                                            </TableRow>
                                        </TableHead>
                            </Table>
                        </TableContainer>
                    </Grid>            */}
        </>
    )
}

export default CampaignView