import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import RICIBs from 'react-individual-character-input-boxes';

const useStyles = makeStyles(() => ({
    separetedInput: {
        "& input": {
            width: 50,
            height: 70,
            background: "#FFFFFF",
            border: "1px solid #EBEBEB",
            borderRadius: 12,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
            marginTop: 0,
            marginBottom: 0,
            fontFamily: "Roboto",
            fontSize: 24,
            fontWeight: 400,
            lineHeight: 24
        },
        "& input:focus": {
            border: "none",
            outline: "none"
        },
        "& input:hover": {
            border: "none",
            outline: "none"
        },
    }
}))

const LimitedDigitsInput = ({ onChange, digits }) => {
    const classes = useStyles()
     
    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.separetedInput}>
            <RICIBs
                amount={digits}
                autoFocus
                handleOutputString={onChange}
                inputRegExp={/^[0-9]$/}
                />
        </Grid>
    )
}

export default LimitedDigitsInput