import React, {useState} from "react";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {basicButtonStyles} from "../../../Components/Demo/Header/styles";
import {useHistory} from "react-router-dom";
import DemoLayout from "../../../Components/Demo/DemoLayout";
import NewOpportunitiesTable from "../../../Components/Demo/NewOpportunitiesTable/NewOpportunitiesTable";

const UINextButton = styled(Button)({
  ...basicButtonStyles,
  width: '100%',
  padding: '14px 34px',
  maxWidth: '228px',
  height: '71px',
  borderRadius: '10px',
  backgroundColor: '#0BCB47',
  borderColor: '#0BCB47',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  '&:hover': {
    opacity: '0.9',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: '0.5',
    color: '#FFF'
  }
});

const UITestButton = styled(UINextButton)({
  backgroundColor: 'transparent',
  borderColor: '#4AC670',
  color: '#4AC670',
  marginRight: '30px',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#4AC670',
  },
});

const buttonGroup = {
  marginTop: '20px',
  marginBottom: '50px',
  display: 'flex',
  justifyContent: 'center',
};

const DemoOpportunities = () => {
  const history = useHistory();

  const [modalOpenSettings, setModalOpenSettings] = useState(undefined);

  const tableData = [
    {
      info: {
        title: 'Retention',
        totalSimplePolicies: 151,
        totalPremiumPolicies: 688716
      },
      opportunity: [
        { title: 'Possible cash flow issue',  policies: 25, premium: 166614, priority: 'High' },
        { title: 'Agent attention needed',  policies: 91, premium: 388997, priority: 'Medium' },
        { title: 'Possible policy update needed',  policies: 28, premium: 106484, priority: 'High' },
        { title: 'No indicators',  policies: 7, premium: 26621, priority: 'Medium', isLast: true, },
      ],
    },
    {
      info: {
        title: 'New Business',
        totalSimplePolicies: 4317,
        totalPremiumPolicies: 8872473,
      },
      opportunity: [
        { title: "Life Event", policies: 1479, premium: 2363518, priority: 'High' },
        { title: 'Promotion', policies: 819, premium: 1986394, priority: 'Medium' },
        { title: 'Career Pivot', policies: 1941, premium: 4076004, priority: 'High' },
        { title: 'Family Change', policies: 22, premium: 102500, priority: 'Medium' },
        { title: 'Return to Work', policies: 56, premium: 271557, priority: 'High' },
      ],
    },
  ];

  return (
    <DemoLayout
      pageTitle="Upside Opportunities"
      modalInfo={modalOpenSettings}
      onModalClose={(defaultValues) => setModalOpenSettings(defaultValues)}
    >
      {/*<OpportunitiesTable tableData={tableData} />*/}
      <NewOpportunitiesTable tableData={tableData} />
      <div style={buttonGroup}>
        <UITestButton onClick={() => setModalOpenSettings({
          name: 'contact',
          value: 'To test the campaign with this data, please contact our sales team.'
        })}
        >Test With My Data</UITestButton>
        <UINextButton onClick={() => history.push('/demo-recommended-actions')}>
          Policy Action Details
        </UINextButton>
      </div>
    </DemoLayout>
  )
};

export default DemoOpportunities;
