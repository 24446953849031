import { Tooltip, styled } from "@material-ui/core";
import { tooltipClasses } from "@mui/material";


export const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontFamily: "Montserrat!important",
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "13px",
      color: "#1C1C1C",
      background: "#FFFFFF",
      textAlign: "center",
      width: 235,
      borderRadius: 2,
      filter: "drop-shadow(0px 0px 6px rgba(106, 108, 113, 0.25))",
      padding: "8px 20px"
    },
}));