import React from "react";
import { Grid, Popover, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as IconMale } from "../../../Assets/icon-policy-male.svg";
import { ReactComponent as IconFemale } from "../../../Assets/icon-policy-male.svg";
import { ReactComponent as CloseIcon } from "../../../Assets/icon-circle-cross.svg";
import DistiributionMap from "../../Run/Charts/DistributionMap/DsitributionMap";
import PriorityIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator";
import NumberFormat from "react-number-format";
import moment from "moment";
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import clsx from "clsx";
import { number } from "@amcharts/amcharts4/core";

const useStyles = makeStyles(() => ({
    popoverRoot: {
        "& .MuiPopover-paper": {
            maxWidth: 1091,
            width: "100%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.22)",
            borderRadius: 8,
            overflowX: "auto"
        }
    },
    popoverRetention: {
        "& .MuiPopover-paper": {
            background: "#ebebfb",
        }
    },
    popoverNewbuisness: {
        "& .MuiPopover-paper": {
            background: "#f2fcf4",
        }
    },
    rootContainer: {
        width: "100%",
        padding: "59px 41px 43px 41px",
    },
    detailsContainer: {
        minWidth: 1009,
        "& .MuiGrid-container:first-child": {
            maxWidth: 285,
            paddingLeft: 0
        },
        "& .MuiGrid-container": {
            maxWidth: 354,
            paddingLeft: 8
        },
        "& h1": {
            fontFamily: "Roboto!important",
            fontWeight: "700!important",
            fontSize: "22px!important",
            lineHeight: "26px!important",
            paddingTop: 21
        },
        "& .MuiGrid-item": {
            width: "100%",
            padding: "17px 27px 17px 27px",
            borderRadius: 6,
            border: "1px solid #D9E1EE",
            background: "#FFFFFF"
        },
    },
    genderContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        paddingTop: 23
    },
    chartContainer: {
        display: "flex",
        width: "100%",
        paddingTop: 12,
        height: 225
    },
    dataContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // height: "100%",
        paddingTop: 17
    },
    bottomContainer: {
        minHeight: 176
    },
    dataItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "8px 20px 8px 13px",
        border: "1px solid #D9E1EE",
        "& span": {
            display: "flex",
            color: "#000000",
            "& svg": {
                marginRight: 5
            },
        },
        "& .MuiTypography-root": {
            color: "#6A6C71",
            fontFamily: "Roboto!important",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "16px"
        },

    },
    flightRiskItem: {
        minHeight: 582,
        "& .story--title": {
            textTransform: "capitalize",
            lineHeight: "22px!important"
        }
    },
    flightRiskList: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "20px",
        paddingLeft: 18,
        display: "none",
        "& li": {
            paddingBottom: 20
        }
    },
    title: {
        "&.MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: "18px!important",
            lineHeight: "18px",
            color: "#000"
        },

    },
    closeIcon: {
        position: "absolute",
        top: 12,
        right: 12,
        cursor: "pointer"
    },
    positive: {
        color: "#0BCB47"
    },
    negative: {
        color: "#FF4B62"
    }

}))

const DetailsPopup = ({ open, onClose, policy, anchor }) => {
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isCluster = policy?.model_type?.toLowerCase() === "cluster" || JSON.parse(sessionStorage.getItem("run_data"))?.model === "cluster" 
    
    const renderValue = (value, number = false, prefix = currency) => {
        if (!number) {
            return value !== null && value !== undefined ? value : "N/A" 
        } else {
           return value !== null && value !== undefined ? <NumberFormat value={Math.floor(value)} displayType={'text'} thousandSeparator={true} prefix={prefix}/> : "N/A" 
        }
        
    }

    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }} 
            className={clsx(
                classes.popoverRoot, 
                policy?.model_type === "ui" ? classes.popoverNewbuisness : classes.popoverRetention
            )}>
                <div className={classes.rootContainer}>
                    {policy &&  <Grid container justifyContent="center" className={classes.detailsContainer}>
                    <Grid container direction="column">
                        <Grid item style={{height: "100%"}}>
                            <Typography className={classes.title}>Demographic</Typography>
                            <div className={classes.genderContainer}>
                                {
                                    policy.gender !== "F" ? <IconMale/> : <IconFemale/>
                                }
                            </div>
                            <div className={classes.chartContainer}>
                                <DistiributionMap mapData={[{ id: "US-"+policy.State, value: policy.premium }]} mapDataByCounties={[{ name: "US-"+policy.City, value: policy.premium }]} sm/>
                            </div>
                            <div className={classes.dataContainer}>
                                {policy["age"] && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "Prospect Age" : "Policyholder Age"}:</Typography>
                                    <Typography><span>{renderValue(policy["age"], number, '')}</span></Typography>
                                </div>}
                                {policy["gender"] && <div className={classes.dataItem}>
                                    <Typography>Gender:</Typography>
                                    <Typography><span>{renderValue(policy["gender"])}</span></Typography>
                                </div>}
                                {policy["zipcode"] && <div className={classes.dataItem}>
                                    <Typography>Zip code:</Typography>
                                    <Typography><span>{renderValue(policy["zipcode"] ? policy["zipcode"] : "N/A")}</span></Typography>
                                </div>}
                                {policy["issue_state"] && isAnnuities ? <div className={classes.dataItem}>
                                    <Typography>Issue State:</Typography>
                                    <Typography><span>{renderValue(policy["issue_state"])}</span></Typography>
                                </div>
                                : policy["State"] &&
                                <div className={classes.dataItem}>
                                    <Typography>State:</Typography>
                                    <Typography><span>{renderValue(policy["State"])}</span></Typography>
                                </div>
                                }
                                {isAnnuities ? <div className={classes.dataItem}>
                                    <Typography>Residency State:</Typography>
                                    <Typography><span>{renderValue(policy["residence_state"])}</span></Typography>
                                </div> :                                
                                <div className={classes.dataItem}>
                                    <Typography>Household income:</Typography>
                                    <Typography>{renderValue(policy["per_cap_income"], true)}</Typography>
                                </div>
                                }

                            </div>

                        </Grid>
                    </Grid>
                    <Grid container direction="column" justifyContent="space-between">
                    {/* Current Policy */}
                        <Grid item style={isAnnuities ? { minHeight: 571 } : {}}>
                            <Typography className={classes.title}>{isCluster ? "Segment details" : "Current Policy"}</Typography>
                            {isAnnuities ? 
                            <div className={classes.dataContainer}>
                                {policy["policy"] && <div className={classes.dataItem}>
                                    <Typography>Policy ID:</Typography>
                                    <Typography>{policy["policy"]?.toString()?.length > 10 ? <Tooltip title={policy["policy"]}><span>{`${policy["policy"].toString().slice(0, 9)}...`}</span></Tooltip> : <span>{renderValue(policy["policy"])}</span>}</Typography>
                                </div>}
                                {policy["product_market_name"] && <div className={classes.dataItem}>
                                    <Typography>Product Market Name:</Typography>
                                    <Typography style={{ textTransform: "uppercase" }}>{<span>{renderValue(policy["product_market_name"])}</span>}</Typography>
                                </div>}
                                {policy["issue_date"] && <div className={classes.dataItem}>
                                    <Typography>Policy Issue Date:</Typography>
                                    <Typography>{<span>{renderValue(moment(policy["issue_date"]).format("MM/DD/YYYY"))}</span>}</Typography>
                                </div>}
                                {policy["contract_value"] !== null && <div className={classes.dataItem}>
                                    <Typography>Contract Value:</Typography>
                                    <Typography>{renderValue(policy["contract_value"], true)}</Typography>
                                </div>}
                                {policy["surr_value"] !== null && <div className={classes.dataItem}>
                                    <Typography>Surrender Value:</Typography>
                                    <Typography>{renderValue(policy["surr_value"], true)}</Typography>
                                </div>}
                                {policy["end_of_surr"] && <div className={classes.dataItem}>
                                    <Typography>End of Surrender Charge:</Typography>
                                    <Typography>{renderValue(moment(policy["end_of_surr"]).format("MM/DD/YYYY"))}</Typography>
                                </div>}
                                {policy["years"] ? <div className={classes.dataItem}>
                                    <Typography>Years Left in Surr. Value:</Typography>
                                    <Typography>{renderValue(policy["years"])}</Typography>
                                </div> : ''}
                                {policy["account_value"] !== null && <div className={classes.dataItem}>
                                    <Typography>Income Account Value:</Typography>
                                    <Typography>{renderValue(policy["account_value"], true)}</Typography>
                                </div>}
                                {policy["libr_status"] && <div className={classes.dataItem}>
                                    <Typography>LIBR Status:</Typography>
                                    <Typography className={classes.positive}>{renderValue(policy["libr_status"])}</Typography>
                                </div>}
                                {policy["libr_fee"] !== null && <div className={classes.dataItem}>
                                    <Typography>LIBR Fee:</Typography>
                                    <Typography>{renderValue(policy["libr_fee"], true)}</Typography>
                                </div>}
                                {policy["libr_fee"] !== null && <div className={classes.dataItem}>
                                    <Typography>LIBR Payment:</Typography>
                                    <Typography>{renderValue(policy["libr_payment"], true)}</Typography>
                                </div>}
                                {(policy["contract_loss_dollars"] || policy["contract_loss"]) ? <div className={classes.dataItem}>
                                    <Typography>Contract Loss:</Typography>
                                    <Typography>{`${policy["contract_loss_dollars"] ? nFormatter(policy["contract_loss_dollars"], 2, currency) : "N/A"} ${policy["contract_loss"] ? `(${policy["contract_loss"].toFixed(2)}%)` : "(N/A)"}` }</Typography>
                                </div> : ''}
                                {(policy["income_loss"] || (policy["contract_value"] && policy["account_value"] && policy["surr_value"])) ? <div className={classes.dataItem}>
                                    <Typography>Income Loss:</Typography>
                                    <Typography>{`${policy["contract_value"] && policy["account_value"] && policy["surr_value"] ? nFormatter(policy["contract_value"]-policy["account_value"]/policy["surr_value"], 2, currency) : "N/A"} ${policy["income_loss"] ? `(${policy["income_loss"].toFixed(2)}%)` : "(N/A)"}`}</Typography>
                                </div> : ''}
                                {policy["withdraw_annual"] !== null && <div className={classes.dataItem}>
                                    <Typography>Annual Withdrawal (12 months):</Typography>
                                    <Typography>{renderValue(policy["withdraw_annual"], true)}</Typography>
                                </div>}
                                {policy["joint_owner"] && <div className={classes.dataItem}>
                                    <Typography>Joint Owner:</Typography>
                                    <Typography className={classes.positive}>{renderValue(policy["joint_owner"])}</Typography>
                                </div>}
                                {policy["joint_owner"] && <div className={classes.dataItem}>
                                    <Typography>Owned by Trust:</Typography>
                                    <Typography className={classes.positive}>{renderValue(policy["owned_by_trust"])}</Typography>
                                </div>}
                                {policy["corparate_owned"] && <div className={classes.dataItem}>
                                    <Typography>Owned by a Non-natural Entity:</Typography>
                                    <Typography className={classes.negative}>{renderValue(policy["corparate_owned"])}</Typography>
                                </div>}
                                {policy["power_of_attroney"] && <div className={classes.dataItem}>
                                    <Typography>Power of Attorney:</Typography>
                                    <Typography className={classes.positive}>{renderValue(policy["power_of_attroney"])}</Typography>
                                </div>}
                                {policy["agent_id"] && <div className={classes.dataItem}>
                                    <Typography>Agent ID:</Typography>
                                    <Typography>{policy["agent_id"]?.toString()?.length > 10 ? <Tooltip title={policy["agent_id"]}><span>{`${policy["agent_id"].toString().slice(0, 9)}...`}</span></Tooltip> : <span>{policy["agent_id"] ? renderValue(policy["agent_id"]) : "N/A"}</span>}</Typography>
                                </div>}
                                {policy["agent_status"] && <div className={classes.dataItem}>
                                    <Typography>Agent Status:</Typography>
                                    <Typography>{renderValue(policy["agent_status"])}</Typography>
                                </div>}
                                <div className={classes.dataItem}>
                                    <Typography>IMO:</Typography>
                                    <Typography>KISSER</Typography>
                                </div>
                            </div>      
                            : <div className={classes.dataContainer}>
                                {policy["policy"] && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "ID" : "Policy ID"}:</Typography>
                                    <Typography>{policy["policy"]?.toString()?.length > 10 ? <Tooltip title={policy["policy"]}><span>{`${policy["policy"].toString().slice(0, 9)}...`}</span></Tooltip> : <span>{renderValue(policy["policy"])}</span>}</Typography>
                                </div>}
                                {policy[isCluster ?  "cluster" : "model_type"] && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "Segment" : "Policy Type"}:</Typography>
                                    <Typography style={{ textTransform: "uppercase" }}>{<span>{renderValue(policy[isCluster ?  "cluster" : "product_type"])}</span>}</Typography>
                                </div>}
                                {policy["issue_date"] && <div className={classes.dataItem}>
                                    <Typography>Policy Issue Date:</Typography>
                                    <Typography>{<span>{renderValue(moment(policy["issue_date"]).format("MM/DD/YYYY"))}</span>}</Typography>
                                </div>}
                                {policy["dob"] && <div className={classes.dataItem}>
                                    <Typography>Policy Date Of Birth:</Typography>
                                    <Typography>{<span>{renderValue(moment(policy["dob"]).format("MM/DD/YYYY"))}</span>}</Typography>
                                </div>}
                                {policy[isCluster ?  "segment_income" : "premium"] !== null && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "Segment Avg. Income" : "Annual Premium"}:</Typography>
                                    <Typography>{renderValue(policy[isCluster ?  "segment_income" : "premium"], true)}</Typography>
                                </div>}
                                {isCluster && policy["segment_age"] !== null && <div className={classes.dataItem}>
                                    <Typography>Segment Avg. Age:</Typography>
                                    <Typography>{renderValue(policy["segment_age"], true, "")}</Typography>
                                </div>}
                                {policy[isCluster ?  "product_preference" : "face_amount"] !== null && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "Insurance Preference" : "Face Amount"}:</Typography>
                                    <Typography style={{ textAlign: "right" }}>{isCluster ? renderValue(policy["product_preference"]) : renderValue(policy["face_amount"], true)}</Typography>
                                </div>}
                                {(policy["account_value"] !== null && policy["product_type"]?.toLowerCase() !== "whole life") && <div className={classes.dataItem}>
                                    <Typography>{isCluster ? "Avg. Coverage" : "Account Value"}:</Typography>
                                    <Typography>
                                        {
                                            isCluster ? 
                                                renderValue(`${currency}${policy[policy["MARKET_NAME_LAG"]?.toLowerCase() !== "whole life" ? "fa_top_tm" : "fa_top_wl"]}K`, false)
                                            : renderValue(policy["account_value"], true)
                                        }
                                    </Typography>
                                </div>}
                                {policy["payment_method"] && <div className={classes.dataItem}>
                                    <Typography>Payment Frequency:</Typography>
                                    <Typography>{policy["payment_method"] ? <span>{policy["payment_method"]}</span> : "N/A"}</Typography>
                                </div>}
                            </div>}
                        </Grid>

                        
                        {/* End of second column */}
                    </Grid>

                    <Grid container direction="column">

                        {/* Model Results of third column */}
                        {isAnnuities 
                        &&
                            <Grid item className={classes.bottomContainer}>
                            <Typography className={classes.title}>Model Results</Typography>
                            <div className={classes.dataContainer}>
                                {policy["priority"] !== undefined && <div className={classes.dataItem}>
                                    <Typography>Urgency:</Typography>
                                    <Typography><span><PriorityIndicator priority={policy["priority"]}/> {policy["priority"]}</span></Typography>
                                </div>}
                                <div className={classes.dataItem}>
                                    <Typography>Probability to Surrender:</Typography>
                                    <Typography>{renderValue(policy["surrender_score_platform"])}</Typography>
                                </div>
                                {policy["account_value"] !== undefined && <div className={classes.dataItem}>
                                    <Typography><span>Account Value to Retain:</span></Typography>
                                    <Typography>{renderValue(policy["account_value"], true, currency)}</Typography>
                                </div>}
                            </div>
                            </Grid>
                        }

                        <Grid item className={classes.flightRiskItem} style={isAnnuities ? { marginTop: 23 } : {}}>
                            <Typography 
                                className={clsx("story--title", classes.title)}>
                                {isCluster ? "Product Strategy" : "Upside Opportunity"}<br/>{policy["story"]}
                            </Typography>
                            <ul className={classes.flightRiskList}>
                                <li>Actively watches market rates or has a professional advisor working for her</li>
                                <li>Interested in optimizing return on assets</li>
                                <li>High likelihood to lapse due to other market opportunities</li>
                            </ul>
                        </Grid>
                        {/* End of third column */}
                    </Grid>
                </Grid>}
                <CloseIcon className={classes.closeIcon} onClick={onClose} />
                </div>

        </Popover>
    )
}

export default DetailsPopup