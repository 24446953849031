import React, { useState, useEffect } from "react";
import { Button, Grid, makeStyles, MenuItem, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";import { ReactComponent as IconArrow } from "../../../Assets/icon-growth.svg";
import { ReactComponent as InfoIcon } from '../../../Assets/icon-info2.svg';
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import CampaignTypeSelect from "../CampaignTypeSelect/CampaignTypeSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectIds, selectOpportunities, selectProductsData, selectStoriesIsLoading, setActiveTab, setOpportunities, setProjectIds, setProjectLabels, setStoriesIsLoading } from "../BookAnalysisSlice";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { setDate, setDuration, setEffectiveDate, setModel, setPolicies, setPotential, setRunName } from "../../../Components/Run/RunSlice";
import { setProjectName } from "../../NewProject/NewProjectSlice";
import { selectUserCompany, setActiveOption } from "../../../AppSlice";
import { setPrecisionConfig } from "../../../Components/Run/Charts/ChartsSlice";
import moment from "moment";
import clsx from "clsx";
import { ArrowDropDown } from "@mui/icons-material";
import { ReactComponent as IconArrowUp } from "../../../Assets/icon-arrow-up.svg"
import { basicButtonStyles } from "../../../Components/Demo/Header/styles";
import CreateCampaignsDialog from "../CreateCampaignsDialog/CreateCampaignsDialog";
import BookAnalysisService from "../BookAnalysisService";
import UnregisteredPopup from "../../../Components/Popups/UnregisteredPopup/UnregisteredPopup";
import { InfoTooltip } from "../../../Components/ui/tooltips";
import NoDataIndicator from "../../../Components/Run/Charts/NoDataIndicator/NoDataIndicator";
import { UIViewButton, uiViewButtonStyles } from "../../../Components/ui/buttons";
import Loader from "../../../Components/Loader/Loader";
import appApiService from "../../../AppService";
import { resetFilters } from "../../../Components/Filters/FiltersSlice";

const useStyles = makeStyles(() => ({
    sideBar: {
        maxWidth: '25%'
    },
    sideBarContainer: {
        background: "#F8F9FB",
        borderRadius: "8px 0px 0px 8px",
        height: "100%",
        padding: "32px 0",
        paddingLeft: 24
    },
    sideBarTitle: {
        fontFamily: "Strawford",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "24px",
        color: "#232033",
        display: "flex",
        paddingBottom: 12
    },
    additionalStatsValue: {
        fontFamily: "Roboto!important",
        fontSize: 22,
        fontWeight: 700,
        lineHeight: "26px",
        color: "#232033",
        paddingTop: 4,
        paddingBottom: 12
    },
    additionalStatsText: {
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#232033"
    },
    opportunityText: {
        fontFamily: "Strawford!important",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "29px"
    },
    highLightText: {
        color: "#363ED3",
        ".MuiTableCell-root &": {
            color: "#363ED3"
        }
    },
    bgDark: {
        background: "#F8F9FB",
        height: 60
    },
    tableContainer: {
        maxWidth: '75%',
        flexDirection: "column",
        "& .MuiTableContainer-root": {
            background: "#FFFFFF",
        }
    },
    tableHead: {
        "& .MuiTableCell-root": {
          background: "#F8F9FB",
          height: 60,
          fontFamily: "Roboto!important",
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '16px',
          color: "#232033",
          borderBottom: "1px solid #F8F9FB"
        },
        "& .MuiTableCell-root:first-child": {
            borderTopLeftRadius: 8
        },
        "& .MuiTableCell-root:last-child": {
            borderTopRightRadius: 8
        }
    },
    tableBody: {
        "& .MuiTableCell-root": {
          background: "#FFFFFF",
          height: 62,
          fontFamily: "Roboto!important",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '20px',
          color: "#232033",
          borderBottom: "1px solid #F8F9FB"
        },
        "& .MuiTableCell-root#highlight": {
            color: "#363ED3"
        }
    },
    tableFooterContainer: {
        "& .MuiTableHead-root": {
            visibility: "hidden",
            "& .MuiTableCell-root": {
              borderBottom: "none"
            }
        },
        "& .MuiTableBody-root": {
            position: "relative",
            bottom: 61
        }
    },
    tableFooter: {
        "& .MuiTableCell-root": {
          background: "#F8F9FB",
          height: 60,
          fontFamily: "Roboto!important",
          fontWeight: 700,
          fontSize: 22,
          lineHeight: '26px',
          color: "#232033"
        },
        "& .MuiTableCell-root:first-child": {
            borderBottomLeftRadius: 8
        },
        "& .MuiTableCell-root:last-child": {
            borderBottomRightRadius: 8
        }
    },
    totalIcon: {
        borderRadius: "50%",
        background: "rgba(133, 212, 178, 1)",
        boxShadow: "0 0 0 3px rgba(133, 212, 178, 0.27)",
        width: 17,
        height: 17,
        textAlign: "center", 
        marginRight: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& path": {
            fill: "#fff"
        },
        "& svg": {
            marginBottom: "1px",
            transform: "rotate(90deg)"
        }
    },
    infoIconBox: {
        border: "1px solid #C2C2C2",
        borderRadius: "50%",
        width: 16,
        height: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        marginLeft: 7
    },
    runsSelect: {
        visibility: "hidden",
        top: 13,
        "& svg": {
            color: "#000000"
        }
    },
    runsSelectLapse: {
        visibility: "hidden",
        top: 43,
        right: 143,
        "& svg": {
            color: "#000000"
        }
    },
    runsSelectItem: {
        padding: "5px 12px",
        fontFamily: "Roboto!important",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '30px',
        color: "#312E40",
        width: 215,
        justifyContent: "center",
        textTransform: "capitalize"
    },
    expandContainer: {
        background: "#FFFFFF",
        paddingRight: 40,    
        height: 44,    
        borderTop: "1px solid #F8F9FB",
        borderBottom: "4px solid #0BCB47",
        "& svg": {
            marginLeft: 8
        }
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 25,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px",
        "& .nodata-indicator--container": {
            width: "76%"
        },
        "& .nodata-indicator--text": {
            top: 136
        }
    },
    backdrop: {
        position: "fixed",
        height: "100vh",
        background: "#00000095",
        top: 0,
        left: 80,
        zIndex: 1000
    }
}))


const RunResultsButton = styled(Button)(() => ({
      ...basicButtonStyles,
      borderRadius: '10px',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      color: '#FFF',
      fontFamily: "Roboto!important",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
      width: "auto",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 15px",
      '&:hover': {
        opacity: '0.85',
        backgroundColor: '#363ED3',
        borderColor: '#FFF',
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: '#1D2398',
        color: '#FFFFFF'
      },
      '&:active': {
        backgroundColor: '#1D2398'
      },
      "@media (max-width:1228px)": {
        width: 'unset'
      },
  })); 

const RunCampaignsButton =  styled(Button)(() => ({
    ...uiViewButtonStyles,
    backgroundColor: "#363ED3",
    marginLeft: 36,
    '&:hover': {
        opacity: '0.85',
        backgroundColor: "#363ED3",
        borderColor: '#FFF',
        boxShadow: 'none',
    },
    '&:active': {
        backgroundColor: '#1D2398'
    },
    '&.Mui-disabled': {
      backgroundColor: '#1D2398',
      color: '#FFFFFF'
    },
}));

const ExpandButton =  styled(Button)(() => ({
    backgroundColor: "#FFFFFF",
    color: "#363ED3",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textTransform: "capitalize",
    '&:hover': {
        backgroundColor: "#FFFFFF",
        opacity: '0.85'
    },
}));

const ApiService = new BookAnalysisService()
const AppService = new appApiService()

const Opportunities = ({ outSideRef, projectType }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const tableData = useSelector(selectOpportunities)
    const headersData = useSelector(selectProductsData)
    const userType = useSelector(selectUserCompany)
    const user = useSelector(selectUserCompany)
    const demo = history.location.search.includes("demo") || user === "platform-anonym" 
    const projectIds = useSelector(selectIds)
    const isStoriesLoading = useSelector(selectStoriesIsLoading)
    const [ uiRunsSelectOpen, setUiRunsSelectOpen ] = useState(false)
    const [ lapseRunsSelectOpen, setLapseRunsSelectOpen ] = useState(false)
    const [ openCampaignsDialog, setOpenCampaignsDialog ] = useState(false)
    const [ openRegisterDialog, setOpenRegisterDialog ] = useState(false)
    const currency = getCountryCurrencySymbol()
    const [ expandRetentionTable, setExpandRetentionTable ] = useState(false)
    const [ expandNewBusinessTable, setExpandNewBusinessTable ] = useState(false)
    const [ campaignsData, setCampaignsData ] = useState({
        opportunitiesLapse: [],
        opportunitiesUI: []
    })

    const isAnnuties = projectType === "annuities"
    // move empty table to bottom
    const isReverse = tableData?.opportunitiesUI?.length > 0 && !tableData?.opportunitiesLapse?.length
    
    const handleCloseCampaignsDialog = () => {
        setOpenCampaignsDialog(false)
      }

    const handleUiClick = () => {
        if (headersData?.ui_runs?.length > 1) {
            setUiRunsSelectOpen(true)
        } else {
            handleDataModelingClick(headersData.ui_runs[0])
        }
    }

    const handleLapseClick = () => {
        if (headersData?.lapse_runs?.length > 1) {
            setLapseRunsSelectOpen(true)
        } else {
            handleDataModelingClick(headersData.lapse_runs[0])
        }
    }

    const handleDataModelingClick = (model) => {
        dispatch(resetFilters())
        if (model) {
            const update = moment(model.updated_at).format("MMM DD, YYYY")
            dispatch(setDate(model.created_at))
            dispatch(setEffectiveDate(model.config?.effective_date ? moment(model.config.effective_date).format("MMM DD, YYYY") : update))
            dispatch(setPolicies(model.project.total_policies))
            dispatch(setPrecisionConfig(model.precision_cfg))
            dispatch(setPotential(model.project.total_premium))
            dispatch(setModel(model.model_type))
            dispatch(setProjectName(model.project.project_name))
            dispatch(setRunName(model.run_name))
            dispatch(setDuration(model.period_months))
            dispatch(setActiveOption("Run"))
            sessionStorage.setItem(
              "run_data", 
              JSON.stringify(
                { 
                  projectName: model.project.project_name, 
                  runName: model.run_name, 
                  model: model.model_type, 
                  date: moment(model.created_at).format("MMM DD, YYYY"), 
                  policies_in_run: model.project.total_policies, 
                  premium: model.project.total_premium,
                  premium_loss: model.project.potential_premium_loss,
                  cfg: model.precision_cfg,
                  duration: model.period_months,
                  effective_date: model.config?.effective_date ? model.config.effective_date : update,
                }
              )
            )
            history.push(`/run?${model.run_id}${demo ? "&demo=true" : ""}`)
        }
    }

    const handleCampaignsPopupOpen = () => {
        if (userType === "platform-anonym") {
            setOpenRegisterDialog(true)
        } else if (demo) {
            history.push("/campaigns")
        } else {
            setOpenCampaignsDialog(true)
        }

    }
    
    const handleChangeStory = (story, model, value) => {
        const data = model === "lapse" 
        ? campaignsData?.opportunitiesLapse?.filter(({ name }) => name !== story) 
        : campaignsData?.opportunitiesUI?.filter(({ name }) => name !== story) 
        setCampaignsData({
            opportunitiesLapse: model === "lapse" ? [
                ...data,
                { name: story, action: value }
            ] : campaignsData.opportunitiesLapse,
            opportunitiesUI: model === "ui" ? [
                ...data,
                { name: story, action: value }
            ] : campaignsData.opportunitiesUI,
        })
    }

    const handleRunAllCampaigns = (callback = () => {}) => {
        const lapseStories = campaignsData?.opportunitiesLapse
        const uiStories = campaignsData?.opportunitiesUI
        const runs = []
        headersData?.lapse_runs?.length && runs.push(...headersData.lapse_runs.map(({ run_id }) => run_id))
        headersData?.ui_runs?.length && runs.push(...headersData.ui_runs.map(({ run_id }) => run_id))

        if (lapseStories?.length) {
            ApiService._postCampaignsStories({
                run_ids: runs,
                stories: lapseStories
            },
            () => {
                !uiStories?.length && callback()
            }, (err) => console.log(err))
        }

        if (uiStories?.length) {
            ApiService._postCampaignsStories({
                run_ids: runs,
                stories: uiStories
            },
            () => {
                callback()
            }, (err) => console.log(err))            
        }
    }

    useEffect(() => {                    
        if (!projectIds?.length) {
            dispatch(setStoriesIsLoading(true))

            AppService._getRuns(
                localStorage.getItem("companyID"),
                (res) => {
                    const meta = []
                    const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                        meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "",})
                        return project_id
                    })
                    dispatch(setProjectIds(ids))
                    dispatch(setProjectLabels(meta))
                }, 
            (error) => { dispatch(setStoriesIsLoading(false)); console.log(error);}
            )

        }
    }, [dispatch, projectIds])

    useEffect(() => {
        dispatch(setStoriesIsLoading(true))
        ApiService._getImpactByStories(demo, projectIds, (response) => {
            dispatch(setOpportunities(response.data))
            dispatch(setStoriesIsLoading(false))
        }, (error) => { dispatch(setStoriesIsLoading(false)); console.log(error); })
    }, [dispatch, projectIds, demo])

    // Close the dropdown if clicked outside
    useEffect(() => {
        const checkIfClickedOutside = () => {
            if (uiRunsSelectOpen) {
                setUiRunsSelectOpen(false);
            }
            if (lapseRunsSelectOpen) {
                setLapseRunsSelectOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [lapseRunsSelectOpen, uiRunsSelectOpen, outSideRef])
    
    return (
        <Grid container direction="column" justifyContent="center">
            {isStoriesLoading && <Grid container justifyContent="center" alignItems="center" className={classes.backdrop}>
                <Loader/>
            </Grid>}
            <Grid container justifyContent="center" className={classes.main}>
            <Grid container justifyContent="center" direction={
                isReverse ? 
                "column-reverse" : "column"
                }>
                <Grid container justifyContent="flex-end" style={tableData?.opportunitiesLapse?.length > 0 ? {} : { opacity: .4 }}>  
                    {!tableData?.opportunitiesLapse?.length && <NoDataIndicator title="No models have been run to reveal new business opportunities"/>}
                    <Grid container direction="column" justifyContent="space-between" className={classes.sideBar} style={!isReverse ? { paddingTop: 64 } : {}}>                  
                        <Grid container justifyContent="flex-start" className={classes.sideBarContainer}  style={{ background: "#363ED310", marginBottom: 44 }}>
                            <Grid>
                                <Grid item container className={classes.sideBarTitle} alignItems="center">
                                    Retention
                                    <InfoTooltip title="Policy upgrades and cross-sell opportunities" placement="top">
                                        <div className={classes.infoIconBox}>
                                            <InfoIcon/>
                                        </div>
                                    </InfoTooltip>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.additionalStatsText}>Policies</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.additionalStatsValue}>
                                            <NumberFormat value={tableData.policiesLapse} displayType={'text'} thousandSeparator={true}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.additionalStatsText} style={{ color: isAnnuties ? 'rgba(35, 32, 51, 0.25)' : '#232033'}}>{`${isAnnuties ? "Potential Upside" : "Potential Premium Upside"}`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={clsx(classes.additionalStatsValue, classes.highLightText)}>{nFormatter(tableData.premiumLapse, 2, currency)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="flex-start">
                                    <RunResultsButton 
                                        disableRipple
                                        onClick={handleLapseClick} 
                                        disabled={headersData?.lapse_runs?.length === 0 || user === "VidaCaixa"} 
                                        style={headersData?.lapse_runs?.length === 0 ? { justifyContent: "center" } : {}}>               
                                        {"Run Results"}
                                        {headersData?.lapse_runs?.length > 1 && <ArrowDropDown/>}
                                    </RunResultsButton>
                                    {
                                        headersData?.lapse_runs?.length > 1 &&
                                    <Select className={classes.runsSelect} open={lapseRunsSelectOpen} value={""} onChange={(e) => handleDataModelingClick(e.target.value)}>
                                            {
                                                [...headersData.lapse_runs]
                                                    ?.sort((a,b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf())
                                                    ?.map((item) => <MenuItem className={classes.runsSelectItem} value={item}>{item?.project?.project_name} {item?.model_type}</MenuItem>) 
                                            }
                                    </Select> 
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.tableContainer}>
                        <TableContainer className={!isReverse ? '' : classes.tableFooterContainer} style={!expandRetentionTable ? { height: !isReverse ? 400 : 287, overflow: "hidden" } : { minHeight: 400 }}>
                            <Table>
                                <TableHead className={!isReverse ? classes.tableHead : ''} style={!isReverse ? { borderBottom: "4px solid #363ED3" } : { height: 0 }}>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ width: 158}}>Policyholder Stories</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Total Prospects" : <>Number of<br/>Prospects</>}</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "AUM" : "Premium"}</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Assignable Prospects" : <>New Policies<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "AUM of Assignable Prospects" : <>New Premium<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">Recommended action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {
                                        tableData?.opportunitiesLapse?.map(
                                            (data) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ color: "#363ED3", fontWeight: 700, lineHeight: "24px", textTransform: "capitalize" }}>
                                                        {data["opportunity"]}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {<NumberFormat value={data["prospects"] ? data["prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {nFormatter(data["potential_premium"], 2, currency)}
                                                    </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {<NumberFormat value={data["assignable_prospects"] ? data["assignable_prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {nFormatter(data["assignable_prospects_potential_premium"], 2, currency)}
                                                        </TableCell>    
                                                    <TableCell component="th" scope="row">
                                                        {<CampaignTypeSelect defaultValue={data["campaign"]} story={data["opportunity"]} model="lapse" onChange={handleChangeStory}/>}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid container justifyContent="flex-end" className={classes.expandContainer} style={tableData?.opportunitiesUI?.length > 0 ? {borderBottom: !isReverse ? "4px solid #0BCB47" : "none"} : { borderBottom: !isReverse ? "4px solid" : "none", borderBottomColor: "#0BCB4740" }}>
                            <ExpandButton disabled={!tableData?.opportunitiesLapse?.length} onClick={() => setExpandRetentionTable(!expandRetentionTable)}>
                                {expandRetentionTable ? "View less" : "View all"}
                                <IconArrowUp style={expandRetentionTable ? {} : { rotate: "180deg" }}/>
                            </ExpandButton>
                        </Grid>                                
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-end" style={tableData?.opportunitiesUI?.length > 0 ? {} : { opacity: .4 }}>
                    {!tableData?.opportunitiesUI?.length && <NoDataIndicator title="No models have been run to reveal new business opportunities"/>}  
                    <Grid container direction="column" justifyContent="space-between" className={classes.sideBar}  style={isReverse ? { paddingTop: 64 } : {}}>                  
                        <Grid container justifyContent="flex-start" className={classes.sideBarContainer} style={{ background: "#0BCB4710", marginTop: -4, marginBottom: 44 }}>
                            <Grid>
                                <Grid item container className={classes.sideBarTitle} alignItems="center">
                                    New Business
                                    <InfoTooltip title="Policy upgrades and cross-sell opportunities" placement="top">
                                        <div className={classes.infoIconBox}>
                                            <InfoIcon/>
                                        </div>
                                    </InfoTooltip>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.additionalStatsText}>Policies</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.additionalStatsValue}>
                                            <NumberFormat value={tableData.policiesUI} displayType={'text'} thousandSeparator={true}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <Typography className={classes.additionalStatsText} style={{ color: isAnnuties ? 'rgba(35, 32, 51, 0.25)' : '#232033' }}>{`${isAnnuties ? "Potential AUM" : "Potential Premium Upside"}`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={clsx(classes.additionalStatsValue, classes.highLightText)}>{nFormatter(tableData.premiumUI, 2, currency)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="flex-start">
                                    <RunResultsButton 
                                    onClick={handleUiClick} 
                                    disabled={headersData?.ui_runs?.length === 0 || user === "VidaCaixa"}
                                    style={headersData?.lapse_runs?.length === 0 ? { justifyContent: "center" } : {}}>
                                        {"Run Results"}
                                        {headersData?.ui_runs?.length > 1 && <ArrowDropDown/>}
                                    </RunResultsButton>
                                    {
                                        headersData?.ui_runs?.length > 1 &&
                                    <Select className={classes.runsSelect} open={uiRunsSelectOpen} value={""} onChange={(e) => handleDataModelingClick(e.target.value)}>
                                            {
                                                [...headersData.ui_runs]
                                                    ?.sort((a,b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf())
                                                    ?.map((item) => <MenuItem className={classes.runsSelectItem} value={item}>{item?.project?.project_name} {item?.model_type}</MenuItem>) 
                                            }
                                    </Select> 
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.tableContainer}>
                        <TableContainer className={isReverse ? '' : classes.tableFooterContainer} style={!expandNewBusinessTable ? { height: isReverse ? 400 : 287, overflow: "hidden", } : { minHeight: 287}}>
                            <Table>
                                <TableHead className={isReverse ? classes.tableHead : ''} style={isReverse ? { borderBottom: "4px solid #0BCB47" } : { height: 0 }}>
                                    <TableRow>
                                    <TableCell component="th" scope="row" style={{ width: 158}}>Policyholder Stories</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Total Prospects" : <>Number of<br/>Prospects</>}</TableCell>
                                        <TableCell component="th" scope="row">AUM</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Assignable Prospects" : <>New Policies<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "AUM of Assignable Prospects" : <>New Premium<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">Recommended action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {
                                        tableData?.opportunitiesUI?.map(
                                            (data) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" style={{ color: "#363ED3", fontWeight: 700, lineHeight: "24px", textTransform: "capitalize" }}>
                                                        {data["opportunity"]}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {<NumberFormat value={data["prospects"] ? data["prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {nFormatter(data["potential_premium"], 2, currency)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {<NumberFormat value={data["assignable_prospects"] ? data["assignable_prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {nFormatter(data["assignable_prospects_potential_premium"], 2, currency)}
                                                    </TableCell>      
                                                    <TableCell component="th" scope="row">
                                                        {<CampaignTypeSelect defaultValue={data["campaign"]} story={data["opportunity"]} model="ui" onChange={handleChangeStory}/>}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid container justifyContent="flex-end" className={classes.expandContainer} style={{ borderBottom: isReverse ? "4px solid #363ED3" : "none" }}>
                            <ExpandButton disabled={!tableData?.opportunitiesUI?.length} onClick={() => setExpandNewBusinessTable(!expandNewBusinessTable)}>
                                {expandNewBusinessTable ? "View less" : "View all"}
                                <IconArrowUp style={expandNewBusinessTable ? {} : { rotate: "180deg" }}/>
                            </ExpandButton>
                        </Grid>                               
                    </Grid>
                </Grid>   
            </Grid>                          
                <Grid container justifyContent="center" style={{ height: "unset", paddingTop: 11, borderTop: "4px solid #363ED3" }}>
                    <Grid container direction="column" justifyContent="space-between" className={classes.sideBar}>
                        <Grid container justifyContent="flex-start" alignItems="center" className={classes.bgDark} style={{ borderTop: "2px solid #FFFFFF" }}>
                            <Grid item className={classes.sideBarTitle} style={{ paddingLeft: 24, paddingBottom: 0 }}>
                                <div className={classes.totalIcon}>
                                        <IconArrow/>
                                </div>
                                Total
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.tableContainer}>
                        <TableContainer className={classes.tableFooterContainer} style={{ maxHeight: 60, overflow: "hidden" }}>
                            <Table>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                    <TableCell component="th" scope="row" style={{ width: 158}}>Policyholder Stories</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Total Prospects" : <>Number of<br/>Prospects</>}</TableCell>
                                        <TableCell component="th" scope="row">AUM</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "Assignable Prospects" : <>New Policies<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">{isAnnuties ? "AUM of Assignable Prospects" : <>New Premium<br/>In Pipeline</>}</TableCell>
                                        <TableCell component="th" scope="row">Recommended action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    <TableRow className={classes.tableFooter}>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell component="th" scope="row" style={{ padding: '9px 12px 12px' }}>
                                                <NumberFormat value={tableData.total_policies} displayType={'text'} thousandSeparator={true}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{ padding: '9px 12px 12px' }}>{nFormatter(tableData.total_premium, 2, currency)}</TableCell>
                                        <TableCell component="th" scope="row" style={{ padding: '9px 12px 12px' }}><NumberFormat value={tableData.total_assignable_prospects} displayType={'text'} thousandSeparator={true}/></TableCell>
                                        <TableCell component="th" scope="row" id="highlight" className={classes.highLightText} style={{ padding: '9px 12px 12px' }}>{nFormatter(tableData.total_assignable_prospects_potential_premium, 2, currency)}</TableCell>
                                        <TableCell component="th" scope="row"><div style={{ visibility: "hidden"}}><CampaignTypeSelect defaultValue={"Postpone"}/></div></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                    </TableContainer>    

                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" style={{ padding: "42px 0" }}>
                <UIViewButton disableRipple onClick={() => dispatch(setActiveTab("Prospects"))}>
                    View Prospects
                </UIViewButton>
                <RunCampaignsButton disableRipple onClick={handleCampaignsPopupOpen}>
                    Run All Campaigns
                </RunCampaignsButton>
            </Grid>

            <CreateCampaignsDialog open={openCampaignsDialog} onClose={handleCloseCampaignsDialog} onConfirm={handleRunAllCampaigns} demo={userType === "platform-anonym"}/>
            <UnregisteredPopup open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}/>
        </Grid>
    )
}

export default Opportunities