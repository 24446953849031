import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import FormInput from "../../Components/Forms/FormInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ReactComponent as AtidotText } from "../../Assets/icon-atidot-text.svg";
import  CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { clearResetCode, selectResetCode, selectUserName, setEmail, setResetCode, setUserName } from "../Login/LoginSlice";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { decryptData } from "../CreateCampaign/helpers";
import SendCodeForm from "../../Components/Forms/SendCodeForm/SendCodeForm";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";

const useStyles = makeStyles(() => ({
    signUpBackground: {
        background: "#F8F9FB",
        width: "100%",
        height: "100vh",
        "& #laptopSVG": {
            left: -59,
            top: 10,
            "@media (min-width:1440px)": {
                top: 80
            }
        },
        "& #chartSVG": {
            top: 240,
            left: 5,
            "@media (min-width:1440px)": {
                top: 311
            }
        },
        "& #paperPlaneSVG": {
            top: 89,
            right: 38,
            "@media (min-width:1440px)": {
                top: 159
            }
        }
    },
    backgroundImage: {
        position: "absolute",
        zIndex: 0,
    },
    signInTitle:{
        fontFamily: 'Strawford!important',
        fontWeight: 600,
        fontSize: 24,
    },
    logoContainer:{
        paddingBottom: 10,
        "@media (min-width:1440px)": {
            paddingBottom: 30,
        }
    },
    newpassText:{
        fontFamily: 'Strawford!important',
        fontWeight: 400,
        fontSize: 32,
        lineHeight: "32px",
        color: '#312E40',
        paddingBottom: 15,
        "@media (min-width:1440px)": {
            paddingBottom: 27,
        }
    },
    successText:{
        paddingTop: 16,
        fontFamily: 'Roboto!important',
        fontWeight: 400,
        fontSize: 16,
        maxWidth: 756,
        '& a': {
            color: '#3444CB',
            textDecoration: "none"
        }
    },
    buttonContainer:{
        textAlign: "center",
        paddingTop: 15,
        paddingBottom: 15,
    },
    buttonBlock:{
        background: '#0BCB47',
        width: 248,
        height: 55,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: 'capitalize',
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif !important",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
          backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        }
    },
    signInRegister:{
        color: '#312E40',
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 300,
        lineHeight: "19px",
        paddingTop: 32,
        '& span': {
            color: '#363ED3',
            fontWeight: 700,
            cursor: "pointer",
            textDecoration: "underline"
        }
    },
    forgotSubtitle: {
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "120%",
        textAlign: "center",
        color: "#312E40",
        paddingBottom: 16,
        "& a": {
            fontWeight: 500,
            color: "#000000"
        },
        "@media (min-width:1440px)": {
            paddingBottom: 20,
        }
    },
    footerText: {
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 300,
        lineHeight: "120%",
        textAlign: "center",
        color: "#312E40",
        "& a": {
            fontWeight: 500,
            color: "#000000"
        }
    },
    signupForm: {
        width: "100%",
    },
    loginInputLabel: {
        color: "#312E40",
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "16px",
        paddingTop: 8,
        paddingBottom: 8
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        },
        paddingTop: 34
    },
    goBackIcon: {
        position: "absolute",
        cursor: "pointer",
        top: 24,
        left: 24
    },
    inputsContainer: {
        maxWidth: 367
    },
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [success, setSuccess] = useState(false);
    const code = useSelector(selectResetCode)
    const user = useSelector(selectUserName)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')
    const formRef = useRef(null)

    function handleSubmit() {
        if (username === user) {
            setShowAlert(false)
            setAlertMessage("")        
            axios.post(
                process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${username}/password/reset`,
                {
                    code,
                    password: newPassword
                }
            )
                .then(() => {
                    setShowAlert(false)
                    setAlertMessage("")
                    dispatch(clearResetCode())
                    dispatch(setUserName(''))
                    setSuccess(true)
                    localStorage.removeItem("resetCode")
                })
                .catch(() => {
                    setShowAlert(true)
                    setAlertSeverity('error')
                    setAlertMessage("Code you entered is incorrect!")
                })
            
        } else {
            setShowAlert(true)
            setAlertSeverity('error')
            setAlertMessage("Please enter the email we send a code to!")
        }
    }

    const handleSendCode = (handleSuccess, handleError, email) => {
        axios.get(
            process.env.REACT_APP_AUTH_API_URL+`/v1/users/email=${email}/password/reset/code`
        )
            .then((res) => {
                handleSuccess()
                setShowAlert(false)
                setAlertMessage("")
                dispatch(setUserName(email))
                dispatch(setEmail(email))
                const date = new Date();
                history.push("/confirmreset?"+date.getTime())
            })
            .catch((err) => {
                handleError(err)
            })
    } 

    useEffect(() => {
        setUsername(history?.location?.search ? history.location.search?.replace("?", "") : "")
    }, [history.location.search])

    useEffect(() => {
        ValidatorForm.addValidationRule("isPasswordsMatch", (value) => {
            if (value !== newPassword) {
                return false
            }
            return true
        })
    }, [newPassword])

    useEffect(() => {
        if (!user) {
            dispatch(setUserName(localStorage.getItem('resetEmail')))
        }
    }, [user, dispatch])

    useEffect(() => {
        if (!code && localStorage.getItem('resetCode')) {
            dispatch(setResetCode(decryptData(localStorage.getItem('resetCode'))))
        }
    }, [code, dispatch])

    return (
        <LoginContainer> 
            <CloseIcon onClick={() => history.push("/login")} className={classes.goBackIcon}/>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                    {success ?
                    <Grid item>
                        <Typography align="center" component="h1" variant="h5" className={classes.signInTitle}>
                            Your password is successfully reset
                        </Typography>
                        <Typography align="center" component="p" className={classes.successText}>
                            Go back to? <Link to="/login">Log In</Link> 
                        </Typography>
                    </Grid>
                    : <Grid container direction="column" justifyContent="center" alignContent="center">
                        <Grid align="center" item className={classes.logoContainer}>
                            <AtidotText/>
                        </Grid>    
                        <Typography align="center" component="p" className={classes.newpassText}>
                            {user ? "Please Enter New Password" : "Forgot your password?"}
                        </Typography>
                        {!user && <Typography align="center" component="p" className={classes.forgotSubtitle}>
                            To reset your password, enter your email address
                        </Typography>}
                    </Grid>
                    }
                    <Grid item className={classes.signupForm}>
                    {!success && user ?
                        <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                            <Grid container direction="column">
                                <Grid container justifyContent="center">
                                    <Grid container direction="column" className={classes.inputsContainer}>                                        
                                        <Grid item>
                                            <Typography className={classes.loginInputLabel}>E-mail</Typography>
                                            <FormInput
                                                type="email"
                                                name="email"
                                                value={username}
                                                onChange={(event) => setUsername(event.target.value)}
                                                validators={['required', 'isEmail']}
                                                errorMessages={['this field is required', 'Please enter a valid email address']}
                                                required
                                                data-testid="emailInput"/>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.loginInputLabel}>New Password</Typography>
                                            <FormInput
                                                showText={false}
                                                name="password"
                                                value={newPassword}
                                                onChange={(event) => setNewPassword(event.target.value)}
                                                validators={['required', 'matchRegexp:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*[!@#$%^&*?():;}{~`]).{8,}']}
                                                errorMessages={['this field is required', 'Must be 8 or more characters, consist only numbers and letters and contain both upper and lower case letters']}
                                                required
                                                data-testid="passwordInput"/>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.loginInputLabel}>Confirm Password</Typography>
                                            <FormInput
                                                showText={false}
                                                name="password"
                                                value={confirmPassword}
                                                onChange={(event) => setConfirmPassword(event.target.value)}
                                                validators={['required', 'isPasswordsMatch']}
                                                errorMessages={['this field is required', 'Passwords doesn`t match']}
                                                required
                                                data-testid="passwordInput"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" direction="column">
                                    <Grid item className={classes.buttonContainer}>
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.buttonBlock}
                                        data-testid="loginButton"
                                        disabled={false}
                                        >
                                            Confirm
                                        </Button>
                                    </Grid>
                                    <Grid item container justifyContent="center">
                                        <Typography className={classes.footerText}>
                                        For assistance or inquiries, please email us at <a href="mailto:support@atidot.com">support@atidot.com</a>
                                        </Typography>
                                    </Grid>

                                </Grid>

                                <Grid item justifyContent="center" className={classes.alert}>
                                    {showAlert ? 
                                        <Alert variant="outlined" severity={alertSeverity}>
                                        <AlertTitle>{alertTitle}</AlertTitle>
                                        {alertMessage}
                                        </Alert>
                                        : ''}
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        : !success && 
                        <SendCodeForm onSend={handleSendCode}/>
                        }
                        </Grid>
            </Grid>
        </LoginContainer>    
    )
}

export default ForgotPassword