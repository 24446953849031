import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';

// Components
import Step1 from "../../Components/StepsComponents/Step1/Step1";
import Step2 from "../../Components/StepsComponents/Step2/Step2";
import Step3 from "../../Components/StepsComponents/Step3/Step3";
import InformationPopup from "../../Components/Popups/InformationPopup/InformationPopup";

// Redux
import {
  resetValues,
  selectCsvInfo,
  selectSourceId,
  selectMapperOptions,
  selectProjectName,
  setRunId,
  setCsvInfo,
  setSourceId,
  selectDataEffectiveYear,
  selectDataEffectiveMonth,
  selectZipCheck,
  selectTableName,
  selectDBType,
  setAutoMapping,
  setMapperError,
  setRunIds,
  selectProjectId,
  setProjectId,
  selectFileName,
  setSelectSourceMode,
  selectDuration,
  selectProductType,
  selectValidateCheck,
  setValidationError,
} from "./NewProjectSlice";
import { selectStep2Component, selectUserRole, setActiveOption, setSearch, setStep2Component } from '../../AppSlice';
import newProjectApiService from "./NewProjectService";

// Common
import { amplitudeService } from "../../global/helpers";
import ProjectApiService from "../../Components/Projects/ProjectService";
import { User, checkPermission } from "../../global/user";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  button: {
    color: "#312e40",
    "&:hover": {
      backgroundColor: "#363ED3",
      color: "white",
    },
    textTransform: "capitalize",
  },
  horizontal: {
    maxWidth: "560px",
    margin: "0 auto",
  },
  stepHorizontal: {
    padding: 0,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  TitleProperties: {
    marginLeft: 77,
  },
  ExitProperties: {
    position: "absolute",
    right: 20,
  },
  floatRight: {
    float: "right",
  },
  headerBorder: {
    borderBottom: "1px solid #d9e1ee",
  },
  footerBorder: {
    borderTop: "1px solid #d9e1ee",
    position: "absolute",
    bottom: 0,
    paddingTop: 5,
  },
  StepperContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f8f9fb",
    minHeight: "calc(100vh - 170px)",
  },
  StepperContainerBody: {
    maxWidth: "none",
  },
  customFooter: {
    display: "flex",
    paddingLeft: "25%",
    paddingRight: "25%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    height: '100px',
    position: "fixed",
    bottom: 0,
  },
  customButtonContainer: {
    gridColumn: "2",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "30px 0",
  },
  customNextButton: {
    backgroundColor: "#363ed3",
    color: "white !important",
    width: "148px",
    borderRadius: "12px",
    padding: "9px 10px",
    fontSize: "13px",
    fontWeight: "normal",
    textTransform: "none",
    cursor: "pointer",
    "&.Mui-disabled": {
      color: "#CBCACB !important",
      backgroundColor: "#E4E5E5 !important",
    },
  },
  customBackButton: {
    width: "120px",
    borderRadius: "12px",
    padding: "9px 10px",
    fontSize: "13px",
    fontWeight: "normal",
    textTransform: "none",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#363ed3",
    },
  },
  customColorStreper: {
    backgroundColor: "#363ed3",
  },
  icon: {
    width: "32px",
    height: "32px",
    color: "white",
    border: 'solid 1px #d9e1ee',
    borderRadius: '50%',
    marginRight: '6px',
    "& text": { fill: "#242a8c !important", fontFamily: "'Strawford', sans-serif !important", fontSize: '14px' },

    "&$activeIcon": {
      color: "#363ed3",
      cx: '16px',
      cy: '16px',
      r: '16px',
      "& text": { fill: "white !important", fontFamily: "'Strawford', sans-serif !important", fontSize: '14px' },
    },
    "&$completedIcon": {
      color: "#363ed3",
      "& text": { fill: "white !important" }
    }
  },
  activeIcon: {},
  labelStyle: {
    color: '#312e40 !important',
    fontFamily: "'Strawford', sans-serif !important",
    fontWeight: 'normal !important'
  },
  styleTitle: {
    fontFamily: 'Strawford !important',
    fontSize: '18px',
    color: '#312e40',
  },
  completedIcon: {},
  stepperRoot: {
    padding: "1.5rem 0",
  },
  validationPopover: {
    width: 540,
    minHeight: 500,
    borderRadius: 9,
    border: "1px solid #d9e1ee",
    backgroundColor: "#FFF",
    padding: 25,
    top: "50px!important"
  },
  validationPopoverHeader: {
    borderBottom: '1px solid #d9e1ee',
    paddingBottom: 25
  },
  validationPopoverContent: {
    textAlign: 'center'
  },
  validatedHeaderTitle: {
    fontWeight: 500,
    fontSize: 16
  },
  validationHeaderLoader: {
    width: "24px!important",
    height: "24px!important"
  },
  validationContentLoader: {
    margin: "0 auto",
    marginTop: "20%"
  },
  successStatus: {
    color: "#4caf50!important"
  },
  errorStatus: {
    color: "#ef5350!important"
  },
  validatedPolicySuccess: {
    backgroundColor: "rgb(237, 247, 237)",
    marginTop: 25,
    borderRadius: 5
  },
  validatedPolicyError: {
    backgroundColor: "rgb(253, 237, 237)",
    marginTop: 25,
    borderRadius: 5
  },
  exportInvalidCsvButton: {
    marginTop: 10,
    border: "1px solid",
    padding: "6px 16px",
  },
  exportInvalidCsvIcon: {
    fill: "#ef5350",
    width: 18,
    height: 18
  },
  mapDataButton: {
    position: "relative",
    top: 150,
    right: 4
  }
}));

const ApiService = new newProjectApiService()
const ProjectsService = new ProjectApiService()

function getSteps() {
  return ["Start New Project", "Select Data Source", "Match Fields"];
}

const NewProject = () => {
  const classes = useStyles();
  const history = useHistory();
  const rootRef = useRef(null)
  const [activeStep, setActiveStep] = useState(0);
  const [runModelNames, setRunModelNames] = useState([]);
  const [status, setStatus] = useState({
    id: null,
    success: false
  });
  const [openValidation, setOpenValidation] = useState(false)
  const dispatch = useDispatch();
  const steps = getSteps();
  const projectName = useSelector(selectProjectName);
  const projectId = useSelector(selectProjectId);
  const sourceId = useSelector(selectSourceId);
  const dataEffectiveYear = useSelector(selectDataEffectiveYear);
  const dataEffectiveMonth = useSelector(selectDataEffectiveMonth);
  const zipCheck = useSelector(selectZipCheck);
  const validateCheck = useSelector(selectValidateCheck);
  const duration = useSelector(selectDuration);
  const product = useSelector(selectProductType);

  const Step2Component = useSelector(selectStep2Component);
  const tableName = useSelector(selectTableName);
  const dbType = useSelector(selectDBType);
  const csvInfo = useSelector(selectCsvInfo);
  const mapperOptions = useSelector(selectMapperOptions);
  const fileName = useSelector(selectFileName);

  const userRole = useSelector(selectUserRole);
  const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnStage;

  useEffect(() => {
    if (status.success) {
      sessionStorage.removeItem("current_id")
      history.push("/")
    }
  }, [status]);

  useEffect(() => {
    return () => {
      handleExitNewProject(status)
    };
  }, []);

  const handleUploadCsv = (response) => {
    amplitudeService._throwEvent("FILE_UPLOAD", { "file_name": fileName })
    dispatch(setSourceId(response.data['source_id']))
  }

  const handleLoadDB = (response) => {
    dispatch(setSourceId(response.data.source_id));
  }

  const handleLoadS3Bucket = (response) => {
    dispatch(setSourceId(response.data.source_id));
  }

  const goBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    } else {
      handleExitNewProject(status)
    }
  }

  const handleBackClick = () => {
    setActiveStep(0)
  }

  const handleClose = () => {
    history.push("/")
  }

  const handleCreateProject = (models, enableNextButton) => {
    const date = new Date()
    ApiService._postNewProject({
      project_name: projectName ? projectName : date.toISOString().split("T")[0] + "-" + date.getTime().toString().substring(7),
      source_id: sourceId.toString(),
      model_type:"lapse"
    },
      (response) => {
        sessionStorage.setItem("current_id", response.data["project_id"])
        dispatch(setProjectId(response.data["project_id"]));
        setStatus({ id: response.data["project_id"], success: false })
        const payloads = []
        models.forEach((model, idx) => {
          const runName = crypto.randomUUID().split("-")[0]
          let runPayload = {
            extra_parameters: {
              predict_date: `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}`
            },
            model_type: model.toLowerCase(),
            run_name: runName,
            period_months: duration,
            product_type: product,
            train_model: false
          }
          if (dataEffectiveYear && dataEffectiveMonth) {
            runPayload["effective_date"] = `${date.getDate() < 9 ? "0" : ""}${date.getDate()} ${dataEffectiveMonth} ${dataEffectiveYear}`
          }
          payloads.push(runPayload)

        })
        ApiService._postCreateAllRuns(
          {
            project_id: parseInt(sessionStorage.getItem("current_id")),
            runs: payloads
          },
          (response) => {
            setOpenValidation(false)
            sessionStorage.removeItem("atidot_platform_data")

            if (!response.data["runs"]?.length && response.data["failed"]?.length) {
              ProjectsService._postArchiveProject(sessionStorage.getItem("current_id"), () => {
                dispatch(setRunIds([]));
                setStatus({ ...status, success: true });
                dispatch(setSelectSourceMode(false));
                dispatch(setProjectId(''));
                enableNextButton()
              }, (err) => { console.log(err) })
            } else {
              const runs = response["runs"]?.map((run) => ({ [run.model_type]: run.run_id }))
              dispatch(setRunIds(runs));
              setStatus({ ...status, success: true });
              dispatch(setSelectSourceMode(false));
              dispatch(setProjectId(''));
              enableNextButton()
              amplitudeService._throwEvent("CREATE_PROJECT", {
                "project": projectName
              })
            }
          },
          (error) => {
            setOpenValidation(false)

            console.log(error)
            ProjectsService._postArchiveProject(sessionStorage.getItem("current_id"), () => {
              dispatch(setMapperError({ present: true, message: "" }))
              dispatch(resetValues())
              dispatch(setSearch(""))
              enableNextButton()
            }, (err) => { console.log(err) })
          }
        )
      }, (error) => {
        dispatch(setMapperError({ present: true, message: error?.response?.data?.error || '' }))
        console.log(error)
        //ApiService._deleteCancelProjectCreation(projectId, () => {}, (err) => console.log(err))
      })

  }

  const handleFirstStep = (models) => {
    setRunModelNames(isBroker ? ["Lapse", "UI"] : models )
    if (Step2Component === "Uploaded") {
      dispatch(setSelectSourceMode(true));
    }
    //dispatch(setRunModel(models[0]));
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSecondStep = () => {
    if (Object.keys(csvInfo).length === 0) {
      if (Step2Component === "SF") {
        ApiService._getSFTableColumns(sourceId, (response) => {
          dispatch(setCsvInfo({
            headers: response.data,
            columns: response.data
          }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }, (error) => {
          console.log(error)
        })
        return
      }

      if (Step2Component !== "DB") {
        ApiService._getCsvHeaders(sourceId, (response) => {
          dispatch(setCsvInfo(response.data.csv_info));
          let autoMapping = {}
          if (!isBroker) {
            switch (runModelNames[0]) {
              case 'UI':
                autoMapping = response.data.csv_info.ui_auto_mapping
                break;
  
              case 'Cross-sell':
                autoMapping = response.data.csv_info.lapse_auto_mapping
                break;
  
              default:
                autoMapping = response.data.csv_info.lapse_auto_mapping
                break;
            }
          } else {
            autoMapping = response.data.csv_info.broker_auto_mapping
          }

          dispatch(setAutoMapping(autoMapping));
          setActiveStep((prevActiveStep) => prevActiveStep + 1)

        }, (error) => {
          console.log(error)
        })
      } else {
        if (dbType === "postgres" || dbType === "mysql") {
          ApiService._getDBTableColumns(sourceId, tableName, (response) => {
            let data = response.data.db_source_info;
            data.headers = data.columns;
            dispatch(setCsvInfo(data));
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }, (error) => {
            console.log(error)
          })
        } else if (dbType === "s3") {
          ApiService._postS3Data(sourceId, projectId,
            (response) => {
              let data = response.data.csv_info;
              dispatch(setCsvInfo(data));
              dispatch(setSourceId(response.data.source_id))
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }, (error) => {
              console.log(error)
            })
        }
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleExecute = (enableNextButton) => {
    setOpenValidation(true)

    if (!isBroker) {
      runModelNames.forEach((model, idx) => {
        const modelType = model.toLowerCase()
  
        let data = {
          model_type: modelType,
          mapper: mapperOptions,
          validate: validateCheck
        };
  
        data["zip"] = zipCheck ? "True" : "False";
  
        ApiService._postProjectMapper(sourceId, data, () => {
          dispatch(setMapperError({ present: false, message: "" }))
          setOpenValidation(false)
  
          if (idx === runModelNames.length - 1) {
            handleCreateProject([modelType], enableNextButton)
          }
        },
          (error) => {
            setOpenValidation(false)
  
            if (error?.response?.data?.status_code === 406) {
              let rows = 0
              if (error.response?.data?.lapse?.result === "failed") {
                rows = error.response.data.lapse.total_rows - error.response.data.lapse.valid_rows
              }
              if (error.response?.data?.ui?.result === "failed") {
                rows = error.response.data.ui.total_rows - error.response.data.ui.valid_rows
              }
              dispatch(setValidationError({ present: true, message: `We found ${rows} rows invalid, which exceed threshold limitation`, title: "File Validation failed" }))
              enableNextButton()
              return;
            }
            dispatch(setMapperError({ present: true, message: error?.response?.data?.error }))
            dispatch(resetValues())
            dispatch(setSearch(""))
            enableNextButton()
            return;
          })
  
  
  
      })     
    } else {
      ApiService._postProjectMapper(sourceId, {
        model_type: "lapse",
        mapper: {
          ...Object.assign({}, ...Object.entries(mapperOptions)
          .map((item) => {
            const replaceLapseColumn = item[0] === "current_age" ? "age" : item[0] === "zip_code" ? "zipcode" : item[1]
            return ({ [item[0]]: replaceLapseColumn })
          }))
        },
        zip: zipCheck ? "True" : "False"
        }, () => {       
          ApiService._postProjectMapper(sourceId, {
          model_type: "ui",
          mapper: 
          {
            ...mapperOptions
          },
          zip: zipCheck ? "True" : "False"
          }, () => { 
            dispatch(setMapperError({present: false, message: ""})) 
            handleCreateProject(runModelNames, enableNextButton)
          },
          (error) => {
            console.log(error);
            dispatch(setMapperError({present: true, message: ""}))
            dispatch(resetValues())
            enableNextButton()
            return;
          }
        ) 
        },
        (error) => {
          console.log(error);
          dispatch(setMapperError({present: true, message: ""}))
          dispatch(resetValues())
          enableNextButton()
          return;
        }
      )  
    }

  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 onNext={handleFirstStep} onClose={handleClose} />;
      case 1:
        return <Step2 onFileUpload={handleUploadCsv} onDBLoad={handleLoadDB} onS3BucketLoad={handleLoadS3Bucket} onNext={handleSecondStep} onBack={handleBackClick} />;
      case 2:
        return <Step3 outSideRef={rootRef} handleExecute={handleExecute} handleClose={handleClose} handleBack={goBack} />;
      default:
        return "Unknown step";
    }
  }

  const handleExitNewProject = (data) => {
    dispatch(setStep2Component(""));
    if (!data.success && sessionStorage.getItem("current_id")) {
      dispatch(setSelectSourceMode(false));
      ApiService._deleteCancelProjectCreation(sessionStorage.getItem("current_id"), () => {
        dispatch(resetValues());
        dispatch(setRunIds([]));
        dispatch(setActiveOption("Projects"))
        sessionStorage.removeItem("current_id")
      }, (err) => console.log(err))
      dispatch(setProjectId(''));
    }
  };

  useEffect(() => {
    if (!userRole || !checkPermission('create_project')) {
      history.push('/')
    }
  }, [userRole, history])

  useEffect(() => {
    if (activeStep === 0) {
      dispatch(setRunId(""));
    }
  }, [activeStep, dispatch])

  return (
    <Grid container className={classes.root} ref={rootRef}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.StepperContainer}
      >
        {activeStep === steps.length ? (
          <Grid item xs={6}></Grid>
        ) : (
          <Grid
            item container
            className={classes.StepperContainerBody}
            justifyContent="center"
            alignItems="center"
          >
            {getStepContent(activeStep)}
          </Grid>
        )}
        <InformationPopup
          open={openValidation} closeControl={false}
          message="Validating Data.." children={<CircularProgress />}
        />
      </Grid>
    </Grid>
  );
}

export default NewProject