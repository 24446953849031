import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    rootContent: {
        flexGrow: 1,
        height: '83vh'
    },
    rootContentAlt: {
        flexGrow: 1
    },
    headerContainer: {
        paddingLeft: 10
    },
    contentHeader: {
        padding: 0,
        marginTop: 47,
        marginLeft: 47,
        marginRight: 40,
        marginBottom: 22,
        alignItems: "center !important"
    },
    projectsHeader: {
        marginTop: 10,
        display: 'flex',
    },

    portfolioAnalysis: {
        paddingRight: 24
    },
    portfolioAnalysisTitle: {
        fontFamily: "Strawford!important",
        "&.MuiTypography-root": {
            fontSize: 25,
            fontWeight: 500,
            lineHeight: "29px",
            paddingBottom: 5,
            // letterSpacing: 1.7
        }
    },
    portfolioAnalysisText: {
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px"
    },
    portfolioAnalysisValue: {
        fontFamily: "Lato!important",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "17px",
        color: '#232033'
    },
    tableCointainer: {
        borderRadius: 12,
        border: "solid 1px #ecf1f9",
        backgroundColor: "#fff",
        padding: 30,
        paddingTop: 0,
    },
    tableHeader: {
        borderRadius: 8,
        backgroundColor: "#eef0f4",
        height: 44,
        '& .MuiTableCell-stickyHeader': {
            textAlign: "left",
            backgroundColor: "#eef0f4",
        },
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        },
    },
    tableCell: {
        borderBottom: "none !important",
        fontFamily: "Roboto !important",
        fontSize: 12,
        lineHeight: "14px",
        color: "#312E40",
        fontWeight: 400,
        paddingTop: 0,
        paddingBottom: 0
    },
    tableRow: {
        backgroundColor: "#eef0f4",
        '& td:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& td:last-child': {
            borderRadius: '0 1em 1em 0'
        },
        height: 60,
    },

    sourcesCount: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 4,
        color: '#C2C2C2'
    },
    dataCount: {
        color: '#6a6c71',
        fontSize: 16,
        cursor: "pointer",
        paddingBottom: 5,


    },
    dataCountActive: {
        color: '#312e40',
        borderBottom: "2px solid #363ed3",
    },
    dataCountFilter: {
        cursor: "pointer",
        '&:first-child': {
            marginRight: 15
        },
        '& .MuiTypography-body1':
        {
            fontSize: 13
        }
    },
    dataCountFilterActive: {
        borderBottom: "2px solid #363ed3",
    },
    dataSortBy: {
        color: '#6a6c71',
        fontSize: 13,

    },
    refreshButtonText: {
        fontSize: 12,
        color: 'black',
        marginLeft: 5
    },
    searchIcon: {
        fontSize: 20,
        color: 'black'
    },
    refreshButton: {
        fontSize: 20,
        color: 'black',
        transform: 'scaleX(-1) rotate(180deg)',

    },
    searchInput: {
        fontSize: 12,
        maxWidth: "unset",
        width: "100%"
    },
    searchInputContainer: {
        fontSize: 12,
        marginBottom: "13%"

    },
    searchByContainer: {
        "& ol li.MuiBreadcrumbs-separator": {
            margin: 0
        }
    },
    searchByText: {
        display: "flex",
        alignItems: "center"
    },
    searchByButton: {
        padding: "0 3px",
        minWidth: "unset",
        "&:hover , &:focus": {
            backgroundColor: "transparent"
        }
    },
    searchByButtonActive: {
        color: "#363ed3"
    },
    containerDataSources: {
        marginLeft: 47,
        paddingBottom: 37
    },
    tableDataSources: {
        backgroundColor: '#fff',
        borderRadius: 12,
        borderCollapse: 'collapse'
    },
    dataSourceContainer: {
        borderBottom: "2px solid #ECF1F9;",
        marginLeft: 6,
        marginTop: 16,
        '& .MuiTableCell-root': {
            padding: '8px 12px'
        }
    },
    datasourceFirstContainer: {
        display: 'flex',
        alignItems: 'baseline'
    },
    dataSourceName: {
        fontSize: 14,
        fontWeight: 500,
        color: "#312e40",
        marginRight: 6,
        fontFamily: 'Roboto'

    },
    dataSourceSize: {
        fontSize: 10,
        fontWeight: 500,
        color: "#000",
        backgroundColor: '#f3f7ff',
        padding: "2px 9px",
        borderRadius: 6

    },
    dataSourceDateContainer: {
        marginTop: 5,
        marginBottom: 14,
    },
    dataSourceDate: {
        fontSize: 11,
        color: "#6a6c71",
        padding: '0 5px'
    },
    dataSourceType: {
        fontSize: 11,
        color: "#6a6c71"
    },
    dotsContainer: {
        position: "absolute",
        right: "7.5%"
    },
    dotsMenu: {
        "&:hover": {
            backgroundColor: "#dee9fe"
        }
    },
    filterButton: {
        marginRight: 3,
        color: "#52525299",
        width: '18px!important',
        height: '18px!important',
    },
    filterButtonInverted: {
        transform: 'scaleX(-1) rotate(180deg)'
    },
    FilterContainer: {
        borderRadius: 9.5,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'start'

    },
    FilterTypeContainer: {
        borderRadius: 9.5,
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: 180,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',

    },
    ActiveFilter: {
        border: 'solid 1px #363ed3'
    },
    centerAligned: {
        display: "flex",
        alignItems: "center"
    },
    DividerStyle: {
        height: 32,
        marginTop: "2.9%"
    },
    noResult: {
        minHeight: 250,
        alignItems: "center"
    },
    popoverContainer: {
        "& .MuiPaper-root": {
            background: "#fff",
            borderRadius: 10,
            border: "1px solid #D9E1EE",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)"
        }
    },
    actionsPopoverContainer: {
        borderRadius: 12,
        backgroundColor: "#fff",
        padding: 12,
    },
    actionButtonContainer: {
        width: '100%',
        textAlign: "left",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0000001f"
        }
    },
    actionButton: {
        color: "#312E40",
        padding: "3px 0",
        margin: 0,
        fontFamily: "Roboto",
        fontSize: "14px!important",
        fontWeight: "400!important",
        "& a": {
            color: "#312E40",
            textDecoration: "none"
        } 
    }
}));