import Select from "@mui/material/Select";
import {Box, FormControl, InputBase, makeStyles, MenuItem} from "@material-ui/core";
import React, {useEffect} from "react";
import {Checkbox, Chip, ListItemText, styled} from "@mui/material";

export const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D9E1EE',
        borderRadius: '6px',
        width: 'fit-content',
        padding: '5px 13px',
    },
    label: {
        fontSize: '12px',
        marginRight: '10px',
        fontFamily: 'Strawford',
        fontWeight: 400,
    },
    select: {
        "& ul": {
            padding: '20px 0',
        },
        '& li': {
            padding: '0 20px',
            marginBottom: '15px',
        },
        '& li:hover': {
            background: '#fff',
        },
        '& li:last-child': {
            marginBottom: '0',
        },
        '& li.Mui-selected': {
            background: '#fff',
            '&:hover': {
                background: '#fff',
            },
        },
        "& span": {
            padding: '0 10px 0 0',
            fontSize: 12,
            color: '#232033',
            fontWeight: 700,
            fontFamily: 'Strawford',
        },
    },
}));

const RegularInput = styled(InputBase)({
    '& .MuiInputBase-input': {
        minWidth: 'fix-content',
        borderRadius: 15,
        position: 'relative',
        backgroundColor: '#FFF',
        border: 'none',
        padding: '3px 10px 3px 0',
        fontSize: '12px',
        fontWeight: 700,
        '&:focus': {
            outline: 'none',
        },
    },
});

const CheckedIcon = (props) => (
    <svg
        width={12}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={12} height={12} rx={1} fill="#363ED3" fillOpacity={0.2} />
        <path d="m3 5 2.5 2.5 4-4" stroke="#363ED3" />
    </svg>
)

const UnCheckedIcon = (props) => (
    <svg
        width={12}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path stroke="#DEE6F0" d="M.5.5h11v11H.5z" />
    </svg>
)

const FilterDropDownInput = ({
    label,
    options,
    id = 'demo-customized-select',
    hasNone = false,
    onChange,
    isMultiple = true,
    isInit = false,
    inputWidth = 210,
}) => {
    const classes = useStyles();

    const values = options.map(item => item.value);

    const defaultValues = isMultiple ? values : values[0];

    const [selectedValue, setSelectedValue] = React.useState(defaultValues);

    useEffect(() => {
        if (isInit) setSelectedValue(defaultValues)
    }, [isInit])

    const handleChange = (event) => {
        if (isMultiple && event.target.value.length === 0) return;
        setSelectedValue(event.target.value);
        onChange && onChange(event.target.value)
    };

    const isMultipleRender = (selected) => {
        return selected.map((value) => {
            const itemByValue = options.find((item) => item.value === value)
            return (
                <Chip
                    size="small"
                    key={value}
                    label={itemByValue.label}
                    sx={{
                        padding: '4px 8px',
                        background: '#EEF0F4',
                        borderRadius: '4px',
                        fontSize: 12,
                        color: '#232033',
                        fontWeight: 700,
                        fontFamily: 'Strawford',
                    }}
                />
            )
        })
    }

    const singleRender = (selected) => {
        const selectedOption = options.find(({value}) => value === selected);
        return selectedOption ? selectedOption.label : ''
    }

    const renderSelectProps = {
        id,
        value: selectedValue,
        onChange: handleChange,
        input: <RegularInput />,
        sx: {
            'svg': {
                right: '-5px',
            }
        },
        MenuProps: {
            classes: {
                paper: classes.select
            },
        },
        multiple: isMultiple,
        renderValue: (selected) => (
            <Box sx={{ display: 'flex', gap: '4px', width: `${inputWidth}px`, overflow: 'hidden', alignItems: 'center' }}>
                <label className={classes.label}>{label}</label>
                {isMultiple
                    ? isMultipleRender(selected)
                    : singleRender(selected)
                }
            </Box>
        ),
    };

    return (
        <FormControl variant="standard">
            <div className={classes.container}>
                <Select
                    {...renderSelectProps}
                >
                    {hasNone && (
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    )}
                    {options.map((option, index) => (
                        <MenuItem value={option.value} key={index}>
                            {
                                isMultiple
                                    ? <>
                                        <Checkbox
                                            checked={selectedValue.includes(option.value)}
                                            checkedIcon={<CheckedIcon />}
                                            icon={<UnCheckedIcon />}
                                        />
                                        <ListItemText primary={option.label} />
                                    </>
                                    : option.label
                            }
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </FormControl>
    )
}

export default FilterDropDownInput;