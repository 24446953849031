import { parseJwt } from "./helpers"

const currentUser = () => localStorage.getItem("token") ? parseJwt(localStorage.getItem("token")) : {"email": "anonym@atidot.com"} 

export const User = () => {
    const user = currentUser()
    return {
        currentUser: user,

        isAdmin: user.roles === "platform:admin",
        isEditor: user.roles === "platform:editor",
        isAnonym: user.email === "anonym@atidot.com",

        isSnowFlake: ['chaganton@gmail.com', 'sonya@atidot.com'].includes(user.email),
        isHideOnProd: ['localhost', 'broker', 'dev', 'stage'].some(cond => window.location.host.includes(cond)),
        isHideOnStage: ['broker', 'localhost', 'dev',].some(cond => window.location.host.includes(cond)),
        isDev: ['chaganton@gmail.com'].includes(user.email)
    }

}

export const checkPermission = (action) => currentUser().permissions?.filter(p => p.action === action).length > 0