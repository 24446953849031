import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Magic from "../../Components/Demo/Magic/Magic";
import MapperComponent from "../../Components/Mappers/MapperComponent";

// Redux
import { selectAutoMapping,setAutoMapping } from "../NewProject/NewProjectSlice";

// Common
import axios from "axios";
import { processUserLoginData } from "../../global/helpers";
import { setAccountType, setCurrentUser, setMenuDisabled, setUserCompany, setUserInitials, setUserLimit, setUserPermissions, setUserRole } from "../../AppSlice";
import { useHistory } from "react-router";

const DemoMapper = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const containerRef = useRef(null)
    const [ demoNextClicked, setDemoNextClicked ] = useState(false)
    const autoMapping = useSelector(selectAutoMapping)

    const handleExecute = (enableNextButton) => {   
      setDemoNextClicked(true)
      enableNextButton()
    }

    useEffect(() => {
      !autoMapping?.length && dispatch(setAutoMapping([
        {
            "column_name": null,
            "is_optional": true,
            "rename_to": "index",
            "title": "Index"
        },
        {
            "column_name": "policy",
            "is_optional": false,
            "rename_to": "policy",
            "title": "Policy ID"
        },
        {
            "column_name": "age",
            "is_optional": false,
            "rename_to": "age",
            "title": "Current Age"
        },
        {
            "column_name": "gender",
            "is_optional": false,
            "rename_to": "gender",
            "title": "Gender"
        },
        {
            "column_name": "zip_code",
            "is_optional": false,
            "rename_to": "zip_code",
            "title": "Zip Code"
        },
        {
            "column_name": "premium",
            "is_optional": false,
            "rename_to": "premium",
            "title": "Annual Premium"
        },
        {
            "column_name": "face_amount",
            "is_optional": false,
            "rename_to": "face_amount",
            "title": "Face Amount"
        },
        {
            "column_name": "product",
            "is_optional": false,
            "rename_to": "product",
            "title": "Product Type"
        },
        {
            "column_name": "issue_date",
            "is_optional": false,
            "rename_to": "issue_date",
            "title": "Issue Date"
        },
        {
            "column_name": "state",
            "is_optional": true,
            "rename_to": "state_of_applicant",
            "title": "State"
        },
        {
            "column_name": "termdate",
            "is_optional": true,
            "rename_to": "termdate",
            "title": "State"
        }
    ]
    ))
      axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/login", {
        email: "anonym@atidot.com",
        password: "Anonym123"
      }).then((response)=>{
        sessionStorage.removeItem("atidot_platform_data")
        processUserLoginData(response, dispatch, setUserInitials, setUserRole, setUserPermissions,
                            setUserLimit, setUserCompany, setMenuDisabled, setCurrentUser, setAccountType)
      }).catch((error)=>{
        console.log(error.response.status);
        history.push("/login")
      })
    }, [dispatch, autoMapping])

    return(
        <div ref={containerRef}>
            {
              demoNextClicked 
              ? <Magic displayTime={2500} blinkInterval={200} demo/>
              : <MapperComponent outSideRef={containerRef} onExecute={handleExecute} demo/>
            }
        </div>
    )
}

export default DemoMapper