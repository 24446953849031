import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import {basicButtonStyles} from "../../Components/Demo/Header/styles";

// Components
import Overview from "./Overview/Overview";
import Opportunities from "./Opportunities/Opportunities";
import ProspectsAE from "./Prospects/ProspectsAE";

// Redux
import { resetFilters, selectProductsData, selectActiveTab, setActiveTab, selectHeaders, selectIds, resetImpactsData } from "./BookAnalysisSlice.js";
import { selectUserCompany, selectUserPermissions } from "../../AppSlice";

// Services
import BookAnalysisService from "./BookAnalysisService";
import { amplitudeService } from "../../global/helpers"
import Impacts from "./Impacts/Impacts";
import HeaderProspects from "../../Components/Headers/HeaderProspects";
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer.jsx";

const useStyles = makeStyles(() => ({
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: 235,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        }
    },
    tabActive: {
        color: '#363ED3',
        height: 39,
        position: 'relative',
        bottom: -1,
        zIndex: 900
    },
    headerContainer: {
        paddingBottom: 21
    },
    portfolioAnalysis: {
        paddingRight: 24
    },
    portfolioAnalysisTitle: {
        fontFamily: "Strawford!important",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "24px",
        paddingBottom: 12
    },
    portfolioAnalysisText: {
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px"
    },
    portfolioAnalysisValue: {
        fontFamily: "Lato!important",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "17px",
        color: '#232033'
    },
    predictionContainer: {
        borderColor: "#0BCB47",
        maxWidth: 411,    
        "& path": {
            fill: '#0BCB47',
            stroke: '#0BCB47'
        },
        "@media (max-width:1310px)": {
            maxWidth: 347  
        }
    },
    addDataCotainer: {
        display: "flex",
        maxWidth: 178, 
        "@media (max-width:1380px)": {
            maxWidth: 120  
        }
    },
    demoText: {
        fontFamily: "Roboto!important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "20px",
        color: '#515151', 
        paddingBottom: 34,       
        "& span": {
            color: '#363ED3', 
            textDecoration: "underline",
            cursor: "pointer", 
            fontWeight: 500
        }
        
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 25,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px"
    }

}));

export const uiButtonStyles = {
    ...basicButtonStyles,
    width: 'auto',
    padding: '14px 34px',
    maxWidth: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: '#D8D8D8'
    },
  };

const defaultTabs = [
  'Impact on Premium',
  'Immediate Opportunities',
  //'Prospects',
];

const ApiService = new BookAnalysisService()

const BookAnalysis = ({ demo = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const headersData = useSelector(selectProductsData)
    const headerProspects = useSelector(selectHeaders)
    const activeTab = useSelector(selectActiveTab)
    const permissions = useSelector(selectUserPermissions)
    const projectIds = useSelector(selectIds)
    const user = useSelector(selectUserCompany)
    const rootRef = useRef(null)
    const [ tabs, setTabs ] = useState(defaultTabs)
    const isAnnuities = localStorage.getItem("accountType") === "annuities"

    const projectType = (
        (headersData?.ui_runs?.length > 0 && headersData?.ui_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        || (headersData?.lapse_runs?.length > 0 && headersData?.lapse_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        ? "annuities" : "regular"
    )

    const registrationRedirect = () => {
      localStorage.removeItem("token")
      localStorage.removeItem("refresh")
      localStorage.removeItem("userName")
      history.push("/registration")
    }
    
    const handleChangeTab = (item) => {
        if (item === "Impacts on Assets" || item === "Impacts on Premium") {
            dispatch(resetImpactsData())
        }
        if (item !== "Prospects" && item !== "ProspectsAE") {
            dispatch(resetFilters()) 
        }
        dispatch(setActiveTab(item))
        amplitudeService._throwEvent("BOOK_TAB_CHANGE", {'tab': item})
      }

    const getStepComponent = () => {
        switch (activeTab) {
            case 'Annual Impact':
                return <Overview/>
            case 'Immediate Opportunities':
                return <Opportunities outSideRef={rootRef} projectType={projectType}/>
            case 'Prospects':
                return <ProspectsAE handleLogout={registrationRedirect} projectType={projectType}/>
            case 'ProspectsAE':
                return <ProspectsAE handleLogout={registrationRedirect} projectType={projectType} annuities/>
           case 'Impacts on Assets':
                return <Impacts/>
           case 'Impact on Premium':
                return <Impacts/>
        
            default:
                break;
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetImpactsData())
       }
    }, [dispatch])

    useEffect(() => {
        if (isAnnuities) {
           setTabs([
               'Impacts on Assets',
               'Immediate Opportunities',
               //'ProspectsAE'
             ])
          dispatch(setActiveTab('Impacts on Assets'))  
       }
        return () => {
            setTabs(defaultTabs)
            dispatch(setActiveTab('Impact on Premium'))  
       }
    }, [permissions])



    return (
        <Grid container direction="column" ref={rootRef}> 
            <HeaderContainer title="Portfolio Optimizer">
                <HeaderProspects data={headerProspects} titles={["Projects", "Prediction Start Date", "Prospects", `${isAnnuities ? "AUM Upside" : "Premium Upside"}`]}/>
            </HeaderContainer>
            {demo && 
            <Grid container justifyContent="flex-start">
                <Typography className={classes.demoText}>
                    The following analysis is based on a demo data. Please <span onClick={() => history.push("/registration")}>create account</span> to see your real results.
                </Typography>
            </Grid>
            }
            <Grid container justifyContent="flex-start">
                {
                    tabs.map((item) => (
                        <Grid item className={clsx(classes.tab, activeTab === item ? classes.tabActive : '')} onClick={() => handleChangeTab(item)}>
                            <Typography>{item?.replace("AE", "")}</Typography>
                        </Grid>                        
                    ))
                }
            </Grid>
            <Grid className={activeTab !== "Immediate Opportunities" && activeTab !== `${isAnnuities ? "Impacts on Assets" : "Impact on Premium"}` ? classes.main : ""}>            
                {
                    getStepComponent()
                }
            </Grid>

        </Grid>
    )
}

export default BookAnalysis