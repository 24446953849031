import React, { useRef, useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import SnowFlakeIcon from '../../../Assets/snowflake-logo.svg';
import DataBricksIcon from '../../../Assets/databricks-logo.svg';
import PostgressSQLIcon from '../../../Assets/postgress-logo.svg';
import GoogleBigQueryIcon from '../../../Assets/bigquery-logo.svg';
import RedShiftIcon from '../../../Assets/redshift-logo.svg';
import S3Icon from '../../../Assets/s3-logo.svg';
import CreateEditConnector from './ConnectorDialog/ConnectorDialog'
import DataHandlerApiService from '../../../services/datahandler'

import { useStyles } from './style'
import ConnectorCard from "./ConnectorCard/ConnectorCard";

const connectorItems = [
    {
        key: "databricks",
        displayName: "DataBricks",
        logo: DataBricksIcon
    },
    {
        key: "postgress",
        displayName: "PostgressSQL",
        logo: PostgressSQLIcon
    },
    {
        key: "s3",
        displayName: "AmazonS3",
        logo: S3Icon
    },
    {
        key: "google",
        displayName: "BigQuery",
        logo: GoogleBigQueryIcon
    },
    {
        key: "snowflake",
        displayName: "SnowFlake",
        logo: SnowFlakeIcon
    },
    {
        key: "redshift",
        displayName: "RedShify",
        logo: RedShiftIcon
    },
]


const Connectors = () => {
    const classes = useStyles()
    const rootRef = useRef(null)

    const [ConnectorDialogOpen, setConnectorDialogOpen] = useState(false)
    const [connectorDialogParams, setConnectorDialogParams] = useState({
        key: "snowflake",
        displayName: "SnowFlake",
        logo: SnowFlakeIcon
    })
    const [isEdit, setIsEdit] = useState(false)
    const [connectorsList, setConnectorsList] = useState({})

    const ApiService = new DataHandlerApiService()

    const loadConnectors = () => {
        ApiService.getConnectors((response) => {
            let connectors = {}
            response.data[0].forEach(connector => {
                connectors[connector.type] = connector
            })
            setConnectorsList(connectors)
        }, (e) => console.error(e))
    }

    const handleConfigureClick = (e, item) => {
        setConnectorDialogOpen(true)
        console.log(e, item);
        setConnectorDialogParams(connectorItems.find(({key}) => key === item))
    }

    useEffect(() => {
        loadConnectors()
    },[])


    return (
        <Grid container ref={rootRef} className={classes.container}>
            {
                connectorItems.map((item) => (
                    <ConnectorCard {...item} name={item.key} list={connectorsList} handleConfigure={handleConfigureClick}/>
                ))
            }

            <CreateEditConnector
                onSubmit={loadConnectors}
                connector={{}}
                open={ConnectorDialogOpen}
                setOpen={setConnectorDialogOpen}
                isEdit={isEdit}
                config={connectorDialogParams}/>
        </Grid>
    )
}

export default Connectors