import './UIbyGender.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLayoutEffect, useRef } from 'react';
import NoDataIndicator from '../NoDataIndicator/NoDataIndicator';

am4core.useTheme(am4themes_animated);

export default function UIbyGender({paddingRight, chartData, title, currency, hasData = true}) {

  const chart = useRef(null);

  useLayoutEffect(() => {
    
    let total = 0
    chartData.forEach((item) => {
      if(item?.value > 0) total =+ item.value
    })
    let x = am4core.create("UIbyGender", am4charts.XYChart);
    
    
    // Create axes
    x.strokeLinejoin="round"
    var categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "id";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.minGridDistance = 2;
    
    
    categoryAxis.title.align = "center";
    categoryAxis.title.valign = "center";
    categoryAxis.title.dy = -8;
   /* categoryAxis.title.paddingLeft = -10; */
    
    
/* 
    categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
    /* if (target.dataItem && target.dataItem.index & 2 == 2) {
        return dy + 25;
    } 
    return dy;
    }); */

    var valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeOpacity = 0.05;
    valueAxis.numberFormatter.numberFormat = `${currency ? `'${currency}'` : ""}#.a`;
    valueAxis.min= 0;

    var series = x.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "id";
    series.name = "Value";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.width = am4core.percent(80);
    series.columns.template.maxWidth = 6;
    
    series.columns.template.adapter.add("fill",function(fill,target){
      //return target.dataItem.dataContext["color"]
      return target.dataItem.dataContext["color"]
    })
    series.columns.template.adapter.add("stroke",function(fill,target){
      return target.dataItem.dataContext["color"]
    })
    //series.columns.template.fillOpacity = .8;

       var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.column.cornerRadius(60, 60, 10, 10);

    if (chartData.length!==3){
      if(chartData.findIndex(el => el.id ==="Male")===-1){
        chartData.push({id:'Male', value:0, color:"#4b54ff", real:1})
      }   
      if(chartData.findIndex(el => el.id ==="Female")===-1){
        chartData.push({id:'Female', value:0, color:"#ff656f", real: 2})
      }
    }

    x.data = chartData
    x.numberFormatter.numberFormat = total > 0 ? `${currency ? `'${currency}'` : ""}#.a` : `${currency ? `'${currency}'` : ""}#`
    x.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ]

    let topContainer = x.chartContainer.createChild(am4core.Container);
    topContainer.layout = "absolute";
    topContainer.toBack();
    topContainer.paddingBottom = 15;
    topContainer.width = am4core.percent(100);
    
    let axisTitle = topContainer.createChild(am4core.Label);
    axisTitle.text = title;
    axisTitle.fontWeight = 700;
    axisTitle.fontFamily = "Roboto";
    axisTitle.align = "left";
    axisTitle.fontSize=16
    axisTitle.marginBottom=20
    
    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [chartData]);

   
   useLayoutEffect(() => {
    chart.current.paddingRight= paddingRight
}, [paddingRight]);
  
  return (
    <div className='BarsBox'>
      {!hasData && <NoDataIndicator/>}
      <div id="UIbyGender" style={hasData ? {} : { opacity: .2 }}></div>
      <div style={{position: 'relative', zIndex:2, bottom: 20, width:'98%', height:22, backgroundColor: 'white', borderRadius: '5px'}}></div>
    </div>
  );
}