import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Magic from "../../Components/Demo/Magic/Magic";
import MapperComponent from "../../Components/Mappers/MapperComponent";

// Redux
import { setLapseID, setMenuDisabled, setUiID } from "../../AppSlice";
import { resetValues, selectAutoMapping, selectCsvInfo, selectMapperOptions, selectSourceId, selectZipCheck, setMapperError, setProjectId, setRunIds, setSelectSourceMode } from "../NewProject/NewProjectSlice";

// Common
import newProjectApiService from "../NewProject/NewProjectService";

const ApiService = new newProjectApiService()

const Mapper = () => {
    const dispatch = useDispatch()
    const containerRef = useRef(null)
    const [ demoNextClicked, setDemoNextClicked ] = useState(false)
    const autoMapping = useSelector(selectAutoMapping)
    const sourceId = useSelector(selectSourceId)
    const mapperOptions = useSelector(selectMapperOptions)
    const zipCheck = useSelector(selectZipCheck)
    const csvInfo = useSelector(selectCsvInfo)

    const handleExecute = (enableNextButton) => {     
        let mapperValuesLapse = {}
        let mapperValuesUI = {}
        Object.assign(
          mapperValuesLapse,
          ...Object.entries(mapperOptions)
          .filter((item) => item[0] !== 'state_of_applicant' && item[0] !== 'zip_code')
          .map((item) => ({ [item[1]]: item[0]}))
        )
        Object.assign(
          mapperValuesUI,
          ...Object.entries(mapperOptions)
          .filter((item) => item[0] !== 'termdate')
          .map((item) => ({[item[1]]: item[0]}) )
          )
          
        ApiService._postProjectMapper(sourceId, {
          model_type: "lapse",
          mapper: {
              ...mapperValuesLapse,
              "zip_code": "zipcode"
          },
          zip: zipCheck ? "True" : "False"
          }, (res) => {  
          },
          (error) => {
            console.log(error);
            dispatch(setMapperError({present: true, message: ""}))
            dispatch(resetValues())
            enableNextButton()
            return;
          }
        )  
        ApiService._postProjectMapper(sourceId, {
          model_type: "ui",
          mapper: 
          {
            ...mapperValuesUI
          },
          zip: zipCheck ? "True" : "False"
          }, () => { 
            dispatch(setMapperError({present: false, message: ""})) 
          },
          (error) => {
            console.log(error);
            dispatch(setMapperError({present: true, message: ""}))
            dispatch(resetValues())
            enableNextButton()
            return;
          }
        )
        const date = new Date()
        ApiService._postNewProject({
          project_name: date.toISOString().split("T")[0] + "-" + date.getTime().toString().substring(7),
          source_id: sourceId.toString(),
          model_type: "lapse"      
        },
        (response) => {
          sessionStorage.setItem("current_id", response.data["project_id"])
          dispatch(setProjectId(response.data["project_id"]));
          const payloads = [
            {
              extra_parameters: {
                predict_date: `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}`
              },
              model_type: "lapse",
              run_name: crypto.randomUUID().split("-")[0],
              period_months: 3,
              product_type: "Whole Life",
              train_model: false
            },
            {
              extra_parameters: {
                predict_date: `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}`
              },
              model_type: "ui",
              run_name: crypto.randomUUID().split("-")[0],
              period_months: 3,
              product_type: "Whole Life",
              train_model: false
            },
          ]
          ApiService._postCreateAllRuns(
            {
              project_id: parseInt(sessionStorage.getItem("current_id")),
              runs: payloads
            },
            (response) => {
              sessionStorage.removeItem("atidot_platform_data")
              if (response.data["failed"]?.length === 0) {
                const runs = response.data["runs"]?.map((run) => {
                  run.model_type === "lapse" && dispatch(setLapseID(run.run_id));
                  run.model_type === "ui" && dispatch(setUiID(run.run_id));
                  return { [run.model_type]: run.run_id }
                })
                dispatch(setRunIds(runs));
                dispatch(setSelectSourceMode(false));
                dispatch(setProjectId(''));
                dispatch(setMenuDisabled(false))
                setDemoNextClicked(true)
                enableNextButton()
              } else {
                dispatch(setMapperError({present: true, message: ""}))
                dispatch(resetValues())
                enableNextButton()
              }
            },
            (error) => {
              console.log(error)
              dispatch(setMapperError({present: true, message: ""}))
              dispatch(resetValues())
              enableNextButton()
            }
          )
          }, (error) => {
            dispatch(setMapperError({present: true, message: ""}))
            console.log(error)
        })   

    }

    return(
        <div ref={containerRef}>
            {
              demoNextClicked 
              ? <Magic displayTime={2500} blinkInterval={1500} demo={false}/>
              : <MapperComponent outSideRef={containerRef} onExecute={handleExecute} demo={false}/>
            }
        </div>
    )
}

export default Mapper