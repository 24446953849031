import React, { useRef, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';


// Icons
import ProjectsIcon from './Assets/icon-projects-default.svg';
import ProjectsActiveIcon from './Assets/icon-projects-active.svg';
import MagnifierIcon from './Assets/icon-magnifier-default.svg';
import MagnifierActiveIcon from './Assets/icon-magnifier-active.svg';
//import DiamondIcon from './Assets/icon-diamond-default.svg';
//import DiamondActiveIcon from './Assets/icon-diamond-active.svg';
import RocketIcon from './Assets/icon-rocket-default.svg';
import RocketActiveIcon from './Assets/icon-rocket-active.svg';
import DashboardIcon from './Assets/icon-dashboard.svg';
import DashboardActiveIcon from './Assets/icon-dashboard-active.svg';
import SettingIcon from './Assets/icon-preference.svg';
import SettingIconAcive from './Assets/icon-preference-active.png';
import ProspectsIcon from './Assets/icon-prospects.svg';
import ProspectsActiveIcon from './Assets/icon-prospects-active.svg';
import OptimizerIcon from './Assets/icon-portfolio-optimizer.svg'
import OptimizerActiveIcon from './Assets/icon-portfolio-optimizer-active.svg'
import {ReactComponent as LogoIcon} from './Assets/icon-logo.svg';

// MUI
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Avatar, Button, Grid, IconButton, Popover } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import Project from './Components/Projects/Project';
import AlertDialog from './Components/AlertDialog/AlertDialog';
import DataManagement from './Components/DataManagement/DataManagement';
import InformationPopup from './Components/Popups/InformationPopup/InformationPopup';



// Pages
import sampleCampaignList from './Pages/Campaigns/sampleCampaignList.json'
import Settings from './Pages/Settings/Settings';
import Campaigns from './Pages/Campaigns/Campaigns';
import RunPage from './Pages/RunPage/RunPage';
import Mapper from './Pages/Mapper/Mapper';
import Upload from './Pages/Upload/UploadFile';
import FileManagment from './Pages/FileManagement/FileManagement';
import NewProject from './Pages/NewProject/NewProject';
import NewDataSource from './Pages/NewDataSource/NewDataSource';
import NewUserDemo from './Pages/NewUserDemo/NewUserDemo';
import SampleInfo from './Pages/NewUserDemo/SampleInfo';
import Library from './Pages/Library/Library';
import { removeCampaignProgress, updateCampaignsProgress } from './Pages/CreateCampaign/CreateCampaignSlice';
import { setSelectSourceMode } from './Pages/NewProject/NewProjectSlice';
import BookAnalysis from './Pages/BookAnalysis/BookAnalysis';
import CreateCampaign from './Pages/CreateCampaign/CreateCampaign';
import DashboardPage from './Pages/Dashboard/Dashboard';
import ProspectsPage from './Pages/Prospects/Prospects';



// Redux
import { removeRunProgress, resetData, selectActiveOption, selectUserInitials, selectUserRole, setActiveOption,
  setCampaignsCounts,  setProjectInformatiom, setProjectsLoaded, setRunLeads, setSearch, setUserCompany,
  setUserInitials, setUserName, setUserRole, setUserPermissions, setUserLimit, updateRunsProgress,
  setMenuDisabled, selectMenuDisabled, selectUserCompany, setUiID, setLapseID, selectUserName, setCurrentUser,
  selectUserLimit, setDemoData, setProjectMetadata, setAccountType, selectLoading} from './AppSlice';
import { setLoaded, setPoliciesActual} from './Components/Run/RunSlice';

// Common
import { amplitudeService, parseJwt, processUserLoginData } from './global/helpers';
import { useMobileDetect } from './global/useMobileDetect';
import { User, checkPermission } from './global/user'
import appApiService from './AppService';
import { CircularProgress } from '@mui/material';
import TableauDemo from './Pages/TableauDemo/TableauDemo';
import DemoMapper from './Pages/DemoMapper/DemoMapper';

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    "& main": {
      "@media (max-width:1280px)": {
        paddingTop: 8
      }
    }
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 999
    },
  },
  headerActiveOption: {
    fontSize: 18,
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 30,
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: 'white',
    color: 'black',
    boxShadow: "none",
    borderBottom: "1px solid #ecf1f9",
    zIndex: 998

  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuText: {
    fontSize: 11,
    fontWeight: 'normal !important',
    fontStretch: 'normal !important',
    fontStyle: 'normal !important',
    lineHeight: 1.33,
    letterSpacing: 'normal !important',
    textAlign: 'center !important',
    marginBottom: 0
  },
  toolbar: theme.mixins.toolbar,
  centerToolbarIcon: {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    minHeight: 64
  },
  toolbarPadding: {
    padding: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#363ed3',
    color: '#fff',
    fontSize: 9,
  },
  listItem: {
    flexDirection: 'column',
    padding: 0,
    paddingTop: 12,
    paddingBottom: 15
  },
  listItemContainer: {
    display: 'flex',
    justifyContent: "center",
    width: "100%",
  },
  listItemDisabled: {
    cursor: 'default',
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  listItemIcon: {
    justifyContent: 'center',
    minWidth: 59,
  },
  bottomOptions: {
    position: 'fixed',
    bottom: 0
  },
  newProjectIcon: {
    color: '#d82832',
  },
  newProjectButtonContainer: {
    position: 'fixed',
    border: '1px solid #d9e1ee',
    borderRadius: 12,
    right: 80
  },
  newProjectButton: {
    backgroundColor: '#fff',
    borderRadius: 12,
    textTransform: 'capitalize',
    fontSize: 12

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f8f9fb",
    minHeight: '100vh',
  },
  warningAlert: {
    color: "#dd9c00 !important",
    backgroundColor: "#fff7c5 !important",
    fontSize: 13,
    height: 40,
    padding: "4px 10px"
  },
  mobileContainer: {
    height: "100vh",
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 600,
    backgroundColor: "#f8f9fb"
  },

}));


const menuOptions = () => [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    url: "/dashboard"
  },
  {
    name: <>Portfolio<br/>Optimizer</>,
    icon: OptimizerIcon,
    activeIcon: OptimizerActiveIcon,
    url: "/book"
  },
  {
    name: "Prospects",
    icon: ProspectsIcon,
    activeIcon: ProspectsActiveIcon,
    url: "/prospects"
  },
  {
    name: "Campaigns",
    icon: RocketIcon,
    activeIcon: RocketActiveIcon,
    url: "/campaigns"
  },
  {
    name: "Projects",
    icon: ProjectsIcon,
    activeIcon: ProjectsActiveIcon,
    url: "/"
  },
  {
    name: <>Data<br/>Management</>,
    icon: MagnifierIcon,
    activeIcon: MagnifierActiveIcon,
    url: "/datasources"
  },
  // {
  //   name: <>External<br/>Dashboard</>,
  //   icon: TableauIcon,
  //   activeIcon: TableauActiveIcon,
  //   url: "/tableau-demo"
  // },
  {
    name: "Settings",
    icon: SettingIcon,
    activeIcon: SettingIconAcive,
    url: "/settings"
  },
  // {
  //   name: "Models",
  //   icon: DiamondIcon,
  //   activeIcon: DiamondActiveIcon,
  //   url: "/library"
  // },
].filter( (item) => {

    switch (item.name) {
      case "Projects":
        return checkPermission('view_projects')
      case "Campaigns":
        return checkPermission('view_campaign')
      case "Prospects":
        return checkPermission('view_prospect_page')
      case "Settings":
        return checkPermission('view_settings_page')
    
      default:
        return true;
    }

  }
)

const ApiService = new appApiService();


const App = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const activeOption = useSelector(selectActiveOption)
  
  const { currentUser } = User()
  const userInitials = useSelector(selectUserInitials)
  const userName = useSelector(selectUserName)

  const [hover, setHover] = React.useState("")
  const [activeItem, setActiveItem] = React.useState("")
  const [login, setLogin] = React.useState(true)
  const dispatch = useDispatch()
  const history = useHistory();
  const userRole = useSelector(selectUserRole)
  const userLimit = useSelector(selectUserLimit)
  const userCompany = useSelector(selectUserCompany)
  const menuDisabled = useSelector(selectMenuDisabled)
  const isLoading = useSelector(selectLoading)
  const projectsLoaderRef = useRef()

  const [OpenErrorPopover, setOpenErrorPopover] = React.useState(false);
  const [OpenLogoutPopover, setOpenLogoutPopover] = React.useState(false);
  const [socketProgress, setSocketProgress] = React.useState(null);
  const [alert, setAlert] = React.useState('No runs to explore');
  const ErrorPopoverRef = React.useRef({ top: 0, left: 0});
  const LogoutPopoverRef = React.useRef();
  const socketref = React.useRef(null)

  const isAnnuities = localStorage.getItem("accountType") === "annuities"
  const blockedRouts = []
  const isMobile = useMobileDetect()
  
  useEffect(() => {
      if (!socketProgress) {  
        sessionStorage.removeItem("atidot_platform_data")
        const socket = ApiService._openProgressWebSocket() 
        socketref.current = socket
        setSocketProgress(socket) 
        
        socket.on("update_run_progress", (response) => {
          if (response.progress !== 100 && response.progress !== -1) {
            dispatch(updateRunsProgress({
              ...response
            }))
          } else {
            sessionStorage.removeItem("atidot_platform_data")
            dispatch(removeRunProgress(response.id))
          }
          sessionStorage.removeItem("atidot_platform_data")
          resetProjects(response.progress)

        });

        socket.on("update_campaign_progress", (response) => {
          if (response.progress !== 100) {
            dispatch(updateCampaignsProgress({
              ...response
            }))
          } else {
            dispatch(removeCampaignProgress(response.id))
          }
          resetCampaigns()

        });

        socket.io.on("close", () => {
          console.log('close');
          setSocketProgress(null)  
        });        
        
        socket.on("leave_company_room", () => {
          sessionStorage.removeItem("atidot_platform_data")
          setSocketProgress(null)  
        });
        
      }


  }, [socketProgress, dispatch])

  function trackPageView() {
    amplitudeService._throwEvent("PAGE_OPEN", {'path': history.location.pathname})
  }

  React.useEffect(() => {
    // trackPageView() // To track the first pageview upon load
    const unlisten = history.listen(trackPageView); // To track the subsequent pageviews
    // stop the listener when component unmounts
    return unlisten
  }, [history]);

  const handleLeaveRoom = () => {
    if (socketProgress) {
      socketProgress.emit("leave_company_room", 'asd')    
    }
  }

  const resetProjects = (progress) => {
    // Hide loading
    !projectsLoaderRef.current && dispatch(setProjectsLoaded(false))

    // Get runs data
    ApiService._getRuns(
      localStorage.getItem("companyID"),
      (response) => {
        // if user doesn't has datasource uploaded - show demo data
        if (response.data?.count === 0 && history.location.pathname !== "/firstlogin") {

          // Load demo data
          ApiService._getDemoRunsData((res) => {
            dispatch(setDemoData(true))
            dispatch(setSearch(""))
            dispatch(setProjectInformatiom(res.data))
            res.data?.metadata && dispatch(setProjectMetadata(res.data))
            const runIds = []
            res.data?.projects?.forEach(({ runs }) => runs.forEach(({ run_id }) => runIds.push(run_id)))
            User().isAnonym && ApiService._getRuningCampaignsById(runIds, (res) => {
              dispatch(setCampaignsCounts(res.data))
            }, (err) => console.log(err))
  
            projectsLoaderRef.current = setTimeout(() => dispatch(setProjectsLoaded(true)), 1000)
            history.push("/?demo")
          },
          (error) => { console.log(error) })

        } else {
          // Data present. Save in state
          dispatch(setDemoData(false))
          dispatch(setSearch(""))
          dispatch(setProjectInformatiom(response.data))

          if (progress === undefined || progress === 100) {
            response.data?.metadata && dispatch(setProjectMetadata(response.data))
          }

          const runIds = []
          response.data?.projects?.forEach(({ runs }) => runs.forEach(({ run_id }) => runIds.push(run_id)))

          !User().isAnonym && ApiService._getRuningCampaignsById(runIds, (response) => {
            dispatch(setCampaignsCounts(response.data))
          }, (err) => console.log(err))

          projectsLoaderRef.current = setTimeout(() => dispatch(setProjectsLoaded(true)), 1000)
          history.location.search && history.push("/")
        }
      },
      (error) => console.log(error.response)
    )
  };

  const resetCampaigns = () => {
          ApiService._getAllCampaigns((response) => {
            if (response.data.count !== 0 || localStorage.getItem(`demo_campaign_finished_${currentUser.email}`))
            {
              dispatch(setRunLeads(response.data.metadata_list))
              localStorage.setItem(`demo_campaign_finished_${currentUser.email}`, true)
            }
            else {
              dispatch(setRunLeads(sampleCampaignList.metadata_list))
            }
          }, (err) => console.log(err))
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeActiveSelected = (url, name) => {
    if (
        userRole !== "file_upload_only" && 
        userRole !== "sftp" && 
        ((userCompany !== "platform-anonym" && !menuDisabled) || ["/book"].includes(url)) && 
        !(
          blockedRouts.includes(url) && 
          isAnnuities
        )
      ) {
      projectsLoaderRef.current = null
      // Return on dashboard if user is anonym or if user doesn't has access to dashboard 
      if (url === '/dashboard' && (User().isAnonym) ) { return }
      if(url !== "/run") {
        if (String(name.type) === 'Symbol(react.fragment)') { name = name.props.children.toString().replace(',[object Object],',' ')}
        amplitudeService._throwEvent("MENU_ITEM_"+name)
        dispatch(setLoaded(false))
        sessionStorage.removeItem("runId")
        history.push(url)
      } else {
        //amplitudeService._throwEvent("RESULT_VIEW")
        //moveToLastRun();
      }
    }
  }

  const handleOnHover = (hoveredItem) => {
    if (userRole !== "file_upload_only" && userRole !== "sftp" && hoveredItem !== "/run") {
      setHover(hoveredItem)
    }
  }

  const handleLogout = () => {
    amplitudeService._throwEvent("LOGOUT", {'email': User().currentUser.email})
    dispatch(resetData())
    dispatch(setMenuDisabled(false))
    dispatch(setUiID(0))
    dispatch(setLapseID(0))
    localStorage.removeItem("token")
    localStorage.removeItem("refresh")
    localStorage.removeItem("userName")
    localStorage.removeItem("userID")
    localStorage.removeItem("accountType")
    localStorage.removeItem("companyID")
    sessionStorage.removeItem("atidot_platform_data")
    handleLeaveRoom()
    setLogin(false)
    history.push("/login")
  }

  
  useEffect(() => {
    if (login && ["/", "/book"].includes(history.location.pathname) && !history.location.search) {
      setLogin(false)
      ApiService._getDataSources((response) => {
        if (!response.data?.count && checkPermission('add_datasource')) {
          dispatch(setActiveOption(''))
          history.push("/firstlogin")
        }
      }, (error) => console.log(error))
    }
  }, [login, history, dispatch])

  useEffect(() => {
    if (!userInitials) {
      dispatch(setUserInitials(localStorage.getItem("userName")?.includes("americanequity") ? "AE" : localStorage.getItem("userName")?.charAt().toUpperCase()))
      dispatch(setUserName(localStorage.getItem("userName")))
    }
    //!userInitials && dispatch(setUserInitials(localStorage.getItem("userName")?.charAt().toUpperCase()))
  }, [userInitials, dispatch])

  useEffect(() => {
    if(history.location.pathname !== "/run") {
      dispatch(setPoliciesActual(0))
      sessionStorage.removeItem("run_data")
    }
    if(history.location.pathname !== "/") {
      projectsLoaderRef.current = null
    }
    if (history.location.pathname !== "/settings" && history.location.pathname !== "/campaigns/view") {
      const activeComponent = menuOptions().find(({url}) => url === history.location.pathname)
      dispatch(setActiveOption(activeComponent?.url === "/datasources" ? "Data Management" : activeComponent?.url === "/book" ? "Portfolio Analysis" : activeComponent?.name))
    }
    if(history.location.pathname === "/datasources") {
      dispatch(setSelectSourceMode(false));
    }
    if(history.location.pathname === "/mapper") {
      handleLogout()
    }
    if (!localStorage.getItem("refresh")) {
      history.push("/mapper")
    }
  }, [history.location.pathname, dispatch, history])
  
  useEffect(() => {
    if(localStorage.getItem("token")) {
      const payload = parseJwt(localStorage.getItem("token"))
      dispatch(setCurrentUser(payload))
      dispatch(setUserRole(payload["roles"]))
      payload['permissions'] && dispatch(setUserPermissions(payload['permissions']))
      dispatch(setUserLimit(payload['user_limit']))
      dispatch(setUserCompany(payload["company_name"]))
    }
  }, [userRole, dispatch])

  useEffect(() => {
    if(userRole === "file_upload_only" || userRole === "sftp") {
      history.push("/fileupload")
      dispatch(setActiveOption('Atidot POC File Management'))
    }
  }, [userRole, dispatch, history, history.location.pathname])
  
  useEffect(() => {
    //console.log(history.location.pathname);
    history.listen((location, action) => {
      setActiveItem(location.pathname)
    })
  }, [history])
  
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


  const drawer = (
    <div>
      <div className={classes.centerToolbarIcon} >
        <LogoIcon />
      </div>
      {(userRole !== "file_upload_only" && userRole !== "sftp") && <List>
        {
          menuOptions()
          .filter(
            (item) => {
              const showSettings = item.name !== "Settings" || (userLimit === null || userLimit >= 2);
              
              const showEmbedded = item.url !== `/tableau-demo` || userRole === "platform:admin"
              
              return showSettings && showEmbedded
              }
            
            )
            .map((item, index) => {
                return (
                  <ListItem 
                    className={clsx(
                      classes.listItem, 
                        ((userCompany === "platform-anonym" || menuDisabled) && item.url !== "/book") || 
                        (
                          blockedRouts.includes(item.url) && 
                          isAnnuities
                        )
                      ? classes.listItemDisabled : ""
                      )} 
                    ref={item.name === "Run" ? ErrorPopoverRef : null} button key={item.url} 
                    onClick={() => changeActiveSelected(item.url, item.name)} 
                    onMouseOver={() => handleOnHover(item.url)} onMouseLeave={() => handleOnHover("")}
                    >
                      <div className={classes.listItemContainer} style={history.location.pathname === item.url || activeItem === item.url ? { borderLeft: "3px solid #FFFFFF" } : {}}>
                        <div>
                          <ListItemIcon className={classes.listItemIcon}>
                            <img alt={item.name} 
                              src={(
                                history.location.pathname === item.url || hover === item.url) && 
                                (((userCompany !== "platform-anonym" && !menuDisabled) || item.url === "/book")) &&  
                                !(
                                  blockedRouts.includes(item.url) && 
                                  isAnnuities
                                ) ? item.activeIcon : item.icon
                                }/>
                          </ListItemIcon>
                          <ListItemText data-testid={`menu${index}`} disableTypography={true} className={classes.menuText} primary={item.name}/>
                        </div>
                      </div>

                  </ListItem>
                )

            })
        }
      </List>
      }

      <List className={classes.bottomOptions}>
        {userCompany !== "platform-anonym" && <ListItem style={{ paddingLeft: 10 }} className={classes.listItem} button key='Profile' ref={LogoutPopoverRef} onClick={() => setOpenLogoutPopover(true)}>
          <ListItemIcon className={classes.listItemIcon}>
          <HtmlTooltip
            placement="right"
            title={
              <React.Fragment>
                <div>{userName}</div>
                <div>{userCompany}</div>
                <div>{userRole.replace("platform:", "").toUpperCase()}</div>
              </React.Fragment>
            }
          >
            <Avatar style={{ backgroundColor: "#0BCB47"}}>{userInitials || currentUser?.email?.charAt()?.toUpperCase()}</Avatar>
          </HtmlTooltip>

            </ListItemIcon>
        </ListItem>}             
      </List>
      <InformationPopup
        open={OpenLogoutPopover}
        onClose={() => setOpenLogoutPopover(false)}
        onConfirm={handleLogout}
        title="Are you sure you want to log out?"
        closeText="Cancel"
        confirmText="Logout"
        buttonDirection="right"
        />
      <Popover
        id="simple-popover"
        open={OpenErrorPopover}
        anchorEl={ErrorPopoverRef.current}
        onClick={(e) => { e.stopPropagation(); setOpenErrorPopover(false) }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        >
        <Alert severity='warning' className={classes.warningAlert}>{alert}</Alert>
       </Popover>
       <AlertDialog></AlertDialog>
    </div>
  );

  // End of Draver

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
    <>
      <div className={classes.root}>
        {
        isMobile ? 
          <Grid container justifyContent='center' alignItems='center' className={classes.mobileContainer}>
            Mobile is Not Supported
          </Grid>
        : <>
          <Switch>
            
            <Route  exact path="/library">
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbarPadding}>
                          <Typography variant="h4" className={classes.headerActiveOption} noWrap>
                            {activeOption}
                          </Typography>
                        <div className={classes.newProjectButtonContainer}>
                          {checkPermission('create_project') && <Button data-testid="newProjectBtn"
                                                                variant="outlined"
                                                                disableElevation={true}
                                                                disableFocusRipple={true}
                                                                className={classes.newProjectButton}
                                                                startIcon={<AddIcon className={classes.newProjectIcon} />}
                                                                onClick={() => {
                                                                  
                                                                    ApiService._getDataSources((response) => {
                                                                      if (!response.data?.count) {
                                                                        dispatch(setActiveOption(''))
                                                                        history.push("/firstlogin")
                                                                      } else {
                                                                        sessionStorage.removeItem("current_id")
                                                                        dispatch(setActiveOption(''))
                                                                        history.push("/NewProject")
                                                                      }
                                                                    }, (error) => console.log(error))
                                                                  
                                                                }}
                          >
                            Set New Project
                          </Button>
                        }
                      </div>
                </Toolbar>
              </AppBar>
            </Route>

            <Route path="/upload"/>
            <Route path="/mapper"/>
            <Route path="/NewProject"/>
            <Route path="/firstlogin"/>
          </Switch>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          {!isLoading ? 
          <main className={classes.content}>
            <Switch>
              <Route path="/upload"/>
              <Route path="/mapper"/>
              <Route path="/NewProject"/>
              <Route path="/firstlogin"/>
            </Switch>
            {
              <Switch>
                <Route exact path="/">
                  <Project className={classes.projectStyle} resetProjects={resetProjects} />
                </Route>
                <Route exact path="/run">
                  <RunPage />
                </Route>
                <Route exact path="/datasources">
                  <DataManagement />
                </Route>
                <Route exact path="/settings">
                  <Settings />
                </Route>
                <Route exact path="/mapper">
                  <DemoMapper />
                </Route>
                <Route exact path="/newmapper">
                  <Mapper />
                </Route>
                <Route exact path="/upload">
                  <Upload />
                </Route>
                <Route path="/fileupload">
                  <FileManagment />
                </Route>
                {userCompany !== "platform-anonym" && <Route path="/firstlogin">
                  <NewUserDemo />
                </Route>
                }
                <Route path="/sample-info">
                  <SampleInfo />
                </Route>
                <Route path="/NewProject" >
                    <NewProject />
                </Route>
                <Route exact path="/NewDataSource" >
                    <NewDataSource />
                </Route>
                <Route exact path="/NewCampaign" >
                  <CreateCampaign />
                </Route>
                <Route exact path="/library" >
                    <Library />
                </Route>
                <Route exact path="/settings" >
                  {(userLimit === null || userLimit >= 2) && checkPermission('view_settings_page') && 
                    <Settings />}
                  {(userLimit !== null || userLimit < 2) && !checkPermission('view_settings_page') && 
                    <Project className={classes.projectStyle} resetProjects={resetProjects} />}
                </Route>
                <Route exact path="/book" >
                  <BookAnalysis demo={userCompany === "platform-anonym"}/>
                </Route>
                {userRole === "platform:admin" && <Route exact path="/tableau-demo" >
                  <TableauDemo/>
                </Route>}
                    <Route path="/campaigns">
                      <Campaigns getCampaigns={resetCampaigns}/>
                    </Route>
                    <Route exact path="/prospects">
                      <ProspectsPage/>
                    </Route>
                    <Route exact path="/dashboard">
                      <DashboardPage />
                    </Route>
              </Switch>
            }
          </main>
          :
          <Grid container justifyContent="center" alignContent="center" style={{height: "100vh"}}>
              <CircularProgress style={{color: "#000",width:96,height:96}}/>
          </Grid>
          }
        </>
        }
      </div>
    </>
  )
}


export default App;
