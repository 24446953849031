import { makeStyles } from "@material-ui/styles"

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    p: '30px 50px',
    width: '100%',
    maxWidth: 900,
    borderRadius: "8px",
    boxShadow: "0px 0px 6px rgba(106, 108, 113, 0.1)",
};

export const useStyles = makeStyles(() => ({
    cancelButton: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        background: "#fff",
        color: "#363ED3",
        border: "1px solid #363ED3",
        borderRadius: 10,
        padding: "14px 54px",
        margin: "0px 30px",
        textTransform: "none",
    },
    confirmButton: {
        color: "#FFF",
        background: "#363ED3",
        borderRadius: "10px",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "14px 54px",
        margin: "0px 30px",
        height: 42,
        "&:hover": {
            background: "#363ED3",
            opacity: 0.85
        }
    },
    confirmOptions: {
        paddingTop: 24,
        justifyContent: "center"
    },
    createTitle: {
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
        paddingBottom: 6,
        paddingTop: 12,
        textTransform: "capitalize",
        color: "#1B1C1E"
    },
    contributionContainer: {
        borderRadius: "6px",
        border: "1px solid #D9E1EE",
        paddingLeft: 16,
        paddingBottom: 16
    },
    formTitle: {
        fontFamily: "Strawford",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "22px"
    },
    smallText: {
        fontSize: 12
    },
    codeBox: {
        width: '100%',
        marginBottom: 10
    }
}))