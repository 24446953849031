import React, { useEffect, useRef } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {basicButtonStyles} from "../../Components/Demo/Header/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserCompany, setColumns } from "../../AppSlice";
import BookAnalysisService from "../BookAnalysis/BookAnalysisService";
import { resetProducts, selectDefaultProspectsColumn, selectHeaders, selectIds, setDefaultProspectsColumns, setProductsIsLoading, setProjectIds, setProjectLabels, setProspectsRuns, setRunsMeta } from "../BookAnalysis/BookAnalysisSlice";
import ProspectsAE from "../BookAnalysis/Prospects/ProspectsAE";
import { useState } from "react";
import UnregisteredPopup from "../../Components/Popups/UnregisteredPopup/UnregisteredPopup";
import HeaderProspects from "../../Components/Headers/HeaderProspects";
import appApiService from "../../AppService";
import { defaultColumnsAnnuities, defaultColumnsLife } from "../../global/constants";
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer";

const useStyles = makeStyles(() => ({
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        width: 235,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Strawford!important",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        }
    },
    tabActive: {
        color: '#363ED3',
        height: 39,
        position: 'relative',
        bottom: -1
    },
    portfolioAnalysis: {
        paddingRight: 24
    },
    portfolioAnalysisTitle: {
        fontFamily: "Strawford!important",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "24px",
        paddingBottom: 12
    },
    portfolioAnalysisText: {
        fontFamily: "Roboto!important",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px"
    },
    portfolioAnalysisValue: {
        fontFamily: "Lato!important",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "17px",
        color: '#232033'
    },
    predictionContainer: {
        borderColor: "#0BCB47",
        maxWidth: 411,    
        "& path": {
            fill: '#0BCB47',
            stroke: '#0BCB47'
        },
        "@media (max-width:1310px)": {
            maxWidth: 347  
        }
    },
    addDataCotainer: {
        display: "flex",
        maxWidth: 178, 
        "@media (max-width:1380px)": {
            maxWidth: 120  
        }
    },
    demoText: {
        fontFamily: "Roboto!important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "20px",
        color: '#515151', 
        paddingBottom: 34,       
        "& span": {
            color: '#363ED3', 
            textDecoration: "underline",
            cursor: "pointer", 
            fontWeight: 500
        }
        
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 25,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px"
    },
    tableHeaderTitle: {        
      fontFamily: "Strawford",
      fontSize: 24,
      fontWeight: 400,
      lineHeight: "24px",
      color: "#232033"
    },
    header: {        
      paddingBottom: 16
    },

}));

export const uiButtonStyles = {
    ...basicButtonStyles,
    width: 'auto',
    padding: '14px 34px',
    maxWidth: '240px',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: '#D8D8D8'
    },
  };

const ApiService = new BookAnalysisService()
const AppService = new appApiService()

const ProspectsPage = ({ demo = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const headersData = useSelector(selectHeaders)
    const projectIds = useSelector(selectIds)
    const user = useSelector(selectUserCompany)
    const defaultColumns = useSelector(selectDefaultProspectsColumn)
    const rootRef = useRef(null)
    
    const [ openRegisterDialog, setOpenRegisterDialog ] = useState(false)
    const [ isDemo, setIsDemo ] = useState(false)

    const projectType = (
        (headersData?.ui_runs?.length > 0 && headersData?.ui_runs?.find(({ config }) => config.product_type?.toLowerCase() === "annuities") !== undefined)
        || (headersData?.lapse_runs?.length > 0 && headersData?.lapse_runs?.find(({ config }) => config.product_type?.toLowerCase() === "annuities") !== undefined)
        ? "annuities" : "regular"
    )

    const isAnnuities = localStorage.getItem("accountType") === "annuities"

      
    const registrationRedirect = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("refresh")
        localStorage.removeItem("userName")
        history.push("/registration")
      }

    useEffect(() => {
            dispatch(setProductsIsLoading(true))
            AppService._getRuns(
                localStorage.getItem("companyID"),
                (res) => {
                    if (res.data?.count !== 0) {
                        const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                        const tmp = {}
                        if (columnsConfig?.length > 0) {
                            Object.assign(tmp, ...Object.keys(defaultColumns)
                                .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                            dispatch(setColumns(
                                tmp
                            ))
                        } else {
                            Object.assign(tmp, ...Object.keys(defaultColumns)
                                .map((key) => ({[key]: defaultColumns[key]})))
                            dispatch(setColumns(
                                tmp
                            ))
                        }
                        const meta = []
                        const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                            meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "",})
                            return project_id
                        })
                        if (!projectIds?.length) {
                            dispatch(setProjectIds(ids))
                            dispatch(setProjectLabels(meta))
                            dispatch(setRunsMeta(res.data))
    
                        }
                        dispatch(setProductsIsLoading(false))
    
                    } else {
                        AppService._getDemoRunsData((res) => {
                            history.push("/prospects?demo")
                            const meta = []         
                            const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                                meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "",})
                                return project_id
                            })
                            if (!projectIds?.length) {
                                dispatch(setProjectIds(ids))
                                dispatch(setProjectLabels(meta))
    
                            }
                            
                            const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                            const tmp = {}
                            if (columnsConfig?.length > 0) {
                                Object.assign(tmp, ...Object.keys(defaultColumns)
                                    .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                                dispatch(setColumns(
                                    tmp
                                ))
                            } else {
                                Object.assign(tmp, ...Object.keys(defaultColumns)
                                    .map((key) => ({[key]: defaultColumns[key]})))
                                dispatch(setColumns(
                                    tmp
                                ))
                            }
                            dispatch(setProductsIsLoading(false))
                        },
                        (error) => { console.log(error) })
                    }
                }, 
            (error) => { console.log(error); dispatch(setProductsIsLoading(false)); }
            )
    }, [dispatch, defaultColumns])

    useEffect(() => {
        if (projectIds?.length) {
            dispatch(setProductsIsLoading(true))
            ApiService._getProductsByIDs(history.location.search?.includes("demo") || user === "platform-anonym", projectIds, (response) => {
                dispatch(setProspectsRuns(response.data))
                dispatch(setProductsIsLoading(false))
            }, (error) => { console.log(error); dispatch(setProductsIsLoading(false)); })            
        }
    }, [dispatch, projectIds])

    return (
        <Grid container direction="column" ref={rootRef}>            

            <HeaderContainer title="Prospects">
                <HeaderProspects data={headersData} titles={["Projects", "Prediction Start Date", "Prospects", `${isAnnuities ? "AUM Upside" : "Premium Upside"}`]}/>
            </HeaderContainer>

            <Grid className={classes.main} style={{ maxWidth: window.innerWidth-143 }}>            
                <ProspectsAE handleLogout={registrationRedirect} projectType={projectType} mode="page" annuities={isAnnuities}/>
            </Grid>
            
            <UnregisteredPopup open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}/>

        </Grid>
    )
}

export default ProspectsPage