import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import FormInput from "../FormInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import InformationPopup from "../../Popups/InformationPopup/InformationPopup";

const useStyles = makeStyles(() => ({
    inputContainer:{
        maxWidth: 248
    },
    buttonContainer:{
        textAlign: "center",
        paddingTop: 15,
        "@media (min-width:1440px)": {
            paddingTop: 25
        }
    },
    buttonBlock:{
        background: '#0BCB47',
        width: 248,
        height: 55,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: 'capitalize',
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif !important",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
          backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        },
    },
    footerText: {
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 300,
        lineHeight: "120%",
        textAlign: "center",
        color: "#312E40",
        paddingTop: 32,
        "& a": {
            fontWeight: 500,
            color: "#000000"
        }
    },
    loginInputLabel: {
        color: "#312E40",
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "16px",
        paddingTop: 8,
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        },
        paddingTop: 34
    }
}));

const SendCodeForm = ({ onSend = () => {},  onError = () => {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState("")

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')
    const formRef = useRef(null)
     
    const sendCodeSuccessCallback = () => {
        setShowAlert(false)
        setAlertMessage("")
    } 
         
    const sendCodeErrorCallback = (err) => {
        setShowAlert(true)
        setAlertSeverity('error')
        setAlertTitle('Error!')
        setAlertMessage(err?.response?.status === 404 ? "No user with such email!" : err?.response?.data?.error)
    } 

    function handleSendCode() {
        onSend(sendCodeSuccessCallback, sendCodeErrorCallback, username)
        
    } 

    useEffect(() => {
        setUsername(history?.location?.search ? history.location.search?.replace("?", "") : "")
    }, [history.location.search])

    return (
        <Grid container direction="column" justifyContent="center">
            <ValidatorForm ref={formRef} onSubmit={handleSendCode}>
                <Grid container direction="column">
                    <Grid container justifyContent="center">
                        <Grid container direction="column" className={classes.inputContainer}>
                            <Grid item>
                                <FormInput
                                    label="Insert Your Email"
                                    type="email"
                                    name="email"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['this field is required', 'Please enter a valid email address']}
                                    required
                                    data-testid="emailInput"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" direction="column">
                        <Grid item className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.buttonBlock}
                                data-testid="loginButton"
                                disabled={false}
                                >
                                Send Code
                            </Button>
                        </Grid>
                        <Grid item justifyContent="center">
                            <Typography className={classes.footerText}>
                                For assistance or inquiries, please email us at <a href="mailto:support@atidot.com">support@atidot.com</a>
                            </Typography>
                        </Grid>

                    </Grid>


                <InformationPopup
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    title={alertTitle}
                    message={alertMessage}
                    error={alertSeverity === "error"}
                    />
                </Grid>
            </ValidatorForm>
        </Grid>    
    )
}

export default SendCodeForm