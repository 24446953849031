import { Button, Grid, makeStyles, styled, Typography } from "@material-ui/core";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Alert, AlertTitle } from "@material-ui/lab";
import FormInput from "../../Components/Forms/FormInput";
import { useHistory } from "react-router-dom";
//import Reaptcha from "reaptcha";
import axios from "axios";
import { amplitudeService } from "../../global/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectUserType, setEmail, setPass, setUserType } from "../Login/LoginSlice";
import LoginContainer from "../../Components/LoginContainer/LoginContainer";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaidIcon from '@mui/icons-material/Paid';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const TypeButton = styled(ToggleButton)({
    width: "50%",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white!important",
      backgroundColor: '#363ED3!important'
    }
})

const useStyles = makeStyles(() => ({
    backgroundImage: {
        position: "absolute",
        zIndex: 0,
    },
    buttonContainer:{
        textAlign: "center",
        paddingBottom: 15
    },
    buttonBlock:{
        background: '#0BCB47',
        width: 465,
        height: 55,
        borderRadius: 10,
        boxShadow: "none",
        textTransform: 'capitalize',
        textAlign: "center",
        fontFamily: "'Roboto', sans-serif !important",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        '&:hover': {
            opacity: '0.85',
            backgroundColor: '#0BCB47',
            boxShadow: "none",
        },
        '&:active': {
          backgroundColor: '#239948'
        },
        '&.Mui-disabled': {
          backgroundColor: '#c4c4c4e5',
          color: '#FFFFFF'
        }
    },
    signInRegister:{
        color: '#312E40',
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        '& span': {
            color: '#363ED3',
            cursor: "pointer"
        }
    },
    typeContainer:{
        paddingTop: 12,
        '& .MuiToggleButtonGroup-root': {
            width: 465
        },
        '& p': {
            margin: 0,
            marginLeft: 5,
            lineHeight: "12px"
        }
    },
    termsContainer:{
        textAlign: 'center',
        color: '#232033',
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        '& a': {
            color: '#363ED3',
            cursor: "pointer",
            textDecoration: "none"
        }
    },
    signupForm: {
        width: "100%",
        maxWidth: 900,
        zIndex: 1
    },
    registrationWrapper: {
        width: 465
    },
    registrationTitle: {
        color: "#363ED3",
        fontFamily: "'Strawford', sans-serif !important",
        fontSize: 24,
        fontWeight: 400,
        paddingTop: 20,
    },
    captchaContainer: {
        paddingTop: 8,
        "& html body .rc-anchor": {
            width: "99%"
        },
        "& html body .rc-anchor-logo-portrait": {
            margin: "10px 0 0 93px"
        },
    },
    passwordTipText: {
        color: "#312E40",
        fontFamily: "'Roboto', sans-serif !important",
        fontSize: 12,
        fontWeight: 300,
        lineHeight: "150%",
        paddingTop: 4,
        "& span": {
            color: "#000000",
            fontWeight: 700
        }
    },
    signupControlsContainer: {
        paddingTop: 20,
    },
    alert: {
        '& .MuiAlert-outlinedError': {
            color: "#d32f2f",
            border: "none",
            padding: 0,
            justifyContent: "center"
        },
        paddingTop: 34
    },
    inputContainer: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: 5
    }
}));

const RegistrationPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const date = new Date();
    const [username, setUsername] = React.useState("")
    const [name, setName] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [company, setCompany] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [disabled, setDisabled] = React.useState(false)
    const type = useSelector(selectUserType)
    const formRef = React.useRef();
    const captchaRef = React.useRef(null);

    const [showAlert, setShowAlert] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState('')
    const [alertMessage, setAlertMessage] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('')
    //const isBypassCaptcha = ['localhost', 'dev'].some(cond => window.location.host.includes(cond))
    //const [recaptchaToken, setRecaptchaToken] = React.useState(isBypassCaptcha ? 'WXkvSjqchv' : '')

    const handleTypeChange = (e, value) => {
        setUsername("")
        setPassword("")
        dispatch(setUserType(value))
    }

    // const handleVerifyCaptcha = () => {
    //     if (isBypassCaptcha) return true

    //     captchaRef.current.getResponse()
    //         .then((response) => {
    //             setRecaptchaToken(response)
    //         })
    // }

    // const resetCaptcha = () => {
    //     if (isBypassCaptcha) return

    //     if(recaptchaToken) {
    //         captchaRef.current.reset()
    //         setRecaptchaToken('')
    //     }
    // }

    const goToLogin = () => {
        history.push("/login")
    }

    function handleSubmit(){
        setDisabled(true)
        setShowAlert(false)
        axios.put(process.env.REACT_APP_AUTH_API_URL + "/v1/users/companies", {
            name: type !== "broker" ?  company : crypto.randomUUID().split("-")[0],
            account_id: 6
        })
            .then((response) => {
                const id = response.data["company"]["id"]
                const payload = {
                    email: username,
                    name: type !== "broker" ? name : "full name",
                    password: password,
                    phone: type !== "broker" ? parseInt(phone) : 100000000000,
                    title,
                    company_id: id,
                    country_code: 'US',
                    role_ids: [4]
                }
                id && axios.post(
                    process.env.REACT_APP_AUTH_API_URL + "/v1/auth/register",
                    payload
                ).then((res)=>{
                    delete payload['password']
                    amplitudeService._throwEvent("REGISTRATION", payload)
                    setShowAlert(false)
                    setDisabled(false)
                    dispatch(setEmail(username))
                    dispatch(setPass(password))
                    history.push("/confirmaccount?time="+date.getTime())
                }).catch((error)=>{
                    setAlertTitle("Error!");
                    setAlertSeverity("error")
                    setAlertMessage(error.response.data.error)
                    setShowAlert(true)
                    setDisabled(false)
                })
            })
            .catch((error)=>{
                setAlertTitle("Error invalid company name!");
                setAlertSeverity("error")
                setAlertMessage("Please enter existing company")
                setShowAlert(true)
                setDisabled(false)
            })
    }


    return (
        <LoginContainer> 
            <Grid container justifyContent="center" alignItems="center" className={classes.signupForm}>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <Typography className={classes.signInRegister}>
                                    Already have an account? <span onClick={goToLogin}>Log In</span>
                                </Typography>
                            </Grid>
                            <Grid item className={classes.typeContainer}>
                                <ToggleButtonGroup
                                value={type}
                                exclusive
                                onChange={handleTypeChange}
                                aria-label="Platform"
                                >
                                <TypeButton value="broker"><AssessmentIcon/> <p>For Broker</p></TypeButton>
                                <TypeButton value="carriers"><PaidIcon/> <p>For Carriers</p></TypeButton>
                                </ToggleButtonGroup>
                            </Grid>                                       
                            <Grid container direction="column" className={classes.registrationWrapper}>
                               
                               <Typography className={classes.registrationTitle}>
                                   Account Information
                               </Typography>
                               <Grid item className={classes.inputContainer}>
                                   <FormInput
                                       label="E-mail"
                                       type="email"
                                       name="email"
                                       value={username}
                                       onChange={(event) => setUsername(event.target.value)}
                                       validators={['required', 'isEmail']}
                                       errorMessages={['this field is required', 'Please enter a valid email address']}
                                       required
                                       autoFocus
                                       data-testid="emailInput"/>
                               </Grid>
                               <Grid item className={classes.inputContainer}>
                                   <FormInput
                                       label="Password"
                                       showText={false}
                                       name="password"
                                       value={password}
                                       onChange={(event) => setPassword(event.target.value)}
                                       validators={['required', 'matchRegexp:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*[!@#$%^&*?():;}{~`]).{8,}']}
                                       errorMessages={['this field is required', 'Must be 8 or more characters, consist only numbers and letters and contain both upper and lower case letters']}
                                       required
                                       data-testid="passwordInput"/>
                               </Grid>
                                {type !== "broker" && <Grid item className={classes.inputContainer}>
                                    <FormInput
                                        label="Full name"
                                        type="fname"
                                        name="fname"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                        validators={['required', 'matchRegexp:^[a-zA-Z ,.]{2,120}$']}
                                        errorMessages={['this field is required', 'Please enter a valid name']}
                                        required
                                        data-testid="fnameInput"/>
                                </Grid>}
                                <Grid item className={classes.inputContainer}>
                                        {type !== "broker" && <FormInput
                                        label="Phone number"
                                        type="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={(event) => setPhone(event.target.value)}
                                        validators={['matchRegexp:^[0-9]{11,12}$']}
                                        errorMessages={['Please enter a valid phone number with country code']}
                                        data-testid="phoneInput"/>}
                                </Grid>
                                <Grid item className={classes.inputContainer} style={{ display: "none"}}>
                                    <FormInput
                                        label="Title"
                                        type="title"
                                        name="title"
                                        value={title}
                                        onChange={(event) => setTitle(event.target.value)}
                                        optional
                                        validators={['matchRegexp:^[a-zA-Z].{2,}']}
                                        errorMessages={['Please enter a valid title']}
                                        data-testid="titleInput"/>
                                </Grid>
                                <Grid item className={classes.inputContainer}>
                                    {type !== "broker" && <FormInput
                                        label="Company Name"
                                        type="company"
                                        name="company"
                                        value={company}
                                        onChange={(event) => setCompany(event.target.value)}
                                        validators={['required', 'matchRegexp:^[a-zA-Z0-9].{1,}']}
                                        errorMessages={['this field is required', 'Please enter a valid company name']}
                                        required
                                        data-testid="companyInput"/>}
                                </Grid>
                               {/*<Grid item className={classes.captchaContainer}>
                                   <Reaptcha
                                       sitekey={process.env.REACT_APP_SITE_KEY}
                                       onExpire={resetCaptcha}
                                       ref={captchaRef}
                                       onVerify={handleVerifyCaptcha}/> 
                               </Grid>*/}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" direction="column" className={classes.signupControlsContainer}>
                            <Grid item className={classes.buttonContainer}>
                                <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.buttonBlock}
                                data-testid="loginButton"
                                disabled={disabled}
                                >
                                    Create account
                                </Button>
                            </Grid>
                            <Grid item justifyContent="center">
                                <Typography className={classes.termsContainer}>
                                    By clicking create account you agree to our <a href="https://www.atidot.com/privacy-notice/" rel="noreferrer" target="_blank">Terms And Conditions</a>
                                </Typography>
                            </Grid>
                        </Grid>

                        {showAlert && <Grid item justifyContent="center" className={classes.alert}>
                            
                                <Alert variant="outlined" severity={alertSeverity}>
                                <AlertTitle>{alertTitle}</AlertTitle>
                                {alertMessage}
                                </Alert>
                        </Grid>}
                </ValidatorForm>
            </Grid>
        </LoginContainer>
    )
}

export default RegistrationPage